.m__english__license-center
  a.telNumber
    +mediaPc()
      pointer-events none
      color inherit
      text-decoration none
  .m__english__title
    margin-top 220px
    margin-bottom 68px
    +mediaSp()
      margin 30px 0
    h2
      font-size 4rem
      text-align center
      +mediaSp()
        font-size 2rem
  h3
    color font-green02
    font-size 3.2rem
    padding 0 20px
    border-bottom 2px solid #ccc
    +mediaSp()
      font-size 2rem
      margin-left -10px
      margin-right -10px
      padding 0 10px
  .m__centers
    font-size 1.6rem
    word-break break-word
    margin-bottom 80px
    padding 0 20px
    +mediaSp()
      font-size 1.3rem
      margin-bottom 40px
      padding 0
    h4
      font-size 2.6rem
      margin-top 20px
      +mediaSp()
        font-size 1.6rem
        margin-top 4px
        margin-bottom 4px
      &:nth-child(n+2)
        margin-top 30px
        +mediaSp()
          margin-top 20px
    &:last-child
      margin-bottom 100p
      +mediaSp()
        margin-bottom 50px