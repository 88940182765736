.m__access
  &__map
    position relative
    padding-bottom 150px
    +mediaSp()
      margin 0 -0 50px
      padding-bottom 0
    .l__contents__inner--width
      +mediaPc()
        padding-left 60px
        padding-right 0
        width 1140px
      +mediaSp()
        padding 0
    .map
      text-align center
      padding-left 60px
      position relative
      +mediaSp()
        padding-left 0
      iframe
        width 100%
        height 55vw
        margin-bottom 20px
        +mediaPc()
          pointer-events none
        +mediaSp()
          height 66.7vw
      .scroll-overview
        display none
        position absolute
        left 60px
        top 0
        width calc(100% - 60px)
        height 55vw
        background rgba(bg-black, 0.5)
        p
          position absolute
          left 50%
          top 50%
          transform translate(-50%, -50%)
          font-size 2.6rem
          color #fff
    .information
      padding 30px 60px
      +mediaSp()
        padding 15px 10px 25px
      p.address
        span
          display inline-block
          padding-right 30px
        .tel
          display inline-block
      dl.contents
        margin-top 50px
        +mediaSp()
          margin-top 25px
        dt
          &::before
            content "●"
        dd
          padding-left 1em
          ul.link
            li
              margin-top 10px
              a
                &::before
                  content "＞"
                &.bus
                  &::before
                    display none
                &.newTimeTable
                  &::before
                    display none
                  color #f08066
                  padding-left 10px
                  +mediaSp()
                    display block
              img
                margin-left 5px
    .accordion-wrap
      position relative
      .title
        font-size 2.6rem
        padding 20px 60px
        background bg-light-gray
        cursor pointer
        +mediaSp()
          padding 12px 15px
          font-size 1.3rem
          text-align center
        &::after
          content "＋"
          position absolute
          right 30px
          top 22px
          font-size 2.2rem
          transition .3s
          +mediaSp()
            right 70px
            font-size 1.4rem
            top 11px
          &:hover
            cursor pointer
        &.open
          &::after
            transform rotate(45deg)
      .accordion-content
        background bg-light-gray
        padding 0 60px 40px
        margin 0 0 40px
        display none
        regular()
        +mediaSp()
          margin-bottom 0
          padding 0 10px 30px
        dl
          margin-top 30px
          &:first-of-type
            margin-top 0
          dt
            &::before
              content "●"
    .m__common-btn__arrow
      text-align right
      margin-top 30px
      +mediaSp()
        margin-top 0
        text-align center
        .m__common__flex-arrow
          width 100%
          font-size 1.6rem
    &--futako
      .m__access__school-name
        background bg-futako
        img
          height 301px
      .information
        dl.contents
          dt
            &::before
              color bg-futako
      .accordion-wrap
        .accordion-content
          dl
            dt
              &::before
                color bg-futako
    &--seijo
      .m__access__school-name
        background bg-seijo
        img
          height 116px
      .information
        dl.contents
          dt
            &::before
              color bg-seijo
      .accordion-wrap
        .accordion-content
          dl
            dt
              &::before
                color bg-seijo
    &--shakujii
      .m__access__school-name
        background bg-shakujii
        img
          height 188px
      .information
        dl.contents
          dt
            &::before
              color bg-shakujii
      .accordion-wrap
        .accordion-content
          dl
            dt
              &::before
                color bg-shakujii
    &--akitsu
      .m__access__school-name
        background bg-akitsu
        img
          height 132px
      .information
        dl.contents
          dt
            &::before
              color bg-akitsu
      .accordion-wrap
        .accordion-content
          dl
            dt
              &::before
                color bg-akitsu
    &--yokohama
      .m__access__school-name
        background bg-yokohama
        img
          height 174px
      .information
        dl.contents
          dt
            &::before
              color bg-yokohama
      .accordion-wrap
        .accordion-content
          dl
            dt
              &::before
                color bg-yokohama

  &__school-name
    position absolute
    left 0
    top 0
    padding 20px 8px 20px 24px
    width 60px
    height 100%
    .school-name, .school-alphabet
      display none
      alphabet()
      bold()
    +mediaSp()
      position static
      left 0
      width 100%
      padding 15px 20px
      .school-name
        display inline-block
        font-size 1.8rem
        padding-right 10px
      .school-alphabet
        display inline-block
        font-size 1.6rem
    img
      width 28px
      +mediaSp()
        display none
    &.fixed
      img
        position fixed
        top 80px
      &.end
        img
          position absolute
          top auto
          bottom 20px

  &__description
    font-size 3.2rem
    line-height 1.4
    span
      font-size 2.6rem
    +mediaSp()
      font-size 1.6rem
      span
        font-size 1.3rem
  &__general-map
    margin-top 20px
    position relative
    .js-map-zoom
      display none
    +mediaSp()
      margin 5px -10px 0
      .js-map-zoom
        display block
        .plus, .minus
          position absolute
          right 10px
          width 35px
          height 35px
          background rgba(bg-black, 0.8)
          z-index 3
          border-radius 4px
          &.disabled
            opacity .5
            pointer-events none
        .plus
          bottom 55px
          &::before
            content ""
            display block
            width 22px
            height 2px
            background #fff
            position absolute
            left 50%
            top 50%
            transform translate(-50%, -50%)
          &::after
            content ""
            display block
            width 2px
            height 22px
            background #fff
            position absolute
            left 50%
            top 50%
            transform translate(-50%, -50%)
        .minus
          bottom 15px
          &::before
            content ""
            display block
            width 22px
            height 2px
            background #fff
            position absolute
            left 50%
            top 50%
            transform translate(-50%, -50%)
    .top
      background bg-black
      padding 15px 75px
      +mediaSp()
        padding 15px 25px
      p
        color #fff
        font-size 2.6rem
        text-align center
        +mediaSp()
          font-size 1.6rem
      ul
        display flex
        justify-content space-between
        align-items center
        flex-wrap wrap
        li
          font-size 2.4rem
          +mediaSp()
            font-size 1.9rem
            &:nth-child(even)
              width 100px
            &:nth-child(odd)
              min-width 180px
          &.futako
            color bg-futako
          &.seijo
            color bg-seijo
          &.shakujii
            color bg-shakujii
          &.akitsu
            color bg-akitsu
          &.yokohama
            color bg-yokohama
    .map-wrap
      position relative
      +mediaSp()
        padding 0
        overflow auto
        width 100vw
        height 120vw
        background bg-light-gray
        .map
          width 100%
          height 100%
          background none
          transition width .3s, height .3s
          img
            +mediaPc()
              display none
            height 120vw
            transform-origin left top
            transition transform .5s
          &.scale-2
            width 125%
            height 125%
            img, ul
              transform scale(1.25)
          &.scale-3
            width 150%
            height 150%
            img, ul
              transform scale(1.5)
          &.scale-4
            width 175%
            height 175%
            img, ul
              transform scale(1.75)
          &.scale-5
            width 200%
            height 200%
            img, ul
              transform scale(2)
      width 100%
      height 760px
      padding 25px 23px
      background bg-light-gray
      position relative
      img
        +mediaPc()
          max-width 100%
      ul
        width 100%
        height 100%
        +mediaSp()
          position absolute
          left 0
          top 0
          transform-origin left top
          transition transform .5s
        li
          position absolute
          font-size 2rem
          z-index 2
          alphabet()
          +mediaSp()
            font-size 1.1rem
          a
            color font-black
            text-shadow 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff
            &:hover
              color font-green
          &::after
            content ""
            display inline-block
            width 22px
            height 32px
            vertical-align -5px
            margin-left 1px
            +mediaSp()
              width 15px
              height 22px
              vertical-align -3px
          &.futako
            left 358px
            top 384px
            &::after
              background url(image_path+'/access/pin_futako.svg') center center no-repeat
              background-size 22px 32px
            +mediaSp()
              left calc(86vw - 80px)
              top 61vw
              &::after
                background-size 15px 22px
          &.seijo
            left 407px
            top 342px
            &::after
              background url(image_path+'/access/pin_seijo.svg') center center no-repeat
              background-size 22px 32px
            +mediaSp()
              left calc(85vw - 60px)
              top 55vw
              &::after
                background-size 15px 22px
          &.shakujii
            left 335px
            top 105px
            &::after
              background url(image_path+'/access/pin_shakujii.svg') center center no-repeat
              background-size 22px 32px
            +mediaSp()
              left calc(74vw - 60px)
              top 16vw
              &::after
                background-size 15px 22px
          &.akitsu
            left 75px
            top 170px
            &::after
              background url(image_path+'/access/pin_akitsu.svg') center center no-repeat
              background-size 22px 32px
            +mediaSp()
              left calc(19vw - 30px)
              top 25.6vw
              &::after
                background-size 15px 22px
          &.yokohama
            left 447px
            bottom 154px
            &::after
              background url(image_path+'/access/pin_yokohama.svg') center center no-repeat
              background-size 22px 32px
            +mediaSp()
              left calc(97vw - 70px)
              bottom 21.3vw
              width 70px
              &::after
                background-size 15px 22px

  &__bus-navi
    text-align center
    margin 100px 0 130px
    +mediaSp()
      margin 30px 0
    &--mb60-30
      margin-bottom 60px
      +mediaSp()
        margin-bottom 25px
    p
      font-size 2.6rem
      line-height 1.4
      margin-bottom 10px
      +mediaSp()
        font-size 1.4rem
        padding-bottom 0
    a
      padding-left 55px
      position relative
      display inline-block
      +mediaSp()
        padding-left 35px
      &::before
        content ""
        display inline-block
        width 45px
        height 1px
        background bg-green
        position absolute
        left 0
        top 50%
        margin-top -2px
        transition .3s
        +mediaSp()
          width 22px
      &::after
        content ""
        width 15px
        height 1px
        background bg-green
        position absolute
        left 33px
        top 18px
        transform rotate(45deg)
        transition .3s
        +mediaSp()
          width 10px
          left 13px
          top 12px
      svg
        width 342px
        height 42px
        fill bg-green
        transition .3s
        +mediaSp()
          width 173px
          height 27px
      +mediaPc()
        &:hover
          &::before, &::after
            background bg-light-green
          svg
            fill bg-light-green
      +mediaSp()
        &:focus
          &::before, &::after
            background bg-light-green
          svg
            fill bg-light-green
  &__link-list
    width 500px
    margin 0 auto 100px
    +mediaSp()
      width 100%
      margin-bottom 15px
      text-align center
    li
      display inline-block
      margin-right 30px
      +mediaSp()
        margin 5px 15px 5px 0
      &::before
        content "▼"
        font-size 1.2rem
        vertical-align 2px
        color font-green
        padding-right 5px
        display inline-block
        transition .5s
        +mediaSp()
          font-size 1rem
      &:hover
        &::before
          color font-light-green
      a
        display inline-block
        alphabet()
        font-size 2.4rem
        +mediaSp()
          font-size 1.4rem

  &__notice-box
    border 2px solid border-red
    width 680px
    padding 40px 40px
    margin 60px auto 0
    display flex
    justify-content center
    align-items center
    color font-red
    ul
      li
        padding-left 1em
        text-indent -1em
    +mediaSp()
      width 100%
      padding 10px 15px
      margin-top 30px
    &.font-large
      p
        font-size 1.6rem
        +mediaSp()
          font-size 1.4rem
    &.access
      text-align center
      margin 100px auto -50px
      +mediaSp()
        margin 30px auto 0
    &.navi-list
      text-align center
      margin 50px auto -40px
      +mediaSp()
        margin 30px auto 0
  &__notice-text-area
    margin 40px 40px
    +mediaSp()
      margin 30px 0
    .text
      font-size 2.6rem
      color font-red
      text-align center
      +mediaSp()
        font-size 1.4rem
  &__course-title
    font-size 2.6rem
    margin 60px 0 20px
    &::before
      content "■"
      font-size 2.6rem
      display inline-block
      vertical-align baseline
      color main-color
    +mediaSp()
      font-size 1.5rem
      margin 30px 0 10px
      &::before
        font-size 1.5rem
    .txtNotice
      font-size 2rem
      font-weight 500
      text-indent 35px
      color font-red
      a
        color font-red
      +mediaSp()
        font-size 1.5rem
        text-indent 0
  &__bus-map
    + .m__access__bus-map
      margin-top 30px
      +mediaSp()
        margin-top 15px
    .accordion-wrap
      background bg-light-gray
      position relative
      +mediaSp()
        margin 0 -10px
      .title
        padding 20px 60px
        background main-color
        font-size 2.6rem
        +mediaSp()
          padding 12px 15px
          font-size 1.5rem
        span
          margin-left 10px
          color font-vivid-red
          font-size 2rem
          vertical-align middle
          +mediaSp()
            font-size 1.3rem
            margin-left 0
            display block
        &::after
          content "+"
          position absolute
          right 30px
          top 22px
          font-size 2.2rem
          transition .3s
          +mediaSp()
            right 20px
            font-size 1.4rem
            top 13px
        &:hover
          cursor pointer
        &.open
          &::after
            transform rotate(45deg)
    .acccodion-close
      .title::after
        display none
    .accordion-content
      display none
      padding-bottom 60px
      +mediaSp()
        padding-bottom 30px
      .map
        text-align center
        position relative
        +mediaSp()
          text-align left
          p
            padding 0 10px
        p
          margin-top 10px
          &.emp
            font-size 1.8rem
            color font-red
            +mediaSp()
              font-size 1.6rem
          .red
            color #ff0000
        iframe
          width 100%
          height 600px
          +mediaPc()
            pointer-events none
          +mediaSp()
            height 80vw
        .scroll-overview
          display none
          position absolute
          left 0
          top 0
          width 100%
          height 600px
          background rgba(bg-black, 0.5)
          p
            position absolute
            left 50%
            top 50%
            width 100%
            transform translate(-50%, -50%)
            font-size 2.6rem
            color #fff
  &__bus-table
    +mediaSp()
      padding 0 10px
    table
      width 860px
      margin 30px auto
      background #fff
      +mediaSp()
        width 100%
      tr
        th, td
          padding 5px 20px
          border 1px solid border-black
          vertical-align middle
          +mediaSp()
            padding 5px 10px
            width calc((100vw - 17vw - 20px) / 2)
          span.red
            color font-vivid-red
          span.attention
            color font-vivid-red
            width auto
            display block
    p.notice
      margin 20px 0
      text-align center
    &--type1
      table
        tr
          th, td
            width 345px
            &:nth-child(2)
              width 170px
              background #E4E1E1
              text-align center
              +mediaSp()
                width 17vw
          th
            background rgba(main-color-light, 0.5)
            text-align center
            &:nth-child(2)
              background rgba(main-color-light, 0.5)
          td
            background #fff
            text-align center
            span
              display inline-block
              width 45px
              &.red
                color font-vivid-red
              +mediaSp()
                width 11vw
                text-align center
              &.aster
                font-size 10px
                width 17px
                color font-vivid-red
                margin-left -4px
                &--bracket
                  margin-left 0
                  width 25px
              &.aster-pre
                width 31px
                text-align right
                &--bracket
                  width 23px
              &.aster-after
                width 42px
    &--type2
      table
        tr
          th
            background #E4E1E1
            width 100px
            medium()
            +mediaSp()
              width 40px
          td
            background #fff
            text-align center
            span
              display inline-block
              width 45px
              &.aster
                font-size 10px
                width 25px
                color font-vivid-red
                margin-left -2px
                letter-spacing -0.1rem
                text-align left
                &--bracket
                  width 40px
                +mediaSp()
                  margin-left 0px
                &--narrow
                  margin-left -8px
                  margin-right -16px
                  +mediaSp()
                    margin-left 0
              &.aster-pre
                margin-left 1.7em
                &--bracket
                  width 23px
                +mediaSp()
                  width auto
                &--narrow
                  margin-left 0
          &:first-child
            th
              background rgba(main-color-light, 0.5)
              width auto
    .dial
      p
        text-align center
      .notice
        margin-top 10px
        padding 0 110px
        +mediaSp()
          padding 0
        p
          color font-vivid-red
      ul
        margin-top 5px
        +mediaPc()
          display flex
          align-items center
          justify-content center
        +mediaSp()
          text-align center
        li
          margin 0 40px
          +mediaSp()
            margin 0
            + li
              margin-top 5px
          a
            +mediaPc()
              text-decoration none
              color font-black
              pointer-events none

  &__bus-notice
    margin 50px 0
    +mediaSp()
      margin 30px 0
    .emp
      color font-vivid-red
    ul
      li
        padding-left 1em
        text-indent -1em
        span
          color font-vivid-red
          &.blue
            color font-blue
          &.line
            text-decoration underline
        &::before
          content '・'
        a
          +mediaPc()
            color font-black
            text-decoration none
            pointer-events none
      &.dot-none
        li
          padding-left 2em
          &::before
            content none
  &__bus-course-link
    margin-bottom 100px
    +mediaSp()
      margin-bottom 35px
    ul
      width 750px
      margin 0 auto
      padding-top 30px
      +mediaSp()
        width 100%
        margin-top 0
      li
        border-bottom 1px solid border-black
        + li
          margin-top 50px
          +mediaSp()
            margin-top 20px
        span, a
          display block
          padding 15px 0
          position relative
          font-size 1.8rem
          +mediaSp()
            font-size 1.5rem
            padding 5px 50px 5px 0
        span
          &.emp
            display inline
            color font-red
            padding 5px 0 5px 0
        a
          text-decoration none
          arrow()
          +mediaPc()
            &::before
              right 30px
            &::after
              right 27px
            &:hover::before
              margin-top 0
          &.emp
            color font-red
            padding 0
            margin-top -15px
            margin-bottom 15px
            &::before
              content none
            &::after
              content none
            +mediaSp()
              margin-top -9px
              margin-bottom 3px
          &.new-map
            font-size 1.5rem
            padding-top 10px
            color font-red
            +mediaSp()
              font-size 1.3rem
