.m__plan-other
  &__menu__Area
    padding 50px 0 100px
    +mediaSp()
      padding 10px 0 0
    &--foreign
      padding 20px 0px
      +mediaSp()
        padding 5px 0
    &--senior
      width 860px
      display flex
      flex-wrap wrap
      justify-content space-between
      margin 0 auto
      padding 100px 0
      +mediaSp()
        width 100%
        padding 15px 0 20px
        display block
    &--with__notice
      padding 0 0 100px
    &--special-case
      padding 40px 0
      +mediaSp()
        padding 10px 0 0
        border-bottom 1px solid border-light-gray
    .m__menu__list
      padding-top 20px
      +mediaSp()
        padding-top 10px
      dt
        padding 25px 25px 15px
        border-top 1px solid border-light-gray
        span
          display block
        +mediaSp()
          padding 15px 10px 5px
      dd
        font-size 1.6rem
        padding 0 25px 20px
        .indent
          padding-left 15px
        &:last-child
          border-bottom 1px solid border-light-gray
          +mediaSp()
            border-bottom none
            &.border
              border-bottom 1px solid border-light-gray
        .txtEmp
          color font-red
        +mediaSp()
          padding 0 10px 15px
          font-size 1.3rem
      &--no__borderTop
        padding-top 10px
        margin-bottom 60px
        +mediaSp()
          padding-top 0
          margin-bottom 15px
        dt
          &:first-child
            border-top none
      &--border__bottom
        +mediaSp()
          &:last-child
            border-bottom 1px solid border-light-gray
    &--elder
      padding-bottom 20px
      .m__common-heading__check
        font-size 3rem
        +mediaSp()
          font-size 2.3rem
      .m__menu__list
        dt
          padding 40px 25px 25px
          +mediaSp()
            padding-bottom 15px
        dd
          font-size 2.5rem
          padding-bottom 30px
          +mediaSp()
            font-size 1.9rem
  &__benefits__Area
    width 550px
    margin 0 auto
    padding 40px 0 5px
    +mediaSp()
      width 100%
      padding 20px 0 20px
    ul
      li
        font-size 2.6rem
        padding-bottom 40px
        +mediaSp()
          font-size 1.5rem
          padding-bottom 20px
        &:last-child
          +mediaSp()
            padding-bottom 0
        span
          font-size 1.6rem
          vertical-align baseline
          +mediaSp()
            font-size 1.3rem
          &.blockUnder
            display block
            padding-left 77px
            line-height 0
            &.negMargin
              margin -5px 0 15px
              +mediaSp()
                margin-bottom 0
            +mediaSp()
              padding-left 44px
              line-height 1.2em
        &.car
          &:before
            content ''
            display inline-block
            width 60px
            height 58px
            background-image url(image_path + "/plan/other/icon_car.svg")
            background-size contain
            background-repeat no-repeat
            vertical-align middle
            padding-right 13px
            +mediaSp()
              width 30px
              height 28px
        &.book
          &:before
            content ''
            display inline-block
            width 59px
            height 50px
            background-image url(image_path + "/plan/other/icon_book.svg")
            background-size contain
            background-repeat no-repeat
            vertical-align inherit
            padding-right 14px
            +mediaSp()
              width 30px
              height 25px
        &.cancel
          &:before
            content ''
            display inline-block
            width 48px
            height 54px
            background-image url(image_path + "/plan/other/icon_cancel.svg")
            background-size contain
            background-repeat no-repeat
            vertical-align middle
            padding-left 21px
            margin-left 7px
            +mediaSp()
              width 23px
              height 27px
              padding-left 19px
              margin-left 4px
        &.baby
          &:before
            content ''
            display inline-block
            width 56px
            height 53px
            background-image url(image_path + "/plan/other/icon_baby.svg")
            background-size contain
            background-repeat no-repeat
            vertical-align -webkit-baseline-middle
            margin-left 3px
            padding-right 17px
            +mediaSp()
              width 28px
              height 27px
              margin-left 2px
              padding-right 16px
  &__table__Area
    padding 100px 0 0
    +mediaSp()
      padding 30px 0 0
    .m__plan
      &__tableListArea
        margin 50px auto 100px
        +mediaSp()
          margin 30px auto 25px
        &--plan__other
          table
            tr
              &.headTtl
                th
                  &:nth-child(even)
                    background bg-light-gray
              td
                color font-gray
                &.carType
                  color font-black
                  +mediaSp()
                    padding 5px
                    width 35%
                    font-weight 600
                    &.fontLight
                      font-weight 500
                &:nth-child(even)
                  background bg-light-gray
          ul
            &.txt__sub__table
              padding-top 20px
              font-size 1.6rem
              +mediaSp()
                font-size 1.3rem
              li
                text-indent -1em
                padding-left 1.9em
                +mediaSp()
                  padding-left 1.5em
    &--special-case
      padding 60px 0 20px
      margin-bottom 30px
      +mediaSp()
        padding 20px 0
      .m__plan
        &__tableListArea
          margin 50px auto
          +mediaSp()
            padding 0
            margin 10px 0
  &__link__Area
    padding 50px 0
    +mediaSp()
      padding 30px 0 20px
  &__price__Area
    padding-bottom 100px
    +mediaSp()
      padding 20px 0 30px
    &--sp__pb0
      +mediaSp()
        padding-bottom 0
    &--elder
      +mediaSp()
        padding 0 0 30px
        .text
          padding 0 10px
    &--pb60
      padding-bottom 60px
      +mediaSp()
        padding-bottom 30px
      .text
        font-size 2.2rem
        +mediaSp()
          font-size 1.9rem
          padding 0 10px
    .m__plan-stay
      &__table__Area
        .m__table
          border 3px solid #1a1a1a
          margin-top 40px
          +mediaSp()
            border 1px solid #1a1a1a
            border-right none
            border-left none
            margin-top 10px
          th
            font-weight 500
            padding 5px 0
          td
            text-align center
            padding-left 0
            &.ttlCarType
              width 18%
              padding 5px 0
        &--elder
          .m__table
            th
              font-size 2.8rem
              line-height 4.3rem
              padding 10px 0
              +mediaSp()
                font-size 2rem
                padding 5px 0
                line-height 3rem
              &.txtM
                font-size 2.3rem
                +mediaSp()
                  font-size 1.8rem
            td
              text-align left
              padding 15px
              font-size 2rem
              +mediaSp()
                padding 5px 15px
                font-size 1.8rem
              &.ttlCarType
                width 37%
                text-align center
                font-size 2rem
                +mediaSp()
                  font-weight 600
                  font-size 1.8rem
          .text
            font-size 1.9rem
            padding-top 10px
            +mediaSp()
              font-size 1.7rem
              padding-top 10px
    ul
      padding-top 20px
      +mediaSp()
        padding 20px 5px 30px
      li
        font-size 1.6rem
        text-indent -1em
        padding-left 1em
        +mediaSp()
          font-size 1.3rem
  &__inq__Area
    padding 50px 0 40px
    margin 0 auto
    width 1080px
    border-bottom 1px solid border-light-gray
    +mediaSp()
      width 100%
      padding 30px 0 0
    ul.telList
      display flex
      width 680px
      margin 0 auto
      font-size 1.8rem
      flex-wrap wrap
      +mediaSp()
        width 100%
        display block
      li
        width 50%
        +mediaSp()
          width 100%
          border-bottom 1px solid border-light-gray
          padding 23px 0 12px
        &:first-child
          +mediaSp()
            border-top 1px solid border-light-gray
        dl
          display flex
          flex-wrap wrap
          justify-content center
          +mediaSp()
            font-size 1.3rem
            justify-content flex-end
          dt
            width 110px
            padding-bottom 10px
            +mediaSp()
              width 30%
              font-weight 600
              padding-left 20px
          dd
            &.spNone
              width 160px
            +mediaSp()
              width 50%
      &--center
        justify-content center
  &__list__Area
    padding 50px 0 40px;
    +mediaSp()
      padding 30px 0 10px
    ul
      li
        padding-bottom 20px
        text-indent -1.5rem
        padding-left 1.5rem
