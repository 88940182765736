.m__60th-anniversary

  @-webkit-keyframes buildings_gone {
    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1); }
    100% {
      -webkit-transform: translate3d(0, 140px, 0) scale(0.65); } }
  @-webkit-keyframes sign_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1.8); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(528px, -235px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(528px, -235px, 0) scale(0); } }
  /* 木1 */
  @-webkit-keyframes wood1_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(0.7); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(-282px, -5px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(-282px, -5px, 0) scale(0); } }
  /* 木2 */
  @-webkit-keyframes wood2_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(0.3); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(-89px, 0px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(-89px, 0px, 0) scale(0); } }
  /* 木3 */
  @-webkit-keyframes wood3_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(2); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(-865px, -80px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(-865px, -80px, 0) scale(0); } }
  /* 木4 */
  @-webkit-keyframes wood4_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(2); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(-875px, -78px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(-875px, -78px, 0) scale(0); } }
  /* 木5 */
  @-webkit-keyframes wood5_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(0.7); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(265px, 6px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(265px, 6px, 0) scale(0); } }
  /* 木6 */
  @-webkit-keyframes wood6_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(0.3); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(89px, 12px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(89px, 12px, 0) scale(0); } }
  /* 木7 */
  @-webkit-keyframes wood7_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1.4); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(595px, -83px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(595px, -83px, 0) scale(0); } }
  /* 木8 */
  @-webkit-keyframes wood8_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1.4); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(635px, -83px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(635px, -83px, 0) scale(0); } }
  /* 木9 */
  @-webkit-keyframes wood9_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1.2); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(685px, -33px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(685px, -33px, 0) scale(0); } }
  /* 雑草1 */
  @-webkit-keyframes weed1_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(-277px, -95px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(-277px, -95px, 0) scale(0); } }
  /* 雑草2 */
  @-webkit-keyframes weed2_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(227px, -74px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(227px, -74px, 0) scale(0); } }
  /* 雑草3 */
  @-webkit-keyframes weed3_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(-492px, -95px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(-492px, -95px, 0) scale(0); } }
  /* 雑草4 */
  @-webkit-keyframes weed4_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(567px, -74px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(567px, -74px, 0) scale(0); } }
  /* 雑草5 */
  @-webkit-keyframes weed5_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1.8); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(-727px, -295px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(-727px, -295px, 0) scale(0); } }
  /* 雑草6 */
  @-webkit-keyframes weed6_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1.8); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(687px, -294px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(687px, -294px, 0) scale(0); } }
  /* 石1 */
  @-webkit-keyframes rock1_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1.2); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(417px, -154px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(417px, -154px, 0) scale(0); } }
  /* 石2 */
  @-webkit-keyframes rock2_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(0.7); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(-212px, -52px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(-212px, -52px, 0) scale(0); } }
  /* 石3 */
  @-webkit-keyframes rock3_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1.6); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(617px, -194px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(617px, -194px, 0) scale(0); } }
  /* 石4 */
  @-webkit-keyframes rock4_moving {
    0% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1.4); }
    99% {
      display: block;
      opacity: 1;
      -webkit-transform: translate3d(-602px, -215px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -webkit-transform: translate3d(-602px, -215px, 0) scale(0); } }
  /* 中央赤の車の動き */
  @-webkit-keyframes move_for_title {
    0% {
      -webkit-transform: translate3d(0, 145px, 0) scale(0.95); }
    100% {
      -webkit-transform: translate3d(0, 48px, 0px) scale(1.25); } }
  @-webkit-keyframes car1_fadeout {
    0% {
      -webkit-transform: translate3d(0, 48px, 0px) scale(1.25);
      opacity: 1.0; }
    60% {
      -webkit-transform: translate3d(0, 48px, 0px) scale(1.25);
      opacity: 1.0; }
    100% {
      -webkit-transform: translate3d(0, 48px, 0px) scale(1.25);
      opacity: 0; } }
  @-webkit-keyframes car1_moving {
    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(0.2); }
    100% {
      -webkit-transform: translate3d(0, 145px, 0) scale(0.95); } }
  /* 中央赤の車の動き（上下） */
  @-webkit-keyframes car1_updown {
    0% {
      -webkit-transform: translate3d(0, 0, 0); }
    5% {
      -webkit-transform: translate3d(0, 0, 0); }
    12% {
      -webkit-transform: translate3d(0, -1px, 0); }
    15% {
      -webkit-transform: translate3d(0, -1px, 0); }
    32% {
      -webkit-transform: translate3d(0, 0, 0); }
    100% {
      -webkit-transform: translate3d(0, 0, 0); } }
  /* 左青の車の動き */
  @-webkit-keyframes car2_moving {
    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(0.2); }
    100% {
      -webkit-transform: translate3d(-95px, 85px, 0) scale(0.95); } }
  /* 左青の車の動き（上下） */
  @-webkit-keyframes car2_updown {
    0% {
      -webkit-transform: translate3d(0, 0, 0); }
    10% {
      -webkit-transform: translate3d(0, 0, 0); }
    22% {
      -webkit-transform: translate3d(0, -1px, 0); }
    25% {
      -webkit-transform: translate3d(0, -1px, 0); }
    42% {
      -webkit-transform: translate3d(0, 0, 0); }
    100% {
      -webkit-transform: translate3d(0, 0, 0); } }
  /* 右黄色の車の動き */
  @-webkit-keyframes car3_moving {
    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(0.2); }
    100% {
      -webkit-transform: translate3d(95px, 85px, 0) scale(0.95); } }
  /* 右黄色の車の動き（上下） */
  @-webkit-keyframes car3_updown {
    0% {
      -webkit-transform: translate3d(0, 0, 0); }
    20% {
      -webkit-transform: translate3d(0, 0, 0); }
    32% {
      -webkit-transform: translate3d(0, -1px, 0); }
    35% {
      -webkit-transform: translate3d(0, -1px, 0); }
    52% {
      -webkit-transform: translate3d(0, 0, 0); } }
  @-webkit-keyframes white_back_fadein {
    0% {
      opacity: 0; }
    60% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @-moz-keyframes buildings_gone {
    0% {
      -moz-transform: translate3d(0, 0, 0) scale(1); }
    100% {
      -moz-transform: translate3d(0, 140px, 0) scale(0.65); } }
  @-moz-keyframes sign_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(1.8); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(528px, -235px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(528px, -235px, 0) scale(0); } }
  /* 木1 */
  @-moz-keyframes wood1_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(0.7); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(-282px, -5px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(-282px, -5px, 0) scale(0); } }
  /* 木2 */
  @-moz-keyframes wood2_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(0.3); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(-89px, 0px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(-89px, 0px, 0) scale(0); } }
  /* 木3 */
  @-moz-keyframes wood3_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(2); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(-865px, -80px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(-865px, -80px, 0) scale(0); } }
  /* 木4 */
  @-moz-keyframes wood4_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(2); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(-875px, -78px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(-875px, -78px, 0) scale(0); } }
  /* 木5 */
  @-moz-keyframes wood5_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(0.7); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(265px, 6px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(265px, 6px, 0) scale(0); } }
  /* 木6 */
  @-moz-keyframes wood6_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(0.3); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(89px, 12px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(89px, 12px, 0) scale(0); } }
  /* 木7 */
  @-moz-keyframes wood7_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(1.4); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(595px, -83px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(595px, -83px, 0) scale(0); } }
  /* 木8 */
  @-moz-keyframes wood8_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(1.4); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(635px, -83px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(635px, -83px, 0) scale(0); } }
  /* 木9 */
  @-moz-keyframes wood9_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(1.2); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(685px, -33px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(685px, -33px, 0) scale(0); } }
  /* 雑草1 */
  @-moz-keyframes weed1_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(1); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(-277px, -95px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(-277px, -95px, 0) scale(0); } }
  /* 雑草2 */
  @-moz-keyframes weed2_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(1); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(227px, -74px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(227px, -74px, 0) scale(0); } }
  /* 雑草3 */
  @-moz-keyframes weed3_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(1); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(-492px, -95px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(-492px, -95px, 0) scale(0); } }
  /* 雑草4 */
  @-moz-keyframes weed4_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(1); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(567px, -74px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(567px, -74px, 0) scale(0); } }
  /* 雑草5 */
  @-moz-keyframes weed5_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(1.8); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(-727px, -295px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(-727px, -295px, 0) scale(0); } }
  /* 雑草6 */
  @-moz-keyframes weed6_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(1.8); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(687px, -294px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(687px, -294px, 0) scale(0); } }
  /* 石1 */
  @-moz-keyframes rock1_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(1.2); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(417px, -154px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(417px, -154px, 0) scale(0); } }
  /* 石2 */
  @-moz-keyframes rock2_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(0.7); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(-212px, -52px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(-212px, -52px, 0) scale(0); } }
  /* 石3 */
  @-moz-keyframes rock3_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(1.6); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(617px, -194px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(617px, -194px, 0) scale(0); } }
  /* 石4 */
  @-moz-keyframes rock4_moving {
    0% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0) scale(1.4); }
    99% {
      display: block;
      opacity: 1;
      -moz-transform: translate3d(-602px, -215px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      -moz-transform: translate3d(-602px, -215px, 0) scale(0); } }
  /* 中央赤の車の動き */
  @-moz-keyframes move_for_title {
    0% {
      -moz-transform: translate3d(0, 145px, 0) scale(0.95); }
    100% {
      -moz-transform: translate3d(0, 48px, 0px) scale(1.25); } }
  @-moz-keyframes car1_fadeout {
    0% {
      -moz-transform: translate3d(0, 48px, 0px) scale(1.25);
      opacity: 1.0; }
    60% {
      -moz-transform: translate3d(0, 48px, 0px) scale(1.25);
      opacity: 1.0; }
    100% {
      -moz-transform: translate3d(0, 48px, 0px) scale(1.25);
      opacity: 0; } }
  @-moz-keyframes car1_moving {
    0% {
      -moz-transform: translate3d(0, 0, 0) scale(0.2); }
    100% {
      -moz-transform: translate3d(0, 145px, 0) scale(0.95); } }
  /* 中央赤の車の動き（上下） */
  @-moz-keyframes car1_updown {
    0% {
      -moz-transform: translate3d(0, 0, 0); }
    5% {
      -moz-transform: translate3d(0, 0, 0); }
    12% {
      -moz-transform: translate3d(0, -1px, 0); }
    15% {
      -moz-transform: translate3d(0, -1px, 0); }
    32% {
      -moz-transform: translate3d(0, 0, 0); }
    100% {
      -moz-transform: translate3d(0, 0, 0); } }
  /* 左青の車の動き */
  @-moz-keyframes car2_moving {
    0% {
      -moz-transform: translate3d(0, 0, 0) scale(0.2); }
    100% {
      -moz-transform: translate3d(-95px, 85px, 0) scale(0.95); } }
  /* 左青の車の動き（上下） */
  @-moz-keyframes car2_updown {
    0% {
      -moz-transform: translate3d(0, 0, 0); }
    10% {
      -moz-transform: translate3d(0, 0, 0); }
    22% {
      -moz-transform: translate3d(0, -1px, 0); }
    25% {
      -moz-transform: translate3d(0, -1px, 0); }
    42% {
      -moz-transform: translate3d(0, 0, 0); }
    100% {
      -moz-transform: translate3d(0, 0, 0); } }
  /* 右黄色の車の動き */
  @-moz-keyframes car3_moving {
    0% {
      -moz-transform: translate3d(0, 0, 0) scale(0.2); }
    100% {
      -moz-transform: translate3d(95px, 85px, 0) scale(0.95); } }
  /* 右黄色の車の動き（上下） */
  @-moz-keyframes car3_updown {
    0% {
      -moz-transform: translate3d(0, 0, 0); }
    20% {
      -moz-transform: translate3d(0, 0, 0); }
    32% {
      -moz-transform: translate3d(0, -1px, 0); }
    35% {
      -moz-transform: translate3d(0, -1px, 0); }
    52% {
      -moz-transform: translate3d(0, 0, 0); } }
  @-moz-keyframes white_back_fadein {
    0% {
      opacity: 0; }
    60% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @keyframes buildings_gone {
    0% {
      transform: translate3d(0, 0, 0) scale(1); }
    100% {
      transform: translate3d(0, 140px, 0) scale(0.65); } }
  @keyframes sign_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1.8); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(528px, -235px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(528px, -235px, 0) scale(0); } }
  /* 木1 */
  @keyframes wood1_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(0.7); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(-282px, -5px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(-282px, -5px, 0) scale(0); } }
  /* 木2 */
  @keyframes wood2_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(0.3); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(-89px, 0px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(-89px, 0px, 0) scale(0); } }
  /* 木3 */
  @keyframes wood3_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(2); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(-865px, -80px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(-865px, -80px, 0) scale(0); } }
  /* 木4 */
  @keyframes wood4_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(2); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(-875px, -78px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(-875px, -78px, 0) scale(0); } }
  /* 木5 */
  @keyframes wood5_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(0.7); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(265px, 6px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(265px, 6px, 0) scale(0); } }
  /* 木6 */
  @keyframes wood6_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(0.3); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(89px, 12px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(89px, 12px, 0) scale(0); } }
  /* 木7 */
  @keyframes wood7_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1.4); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(595px, -83px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(595px, -83px, 0) scale(0); } }
  /* 木8 */
  @keyframes wood8_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1.4); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(635px, -83px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(635px, -83px, 0) scale(0); } }
  /* 木9 */
  @keyframes wood9_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1.2); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(685px, -33px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(685px, -33px, 0) scale(0); } }
  /* 雑草1 */
  @keyframes weed1_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(-277px, -95px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(-277px, -95px, 0) scale(0); } }
  /* 雑草2 */
  @keyframes weed2_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(227px, -74px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(227px, -74px, 0) scale(0); } }
  /* 雑草3 */
  @keyframes weed3_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(-492px, -95px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(-492px, -95px, 0) scale(0); } }
  /* 雑草4 */
  @keyframes weed4_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(567px, -74px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(567px, -74px, 0) scale(0); } }
  /* 雑草5 */
  @keyframes weed5_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1.8); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(-727px, -295px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(-727px, -295px, 0) scale(0); } }
  /* 雑草6 */
  @keyframes weed6_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1.8); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(687px, -294px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(687px, -294px, 0) scale(0); } }
  /* 石1 */
  @keyframes rock1_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1.2); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(417px, -154px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(417px, -154px, 0) scale(0); } }
  /* 石2 */
  @keyframes rock2_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(0.7); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(-212px, -52px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(-212px, -52px, 0) scale(0); } }
  /* 石3 */
  @keyframes rock3_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1.6); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(617px, -194px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(617px, -194px, 0) scale(0); } }
  /* 石4 */
  @keyframes rock4_moving {
    0% {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1.4); }
    99% {
      display: block;
      opacity: 1;
      transform: translate3d(-602px, -215px, 0) scale(0); }
    100% {
      display: none;
      opacity: 0;
      transform: translate3d(-602px, -215px, 0) scale(0); } }
  /* 中央赤の車の動き */
  @keyframes move_for_title {
    0% {
      transform: translate3d(0, 145px, 0) scale(0.95); }
    100% {
      transform: translate3d(0, 48px, 0px) scale(1.25); } }
  @keyframes car1_fadeout {
    0% {
      transform: translate3d(0, 48px, 0px) scale(1.25);
      opacity: 1.0; }
    60% {
      transform: translate3d(0, 48px, 0px) scale(1.25);
      opacity: 1.0; }
    100% {
      transform: translate3d(0, 48px, 0px) scale(1.25);
      opacity: 0; } }
  @keyframes car1_moving {
    0% {
      transform: translate3d(0, 0, 0) scale(0.2); }
    100% {
      transform: translate3d(0, 145px, 0) scale(0.95); } }
  /* 中央赤の車の動き（上下） */
  @keyframes car1_updown {
    0% {
      transform: translate3d(0, 0, 0); }
    5% {
      transform: translate3d(0, 0, 0); }
    12% {
      transform: translate3d(0, -1px, 0); }
    15% {
      transform: translate3d(0, -1px, 0); }
    32% {
      transform: translate3d(0, 0, 0); }
    100% {
      transform: translate3d(0, 0, 0); } }
  /* 左青の車の動き */
  @keyframes car2_moving {
    0% {
      transform: translate3d(0, 0, 0) scale(0.2); }
    100% {
      transform: translate3d(-95px, 85px, 0) scale(0.95); } }
  /* 左青の車の動き（上下） */
  @keyframes car2_updown {
    0% {
      transform: translate3d(0, 0, 0); }
    10% {
      transform: translate3d(0, 0, 0); }
    22% {
      transform: translate3d(0, -1px, 0); }
    25% {
      transform: translate3d(0, -1px, 0); }
    42% {
      transform: translate3d(0, 0, 0); }
    100% {
      transform: translate3d(0, 0, 0); } }
  /* 右黄色の車の動き */
  @keyframes car3_moving {
    0% {
      transform: translate3d(0, 0, 0) scale(0.2); }
    100% {
      transform: translate3d(95px, 85px, 0) scale(0.95); } }
  /* 右黄色の車の動き（上下） */
  @keyframes car3_updown {
    0% {
      transform: translate3d(0, 0, 0); }
    20% {
      transform: translate3d(0, 0, 0); }
    32% {
      transform: translate3d(0, -1px, 0); }
    35% {
      transform: translate3d(0, -1px, 0); }
    52% {
      transform: translate3d(0, 0, 0); } }
  @keyframes white_back_fadein {
    0% {
      opacity: 0; }
    60% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  #an60th {
    width: 1200px;
    height: 600px;
    margin: auto;
    margin-left: -15px;
    position: relative;
    overflow: hidden; }
    #an60th #sky {
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute; }
      #an60th #sky.bg_sky {
        background-image: url(/resources/images/60th/background.png); }
    #an60th #buildings {
      background-image: url(/resources/images/60th/buildings.png);
      width: 712px;
      height: 208px;
      z-index: 10;
      position: absolute;
      left: 200px;
      top: 185px; }
    #an60th .buildings_gone {
      -webkit-animation: buildings_gone 15s ease-in forwards;
      -moz-animation: buildings_gone 15s ease-in forwards;
      animation: buildings_gone 15s ease-in forwards; }
    #an60th #ground {
      left: 0;
      width: 1200px;
      height: 100%;
      background-image: url(/resources/images/60th/ground.png);
      z-index: 100;
      position: relative; }
    #an60th #sign {
      width: 65px;
      height: 108px;
      background-image: url(/resources/images/60th/sign.png);
      z-index: 130;
      position: absolute;
      left: -50px;
      top: 536px;
      -webkit-animation: sign_moving 6s ease-out infinite;
      -moz-animation: sign_moving 6s ease-out infinite;
      animation: sign_moving 6s ease-out infinite; }
    #an60th #wood1 {
      /* 木1 */
      width: 244px;
      height: 192px;
      background-image: url(/resources/images/60th/wood2.png);
      z-index: 109;
      position: absolute;
      left: 922px;
      top: 269px;
      -webkit-animation: wood1_moving 6.5s ease-out forwards;
      -moz-animation: wood1_moving 6.5s ease-out forwards;
      animation: wood1_moving 6.5s ease-out forwards; }
    #an60th #wood2 {
      /* 木2 */
      width: 153px;
      height: 192px;
      background-image: url(/resources/images/60th/wood1.png);
      z-index: 108;
      position: absolute;
      left: 715px;
      top: 260px;
      -webkit-animation: wood2_moving 3.5s ease-out forwards;
      -moz-animation: wood2_moving 3.5s ease-out forwards;
      animation: wood2_moving 3.5s ease-out forwards; }
    #an60th #wood3 {
      /* 木3 */
      width: 153px;
      height: 192px;
      background-image: url(/resources/images/60th/wood1.png);
      z-index: 113;
      position: absolute;
      left: 1500px;
      top: 340px;
      -webkit-animation: wood3_moving 8s ease-out infinite;
      -moz-animation: wood3_moving 8s ease-out infinite;
      animation: wood3_moving 8s ease-out infinite; }
    #an60th #wood4 {
      /* 木4 */
      width: 244px;
      height: 192px;
      background-image: url(/resources/images/60th/wood2.png);
      z-index: 112;
      position: absolute;
      left: 1500px;
      top: 340px;
      -webkit-animation: wood4_moving 9s ease-out infinite;
      -moz-animation: wood4_moving 9s ease-out infinite;
      animation: wood4_moving 9s ease-out infinite; }
    #an60th #wood5 {
      /* 木5 */
      width: 264px;
      height: 192px;
      background-image: url(/resources/images/60th/wood3.png);
      z-index: 114;
      position: absolute;
      left: 56px;
      top: 256px;
      -webkit-animation: wood5_moving 5.5s ease-out forwards;
      -moz-animation: wood5_moving 5.5s ease-out forwards;
      animation: wood5_moving 5.5s ease-out forwards; }
    #an60th #wood6 {
      /* 木6 */
      width: 153px;
      height: 192px;
      background-image: url(/resources/images/60th/wood1.png);
      z-index: 113;
      position: absolute;
      left: 285px;
      top: 250px;
      -webkit-animation: wood6_moving 2.5s ease-out forwards;
      -moz-animation: wood6_moving 2.5s ease-out forwards;
      animation: wood6_moving 2.5s ease-out forwards; }
    #an60th #wood7 {
      /* 木7 */
      width: 264px;
      height: 192px;
      background-image: url(/resources/images/60th/wood3.png);
      z-index: 116;
      position: absolute;
      left: -250px;
      top: 346px;
      -webkit-animation: wood7_moving 7.5s ease-out infinite;
      -moz-animation: wood7_moving 7.5s ease-out infinite;
      animation: wood7_moving 7.5s ease-out infinite; }
    #an60th #wood8 {
      /* 木8 */
      width: 153px;
      height: 192px;
      background-image: url(/resources/images/60th/wood1.png);
      z-index: 115;
      position: absolute;
      left: -250px;
      top: 346px;
      -webkit-animation: wood8_moving 7.5s ease-out infinite;
      -moz-animation: wood8_moving 7.5s ease-out infinite;
      animation: wood8_moving 7.5s ease-out infinite; }
    #an60th #wood9 {
      /* 木9 */
      width: 153px;
      height: 192px;
      background-image: url(/resources/images/60th/wood1.png);
      z-index: 113;
      position: absolute;
      left: -300px;
      top: 296px;
      -webkit-animation: wood9_moving 9.5s ease-out infinite;
      -moz-animation: wood9_moving 9.5s ease-out infinite;
      animation: wood9_moving 9.5s ease-out infinite; }
    #an60th #weed1 {
      /* 雑草1 */
      width: 86px;
      height: 42px;
      background-image: url(/resources/images/60th/weed1.png);
      z-index: 117;
      position: absolute;
      left: 922px;
      top: 429px;
      -webkit-animation: weed1_moving 5.5s ease-out forwards;
      -moz-animation: weed1_moving 5.5s ease-out forwards;
      animation: weed1_moving 5.5s ease-out forwards; }
    #an60th #weed2 {
      /* 雑草2 */
      width: 35px;
      height: 42px;
      background-image: url(/resources/images/60th/weed2.png);
      z-index: 118;
      position: absolute;
      left: 252px;
      top: 409px;
      -webkit-animation: weed2_moving 5.5s ease-out forwards;
      -moz-animation: weed2_moving 5.5s ease-out forwards;
      animation: weed2_moving 5.5s ease-out forwards; }
    #an60th #weed3 {
      /* 雑草3 */
      width: 35px;
      height: 42px;
      background-image: url(/resources/images/60th/weed2.png);
      z-index: 110;
      position: absolute;
      left: 1222px;
      top: 429px;
      -webkit-animation: weed3_moving 7.25s ease-out infinite;
      -moz-animation: weed3_moving 7.25s ease-out infinite;
      animation: weed3_moving 7.25s ease-out infinite; }
    #an60th #weed4 {
      /* 雑草4 */
      width: 86px;
      height: 42px;
      background-image: url(/resources/images/60th/weed1.png);
      z-index: 110;
      position: absolute;
      left: -152px;
      top: 409px;
      -webkit-animation: weed4_moving 7s ease-out infinite;
      -moz-animation: weed4_moving 7s ease-out infinite;
      animation: weed4_moving 7s ease-out infinite; }
    #an60th #weed5 {
      /* 雑草5 */
      width: 86px;
      height: 42px;
      background-image: url(/resources/images/60th/weed1.png);
      z-index: 117;
      position: absolute;
      left: 1372px;
      top: 629px;
      -webkit-animation: weed5_moving 6s ease-out infinite;
      -moz-animation: weed5_moving 6s ease-out infinite;
      animation: weed5_moving 6s ease-out infinite; }
    #an60th #weed6 {
      /* 雑草6 */
      width: 35px;
      height: 42px;
      background-image: url(/resources/images/60th/weed2.png);
      z-index: 118;
      position: absolute;
      left: -202px;
      top: 629px;
      -webkit-animation: weed6_moving 7s ease-out infinite;
      -moz-animation: weed6_moving 7s ease-out infinite;
      animation: weed6_moving 7s ease-out infinite; }
    #an60th #rock1 {
      /* 石1 */
      width: 53px;
      height: 41px;
      background-image: url(/resources/images/60th/rock1.png);
      z-index: 119;
      position: absolute;
      left: 52px;
      top: 489px;
      -webkit-animation: rock1_moving 6s ease-out forwards;
      -moz-animation: rock1_moving 6s ease-out forwards;
      animation: rock1_moving 6s ease-out forwards; }
    #an60th #rock2 {
      /* 石2 */
      width: 56px;
      height: 40px;
      background-image: url(/resources/images/60th/rock2.png);
      z-index: 120;
      position: absolute;
      left: 882px;
      top: 389px;
      -webkit-animation: rock2_moving 4.5s ease-out forwards;
      -moz-animation: rock2_moving 4.5s ease-out forwards;
      animation: rock2_moving 4.5s ease-out forwards; }
    #an60th #rock3 {
      /* 石3 */
      width: 56px;
      height: 40px;
      background-image: url(/resources/images/60th/rock2.png);
      z-index: 119;
      position: absolute;
      left: -152px;
      top: 529px;
      -webkit-animation: rock3_moving 6.75s ease-out infinite;
      -moz-animation: rock3_moving 6.75s ease-out infinite;
      animation: rock3_moving 6.75s ease-out infinite; }
    #an60th #rock4 {
      /* 石4 */
      width: 53px;
      height: 41px;
      background-image: url(/resources/images/60th/rock1.png);
      z-index: 116;
      position: absolute;
      left: 1282px;
      top: 549px;
      -webkit-animation: rock4_moving 5.75s ease-out infinite;
      -moz-animation: rock4_moving 5.75s ease-out infinite;
      animation: rock4_moving 5.75s ease-out infinite; }

  #an60th_front {
    margin: auto;
    width: 1200px;
    height: 600px; }
    #an60th_front #white_back {
      margin: auto;
      background-color: white;
      width: 1200px;
      height: 600px;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      opacity: 0; }
    #an60th_front #white_back2 {
      margin: auto;
      background-image: url(/resources/images/60th/white_back.png);
      width: 1200px;
      height: 600px;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
      opacity: 0; }
    #an60th_front .white_back_fadein {
      -webkit-animation: white_back_fadein 1.5s ease-in forwards;
      -moz-animation: white_back_fadein 1.5s ease-in forwards;
      animation: white_back_fadein 1.5s ease-in forwards; }

  #kamifubuki {
    margin: auto;
    margin-left: -15px;
    width: 1200px;
    overflow: hidden; }
    #kamifubuki .transparent {
      opacity: 0; }

  #car1 {
    /* 中央赤の車 */
    width: 166px;
    height: 176px;
    z-index: 200;
    position: absolute;
    left: 520px;
    top: 258px;
    -webkit-animation: car1_moving 5s ease-in forwards;
    -moz-animation: car1_moving 5s ease-in forwards;
    animation: car1_moving 5s ease-in forwards; }
    #car1.move_for_title {
      -webkit-animation: move_for_title 1s ease-in forwards;
      -moz-animation: move_for_title 1s ease-in forwards;
      animation: move_for_title 1s ease-in forwards; }
    #car1.car1_fadeout {
      -webkit-animation: car1_fadeout 1.8s ease-in forwards;
      -moz-animation: car1_fadeout 1.8s ease-in forwards;
      animation: car1_fadeout 1.8s ease-in forwards; }
    #car1 .car_image {
      width: 100%;
      height: 100%;
      background-image: url(/resources/images/60th/car1.png); }
    #car1 .car_updown {
      -webkit-animation: car1_updown 0.7s linear infinite;
      -moz-animation: car1_updown 0.7s linear infinite;
      animation: car1_updown 0.7s linear infinite; }

  #car2 {
    /* 左青の車 */
    width: 124px;
    height: 165px;
    z-index: 151;
    position: absolute;
    left: 500px;
    top: 262px;
    -webkit-animation: car2_moving 4s ease-in forwards;
    -moz-animation: car2_moving 4s ease-in forwards;
    animation: car2_moving 4s ease-in forwards; }
    #car2 .car_image {
      width: 100%;
      height: 100%;
      background-image: url(/resources/images/60th/car2.png); }
    #car2 .car_updown {
      -webkit-animation: car2_updown 0.8s linear infinite;
      -moz-animation: car2_updown 0.8s linear infinite;
      animation: car2_updown 0.8s linear infinite; }

  #car3 {
    /* 右黄色の車 */
    width: 124px;
    height: 156px;
    z-index: 150;
    position: absolute;
    left: 580px;
    top: 267px;
    -webkit-animation: car3_moving 4s ease-in forwards;
    -moz-animation: car3_moving 4s ease-in forwards;
    animation: car3_moving 4s ease-in forwards; }
    #car3 .car_image {
      width: 100%;
      height: 100%;
      background-image: url(/resources/images/60th/car3.png); }
    #car3 .car_updown {
      -webkit-animation: car3_updown 0.8s linear infinite;
      -moz-animation: car3_updown 0.8s linear infinite;
      animation: car3_updown 0.8s linear infinite; }

  /*# sourceMappingURL=60th.css.map */

  @-webkit-keyframes main_menu_over {
    0% {
      visibility: visible;
      opacity: 0; }
    100% {
      visibility: visible;
      opacity: 1; } }
  @-webkit-keyframes disappear {
    0% {
      visibility: visible;
      opacity: 1; }
    99% {
      visibility: visible;
      opacity: 0; }
    100% {
      visibility: hidden;
      opacity: 0; } }
  @-webkit-keyframes scroll_fadein {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 20px, 0); }
    20% {
      opacity: 0;
      -webkit-transform: translate3d(0, 20px, 0); }
    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0); } }
  @-moz-keyframes main_menu_over {
    0% {
      visibility: visible;
      opacity: 0; }
    100% {
      visibility: visible;
      opacity: 1; } }
  @-moz-keyframes disappear {
    0% {
      visibility: visible;
      opacity: 1; }
    99% {
      visibility: visible;
      opacity: 0; }
    100% {
      visibility: hidden;
      opacity: 0; } }
  @-moz-keyframes scroll_fadein {
    0% {
      opacity: 0;
      -moz-transform: translate3d(0, 20px, 0); }
    20% {
      opacity: 0;
      -moz-transform: translate3d(0, 20px, 0); }
    100% {
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0); } }
  @keyframes main_menu_over {
    0% {
      visibility: visible;
      opacity: 0; }
    100% {
      visibility: visible;
      opacity: 1; } }
  @keyframes disappear {
    0% {
      visibility: visible;
      opacity: 1; }
    99% {
      visibility: visible;
      opacity: 0; }
    100% {
      visibility: hidden;
      opacity: 0; } }
  @keyframes scroll_fadein {
    0% {
      opacity: 0;
      transform: translate3d(0, 20px, 0); }
    20% {
      opacity: 0;
      transform: translate3d(0, 20px, 0); }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0); } }
  body {
    margin: auto; }

  .d_none {
    display: none; }

  .d_block {
    display: block; }

  .container {
    width: 1200px;
    min-width: 1200px;
    margin: auto;
    padding: 0;
    position: relative; }
    .container .scroll_fadein {
      opacity: 0; }
    .container .scroll_fadein_anim {
      -webkit-animation: scroll_fadein 1.2s ease forwards;
      -moz-animation: scroll_fadein 1.2s ease forwards;
      animation: scroll_fadein 1.2s ease forwards; }
    .container .row {
      margin: 0; }
    .container#an60th_container {
      height: 600px;
      z-index: 1; }
    .container#main_container {
      margin-top: -600px;
      z-index: 1000; }
      .container#main_container .message_60th {
        margin: auto;
        margin-top: 80px;
        width: 805px;
        height: 116px;
        padding-top: 25px;
        line-height: 40px;
        background-image: url(/resources/images/60th/image1.png);
        background-size: cover;
        text-align: center;
        font-size: 23px;
        font-weight: bold; }
        @media (max-width: 575px) {
          .container#main_container .message_60th {
            width: 100%;
            height: 168px;
            font-size: 34px;
            line-height: 44px;
            padding-top: 35px; } }
      .container#main_container .content_title {
        margin: auto;
        margin-top: 50px;
        padding-top: 120px;
        background-size: cover;
        text-align: center;
        font-size: 17px;
        font-weight: bold;
        width: 760px;
        height: 170px; }
        .container#main_container .content_title.message_ayumi {
          margin-top: 100px;
          background-image: url(/resources/images/60th/ayumi.png); }
        .container#main_container .content_title.message_syokai {
          background-image: url(/resources/images/60th/syokai.png); }
        .container#main_container .content_title.message_campaign {
          background-image: url(/resources/images/60th/campaign.png);
          margin-bottom: -40px; }
        @media (max-width: 575px) {
          .container#main_container .content_title {
            width: 100%;
            height: 269px;
            font-size: 34px;
            padding-top: 160px; } }
      .container#main_container #history_btn_message {
        font-size: 18px;
        margin: auto;
        margin-bottom: 33px;
        text-align: center;
        color: red;
        font-weight: bold; }
        @media (max-width: 575px) {
          .container#main_container #history_btn_message {
            font-size: 34px; } }
      .container#main_container #syokai {
        margin-bottom: 70px; }
        .container#main_container #syokai .family {
          margin: auto;
          padding: 0;
          width: 992px; }
          .container#main_container #syokai .family div {
            float: left; }
        .container#main_container #syokai .large_content {
          width: 397px;
          height: 362px;
          padding: 255px 20px 10px 20px;
          line-height: 28px;
          font-size: 16px; }
          .container#main_container #syokai .large_content#paparu {
            margin-left: 48px;
            background-image: url(/resources/images/60th/family/paparu.png); }
          .container#main_container #syokai .large_content#titil {
            margin-left: 20px;
            background-image: url(/resources/images/60th/family/titil.png); }
        .container#main_container #syokai .small_content {
          width: 197px;
          height: 223px;
          font-size: 13px;
          padding: 160px 10px 5px 10px;
          line-height: 18px;
          margin-top: 25px; }
          .container#main_container #syokai .small_content#banana {
            background-image: url(/resources/images/60th/family/banana.png);
            margin-left: -20px; }
          .container#main_container #syokai .small_content#pine {
            background-image: url(/resources/images/60th/family/pine.png);
            margin-left: 0px; }
          .container#main_container #syokai .small_content#kiddy {
            background-image: url(/resources/images/60th/family/kiddy.png);
            margin-left: 20px; }
          .container#main_container #syokai .small_content#rats {
            background-image: url(/resources/images/60th/family/rats.png);
            margin-left: 40px; }
        @media (max-width: 575px) {
          .container#main_container #syokai .col-sm-3 {
            width: 50%; }
          .container#main_container #syokai .large_content {
            width: 794px;
            height: 724px;
            background-size: cover;
            padding: 500px 25px 10px 25px;
            line-height: 45px;
            margin-top: 50px !important;
            font-size: 40px; }
            .container#main_container #syokai .large_content#paparu {
              margin-left: 80px !important; }
            .container#main_container #syokai .large_content#titil {
              margin-top: 80px !important;
              margin-left: 80px !important; }
          .container#main_container #syokai .small_content {
            width: 473px;
            height: 535px;
            font-size: 37px;
            padding: 380px 20px 5px 20px;
            line-height: 43px;
            margin-top: 80px;
            background-size: cover; }
            .container#main_container #syokai .small_content#banana {
              margin-left: -10px !important; }
            .container#main_container #syokai .small_content#pine {
              margin-left: 10px !important; }
            .container#main_container #syokai .small_content#kiddy {
              margin-left: -10px !important; }
            .container#main_container #syokai .small_content#rats {
              margin-left: 10px !important; } }
      .container#main_container .kikan {
        margin: auto;
        margin-top: 20px;
        font-weight: bold;
        width: 260px;
        height: 60px; }
        .container#main_container .kikan .kaisai {
          margin-right: 10px;
          margin-top: 25px;
          font-size: 14px; }
        .container#main_container .kikan .kara {
          font-size: 26px;
          margin: 16px 10px 0 10px; }
        .container#main_container .kikan .circle {
          width: 70px;
          height: 70px;
          font-size: 28px;
          background-color: black;
          color: white;
          text-align: center;
          padding-top: 13px;
          -webkit-border-radius: 35px;
          -moz-border-radius: 35px;
          border-radius: 35px; }
        @media (max-width: 575px) {
          .container#main_container .kikan {
            width: 500px;
            height: 80px; }
            .container#main_container .kikan .kaisai {
              font-size: 32px;
              margin-top: 40px; }
            .container#main_container .kikan .kara {
              font-size: 48px;
              margin-top: 30px; }
            .container#main_container .kikan .circle {
              width: 120px;
              height: 120px;
              font-size: 50px;
              background-color: black;
              color: white;
              text-align: center;
              padding-top: 26px;
              -webkit-border-radius: 60px;
              -moz-border-radius: 60px;
              border-radius: 60px; } }
      .container#main_container #campaign {
        margin: auto;
        margin-bottom: 80px; }
        .container#main_container #campaign .campaign_content {
          width: 100%;
          margin: auto;
          margin-top: 25px; }
          .container#main_container #campaign .campaign_content .campaign_banner_base {
            width: 1000px;
            height: 407px;
            margin: auto;
            margin-top: 80px;
            background-image: url(/resources/images/60th/campaign2.png); }
          .container#main_container #campaign .campaign_content #campaign_subtitle_each_schools {
            width: 478px;
            height: 63px;
            background-image: url(/resources/images/60th/campaign3.png);
            margin: auto;
            margin-top: 150px; }
          .container#main_container #campaign .campaign_content #present {
            width: 100%;
            height: 10px;
            margin-top: 460px; }
          .container#main_container #campaign .campaign_content #campaign_subtitle_present {
            width: 435px;
            height: 62px;
            background-image: url(/resources/images/60th/present.png);
            margin: auto;
            margin-top: 0px; }
          .container#main_container #campaign .campaign_content #campaign_subtitle_stamp_rally {
            width: 435px;
            height: 62px;
            background-image: url(/resources/images/60th/stamp_rally.png);
            margin: auto;
            margin-top: 160px; }
          .container#main_container #campaign .campaign_content .present_content, .container#main_container #campaign .campaign_content .stamp_rally_content {
            margin-top: 80px; }
            .container#main_container #campaign .campaign_content .present_content .row, .container#main_container #campaign .campaign_content .stamp_rally_content .row {
              margin-bottom: 40px; }
            .container#main_container #campaign .campaign_content .present_content .left_side, .container#main_container #campaign .campaign_content .stamp_rally_content .left_side {
              width: 50%;
              padding-right: 20px;
              margin-top: 50px;
              float: left; }
              .container#main_container #campaign .campaign_content .present_content .left_side.stamp_rally, .container#main_container #campaign .campaign_content .stamp_rally_content .left_side.stamp_rally {
                margin-top: 50px;
                margin-bottom: 80px;
                padding-left: 50px; }
            .container#main_container #campaign .campaign_content .present_content .right_side, .container#main_container #campaign .campaign_content .stamp_rally_content .right_side {
              width: 50%;
              margin-top: 90px;
              padding-right: 50px;
              float: right; }
              .container#main_container #campaign .campaign_content .present_content .right_side.stamp_rally, .container#main_container #campaign .campaign_content .stamp_rally_content .right_side.stamp_rally {
                margin-top: 50px;
                margin-bottom: 80px; }
            .container#main_container #campaign .campaign_content .present_content .circle_ab, .container#main_container #campaign .campaign_content .stamp_rally_content .circle_ab {
              position: absolute;
              left: 15px;
              width: 40px;
              height: 40px;
              font-size: 28px;
              font-weight: bold;
              background-color: #00A384;
              color: white;
              text-align: center;
              line-height: 36px;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              border-radius: 20px; }
            .container#main_container #campaign .campaign_content .present_content .circle_or, .container#main_container #campaign .campaign_content .stamp_rally_content .circle_or {
              position: absolute;
              left: 250px;
              top: -40px;
              width: 110px;
              height: 50px;
              font-size: 62px;
              font-weight: bold;
              background-color: #1D2087;
              color: white;
              text-align: center;
              line-height: 30px;
              -webkit-border-radius: 80px;
              -moz-border-radius: 80px;
              border-radius: 80px;
              transform: rotateZ(-20deg); }
            .container#main_container #campaign .campaign_content .present_content img, .container#main_container #campaign .campaign_content .stamp_rally_content img {
              width: 450px;
              float: right;
              margin-right: 20px; }
              .container#main_container #campaign .campaign_content .present_content img.right_side, .container#main_container #campaign .campaign_content .stamp_rally_content img.right_side {
                float: left; }
            .container#main_container #campaign .campaign_content .present_content .subtitle, .container#main_container #campaign .campaign_content .stamp_rally_content .subtitle {
              color: red;
              margin: auto;
              margin-top: 30px;
              margin-bottom: 50px;
              font-weight: bold;
              font-size: 22px;
              text-align: center; }
            .container#main_container #campaign .campaign_content .present_content h4, .container#main_container #campaign .campaign_content .stamp_rally_content h4 {
              font-weight: bold;
              font-size: 34px;
              line-height: 38px; }
            .container#main_container #campaign .campaign_content .present_content .irisu, .container#main_container #campaign .campaign_content .stamp_rally_content .irisu {
              font-size: 20px;
              color: red;
              font-weight: bold;
              margin-top: -10px; }
            .container#main_container #campaign .campaign_content .present_content .headline, .container#main_container #campaign .campaign_content .stamp_rally_content .headline {
              font-weight: bold;
              font-size: 24px;
              line-height: 32px;
              color: #0A0;
              margin: 25px 0 20px 0; }
            .container#main_container #campaign .campaign_content .present_content .bold, .container#main_container #campaign .campaign_content .stamp_rally_content .bold {
              font-weight: bold;
              font-size: 20px; }
            .container#main_container #campaign .campaign_content .present_content .content, .container#main_container #campaign .campaign_content .stamp_rally_content .content {
              font-size: 19px; }
            .container#main_container #campaign .campaign_content .present_content .col-sm, .container#main_container #campaign .campaign_content .stamp_rally_content .col-sm {
              width: 50%; }
          @media (max-width: 575px) {
            .container#main_container #campaign .campaign_content .campaign_subtitle {
              background-size: cover; }
            .container#main_container #campaign .campaign_content #campaign_subtitle_each_schools {
              width: 956px;
              height: 126px; }
            .container#main_container #campaign .campaign_content #present {
              margin-top: 900px; }
            .container#main_container #campaign .campaign_content #campaign_subtitle_present {
              width: 870px;
              height: 124px;
              margin-top: 100px; }
            .container#main_container #campaign .campaign_content #campaign_subtitle_stamp_rally {
              width: 870px;
              height: 124px; }
            .container#main_container #campaign .campaign_content .present_content .left_side, .container#main_container #campaign .campaign_content .stamp_rally_content .left_side {
              margin: 30px auto !important;
              padding: 0 !important;
              float: none !important;
              text-align: center; }
              .container#main_container #campaign .campaign_content .present_content .left_side.stamp_rally, .container#main_container #campaign .campaign_content .stamp_rally_content .left_side.stamp_rally {
                width: 100%;
                margin-bottom: 160px !important; }
            .container#main_container #campaign .campaign_content .present_content .right_side, .container#main_container #campaign .campaign_content .stamp_rally_content .right_side {
              margin: 30px auto !important;
              padding: 0 !important;
              float: none !important;
              text-align: center; }
              .container#main_container #campaign .campaign_content .present_content .right_side.stamp_rally, .container#main_container #campaign .campaign_content .stamp_rally_content .right_side.stamp_rally {
                width: 100%; }
            .container#main_container #campaign .campaign_content .present_content .circle_ab, .container#main_container #campaign .campaign_content .stamp_rally_content .circle_ab {
              left: 180px;
              width: 80px;
              height: 80px;
              font-size: 56px;
              line-height: 74px;
              -webkit-border-radius: 40px;
              -moz-border-radius: 40px;
              border-radius: 40px; }
            .container#main_container #campaign .campaign_content .present_content .circle_or, .container#main_container #campaign .campaign_content .stamp_rally_content .circle_or {
              left: 490px;
              top: -120px;
              width: 220px;
              height: 100px;
              font-size: 124px;
              line-height: 76px;
              -webkit-border-radius: 80px;
              -moz-border-radius: 80px;
              border-radius: 80px;
              transform: rotateZ(-20deg); }
            .container#main_container #campaign .campaign_content .present_content img, .container#main_container #campaign .campaign_content .stamp_rally_content img {
              width: 80%;
              margin: auto !important;
              padding: 0 !important;
              float: none; }
            .container#main_container #campaign .campaign_content .present_content .subtitle, .container#main_container #campaign .campaign_content .stamp_rally_content .subtitle {
              font-size: 42px; }
            .container#main_container #campaign .campaign_content .present_content h4, .container#main_container #campaign .campaign_content .stamp_rally_content h4 {
              text-align: left;
              font-size: 60px;
              line-height: 68px;
              margin-left: 260px; }
            .container#main_container #campaign .campaign_content .present_content .irisu, .container#main_container #campaign .campaign_content .stamp_rally_content .irisu {
              text-align: left;
              font-size: 30px;
              margin-left: 260px; }
            .container#main_container #campaign .campaign_content .present_content .headline, .container#main_container #campaign .campaign_content .stamp_rally_content .headline {
              text-align: left;
              font-size: 40px;
              line-height: 48px;
              width: 80%;
              margin: 50px 0 30px 160px; }
            .container#main_container #campaign .campaign_content .present_content .bold, .container#main_container #campaign .campaign_content .stamp_rally_content .bold {
              font-size: 30px; }
            .container#main_container #campaign .campaign_content .present_content .content, .container#main_container #campaign .campaign_content .stamp_rally_content .content {
              text-align: left;
              margin-left: 260px;
              font-size: 30px; }
            .container#main_container #campaign .campaign_content .present_content .col-sm, .container#main_container #campaign .campaign_content .stamp_rally_content .col-sm {
              width: 100% !important; } }
      .container#main_container footer {
        width: 100%;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 5px; }
        .container#main_container footer p {
          width: 100%;
          margin: auto;
          text-align: center;
          font-size: 18px; }
          @media (max-width: 575px) {
            .container#main_container footer p {
              font-size: 36px; } }
    .container#kamifubuki_container {
      z-index: 2000; }
    .container#main_container_buttons {
      z-index: 3000; }
      .container#main_container_buttons .top-menu {
        margin: auto;
        margin-top: 890px;
        margin-bottom: 70px;
        width: 835px;
        overflow: hidden; }
        .container#main_container_buttons .top-menu ul {
          display: block;
          margin: auto;
          padding: 0;
          width: 100%;
          font-size: 0; }
          .container#main_container_buttons .top-menu ul li {
            width: 167px;
            height: 56px;
            margin: 0;
            margin-bottom: 20px;
            padding: 0;
            display: inline-block;
            overflow: hidden;
            height: 56px; }
            .container#main_container_buttons .top-menu ul li a {
              display: block;
              width: 100%;
              height: 100%;
              background-image: url(/resources/images/60th/top_menu.png);
              background-position-y: 0; }
              .container#main_container_buttons .top-menu ul li a:hover {
                -webkit-animation: main_menu_over 1s ease-out forwards;
                -moz-animation: main_menu_over 1s ease-out forwards;
                animation: main_menu_over 1s ease-out forwards; }
            .container#main_container_buttons .top-menu ul li#menu_ayumi a {
              background-position-x: 0; }
            .container#main_container_buttons .top-menu ul li#menu_syokai a {
              background-position-x: -167px; }
            .container#main_container_buttons .top-menu ul li#menu_campaign a {
              background-position-x: -334px; }
            .container#main_container_buttons .top-menu ul li#menu_present {
              width: 166px; }
              .container#main_container_buttons .top-menu ul li#menu_present a {
                background-position-x: -501px; }
            .container#main_container_buttons .top-menu ul li#menu_project {
              width: 168px; }
              .container#main_container_buttons .top-menu ul li#menu_project a {
                background-position-x: -667px; }
      .container#main_container_buttons #campaign_banner_link {
        display: block;
        width: 1000px;
        height: 407px;
        margin: auto;
        margin-top: 1250px; }
        .container#main_container_buttons #campaign_banner_link:hover {
          cursor: pointer;
          background-color: white;
          opacity: 0.2; }
      .container#main_container_buttons #each_school_links {
        width: 1000px;
        margin: auto;
        margin-top: 1510px; }
        .container#main_container_buttons #each_school_links a {
          display: block;
          height: 106px;
          float: left;
          background-repeat: no-repeat;
          color: black;
          font-size: 20px;
          padding: 40px 0 0 120px;
          text-decoration: underline;
          font-weight: bold;
          margin-bottom: 30px; }
          .container#main_container_buttons #each_school_links a:hover {
            text-decoration: none;
            cursor: pointer;
            opacity: 0.7; }
          .container#main_container_buttons #each_school_links a#link_futakotamagawa {
            width: 305px;
            background-image: url(/resources/images/60th/links/futakotamagawa.png); }
          .container#main_container_buttons #each_school_links a#link_seijo {
            width: 174px;
            background-image: url(/resources/images/60th/links/seijo.png);
            margin-left: 110px; }
          .container#main_container_buttons #each_school_links a#link_shakujii {
            width: 203px;
            background-image: url(/resources/images/60th/links/shakujii.png);
            margin-left: 180px; }
          .container#main_container_buttons #each_school_links a#link_akitsu {
            width: 188px;
            background-image: url(/resources/images/60th/links/akitsu.png);
            margin-left: 180px; }
          .container#main_container_buttons #each_school_links a#link_yokohama {
            width: 235px;
            background-image: url(/resources/images/60th/links/yokohama.png);
            margin-left: 180px; }
      .container#main_container_buttons #btn_back_to_top {
        position: fixed;
        width: 128px;
        height: 144px;
        right: 30px;
        bottom: 30px;
        z-index: 1000;
        background-image: url(/resources/images/60th/btn_back_to_top.png); }
        .container#main_container_buttons #btn_back_to_top:hover {
          cursor: pointer; }
      .container#main_container_buttons .show_button {
        -webkit-animation: main_menu_over 0.5s ease forwards;
        -moz-animation: main_menu_over 0.5s ease forwards;
        animation: main_menu_over 0.5s ease forwards; }
      .container#main_container_buttons .hide_button {
        -webkit-animation: disappear 0.5s ease forwards;
        -moz-animation: disappear 0.5s ease forwards;
        animation: disappear 0.5s ease forwards; }
      @media (max-width: 575px) {
        .container#main_container_buttons .top-menu {
          margin-top: 930px;
          width: 100%; }
          .container#main_container_buttons .top-menu li {
            width: 20% !important;
            height: 76px !important; }
            .container#main_container_buttons .top-menu li a {
              background-image: url(/resources/images/60th/top_menu_sp.png) !important; }
            .container#main_container_buttons .top-menu li#menu_syokai a {
              background-position-x: -240px !important; }
            .container#main_container_buttons .top-menu li#menu_campaign a {
              background-position-x: -480px !important; }
            .container#main_container_buttons .top-menu li#menu_present a {
              background-position-x: -720px !important; }
            .container#main_container_buttons .top-menu li#menu_project a {
              background-position-x: -960px !important; }
        .container#main_container_buttons #campaign_banner_link {
          margin-top: 3670px; }
        .container#main_container_buttons #each_school_links {
          margin: auto !important;
          margin-top: 330px !important;
          margin-left: 50px !important;
          width: 100% !important; }
          .container#main_container_buttons #each_school_links a {
            height: 212px;
            font-size: 40px;
            padding: 80px 0 0 180px;
            width: 550px !important;
            background-size: 30%;
            margin: auto !important; }
            .container#main_container_buttons #each_school_links a#link_futakotamagawa {
              margin-top: 50px !important;
              margin-right: 50px !important; }
            .container#main_container_buttons #each_school_links a#link_seijo {
              margin-top: 50px !important; }
            .container#main_container_buttons #each_school_links a#link_shakujii {
              margin-top: 80px !important;
              margin-right: 50px !important; }
            .container#main_container_buttons #each_school_links a#link_akitsu {
              margin-top: 80px !important; }
            .container#main_container_buttons #each_school_links a#link_yokohama {
              margin-top: 80px !important; }
        .container#main_container_buttons #btn_back_to_top {
          width: 256px;
          height: 288px;
          right: 50px;
          bottom: 50px;
          background-size: cover; } }
  #finish{
    z-index: 100;
    margin-top: 0px;

  }
  #finish .{
    z-index: 100;
    margin-top: 0px;

  }

  /*# sourceMappingURL=common.css.map */

  @-webkit-keyframes left_button_over {
    0% {
      -webkit-transform: rotate(0deg); }
    10% {
      -webkit-transform: rotate(25deg); }
    20% {
      -webkit-transform: rotate(0deg); }
    30% {
      -webkit-transform: rotate(-25deg); }
    40% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(0deg); } }
  @-webkit-keyframes right_button_over {
    0% {
      -webkit-transform: rotate(180deg); }
    10% {
      -webkit-transform: rotate(205deg); }
    20% {
      -webkit-transform: rotate(180deg); }
    30% {
      -webkit-transform: rotate(155deg); }
    40% {
      -webkit-transform: rotate(180deg); }
    100% {
      -webkit-transform: rotate(180deg); } }
  @-webkit-keyframes car_updown {
    0% {
      -webkit-transform: translate3d(0, 0, 0); }
    10% {
      -webkit-transform: translate3d(0, 1px, 0); }
    30% {
      -webkit-transform: translate3d(0, 1px, 0); }
    50% {
      -webkit-transform: translate3d(0, 0, 0); }
    100% {
      -webkit-transform: translate3d(0, 0, 0); } }
  @-webkit-keyframes move_right0 {
    0% {
      -webkit-transform: translate3d(368px, 0, 0); }
    100% {
      -webkit-transform: translate3d(0px, 0, 0); } }
  @-webkit-keyframes move_left0 {
    0% {
      -webkit-transform: translate3d(-368px, 0, 0); }
    100% {
      -webkit-transform: translate3d(0px, 0, 0); } }
  @-webkit-keyframes move_right1 {
    0% {
      -webkit-transform: translate3d(0px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-368px, 0, 0); } }
  @-webkit-keyframes move_left1 {
    0% {
      -webkit-transform: translate3d(-736px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-368px, 0, 0); } }
  @-webkit-keyframes move_right2 {
    0% {
      -webkit-transform: translate3d(-368px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-736px, 0, 0); } }
  @-webkit-keyframes move_left2 {
    0% {
      -webkit-transform: translate3d(-1104px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-736px, 0, 0); } }
  @-webkit-keyframes move_right3 {
    0% {
      -webkit-transform: translate3d(-736px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-1104px, 0, 0); } }
  @-webkit-keyframes move_left3 {
    0% {
      -webkit-transform: translate3d(-1472px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-1104px, 0, 0); } }
  @-webkit-keyframes move_right4 {
    0% {
      -webkit-transform: translate3d(-1104px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-1472px, 0, 0); } }
  @-webkit-keyframes move_left4 {
    0% {
      -webkit-transform: translate3d(-1840px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-1472px, 0, 0); } }
  @-webkit-keyframes move_right5 {
    0% {
      -webkit-transform: translate3d(-1472px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-1840px, 0, 0); } }
  @-webkit-keyframes move_left5 {
    0% {
      -webkit-transform: translate3d(-2208px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-1840px, 0, 0); } }
  @-webkit-keyframes move_right6 {
    0% {
      -webkit-transform: translate3d(-1840px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-2208px, 0, 0); } }
  @-webkit-keyframes move_left6 {
    0% {
      -webkit-transform: translate3d(-2576px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-2208px, 0, 0); } }
  @-webkit-keyframes move_right7 {
    0% {
      -webkit-transform: translate3d(-2208px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-2576px, 0, 0); } }
  @-webkit-keyframes move_left7 {
    0% {
      -webkit-transform: translate3d(-2944px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-2576px, 0, 0); } }
  @-webkit-keyframes move_right8 {
    0% {
      -webkit-transform: translate3d(-2576px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-2944px, 0, 0); } }
  @-webkit-keyframes move_left8 {
    0% {
      -webkit-transform: translate3d(-3312px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-2944px, 0, 0); } }
  @-webkit-keyframes move_right9 {
    0% {
      -webkit-transform: translate3d(-2944px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-3312px, 0, 0); } }
  @-webkit-keyframes move_left9 {
    0% {
      -webkit-transform: translate3d(-3680px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-3312px, 0, 0); } }
  @-webkit-keyframes move_right10 {
    0% {
      -webkit-transform: translate3d(-3312px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-3680px, 0, 0); } }
  @-webkit-keyframes move_left10 {
    0% {
      -webkit-transform: translate3d(-4048px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-3680px, 0, 0); } }
  @-webkit-keyframes move_right11 {
    0% {
      -webkit-transform: translate3d(-3680px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-4048px, 0, 0); } }
  @-webkit-keyframes move_left11 {
    0% {
      -webkit-transform: translate3d(-4416px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-4048px, 0, 0); } }
  @-webkit-keyframes move_right12 {
    0% {
      -webkit-transform: translate3d(-4048px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-4416px, 0, 0); } }
  @-webkit-keyframes move_left12 {
    0% {
      -webkit-transform: translate3d(-4784px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-4416px, 0, 0); } }
  @-webkit-keyframes move_right13 {
    0% {
      -webkit-transform: translate3d(-4416px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-4784px, 0, 0); } }
  @-webkit-keyframes move_left13 {
    0% {
      -webkit-transform: translate3d(-5152px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-4784px, 0, 0); } }
  @-webkit-keyframes sky_move_right0 {
    0% {
      -webkit-transform: translate3d(40px, 0, 0); }
    100% {
      -webkit-transform: translate3d(0px, 0, 0); } }
  @-webkit-keyframes sky_move_left0 {
    0% {
      -webkit-transform: translate3d(-40px, 0, 0); }
    100% {
      -webkit-transform: translate3d(0px, 0, 0); } }
  @-webkit-keyframes sky_move_right1 {
    0% {
      -webkit-transform: translate3d(0px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-40px, 0, 0); } }
  @-webkit-keyframes sky_move_left1 {
    0% {
      -webkit-transform: translate3d(-80px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-40px, 0, 0); } }
  @-webkit-keyframes sky_move_right2 {
    0% {
      -webkit-transform: translate3d(-40px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-80px, 0, 0); } }
  @-webkit-keyframes sky_move_left2 {
    0% {
      -webkit-transform: translate3d(-120px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-80px, 0, 0); } }
  @-webkit-keyframes sky_move_right3 {
    0% {
      -webkit-transform: translate3d(-80px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-120px, 0, 0); } }
  @-webkit-keyframes sky_move_left3 {
    0% {
      -webkit-transform: translate3d(-160px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-120px, 0, 0); } }
  @-webkit-keyframes sky_move_right4 {
    0% {
      -webkit-transform: translate3d(-120px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-160px, 0, 0); } }
  @-webkit-keyframes sky_move_left4 {
    0% {
      -webkit-transform: translate3d(-200px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-160px, 0, 0); } }
  @-webkit-keyframes sky_move_right5 {
    0% {
      -webkit-transform: translate3d(-160px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-200px, 0, 0); } }
  @-webkit-keyframes sky_move_left5 {
    0% {
      -webkit-transform: translate3d(-240px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-200px, 0, 0); } }
  @-webkit-keyframes sky_move_right6 {
    0% {
      -webkit-transform: translate3d(-200px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-240px, 0, 0); } }
  @-webkit-keyframes sky_move_left6 {
    0% {
      -webkit-transform: translate3d(-280px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-240px, 0, 0); } }
  @-webkit-keyframes sky_move_right7 {
    0% {
      -webkit-transform: translate3d(-240px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-280px, 0, 0); } }
  @-webkit-keyframes sky_move_left7 {
    0% {
      -webkit-transform: translate3d(-320px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-280px, 0, 0); } }
  @-webkit-keyframes sky_move_right8 {
    0% {
      -webkit-transform: translate3d(-280px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-320px, 0, 0); } }
  @-webkit-keyframes sky_move_left8 {
    0% {
      -webkit-transform: translate3d(-360px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-320px, 0, 0); } }
  @-webkit-keyframes sky_move_right9 {
    0% {
      -webkit-transform: translate3d(-320px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-360px, 0, 0); } }
  @-webkit-keyframes sky_move_left9 {
    0% {
      -webkit-transform: translate3d(-400px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-360px, 0, 0); } }
  @-webkit-keyframes sky_move_right10 {
    0% {
      -webkit-transform: translate3d(-360px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-400px, 0, 0); } }
  @-webkit-keyframes sky_move_left10 {
    0% {
      -webkit-transform: translate3d(-440px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-400px, 0, 0); } }
  @-webkit-keyframes sky_move_right11 {
    0% {
      -webkit-transform: translate3d(-400px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-440px, 0, 0); } }
  @-webkit-keyframes sky_move_left11 {
    0% {
      -webkit-transform: translate3d(-480px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-440px, 0, 0); } }
  @-webkit-keyframes sky_move_right12 {
    0% {
      -webkit-transform: translate3d(-440px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-480px, 0, 0); } }
  @-webkit-keyframes sky_move_left12 {
    0% {
      -webkit-transform: translate3d(-520px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-480px, 0, 0); } }
  @-webkit-keyframes sky_move_right13 {
    0% {
      -webkit-transform: translate3d(-480px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-520px, 0, 0); } }
  @-webkit-keyframes sky_move_left13 {
    0% {
      -webkit-transform: translate3d(-560px, 0, 0); }
    100% {
      -webkit-transform: translate3d(-520px, 0, 0); } }
  @-webkit-keyframes current_image {
    0% {
      -webkit-transform: translate3d(-100px, -50px, 0) scale(0.5);
      z-index: 10; }
    100% {
      -webkit-transform: translate3d(100px, -330px, 0) scale(1.6);
      z-index: 100; } }
  @-webkit-keyframes sp_current_image {
    0% {
      -webkit-transform: translate3d(-100px, -50px, 0) scale(0.5);
      z-index: 10; }
    100% {
      -webkit-transform: translate3d(-120px, -490px, 0) scale(2.2);
      z-index: 100; } }
  @-moz-keyframes left_button_over {
    0% {
      -moz-transform: rotate(0deg); }
    10% {
      -moz-transform: rotate(25deg); }
    20% {
      -moz-transform: rotate(0deg); }
    30% {
      -moz-transform: rotate(-25deg); }
    40% {
      -moz-transform: rotate(0deg); }
    100% {
      -moz-transform: rotate(0deg); } }
  @-moz-keyframes right_button_over {
    0% {
      -moz-transform: rotate(180deg); }
    10% {
      -moz-transform: rotate(205deg); }
    20% {
      -moz-transform: rotate(180deg); }
    30% {
      -moz-transform: rotate(155deg); }
    40% {
      -moz-transform: rotate(180deg); }
    100% {
      -moz-transform: rotate(180deg); } }
  @-moz-keyframes car_updown {
    0% {
      -moz-transform: translate3d(0, 0, 0); }
    10% {
      -moz-transform: translate3d(0, 1px, 0); }
    30% {
      -moz-transform: translate3d(0, 1px, 0); }
    50% {
      -moz-transform: translate3d(0, 0, 0); }
    100% {
      -moz-transform: translate3d(0, 0, 0); } }
  @-moz-keyframes move_right0 {
    0% {
      -moz-transform: translate3d(368px, 0, 0); }
    100% {
      -moz-transform: translate3d(0px, 0, 0); } }
  @-moz-keyframes move_left0 {
    0% {
      -moz-transform: translate3d(-368px, 0, 0); }
    100% {
      -moz-transform: translate3d(0px, 0, 0); } }
  @-moz-keyframes move_right1 {
    0% {
      -moz-transform: translate3d(0px, 0, 0); }
    100% {
      -moz-transform: translate3d(-368px, 0, 0); } }
  @-moz-keyframes move_left1 {
    0% {
      -moz-transform: translate3d(-736px, 0, 0); }
    100% {
      -moz-transform: translate3d(-368px, 0, 0); } }
  @-moz-keyframes move_right2 {
    0% {
      -moz-transform: translate3d(-368px, 0, 0); }
    100% {
      -moz-transform: translate3d(-736px, 0, 0); } }
  @-moz-keyframes move_left2 {
    0% {
      -moz-transform: translate3d(-1104px, 0, 0); }
    100% {
      -moz-transform: translate3d(-736px, 0, 0); } }
  @-moz-keyframes move_right3 {
    0% {
      -moz-transform: translate3d(-736px, 0, 0); }
    100% {
      -moz-transform: translate3d(-1104px, 0, 0); } }
  @-moz-keyframes move_left3 {
    0% {
      -moz-transform: translate3d(-1472px, 0, 0); }
    100% {
      -moz-transform: translate3d(-1104px, 0, 0); } }
  @-moz-keyframes move_right4 {
    0% {
      -moz-transform: translate3d(-1104px, 0, 0); }
    100% {
      -moz-transform: translate3d(-1472px, 0, 0); } }
  @-moz-keyframes move_left4 {
    0% {
      -moz-transform: translate3d(-1840px, 0, 0); }
    100% {
      -moz-transform: translate3d(-1472px, 0, 0); } }
  @-moz-keyframes move_right5 {
    0% {
      -moz-transform: translate3d(-1472px, 0, 0); }
    100% {
      -moz-transform: translate3d(-1840px, 0, 0); } }
  @-moz-keyframes move_left5 {
    0% {
      -moz-transform: translate3d(-2208px, 0, 0); }
    100% {
      -moz-transform: translate3d(-1840px, 0, 0); } }
  @-moz-keyframes move_right6 {
    0% {
      -moz-transform: translate3d(-1840px, 0, 0); }
    100% {
      -moz-transform: translate3d(-2208px, 0, 0); } }
  @-moz-keyframes move_left6 {
    0% {
      -moz-transform: translate3d(-2576px, 0, 0); }
    100% {
      -moz-transform: translate3d(-2208px, 0, 0); } }
  @-moz-keyframes move_right7 {
    0% {
      -moz-transform: translate3d(-2208px, 0, 0); }
    100% {
      -moz-transform: translate3d(-2576px, 0, 0); } }
  @-moz-keyframes move_left7 {
    0% {
      -moz-transform: translate3d(-2944px, 0, 0); }
    100% {
      -moz-transform: translate3d(-2576px, 0, 0); } }
  @-moz-keyframes move_right8 {
    0% {
      -moz-transform: translate3d(-2576px, 0, 0); }
    100% {
      -moz-transform: translate3d(-2944px, 0, 0); } }
  @-moz-keyframes move_left8 {
    0% {
      -moz-transform: translate3d(-3312px, 0, 0); }
    100% {
      -moz-transform: translate3d(-2944px, 0, 0); } }
  @-moz-keyframes move_right9 {
    0% {
      -moz-transform: translate3d(-2944px, 0, 0); }
    100% {
      -moz-transform: translate3d(-3312px, 0, 0); } }
  @-moz-keyframes move_left9 {
    0% {
      -moz-transform: translate3d(-3680px, 0, 0); }
    100% {
      -moz-transform: translate3d(-3312px, 0, 0); } }
  @-moz-keyframes move_right10 {
    0% {
      -moz-transform: translate3d(-3312px, 0, 0); }
    100% {
      -moz-transform: translate3d(-3680px, 0, 0); } }
  @-moz-keyframes move_left10 {
    0% {
      -moz-transform: translate3d(-4048px, 0, 0); }
    100% {
      -moz-transform: translate3d(-3680px, 0, 0); } }
  @-moz-keyframes move_right11 {
    0% {
      -moz-transform: translate3d(-3680px, 0, 0); }
    100% {
      -moz-transform: translate3d(-4048px, 0, 0); } }
  @-moz-keyframes move_left11 {
    0% {
      -moz-transform: translate3d(-4416px, 0, 0); }
    100% {
      -moz-transform: translate3d(-4048px, 0, 0); } }
  @-moz-keyframes move_right12 {
    0% {
      -moz-transform: translate3d(-4048px, 0, 0); }
    100% {
      -moz-transform: translate3d(-4416px, 0, 0); } }
  @-moz-keyframes move_left12 {
    0% {
      -moz-transform: translate3d(-4784px, 0, 0); }
    100% {
      -moz-transform: translate3d(-4416px, 0, 0); } }
  @-moz-keyframes move_right13 {
    0% {
      -moz-transform: translate3d(-4416px, 0, 0); }
    100% {
      -moz-transform: translate3d(-4784px, 0, 0); } }
  @-moz-keyframes move_left13 {
    0% {
      -moz-transform: translate3d(-5152px, 0, 0); }
    100% {
      -moz-transform: translate3d(-4784px, 0, 0); } }
  @-moz-keyframes sky_move_right0 {
    0% {
      -moz-transform: translate3d(40px, 0, 0); }
    100% {
      -moz-transform: translate3d(0px, 0, 0); } }
  @-moz-keyframes sky_move_left0 {
    0% {
      -moz-transform: translate3d(-40px, 0, 0); }
    100% {
      -moz-transform: translate3d(0px, 0, 0); } }
  @-moz-keyframes sky_move_right1 {
    0% {
      -moz-transform: translate3d(0px, 0, 0); }
    100% {
      -moz-transform: translate3d(-40px, 0, 0); } }
  @-moz-keyframes sky_move_left1 {
    0% {
      -moz-transform: translate3d(-80px, 0, 0); }
    100% {
      -moz-transform: translate3d(-40px, 0, 0); } }
  @-moz-keyframes sky_move_right2 {
    0% {
      -moz-transform: translate3d(-40px, 0, 0); }
    100% {
      -moz-transform: translate3d(-80px, 0, 0); } }
  @-moz-keyframes sky_move_left2 {
    0% {
      -moz-transform: translate3d(-120px, 0, 0); }
    100% {
      -moz-transform: translate3d(-80px, 0, 0); } }
  @-moz-keyframes sky_move_right3 {
    0% {
      -moz-transform: translate3d(-80px, 0, 0); }
    100% {
      -moz-transform: translate3d(-120px, 0, 0); } }
  @-moz-keyframes sky_move_left3 {
    0% {
      -moz-transform: translate3d(-160px, 0, 0); }
    100% {
      -moz-transform: translate3d(-120px, 0, 0); } }
  @-moz-keyframes sky_move_right4 {
    0% {
      -moz-transform: translate3d(-120px, 0, 0); }
    100% {
      -moz-transform: translate3d(-160px, 0, 0); } }
  @-moz-keyframes sky_move_left4 {
    0% {
      -moz-transform: translate3d(-200px, 0, 0); }
    100% {
      -moz-transform: translate3d(-160px, 0, 0); } }
  @-moz-keyframes sky_move_right5 {
    0% {
      -moz-transform: translate3d(-160px, 0, 0); }
    100% {
      -moz-transform: translate3d(-200px, 0, 0); } }
  @-moz-keyframes sky_move_left5 {
    0% {
      -moz-transform: translate3d(-240px, 0, 0); }
    100% {
      -moz-transform: translate3d(-200px, 0, 0); } }
  @-moz-keyframes sky_move_right6 {
    0% {
      -moz-transform: translate3d(-200px, 0, 0); }
    100% {
      -moz-transform: translate3d(-240px, 0, 0); } }
  @-moz-keyframes sky_move_left6 {
    0% {
      -moz-transform: translate3d(-280px, 0, 0); }
    100% {
      -moz-transform: translate3d(-240px, 0, 0); } }
  @-moz-keyframes sky_move_right7 {
    0% {
      -moz-transform: translate3d(-240px, 0, 0); }
    100% {
      -moz-transform: translate3d(-280px, 0, 0); } }
  @-moz-keyframes sky_move_left7 {
    0% {
      -moz-transform: translate3d(-320px, 0, 0); }
    100% {
      -moz-transform: translate3d(-280px, 0, 0); } }
  @-moz-keyframes sky_move_right8 {
    0% {
      -moz-transform: translate3d(-280px, 0, 0); }
    100% {
      -moz-transform: translate3d(-320px, 0, 0); } }
  @-moz-keyframes sky_move_left8 {
    0% {
      -moz-transform: translate3d(-360px, 0, 0); }
    100% {
      -moz-transform: translate3d(-320px, 0, 0); } }
  @-moz-keyframes sky_move_right9 {
    0% {
      -moz-transform: translate3d(-320px, 0, 0); }
    100% {
      -moz-transform: translate3d(-360px, 0, 0); } }
  @-moz-keyframes sky_move_left9 {
    0% {
      -moz-transform: translate3d(-400px, 0, 0); }
    100% {
      -moz-transform: translate3d(-360px, 0, 0); } }
  @-moz-keyframes sky_move_right10 {
    0% {
      -moz-transform: translate3d(-360px, 0, 0); }
    100% {
      -moz-transform: translate3d(-400px, 0, 0); } }
  @-moz-keyframes sky_move_left10 {
    0% {
      -moz-transform: translate3d(-440px, 0, 0); }
    100% {
      -moz-transform: translate3d(-400px, 0, 0); } }
  @-moz-keyframes sky_move_right11 {
    0% {
      -moz-transform: translate3d(-400px, 0, 0); }
    100% {
      -moz-transform: translate3d(-440px, 0, 0); } }
  @-moz-keyframes sky_move_left11 {
    0% {
      -moz-transform: translate3d(-480px, 0, 0); }
    100% {
      -moz-transform: translate3d(-440px, 0, 0); } }
  @-moz-keyframes sky_move_right12 {
    0% {
      -moz-transform: translate3d(-440px, 0, 0); }
    100% {
      -moz-transform: translate3d(-480px, 0, 0); } }
  @-moz-keyframes sky_move_left12 {
    0% {
      -moz-transform: translate3d(-520px, 0, 0); }
    100% {
      -moz-transform: translate3d(-480px, 0, 0); } }
  @-moz-keyframes sky_move_right13 {
    0% {
      -moz-transform: translate3d(-480px, 0, 0); }
    100% {
      -moz-transform: translate3d(-520px, 0, 0); } }
  @-moz-keyframes sky_move_left13 {
    0% {
      -moz-transform: translate3d(-560px, 0, 0); }
    100% {
      -moz-transform: translate3d(-520px, 0, 0); } }
  @-moz-keyframes current_image {
    0% {
      -moz-transform: translate3d(-100px, -50px, 0) scale(0.5);
      z-index: 10; }
    100% {
      -moz-transform: translate3d(100px, -330px, 0) scale(1.6);
      z-index: 100; } }
  @-moz-keyframes sp_current_image {
    0% {
      -moz-transform: translate3d(-100px, -50px, 0) scale(0.5);
      z-index: 10; }
    100% {
      -moz-transform: translate3d(-120px, -490px, 0) scale(2.2);
      z-index: 100; } }
  @keyframes left_button_over {
    0% {
      transform: rotate(0deg); }
    10% {
      transform: rotate(25deg); }
    20% {
      transform: rotate(0deg); }
    30% {
      transform: rotate(-25deg); }
    40% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(0deg); } }
  @keyframes right_button_over {
    0% {
      transform: rotate(180deg); }
    10% {
      transform: rotate(205deg); }
    20% {
      transform: rotate(180deg); }
    30% {
      transform: rotate(155deg); }
    40% {
      transform: rotate(180deg); }
    100% {
      transform: rotate(180deg); } }
  @keyframes car_updown {
    0% {
      transform: translate3d(0, 0, 0); }
    10% {
      transform: translate3d(0, 1px, 0); }
    30% {
      transform: translate3d(0, 1px, 0); }
    50% {
      transform: translate3d(0, 0, 0); }
    100% {
      transform: translate3d(0, 0, 0); } }
  @keyframes move_right0 {
    0% {
      transform: translate3d(368px, 0, 0); }
    100% {
      transform: translate3d(0px, 0, 0); } }
  @keyframes move_left0 {
    0% {
      transform: translate3d(-368px, 0, 0); }
    100% {
      transform: translate3d(0px, 0, 0); } }
  @keyframes move_right1 {
    0% {
      transform: translate3d(0px, 0, 0); }
    100% {
      transform: translate3d(-368px, 0, 0); } }
  @keyframes move_left1 {
    0% {
      transform: translate3d(-736px, 0, 0); }
    100% {
      transform: translate3d(-368px, 0, 0); } }
  @keyframes move_right2 {
    0% {
      transform: translate3d(-368px, 0, 0); }
    100% {
      transform: translate3d(-736px, 0, 0); } }
  @keyframes move_left2 {
    0% {
      transform: translate3d(-1104px, 0, 0); }
    100% {
      transform: translate3d(-736px, 0, 0); } }
  @keyframes move_right3 {
    0% {
      transform: translate3d(-736px, 0, 0); }
    100% {
      transform: translate3d(-1104px, 0, 0); } }
  @keyframes move_left3 {
    0% {
      transform: translate3d(-1472px, 0, 0); }
    100% {
      transform: translate3d(-1104px, 0, 0); } }
  @keyframes move_right4 {
    0% {
      transform: translate3d(-1104px, 0, 0); }
    100% {
      transform: translate3d(-1472px, 0, 0); } }
  @keyframes move_left4 {
    0% {
      transform: translate3d(-1840px, 0, 0); }
    100% {
      transform: translate3d(-1472px, 0, 0); } }
  @keyframes move_right5 {
    0% {
      transform: translate3d(-1472px, 0, 0); }
    100% {
      transform: translate3d(-1840px, 0, 0); } }
  @keyframes move_left5 {
    0% {
      transform: translate3d(-2208px, 0, 0); }
    100% {
      transform: translate3d(-1840px, 0, 0); } }
  @keyframes move_right6 {
    0% {
      transform: translate3d(-1840px, 0, 0); }
    100% {
      transform: translate3d(-2208px, 0, 0); } }
  @keyframes move_left6 {
    0% {
      transform: translate3d(-2576px, 0, 0); }
    100% {
      transform: translate3d(-2208px, 0, 0); } }
  @keyframes move_right7 {
    0% {
      transform: translate3d(-2208px, 0, 0); }
    100% {
      transform: translate3d(-2576px, 0, 0); } }
  @keyframes move_left7 {
    0% {
      transform: translate3d(-2944px, 0, 0); }
    100% {
      transform: translate3d(-2576px, 0, 0); } }
  @keyframes move_right8 {
    0% {
      transform: translate3d(-2576px, 0, 0); }
    100% {
      transform: translate3d(-2944px, 0, 0); } }
  @keyframes move_left8 {
    0% {
      transform: translate3d(-3312px, 0, 0); }
    100% {
      transform: translate3d(-2944px, 0, 0); } }
  @keyframes move_right9 {
    0% {
      transform: translate3d(-2944px, 0, 0); }
    100% {
      transform: translate3d(-3312px, 0, 0); } }
  @keyframes move_left9 {
    0% {
      transform: translate3d(-3680px, 0, 0); }
    100% {
      transform: translate3d(-3312px, 0, 0); } }
  @keyframes move_right10 {
    0% {
      transform: translate3d(-3312px, 0, 0); }
    100% {
      transform: translate3d(-3680px, 0, 0); } }
  @keyframes move_left10 {
    0% {
      transform: translate3d(-4048px, 0, 0); }
    100% {
      transform: translate3d(-3680px, 0, 0); } }
  @keyframes move_right11 {
    0% {
      transform: translate3d(-3680px, 0, 0); }
    100% {
      transform: translate3d(-4048px, 0, 0); } }
  @keyframes move_left11 {
    0% {
      transform: translate3d(-4416px, 0, 0); }
    100% {
      transform: translate3d(-4048px, 0, 0); } }
  @keyframes move_right12 {
    0% {
      transform: translate3d(-4048px, 0, 0); }
    100% {
      transform: translate3d(-4416px, 0, 0); } }
  @keyframes move_left12 {
    0% {
      transform: translate3d(-4784px, 0, 0); }
    100% {
      transform: translate3d(-4416px, 0, 0); } }
  @keyframes move_right13 {
    0% {
      transform: translate3d(-4416px, 0, 0); }
    100% {
      transform: translate3d(-4784px, 0, 0); } }
  @keyframes move_left13 {
    0% {
      transform: translate3d(-5152px, 0, 0); }
    100% {
      transform: translate3d(-4784px, 0, 0); } }
  @keyframes sky_move_right0 {
    0% {
      transform: translate3d(40px, 0, 0); }
    100% {
      transform: translate3d(0px, 0, 0); } }
  @keyframes sky_move_left0 {
    0% {
      transform: translate3d(-40px, 0, 0); }
    100% {
      transform: translate3d(0px, 0, 0); } }
  @keyframes sky_move_right1 {
    0% {
      transform: translate3d(0px, 0, 0); }
    100% {
      transform: translate3d(-40px, 0, 0); } }
  @keyframes sky_move_left1 {
    0% {
      transform: translate3d(-80px, 0, 0); }
    100% {
      transform: translate3d(-40px, 0, 0); } }
  @keyframes sky_move_right2 {
    0% {
      transform: translate3d(-40px, 0, 0); }
    100% {
      transform: translate3d(-80px, 0, 0); } }
  @keyframes sky_move_left2 {
    0% {
      transform: translate3d(-120px, 0, 0); }
    100% {
      transform: translate3d(-80px, 0, 0); } }
  @keyframes sky_move_right3 {
    0% {
      transform: translate3d(-80px, 0, 0); }
    100% {
      transform: translate3d(-120px, 0, 0); } }
  @keyframes sky_move_left3 {
    0% {
      transform: translate3d(-160px, 0, 0); }
    100% {
      transform: translate3d(-120px, 0, 0); } }
  @keyframes sky_move_right4 {
    0% {
      transform: translate3d(-120px, 0, 0); }
    100% {
      transform: translate3d(-160px, 0, 0); } }
  @keyframes sky_move_left4 {
    0% {
      transform: translate3d(-200px, 0, 0); }
    100% {
      transform: translate3d(-160px, 0, 0); } }
  @keyframes sky_move_right5 {
    0% {
      transform: translate3d(-160px, 0, 0); }
    100% {
      transform: translate3d(-200px, 0, 0); } }
  @keyframes sky_move_left5 {
    0% {
      transform: translate3d(-240px, 0, 0); }
    100% {
      transform: translate3d(-200px, 0, 0); } }
  @keyframes sky_move_right6 {
    0% {
      transform: translate3d(-200px, 0, 0); }
    100% {
      transform: translate3d(-240px, 0, 0); } }
  @keyframes sky_move_left6 {
    0% {
      transform: translate3d(-280px, 0, 0); }
    100% {
      transform: translate3d(-240px, 0, 0); } }
  @keyframes sky_move_right7 {
    0% {
      transform: translate3d(-240px, 0, 0); }
    100% {
      transform: translate3d(-280px, 0, 0); } }
  @keyframes sky_move_left7 {
    0% {
      transform: translate3d(-320px, 0, 0); }
    100% {
      transform: translate3d(-280px, 0, 0); } }
  @keyframes sky_move_right8 {
    0% {
      transform: translate3d(-280px, 0, 0); }
    100% {
      transform: translate3d(-320px, 0, 0); } }
  @keyframes sky_move_left8 {
    0% {
      transform: translate3d(-360px, 0, 0); }
    100% {
      transform: translate3d(-320px, 0, 0); } }
  @keyframes sky_move_right9 {
    0% {
      transform: translate3d(-320px, 0, 0); }
    100% {
      transform: translate3d(-360px, 0, 0); } }
  @keyframes sky_move_left9 {
    0% {
      transform: translate3d(-400px, 0, 0); }
    100% {
      transform: translate3d(-360px, 0, 0); } }
  @keyframes sky_move_right10 {
    0% {
      transform: translate3d(-360px, 0, 0); }
    100% {
      transform: translate3d(-400px, 0, 0); } }
  @keyframes sky_move_left10 {
    0% {
      transform: translate3d(-440px, 0, 0); }
    100% {
      transform: translate3d(-400px, 0, 0); } }
  @keyframes sky_move_right11 {
    0% {
      transform: translate3d(-400px, 0, 0); }
    100% {
      transform: translate3d(-440px, 0, 0); } }
  @keyframes sky_move_left11 {
    0% {
      transform: translate3d(-480px, 0, 0); }
    100% {
      transform: translate3d(-440px, 0, 0); } }
  @keyframes sky_move_right12 {
    0% {
      transform: translate3d(-440px, 0, 0); }
    100% {
      transform: translate3d(-480px, 0, 0); } }
  @keyframes sky_move_left12 {
    0% {
      transform: translate3d(-520px, 0, 0); }
    100% {
      transform: translate3d(-480px, 0, 0); } }
  @keyframes sky_move_right13 {
    0% {
      transform: translate3d(-480px, 0, 0); }
    100% {
      transform: translate3d(-520px, 0, 0); } }
  @keyframes sky_move_left13 {
    0% {
      transform: translate3d(-560px, 0, 0); }
    100% {
      transform: translate3d(-520px, 0, 0); } }
  @keyframes current_image {
    0% {
      transform: translate3d(-100px, -50px, 0) scale(0.5);
      z-index: 10; }
    100% {
      transform: translate3d(100px, -330px, 0) scale(1.6);
      z-index: 100; } }
  @keyframes sp_current_image {
    0% {
      transform: translate3d(-100px, -50px, 0) scale(0.5);
      z-index: 10; }
    100% {
      transform: translate3d(-120px, -490px, 0) scale(2.2);
      z-index: 100; } }
  #history {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: -15px;
    padding: 0;
    width: 1200px;
    height: 620px;
    position: relative;
    overflow: hidden; }
    #history #background {
      width: 3287px;
      height: 620px;
      overflow: hidden;
      position: absolute;
      left: -400px;
      top: 0px;
      background-image: url(/resources/images/60th/history_back.png);
      z-index: 1; }
    #history #road {
      width: 6574px;
      height: 330px;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 290px;
      background-image: url(/resources/images/60th/history_road.png);
      z-index: 300; }
    #history #board {
      width: 6574px;
      height: 620px;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0px;
      z-index: 310; }
      #history #board .his_contents {
        width: 144px;
        height: 160px;
        position: absolute;
        z-index: 10; }
        #history #board .his_contents.current {
          -webkit-animation: current_image 0.2s ease-in forwards;
          -moz-animation: current_image 0.2s ease-in forwards;
          animation: current_image 0.2s ease-in forwards;
          width: 374px; }
          #history #board .his_contents.current .his_message {
            width: 374px !important;
            padding: 5px !important;
            background: #3CC;
            font-size: 19px !important;
            font-weight: bold; }
          #history #board .his_contents.current .his_image {
            width: 374px !important; }
        #history #board .his_contents .his_message {
          width: 100%;
          height: 60px;
          margin-bottom: 14px;
          color: white;
          font-size: 10px;
          text-shadow: 1px 1px 0.5px #333;
          display: table-cell;
          vertical-align: bottom; }
        #history #board .his_contents .his_image {
          width: 138px; }
          #history #board .his_contents .his_image img {
            width: 100%;
            box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.6); }
        #history #board .his_contents#his_content1958 {
          left: 530px;
          top: 380px; }
        #history #board .his_contents#his_content1962 {
          left: 898px;
          top: 380px; }
        #history #board .his_contents#his_content1965 {
          left: 1266px;
          top: 380px; }
        #history #board .his_contents#his_content1985 {
          left: 1634px;
          top: 380px; }
        #history #board .his_contents#his_content1994 {
          left: 2002px;
          top: 380px; }
        #history #board .his_contents#his_content1996 {
          left: 2370px;
          top: 380px; }
        #history #board .his_contents#his_content1999 {
          left: 2738px;
          top: 380px; }
        #history #board .his_contents#his_content2002 {
          left: 3106px;
          top: 380px; }
        #history #board .his_contents#his_content2009 {
          left: 3474px;
          top: 380px; }
        #history #board .his_contents#his_content2011_1 {
          left: 3842px;
          top: 380px; }
        #history #board .his_contents#his_content2011_2 {
          left: 4210px;
          top: 380px; }
        #history #board .his_contents#his_content2016_1 {
          left: 4578px;
          top: 380px; }
        #history #board .his_contents#his_content2016_2 {
          left: 4946px;
          top: 380px; }
        #history #board .his_contents#his_content2018 {
          left: 5314px;
          top: 380px; }
    #history #car {
      width: 131px;
      height: 66px;
      overflow: hidden;
      position: absolute;
      left: 400px;
      top: 535px;
      z-index: 350; }
      #history #car.updown {
        -webkit-animation: car_updown 0.4s linear infinite;
        -moz-animation: car_updown 0.4s linear infinite;
        animation: car_updown 0.4s linear infinite; }
      #history #car div {
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-image: url(/resources/images/60th/history_car.png); }
        #history #car div.turn_left {
          transform: rotate3d(0, 1, 0, 180deg); }
    #history .move_right0 {
      -webkit-animation: move_right0 1.5s linear forwards;
      -moz-animation: move_right0 1.5s linear forwards;
      animation: move_right0 1.5s linear forwards; }
    #history .move_left0 {
      -webkit-animation: move_left0 1.5s linear forwards;
      -moz-animation: move_left0 1.5s linear forwards;
      animation: move_left0 1.5s linear forwards; }
    #history .move_right1 {
      -webkit-animation: move_right1 1.5s linear forwards;
      -moz-animation: move_right1 1.5s linear forwards;
      animation: move_right1 1.5s linear forwards; }
    #history .move_left1 {
      -webkit-animation: move_left1 1.5s linear forwards;
      -moz-animation: move_left1 1.5s linear forwards;
      animation: move_left1 1.5s linear forwards; }
    #history .move_right2 {
      -webkit-animation: move_right2 1.5s linear forwards;
      -moz-animation: move_right2 1.5s linear forwards;
      animation: move_right2 1.5s linear forwards; }
    #history .move_left2 {
      -webkit-animation: move_left2 1.5s linear forwards;
      -moz-animation: move_left2 1.5s linear forwards;
      animation: move_left2 1.5s linear forwards; }
    #history .move_right3 {
      -webkit-animation: move_right3 1.5s linear forwards;
      -moz-animation: move_right3 1.5s linear forwards;
      animation: move_right3 1.5s linear forwards; }
    #history .move_left3 {
      -webkit-animation: move_left3 1.5s linear forwards;
      -moz-animation: move_left3 1.5s linear forwards;
      animation: move_left3 1.5s linear forwards; }
    #history .move_right4 {
      -webkit-animation: move_right4 1.5s linear forwards;
      -moz-animation: move_right4 1.5s linear forwards;
      animation: move_right4 1.5s linear forwards; }
    #history .move_left4 {
      -webkit-animation: move_left4 1.5s linear forwards;
      -moz-animation: move_left4 1.5s linear forwards;
      animation: move_left4 1.5s linear forwards; }
    #history .move_right5 {
      -webkit-animation: move_right5 1.5s linear forwards;
      -moz-animation: move_right5 1.5s linear forwards;
      animation: move_right5 1.5s linear forwards; }
    #history .move_left5 {
      -webkit-animation: move_left5 1.5s linear forwards;
      -moz-animation: move_left5 1.5s linear forwards;
      animation: move_left5 1.5s linear forwards; }
    #history .move_right6 {
      -webkit-animation: move_right6 1.5s linear forwards;
      -moz-animation: move_right6 1.5s linear forwards;
      animation: move_right6 1.5s linear forwards; }
    #history .move_left6 {
      -webkit-animation: move_left6 1.5s linear forwards;
      -moz-animation: move_left6 1.5s linear forwards;
      animation: move_left6 1.5s linear forwards; }
    #history .move_right7 {
      -webkit-animation: move_right7 1.5s linear forwards;
      -moz-animation: move_right7 1.5s linear forwards;
      animation: move_right7 1.5s linear forwards; }
    #history .move_left7 {
      -webkit-animation: move_left7 1.5s linear forwards;
      -moz-animation: move_left7 1.5s linear forwards;
      animation: move_left7 1.5s linear forwards; }
    #history .move_right8 {
      -webkit-animation: move_right8 1.5s linear forwards;
      -moz-animation: move_right8 1.5s linear forwards;
      animation: move_right8 1.5s linear forwards; }
    #history .move_left8 {
      -webkit-animation: move_left8 1.5s linear forwards;
      -moz-animation: move_left8 1.5s linear forwards;
      animation: move_left8 1.5s linear forwards; }
    #history .move_right9 {
      -webkit-animation: move_right9 1.5s linear forwards;
      -moz-animation: move_right9 1.5s linear forwards;
      animation: move_right9 1.5s linear forwards; }
    #history .move_left9 {
      -webkit-animation: move_left9 1.5s linear forwards;
      -moz-animation: move_left9 1.5s linear forwards;
      animation: move_left9 1.5s linear forwards; }
    #history .move_right10 {
      -webkit-animation: move_right10 1.5s linear forwards;
      -moz-animation: move_right10 1.5s linear forwards;
      animation: move_right10 1.5s linear forwards; }
    #history .move_left10 {
      -webkit-animation: move_left10 1.5s linear forwards;
      -moz-animation: move_left10 1.5s linear forwards;
      animation: move_left10 1.5s linear forwards; }
    #history .move_right11 {
      -webkit-animation: move_right11 1.5s linear forwards;
      -moz-animation: move_right11 1.5s linear forwards;
      animation: move_right11 1.5s linear forwards; }
    #history .move_left11 {
      -webkit-animation: move_left11 1.5s linear forwards;
      -moz-animation: move_left11 1.5s linear forwards;
      animation: move_left11 1.5s linear forwards; }
    #history .move_right12 {
      -webkit-animation: move_right12 1.5s linear forwards;
      -moz-animation: move_right12 1.5s linear forwards;
      animation: move_right12 1.5s linear forwards; }
    #history .move_left12 {
      -webkit-animation: move_left12 1.5s linear forwards;
      -moz-animation: move_left12 1.5s linear forwards;
      animation: move_left12 1.5s linear forwards; }
    #history .move_right13 {
      -webkit-animation: move_right13 1.5s linear forwards;
      -moz-animation: move_right13 1.5s linear forwards;
      animation: move_right13 1.5s linear forwards; }
    #history .move_left13 {
      -webkit-animation: move_left13 1.5s linear forwards;
      -moz-animation: move_left13 1.5s linear forwards;
      animation: move_left13 1.5s linear forwards; }
    #history .sky_move_right0 {
      -webkit-animation: sky_move_right0 1.5s linear forwards;
      -moz-animation: sky_move_right0 1.5s linear forwards;
      animation: sky_move_right0 1.5s linear forwards; }
    #history .sky_move_left0 {
      -webkit-animation: sky_move_left0 1.5s linear forwards;
      -moz-animation: sky_move_left0 1.5s linear forwards;
      animation: sky_move_left0 1.5s linear forwards; }
    #history .sky_move_right1 {
      -webkit-animation: sky_move_right1 1.5s linear forwards;
      -moz-animation: sky_move_right1 1.5s linear forwards;
      animation: sky_move_right1 1.5s linear forwards; }
    #history .sky_move_left1 {
      -webkit-animation: sky_move_left1 1.5s linear forwards;
      -moz-animation: sky_move_left1 1.5s linear forwards;
      animation: sky_move_left1 1.5s linear forwards; }
    #history .sky_move_right2 {
      -webkit-animation: sky_move_right2 1.5s linear forwards;
      -moz-animation: sky_move_right2 1.5s linear forwards;
      animation: sky_move_right2 1.5s linear forwards; }
    #history .sky_move_left2 {
      -webkit-animation: sky_move_left2 1.5s linear forwards;
      -moz-animation: sky_move_left2 1.5s linear forwards;
      animation: sky_move_left2 1.5s linear forwards; }
    #history .sky_move_right3 {
      -webkit-animation: sky_move_right3 1.5s linear forwards;
      -moz-animation: sky_move_right3 1.5s linear forwards;
      animation: sky_move_right3 1.5s linear forwards; }
    #history .sky_move_left3 {
      -webkit-animation: sky_move_left3 1.5s linear forwards;
      -moz-animation: sky_move_left3 1.5s linear forwards;
      animation: sky_move_left3 1.5s linear forwards; }
    #history .sky_move_right4 {
      -webkit-animation: sky_move_right4 1.5s linear forwards;
      -moz-animation: sky_move_right4 1.5s linear forwards;
      animation: sky_move_right4 1.5s linear forwards; }
    #history .sky_move_left4 {
      -webkit-animation: sky_move_left4 1.5s linear forwards;
      -moz-animation: sky_move_left4 1.5s linear forwards;
      animation: sky_move_left4 1.5s linear forwards; }
    #history .sky_move_right5 {
      -webkit-animation: sky_move_right5 1.5s linear forwards;
      -moz-animation: sky_move_right5 1.5s linear forwards;
      animation: sky_move_right5 1.5s linear forwards; }
    #history .sky_move_left5 {
      -webkit-animation: sky_move_left5 1.5s linear forwards;
      -moz-animation: sky_move_left5 1.5s linear forwards;
      animation: sky_move_left5 1.5s linear forwards; }
    #history .sky_move_right6 {
      -webkit-animation: sky_move_right6 1.5s linear forwards;
      -moz-animation: sky_move_right6 1.5s linear forwards;
      animation: sky_move_right6 1.5s linear forwards; }
    #history .sky_move_left6 {
      -webkit-animation: sky_move_left6 1.5s linear forwards;
      -moz-animation: sky_move_left6 1.5s linear forwards;
      animation: sky_move_left6 1.5s linear forwards; }
    #history .sky_move_right7 {
      -webkit-animation: sky_move_right7 1.5s linear forwards;
      -moz-animation: sky_move_right7 1.5s linear forwards;
      animation: sky_move_right7 1.5s linear forwards; }
    #history .sky_move_left7 {
      -webkit-animation: sky_move_left7 1.5s linear forwards;
      -moz-animation: sky_move_left7 1.5s linear forwards;
      animation: sky_move_left7 1.5s linear forwards; }
    #history .sky_move_right8 {
      -webkit-animation: sky_move_right8 1.5s linear forwards;
      -moz-animation: sky_move_right8 1.5s linear forwards;
      animation: sky_move_right8 1.5s linear forwards; }
    #history .sky_move_left8 {
      -webkit-animation: sky_move_left8 1.5s linear forwards;
      -moz-animation: sky_move_left8 1.5s linear forwards;
      animation: sky_move_left8 1.5s linear forwards; }
    #history .sky_move_right9 {
      -webkit-animation: sky_move_right9 1.5s linear forwards;
      -moz-animation: sky_move_right9 1.5s linear forwards;
      animation: sky_move_right9 1.5s linear forwards; }
    #history .sky_move_left9 {
      -webkit-animation: sky_move_left9 1.5s linear forwards;
      -moz-animation: sky_move_left9 1.5s linear forwards;
      animation: sky_move_left9 1.5s linear forwards; }
    #history .sky_move_right10 {
      -webkit-animation: sky_move_right10 1.5s linear forwards;
      -moz-animation: sky_move_right10 1.5s linear forwards;
      animation: sky_move_right10 1.5s linear forwards; }
    #history .sky_move_left10 {
      -webkit-animation: sky_move_left10 1.5s linear forwards;
      -moz-animation: sky_move_left10 1.5s linear forwards;
      animation: sky_move_left10 1.5s linear forwards; }
    #history .sky_move_right11 {
      -webkit-animation: sky_move_right11 1.5s linear forwards;
      -moz-animation: sky_move_right11 1.5s linear forwards;
      animation: sky_move_right11 1.5s linear forwards; }
    #history .sky_move_left11 {
      -webkit-animation: sky_move_left11 1.5s linear forwards;
      -moz-animation: sky_move_left11 1.5s linear forwards;
      animation: sky_move_left11 1.5s linear forwards; }
    #history .sky_move_right12 {
      -webkit-animation: sky_move_right12 1.5s linear forwards;
      -moz-animation: sky_move_right12 1.5s linear forwards;
      animation: sky_move_right12 1.5s linear forwards; }
    #history .sky_move_left12 {
      -webkit-animation: sky_move_left12 1.5s linear forwards;
      -moz-animation: sky_move_left12 1.5s linear forwards;
      animation: sky_move_left12 1.5s linear forwards; }
    #history .sky_move_right13 {
      -webkit-animation: sky_move_right13 1.5s linear forwards;
      -moz-animation: sky_move_right13 1.5s linear forwards;
      animation: sky_move_right13 1.5s linear forwards; }
    #history .sky_move_left13 {
      -webkit-animation: sky_move_left13 1.5s linear forwards;
      -moz-animation: sky_move_left13 1.5s linear forwards;
      animation: sky_move_left13 1.5s linear forwards; }
    @media (max-width: 575px) {
      #history {
        height: 850px; }
        #history #background {
          height: 850px;
          background-image: url(/resources/images/60th/history_back_sp.png); }
        #history #road {
          top: 400px;
          height: 450px;
          background-image: url(/resources/images/60th/history_road_sp.png); }
        #history #board {
          height: 850px; }
          #history #board .his_contents {
            top: 590px !important; }
            #history #board .his_contents.current {
              -webkit-animation: sp_current_image 0.2s ease-in forwards;
              -moz-animation: sp_current_image 0.2s ease-in forwards;
              animation: sp_current_image 0.2s ease-in forwards; }
        #history #car {
          top: 760px; } }

  #history_buttons {
    margin: auto;
    margin-top: 110px;
    margin-bottom: 80px;
    margin-left: -16px;
    padding: 0;
    width: 1202px;
    height: 620px;
    position: relative;
    overflow: hidden; }
    #history_buttons .side {
      width: 119px;
      height: 100%;
      z-index: 10;
      position: absolute;
      top: 0; }
      #history_buttons .side#left_side {
        background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
        left: 0; }
      #history_buttons .side#right_side {
        background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
        left: 1083px; }
    #history_buttons .r_button {
      width: 48px;
      height: 48px;
      background-image: url(/resources/images/60th/history_left_button.png);
      background-size: cover;
      margin-top: 410px;
      cursor: pointer;
      position: absolute;
      z-index: 20; }
      #history_buttons .r_button#left_button {
        margin-left: 40px; }
        #history_buttons .r_button#left_button:hover {
          -webkit-animation: left_button_over 1s linear;
          -moz-animation: left_button_over 1s linear;
          animation: left_button_over 1s linear; }
      #history_buttons .r_button#right_button {
        right: 40px;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        transform: rotate(180deg); }
        #history_buttons .r_button#right_button:hover {
          -webkit-animation: right_button_over 1s linear;
          -moz-animation: right_button_over 1s linear;
          animation: right_button_over 1s linear; }
    @media (max-width: 575px) {
      #history_buttons {
        height: 850px;
        margin-top: 285px; }
        #history_buttons .r_button {
          width: 144px;
          height: 144px;
          margin-top: 100px; }
        #history_buttons#left_button {
          margin-left: 100px; }
        #history_buttons#right_button {
          right: 100px; } }

  /*# sourceMappingURL=history.css.map */
