body.birthday, .birthday_2023, .birthday_2024
  background url(image_path+'/birthday/bg.gif') repeat
  +mediaSp()
    padding-top 0
.m__birthday
  animation: fadeIn 5s ease 0s 1 normal;
  -webkit-animation: fadeIn 5s ease 0s 1 normal;
  text-align center
  background #FFFFFF
  margin 0 auto
  padding 2px
  width 750px
  border-right 1px solid #396
  border-left 1px solid #396
  padding-bottom 60px
  box-sizing content-box
  #animation_container
    margin 10px auto
  ul
    margin 20px auto
    display inline-block
    padding-right 20px
    li
      text-align left
      regular()
      margin 10px 0
      background url(image_path+'/birthday/mark.jpg') no-repeat left center
      padding-left 20px
      a
        color #060
  +mediaSp()
    margin 15px auto
    width 95%
    img
      max-width 100%
      height auto
    #animation_container,#canvas,#dom_overlay_container
      max-height 375px
      max-width 100%
    #animation_container
      margin 10px auto
      position relative
      overflow hidden
    #canvas
      position absolute
      top 0
      left 0

@keyframes fadeIn {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0 }
  100% { opacity: 1 }
}
