.m__sitemap
  &__content
    padding 50px 0
    +mediaSp()
      padding 30px 0
    &--top
      margin-top 90px
      +mediaSp()
        margin-top 30px
    &--bottom
      padding-bottom 150px
      +mediaSp()
        padding-bottom 50px
    .m__common-heading__title
      text-align left
      padding 0 15px 5px
      border-bottom 1px solid border-light-gray
      font-size 2.6rem
      +mediaSp()
        font-size 1.5rem
        padding 0 10px 5px
        margin 0 -10px
  &__list
    display flex
    font-size 1.6rem
    padding 0 15px
    margin-top 30px
    +mediaSp()
      display block
      margin 15px -10px 0
      padding 0 10px
      font-size 1.3rem
    a
      color font-black
    .column
      &--left
        width 62%
        +mediaSp()
          width 100%
      +mediaSp()
        &--right
         margin-top 25px
      ul
        li + li
          margin-top 30px
          +mediaSp()
            margin-top 25px
      .submenu
        regular()
        padding-left 1em
        +mediaSp()
          padding-left 0.5em
        ul li
          margin-top 0
        ul.list
          li
            text-indent -1em
            padding-left 1em
            &::before
              content '・'
          &.plan
            display flex
            flex-wrap wrap
            li.blank
              width 100%
              &::before
                display none
              +mediaSp()
                display none
          &.access
            a
              padding-right 1em
              &.block
                +mediaPc()
                  display block
              + a
                text-indent 0
                display inline-block
                padding-right 0