.m__english
  &__mv
    position fixed
    left 0
    top 163px
    width 100%
    height 90vh
    z-index 1
    background url(image_path+'/foreign/img_main_photo.jpg') center -60px no-repeat
    background-size cover
    +mediaSp()
      height 60vw
      top 55px
      background url(image_path+'/foreign/img_main_photo.jpg') center top no-repeat
      background-size 120%
  &__nav
    width 100%
    background #fff
    z-index 101
    position fixed
    &.fixed
      top 60px
      background rgba(255,255,255,0.8)
    > ul
      display flex
      align-items center
      width 1180px
      margin 0 auto
      +mediaSp()
        display none
      > li
        position relative
        font-size 2rem
        text-align center
        padding 15px 10px
        flex 1
        white-space nowrap
        alphabet()
        a
          color font-black
          text-decoration none
          &:hover
            color font-green
        &.main-menu
          cursor pointer
          color font-black
          &:hover
            color font-green
            transition 0.5s
        .sub-menu
          position absolute
          top 60px
          left 0
          padding-top 55px
          display none
          &.open
            display block
          ul
            background #fff
            padding 20px
            position relative
            box-shadow 0 2px 8px rgba(0 0 0 30%)
            font-size 1.6rem
            li
              text-align left
              &:not(:first-child)
                margin-top 13px
              a
                text-decoration underline
                color font-green
                &:hover
                  color #6cd
            &::before
              content ''
              display block
              position absolute
              width 15px
              height 15px
              background #fff
              transform rotate(45deg) skew(15deg,15deg)
              top -6px
              left 30px
              box-shadow 0px -1px 5px 0 rgba(0 0 0 30%)
            &::after
              content ''
              display block
              position absolute
              width 22px
              height 17px
              background #fff
              top 0
              left 27px
        &:nth-child(2)
          .sub-menu
            left 40px
            padding-top 37px
    &.chinese
      > ul
        > li
          chinese()
          .sub-menu
            top 40px
            left 40px
          &:nth-child(2), &:nth-child(5)
            .sub-menu
              left 70px
              top 60px
  &__top-contents
    margin-top calc(90vh - 95px)
    position relative
    z-index 2
    background #fff
    +mediaSp()
      margin-top calc(60vw - 37px)
    .l__contents__inner--width
      position relative
      width 980px
      +mediaSp()
        width 100%
    h2.m__common-heading__title
      padding 30px 0
      font-size 2.6rem
      bold()
      +mediaSp()
        font-size 1.6rem
        padding 20px 0
        word-break break-word
    .m__common__list
      padding 30px 0
      font-size 1.6rem
  &__top-image
    background url(image_path+'/foreign/img_bottom_photo.jpg') center center no-repeat
    background-size 120% auto
    width 100%
    height 30.3vw
    +mediaSp()
      background-size 110% auto
  &__business-hours
    margin 50px auto 0
    width 680px
    padding 10px 40px
    border 3px solid border-red
    color font-red
    font-size 1.8rem
    +mediaSp()
      width 100%
      font-size 1.3rem
      margin 30px auto 0
      padding 15px 20px 0
      border 2px solid border-red
      p
        margin-bottom 1em
        text-indent -0.5em
        padding-left 0.5em
  &__detail
    padding 60px 0
    +mediaSp()
      padding 40px 0
    .m__common-heading__title
      .alphabet
        font-size 2.6rem
        +mediaSp()
          font-size 1.6rem
    .m__common__list
      display flex
      flex-wrap wrap
      padding 20px 0
      +mediaSp()
        display block
        padding 15px 0
      li
        width 100%
        font-size 1.6rem
        &::before
          color #f0dd4d
        +mediaPc()
          &:nth-child(n+2):nth-child(-n+7)
            width auto
            margin-right 20px
        +mediaSp()
          font-size 1.3rem
  &__access
    text-align center
    padding 90px 0
    width 1080px
    margin 0 auto
    +mediaSp()
      width 100%
      padding 50px 0
    h3
      line-height 1
      .alphabet
        font-size 2.6rem
        +mediaSp()
          font-size 1.6rem
    .logo
      text-align center
      margin-top 60px
      width 300px
      height 30px
      fill #3b8d6b
      display inline-block
      +mediaSp()
        width 150px
        height 15px
        margin-top 10px
    ul
      margin-top 20px
      border-top 1px solid border-black
      +mediaSp()
        margin-top 15px
      .school
        alphabet()
        bold()
        font-size 2rem
        +mediaSp()
          font-size 1.3rem
      li
        border-bottom 1px solid border-black
        padding 30px 0
        regular()
        +mediaSp()
          padding 20px 10px
        .school
          alphabet()
          bold()
          font-size 2rem
          +mediaSp()
            font-size 1.3rem
        p
          font-size 1.6rem
          +mediaSp()
            font-size 1.3rem
        span
          margin-right .5em
        a
          &.telNumber
            +mediaPc()
              pointer-events none
              color font-black
              text-decoration none
          +mediaSp()
            medium()
          &.email
            medium()
    .notice
      font-size 1.3rem
      color font-red
      display inline-block
      padding-top 5px
      +mediaSp()
        font-size 1.2rem
  &__btn-area
    padding-bottom 150px
    margin 0 auto
    display flex
    justify-content space-between
    width 730px
    +mediaSp()
      width 100%
      display block
      padding-bottom 50px
    li
      +mediaPc()
        &:nth-child(1)
          .m__common__flex-arrow
            padding 16px 70px 16px 50px
        &:nth-child(2)
          .m__common__flex-arrow
            width 300px
            &:before
              right 100px
            &::after
              right 102px
            &:hover
              &:before
                right 90px
              &::after
                right 92px
      +mediaSp()
        &:nth-child(1)
          margin-bottom 10px
          .m__common__flex-arrow
            padding 16px 60px 16px 40px
        &:nth-child(2)
          .m__common__flex-arrow
            &::before
              right 50%
              margin-right -44px
            &::after
              right 50%
              margin-right -42px
        .m__common__flex-arrow
          width 100%
          &:active
            background bg-green02
  &__introduction
    margin-top 95px
    background bg-header
    padding 50px 0
    word-break break-word
    +mediaSp()
      padding 15px 0
      margin-top 30px
    p
      font-size 2.6rem
      text-align center
      color #fff
      medium()
      +mediaSp()
        font-size 1.3rem
  &__lower
    margin-top 90px
    +mediaSp()
      margin-top 30px
