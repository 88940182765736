.m__guide
  margin-top 100px
  font-size 1.6rem
  +mediaSp()
    margin 30px -10px
    font-size 1.3rem
    dt,dd
      padding 5px 10px
  dt
    padding-bottom 5px
    border-bottom 1px solid border-black
    +mediaSp()
      padding 5px 10px
    &::before
      content "▶"
      font-size 1.1rem
      vertical-align 2px
      color font-green
      margin-right 3px
      +mediaSp()
        font-size 0.8rem
  dd
    margin-bottom 80px
    regular()
    padding-top 10px
    +mediaSp()
      margin-bottom 40px
  .m__inner-list
    display flex
    flex-wrap wrap
    justify-content right
    width 295px
    +mediaSp()
      width 275px
    dt
      border-bottom none
      padding-bottom 0
      width 135px
      font-weight bold
      color font-red
      font-size 1.5rem
      &::before
       content none
      +mediaSp()
        padding 0
        width 115px
        font-size 1.3rem
    dd
      margin-bottom 0
      padding-top 0
      width 160px
      font-weight bold
      color font-red
      font-size 1.5rem
      +mediaSp()
        padding 0
        font-size 1.3rem
