.m__news
  position relative
  h2
    text-align center
    margin 150px 0 37px
    +mediaSp()
      margin 30px 0
  &__year
    a
      color #666
      text-decoration underline
      &:hover
        color font-green
    position relative
    background bg-light-gray
    +mediaSp()
      margin 0 -10px
    ul
      display flex
      align-items center
      padding 20px
      white-space nowrap
      +mediaSp()
        padding 16px 20px
        overflow-x scroll
      li
        border-bottom 0
        font-size 2.4rem
        font-family fontset-alphabet
        margin-right 40px
        bold()
        &:last-child
          margin-right 0
        +mediaSp()
          font-size 1.6rem
          margin-right 20px
    .leftArrow
      &:hover
        .arrow1
          fill font-green
      display none
      position absolute
      top 50%
      transform translateY(-50%)
      left 0
      padding 16px 8px
      background bg-light-gray
    .rightArrow
      display none
      &:hover
        .arrow1
          fill font-green
      position absolute
      transform translateY(-50%)
      top 50%
      right 0
      padding 16px 8px
      background bg-light-gray
  &__list
    border-top 1px solid border-black
    +mediaSp()
      margin 0 -10px
    a
      color #666
      &:hover
        color font-green
    li
      border-bottom 1px solid border-black
      font-size 1.6rem
      display block
      &:hover
        opacity .8
        transition .5s
      a
        display flex
        text-decoration none
        align-items baseline
        padding 36px 20px
        +mediaSp()
          display block
          padding 13px 10px
        .date
          color font-black
          +mediaPc()
            width 120px
          +mediaSp()
            font-size 1.2rem
        .title
          text-decoration underline
          flex 1
          +mediaSp()
            font-size 1.3rem
            display inline
      .label
        display flex
        align-items center
        +mediaSp()
          display inline-block
          vertical-align 0
          line-height 1
        span
          color #fff
          font-size 1.2rem
          padding 0 6px
          margin-right 10px
          background-color #99B0E3
          +mediaPc()
            line-height 1.4
          +mediaSp()
            margin-right 5px
            font-size 0.9rem
          &.new
            background-color #66cccc
          &.attention
            background-color #f09950
            letter-spacing 2px
          &.important
            background-color #f08066
            letter-spacing 2px
          &.etc
            background-color #99b0e3
          &:last-of-type
            margin-right 15px
            +mediaSp()
              margin-right 7px


  &__paging
    ul
      display flex
      align-items center
      justify-content center
      padding 60px 0
      +mediaSp()
        padding 30px 0 10px 0
      li
        margin-right 10px
        &:last-child
          margin-right 0
        a,span
          width 30px
          height 30px
          background bg-light-gray
          font-size 1.4rem
          display flex
          align-items center
          justify-content center
        a
          text-decoration none
          &:hover
            background font-green
            color #fff
            .arrow2
              fill #fff
        &.current
          span
            background font-green
            color #fff
  &__article
    font-size 1.6rem
    +mediaSp()
      margin 0 -10px
    h2
      font-size 3.2rem
      text-align left
      bold()
      margin 150px 0 100px 0
      +mediaSp()
        margin 30px 0
        font-size 1.6rem
        padding 0 10px
    &-inner
      border-bottom 1px solid border-black
      border-top 1px solid border-black
      +mediaSp()
        font-size 1.3rem
        padding 0 10px
      .date
        width 80px
        display inline-block
        +mediaSp()
          width 60px
      .label
        display inline-block
        vertical-align 5px
        +mediaSp()
          vertical-align text-bottom
        span
          color #fff
          font-size 1.2rem
          padding 0 6px
          margin-right 10px
          +mediaPc()
            line-height 1.4
          +mediaSp()
            margin-right 5px
            font-size 0.9rem
          &.new
            background-color #66cccc
          &.attention
            background-color #f09950
            letter-spacing 2px
          &.important
            background-color #f08066
            letter-spacing 2px
          &.etc
            background-color #99b0e3
          &:last-of-type
            margin-right 15px
            +mediaSp()
              margin-right 7px
      .content
        padding-bottom 60px
        +mediaSp()
          padding-bottom 30px
        p
         margin 30px 0
         img
          &.btn--sp100
            margin 0 auto 30px
            display block
            +mediaSp()
              width 100%
              height auto
              margin 0 auto 20px
        h2
          background bg-light-gray
          padding 16px 20px
          margin 30px 0
          font-size 1.6rem
          bold()
          +mediaSp()
            font-size 1.3rem
        h3
          border-left 6px solid #66bb99
          padding-left 20px
          line-height 30px
          margin 30px 0
        ul
          margin-bottom 30px
          li
            text-indent -1em
            padding-left 1em
            margin-bottom 6px
            &:before
              content:'●'
              color #ccc
            ul
              margin 6px 0 0
        ol
          margin-bottom 30px
          counter-reset item
          list-style-type none
          padding-left 0
          li
            text-indent -1.25em
            padding-left 1.25em
            margin-bottom 6px
            &:before
              counter-increment item
              content counter(item)'.'
              padding-right 5px
              color #3b8e6b
            ol
              margin 6px 0 0
        pre
          white-space pre-wrap
        .wp-caption
          margin-bottom 30px
          p
            margin 15px 0 0
            +mediaSp()
              margin 7px 0 0
          img
            max-width 100%
          +mediaSp()
            margin-bottom 15px
            img
              width 100%
    &-paging
      ul
        display flex
        align-items center
        justify-content center
        padding 60px 0
        +mediaSp()
          padding 30px 0 10px 0
        li
          span
            background bg-light-gray
            font-size 1.4rem
            display flex
            align-items center
            justify-content center
            +mediaSp()
              font-size 1.3rem
          a
            text-decoration none
            padding 3px 17px
            &:hover
              background font-green
              color #fff
              .arrow2
                fill #fff
          svg
            margin 0 5px
          &.prev
            margin-right 100px
            +mediaSp()
              margin-right 45px
    .news--corona
      p
        margin 0
      .newsMV
        margin 0 auto
        display block
        +mediaSp()
          width 100%
          height auto
      .leadText
        font-size 1.4rem
        text-align center
        margin-top 20px
      .flexlist
        display flex
        flex-wrap wrap
        justify-content space-evenly
        width 700px
        margin 0 auto
        +mediaSp()
          width 100%
          justify-content space-between
        li
          width 300px
          font-size 1.4rem
          regular()
          margin-bottom 20px
          padding-left 0
          +mediaSp()
            width 48%
          &:before
            content none
          &.float
            width 100%
            display flex
            flex-wrap wrap
            justify-content space-evenly
            margin-bottom 10px
            +mediaSp()
              justify-content space-between
            img
              display inline-block
              +mediaSp()
                width 48%
            .text
              width 630px
              margin 0 auto 10px
              text-indent 0
              +mediaSp()
                width 100%
                margin 0
                padding 0 6px
          img
            display block
            +mediaSp()
              width 100%
          span
            +mediaSp()
              display block
              text-indent 0
              padding 0 3px
