.m__coupon
  padding 70px 0 100px
  width 900px
  margin 90px auto 0
  +mediaSp()
    width 100%
    margin-top 30px
    padding 30px 10px 50px
  .lead
    font-size 2.6rem
    text-align center
    margin-bottom 20px
    +mediaSp()
      font-size 1.5rem
  img
    width 100%
  .notice
    margin-top 30px
    margin-left 17px
    +mediaSp()
      margin-left 0
    p
      text-indent -1em
      padding-left 1em
      font-size 1.6rem
      regular()
      +mediaSp()
        font-size 1.3rem