.m__press
  &__introduction
    margin-top 95px
    background bg-header
    font-size 2.6rem
    color #fff
    padding 40px 0 50px
    +mediaSp()
      padding 15px 0 20px
      margin-top 30px
    h3
      font-size 3.2rem
      medium()
      text-align center
      margin-bottom 5px
      +mediaSp()
        font-size 1.6rem
    p
      +mediaPc()
        width 1080px
      margin 0 auto
      line-height 1.4
      text-align center
      +mediaSp()
        font-size 1.3rem
  &__image
    text-align center
    padding 100px 0 80px
    +mediaSp()
      padding 40px 0 30px
      img
        width 200px
        height auto
  &__contact
    border-top 1px solid border-light-gray
    .m__form__description
      .tel-number
        medium()
        padding 20px
        border-bottom 1px solid border-light-gray
        +mediaSp()
          margin 0 -10px
          padding 10px
        a
          font-size 2.6rem
          vertical-align baseline
          padding-left 10px
          +mediaPc()
            color font-black
            pointer-events none
            text-decoration none
          +mediaSp()
            font-size 1.5rem
      .text
        border-bottom 0
        padding 20px 20px 0
        +mediaSp()
          padding 10px 0 0
    .m__form
      margin 0 -10px