.m__english__converting
  &__content
    padding 50px 0 150px
    font-size 1.6rem
    regular()
    word-break break-word
    +mediaSp()
      font-size 1.3rem
      padding 10px 0 60px
    +mediaPc()
      .l__contents__inner--width
        width 1020px
    .m__common-heading__english
      margin 40px 0 10px
      +mediaSp()
        margin-top 20px
        line-height 1.4
    ol
      counter-reset item
      li
        text-indent -1.3em
        padding-left 1.3em
        &::before
          counter-increment item 1
          margin-right 0.3em
          content counter(item)"."
        ol
          counter-reset item
          li::before
            margin-right 0.5em
          &.alpha
            li
              text-indent -1.45em
              padding-left 1.45em
              &::before
                content counter(item,lower-alpha)")"
          &.number
            li
              text-indent -1.9em
              padding-left 1.9em
              &::before
                content "("counter(item)")"
  &__fee
    .title
      font-size 1.8rem
      bold()
      +mediaSp()
        font-size 1.5rem
    p
      span
        margin-left 0.5em
      +mediaSp()
        + p
          margin-top 15px
        span
          margin-left 0
          display block
