.m__fireworks
  &__introduction
    background bg-header
    text-align center
    padding 40px 0 50px
    margin-top 100px
    color #fff
    line-height 1.5
    +mediaSp()
      margin-top 30px
      padding 13px 5px
    h3
      font-size 3.2rem
      medium()
      +mediaSp()
        font-size 1.6rem
      span
        font-size 2.6rem
        +mediaSp()
          font-size 1.3rem
    p
      &.date
        font-size 3.2rem
        medium()
        +mediaSp()
          font-size 1.6rem
      &.notice
        font-size 1.6rem
        margin-top 5px
        +mediaSp()
          font-size 1.3rem
  &__info-text
    padding 40px 0 50px
    width 800px
    margin 0 auto
    +mediaSp()
      width 100%
      padding 20px 0 0
    .text
      text-align center
      border 3px solid border-red
      color font-red
      font-size 2.6rem
      padding 20px 10px
      +mediaSp()
        font-size 1.3rem
        border 2px solid border-red
        padding 10px 5px
    .sub-text
      text-align center
      color font-red
      font-size 16px
      +mediaSp()
        font-size 12px
  &__image
    display flex
    justify-content center
    align-items center
    padding-bottom 100px
    .img01
      margin-right 20px
      +mediaSp()
        margin 0 0 20px
    +mediaSp()
      margin 0 -10px
      padding-bottom 50px
      display block
      img
        width 100%
    &.out
      opacity 0.5
  &__button-area
    padding 120px 0 150px
    text-align center
    .m__common-btn__arrow
      .m__common__flex-arrow
        width 260px
    +mediaSp()
      padding 50px 0
      background #fff
      margin-top 30px
    .m__common-btn__arrow--back
      .m__common__flex-arrow
        padding 16px 40px 16px 70px
        &::before,&::after
          transition width 0.3s, left 0.3s !important
          right auto
        &::before
          left 35px
        &::after
          left 38px
          transform rotate(45deg)
        +mediaPc()
          &:hover
            &::before
              left 25px
              right auto
            &::after
              left 28px
              right auto
        +mediaSp()
          &::before
            left 45px
          &::after
            left 48px
            transform rotate(45deg)
          &:active
            background bg-green02
            color #fff
            transition 0.3s linear !important
  &__detail
    padding 100px 20px 30px
    font-size 1.6rem
    border-bottom 1px solid border-light-gray
    &.no-border
      border-bottom none
      +mediaSp()
        padding-bottom 0
    +mediaSp()
      padding 20px 10px
      margin 0 -10px
      font-size 1.3rem
    dl
      display flex
      align-items baseline
      +mediaSp()
        margin-bottom 20px
      dt
        white-space nowrap
      p,a
        font-size 2rem
        display block
        +mediaSp()
          font-size 1.3rem
          &.ticket
            font-size 1.6rem
        &.inline
          display inline
          font-size 1.8rem
          &.fz16
            font-size 1.6rem
            +mediaSp()
              font-size 1.3rem
          +mediaSp()
            font-size 1.3rem
      p
        &.release
          color font-red
        &.red
          color font-vivid-red
    > p
      .telnumber
        +mediaPc()
          text-decoration none
          color font-black
          pointer-events none
      &.emp
        color font-red
      a
        padding 0 1em 0 0.5em
    .imgFlyer
      width 100%
      &:first-child
        margin-bottom 50px
        +mediaSp()
          margin-bottom 25px
  &__access
    padding 30px 20px 40px
    +mediaSp()
      padding 20px 10px
      margin 0 -10px
    p
      font-size 1.6rem
      +mediaSp()
        font-size 1.3rem
      .telnumber
        +mediaPc()
          text-decoration none
          color font-black
          pointer-events none
      a
        padding 0 1em 0 0.5em