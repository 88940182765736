.m__csr
  &__mv
    position fixed
    left 0
    top 60px
    width 100vw
    height 100vh
    z-index 1
    background url(image_path + '/company/csr/img_csr_mv.jpg') center center no-repeat
    background-size cover
    &::before
      content ""
      display block
      width 100vw
      height 100vh
      background rgba(bg-black, 0.3)
      position fixed
      left 0
      top 0
    +mediaSp()
      height 60vw
      top 55px

  &__contents
    margin-top calc(100vh - 95px)
    position relative
    z-index 2
    background bg-light-gray
    +mediaSp()
      margin-top calc(60vw - 37px)
    .l__contents__inner--width
      position relative
  &__text-block
    background #fff
    width 777px
    padding 100px 60px 120px
    +mediaSp()
      width auto
      padding 30px 10px
      margin 0 -10px
    p
      regular()
      width 590px
      font-size 1.6rem
      margin-top 5px
      +mediaSp()
        width 100%
        font-size 1.3rem
      + p
        margin-top 1.5em
        line-height 1.75
    a
      font-size 1.6rem
      +mediaSp()
        font-size 1.3rem
    .m__common-heading__title
      text-align left
      +mediaSp()
        font-size 1.6rem
        text-align center
    &--bottom
      margin-top -150px
      position relative
      z-index 3
      +mediaSp()
        .m__common-heading__title
          text-align left
  &__scroll-box
    position relative
    +mediaSp()
      margin 0 -10px
    + .m__csr__scroll-box
      margin-top 50px
    &:nth-child(odd)
      +mediaPc()
        .m__facility__slide-image
          margin-left 0
        .m__facility__scroll-text-box
          left auto
          right 0
          width 750px
          p
            width 480px
            margin-left auto
    &.m__csr__text-block--bottom
      margin-top 30px
  &__slide-image
    &--image1
      width 400px
      height 600px
      background url(image_path + '/company/csr/img_csr01.jpg') left center no-repeat
      background-size 120% auto
      position relative
      margin-top -600px
      margin-right 40px
      margin-left auto
      +mediaSp()
        width 80vw
        height 120vw
        margin-top 50px
        margin-right 0
        background-size cover
        + .m__csr__scroll-text-box
          bottom 30px
      + .m__csr__scroll-text-box
        .m__csr__contents-title
          width 265px
          margin-left auto
          +mediaSp()
            width 100%
    &--image2
      width 600px
      height 540px
      background url(image_path + '/company/csr/img_csr02.jpg') left center no-repeat
      background-size 120% auto
      position relative
      margin-top -50px
      +mediaSp()
        width 80vw
        height 72vw
        margin-top 20px
        margin-left auto
        background-size cover
        + .m__csr__scroll-text-box
          bottom 0
      +mediaPc()
        + .m__csr__scroll-text-box
          p
            margin-left 220px
          .m__csr__contents-title
            width 640px
            margin-left auto
    &--image3
      width 600px
      height 540px
      background url(image_path + '/company/csr/img_csr03.jpg') left center no-repeat
      background-size 100% auto
      position relative
      margin-top -40px
      margin-left auto
      +mediaSp()
        width 80vw
        height 72vw
        margin-top 20px
        background-size cover
        + .m__csr__scroll-text-box
          bottom 30px
      +mediaPc()
        + .m__csr__scroll-text-box
          right auto
          left 0
          .m__csr__contents-title
            margin-left 220px
    &--image4
      width 600px
      height 540px
      background url(image_path + '/company/csr/img_csr04.jpg') left center no-repeat
      background-size 120% auto
      position relative
      margin-top -50px
      +mediaSp()
        width 80vw
        height 72vw
        margin-top 20px
        margin-left auto
        background-size cover
        + .m__csr__scroll-text-box
          bottom 20px
      +mediaPc()
        + .m__csr__scroll-text-box
          p
            margin-left 220px
          .m__csr__contents-title
            width 640px
            margin-left auto
    &--image5
      width 600px
      height 540px
      background url(image_path + '/company/csr/img_csr05.jpg') left center no-repeat
      background-size 140% auto
      position relative
      margin-top -40px
      margin-left auto
      +mediaSp()
        width 80vw
        height 72vw
        margin-top 0
        background-size cover
        + .m__csr__scroll-text-box
          bottom 0
      +mediaPc()
        + .m__csr__scroll-text-box
          right auto
          left 0
          .m__csr__contents-title
            margin-left 320px
          p
            margin-left 150px
  &__contents-title
    opacity .8
    color #fff
    alphabet()
    font-size 6rem
    line-height 1
    margin-bottom 10px
    text-align left
    +mediaSp()
      font-size 3rem
      margin-bottom 10px
      span
        display none
  &__scroll-text-box
    position absolute
    bottom 30px
    right 0
    +mediaSp()
      position relative
      padding 0 10px
      margin-top -20px
      bottom 50px
    p
      font-size 1.6rem
      +mediaSp()
        font-size 1.3rem

  &__bottom
    position relative
    z-index 2
    margin-top -230px
    +mediaSp()
      margin-top 0
    img
      width 100%
