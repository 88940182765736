.m__foreign-language
  &__description
    background bg-header
    color #fff
    font-size 2.5rem
    padding 45px 0
    margin-top 90px
    position relative
    z-index 2
    +mediaSp()
      font-size 1.2rem
      margin-top 30px
      padding 20px 0
  &__main-image
    background url(image_path+'/foreign/img_main_photo.jpg') center center no-repeat
    background-size 140% auto
    width 100%
    height 54vw
    +mediaSp()
      background-size 110% auto
  &__bottom-image
    background url(image_path+'/foreign/img_bottom_photo.jpg') center center no-repeat
    background-size 120% auto
    width 100%
    height 30.3vw
    +mediaSp()
      background-size 110% auto
  &__contents
    position relative
    z-index 2
    background #fff
    h3
      margin-top 60px
      line-height 1
      .chinese
        medium()
        font-size 2.6rem
      .alphabet
        bold()
        font-size 2.6rem
      +mediaSp()
        margin-top 30px
        .chinese
          font-size 1.5rem
        .alphabet
          font-size 1.6rem
  &__text-box
    regular()
    font-size 1.6rem
    background #fff
    margin 30px 0 60px
    +mediaSp()
      font-size 1.3rem
      margin 10px 0 30px
    ul
      &.dot
        margin-top 30px
        li
          text-indent -1em
          padding-left 1em
          line-height 2
          &::before
            content "・"
      &.number
        counter-reset item
        list-style-type none
        li
          text-indent -1.3em
          padding-left 1.3em
          &::before
            counter-increment item
            content counter(item)'.'
            medium()
            padding-right 2px
  &__feature
    padding 50px 0
    background bg-light-gray
    +mediaSp()
      padding 25px 10px
    ul
      width 900px
      margin 0 auto
      +mediaSp()
        width 100%
      li
        padding-left 68px
        font-size 2.6rem
        margin-bottom 30px
        medium()
        min-height 60px
        +mediaSp()
          font-size 1.5rem
          padding-left 35px
          margin-bottom 15px
          min-height 30px
        span
          font-size 1.6rem
          display block
          +mediaSp()
            font-size 1.3rem
        p
          line-height 1.4
          position relative
          &::before
            content ''
            position absolute
            width 80px
            height 60px
            left -80px
            top 50%
            transform translateY(-50%)
            +mediaSp()
              background-size 30px 30px
              width 40px
              height 30px
              left -42px
              top 0
              transform none
        &:nth-child(1)
          p
            &::before
              background url(image_path+'/foreign/icon_eng.svg') no-repeat center
              background-size 47px 54px
              +mediaSp()
                background-size 24px 27px
                top 2px
        &:nth-child(2)
          p
            &::before
              background url(image_path+'/foreign/icon_support.svg') no-repeat center
              background-size 60px 45px
              +mediaSp()
                background-size 30px 23px
        &:nth-child(3)
          p
            &::before
              background url(image_path+'/foreign/icon_grobal.svg') no-repeat center
              background-size 53px 53px
              margin-bottom 0
              +mediaSp()
                background-size 24px 27px

  &__detail
    .btn-box
      margin 60px 0
      display flex
      justify-content center
      +mediaSp()
        display block
        margin 30px 0
      &.chinese
        chinese()
      &.espanol
        .m__common__flex-arrow
          padding 16px 70px 16px 50px
          +mediaPc()
            &::before,&::after
              bottom 28px
          +mediaSp()
            &::before
              right auto
              left 50%
              margin-left 42px
            &::after
              right auto
              left 50%
              margin-left 62px
            &:active
              &::after
                margin-left 62px
          &.en
            &::before
              right 85px
            &::after
              right 88px
            +mediaPc()
              &:hover
                &::before
                  right 75px
                &::after
                  right 78px
            +mediaSp()
              padding 16px 82px 16px 50px
          &.jp
            &::before
              right 77px
            &::after
              right 79px
            +mediaPc()
              &:hover
                &::before
                  right 67px
                &::after
                  right 69px
      .m__common__flex-arrow
        width 300px
        &:nth-of-type(1)
          margin-right 60px
          +mediaSp()
            margin-right 0
            margin-bottom 10px
        &::before
          right 95px
          +mediaSp()
            right auto
            left 50%
            margin-left 26px
        &::after
          right 97px
          +mediaSp()
            right auto
            left 50%
            margin-left 46px
        +mediaPc()
          &:hover
            &::before
              right 85px
              width 34px
            &::after
              right 87px
        +mediaSp()
          width 100%
          &:active
            background bg-green02
            transition .3s
            &::before
              width 24px
            &::after
              margin-left 46px
  &__access
    text-align center
    padding 90px 0
    width 1080px
    margin 0 auto
    +mediaSp()
      width 100%
      padding 50px 0 40px
    h3
      line-height 1
      .alphabet
        font-size 2.6rem
        +mediaSp()
          font-size 1.6rem
      .chinese
        medium()
        font-size 2.6rem
        +mediaSp()
          font-size 1.5rem
    .logo
      text-align center
      margin-top 60px
      width 300px
      height 30px
      fill #3b8d6b
      display inline-block
      +mediaSp()
        width 150px
        height 15px
        margin-top 10px
    ul
      margin-top 20px
      +mediaSp()
        margin-top 15px
      &.espanol
        .school
          alphabet()
          bold()
          font-size 2rem
          +mediaSp()
            font-size 1.3rem
      li
        border-top 1px solid border-light-gray
        padding 30px 0
        regular()
        +mediaSp()
          padding 20px 0
        .school
          chinese()
          bold()
          font-size 2rem
          +mediaSp()
            font-size 1.3rem
        p
          font-size 1.6rem
          +mediaSp()
            font-size 1.3rem
        span
          margin-right .5em
        a
          &.telNumber
            +mediaPc()
              pointer-events none
              color font-black
              text-decoration none
          +mediaSp()
            medium()