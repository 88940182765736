.m__voice
  margin-top 100px
  padding 100px 0 150px
  +mediaSp()
    margin 30px -10px 0
    padding 30px 0 40px
  &__text-block
    background #fff
    padding 35px 50px
    width 800px
    margin-bottom 30px
    font-size 1.6rem
    +mediaSp()
      font-size 1.3rem
      width 86vw
      padding 25px 20px
    .title
      border-bottom 1px solid border-light-gray
      padding-bottom 15px
      span
        position relative
        padding 0 30px
        +mediaSp()
          padding 0 20px
          margin-left 20px
        &::before,&::after
          position absolute
          content ""
          width 2px
          height 30px
          top -5px
          +mediaSp()
            height 15px
            top 4px
        &::before
          transform rotate(-35deg)
          left 10px
        &::after
          transform rotate(35deg)
          right 10px
      &.red
        span
          &::before,&::after
            background #f08066
      &.yellow
        span
          &::before,&::after
            background #f0e44d
      &.blue
        span
          &::before,&::after
            background #00aacc
    .text
      padding 15px 15px 0 15px
      +mediaSp()
        padding 15px 10px 0 10px
    &--right
      margin-left auto