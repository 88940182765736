.m__entertainment
  &__introduction
    margin-top 95px
    background bg-header
    font-size 2.6rem
    color #fff
    padding 40px 0 50px
    +mediaSp()
      padding 15px 10px
      margin-top 30px
    h3
      font-size 3.2rem
      medium()
      text-align center
      +mediaSp()
        font-size 1.6rem
  &__nav
    &.pc
      position fixed
      z-index 10
      top 40vh
      +mediaSp()
        display none
      ul
        font-size 1.6rem
        background bg-black
        width 330px
        opacity 0.85
        padding 0 30px
        li
          alphabet()
          a
            color #fff
            text-decoration none
          .m__common-btn__arrow
            .m__common__flex-arrow
              padding 20px 10px
              text-align left
              width 100%
              &::before
                right 24px
                bottom 32px
              &::after
                right 27px
                bottom 31px
              &:hover
                &::before
                  right 14px
                  bottom 32px
                &::after
                  right 17px
                  bottom 31px
          &:not(:last-child)
            border-bottom 1px solid border-gray
    &.sp
      +mediaPc()
        display none
      padding 40px 45px
      li
        medium()
        alphabet()
        font-size 1.4rem
        &::before
          content '▼'
          font-size 1.1rem
          margin-right 2px
          color font-green
        &:not(:first-child)
          margin-top 20px
  &__contents
    position relative
    z-index 2
    background bg-light-gray
    padding-bottom 150px
    +mediaSp()
      padding-bottom 50px
    .l__contents__inner--width
      position relative
      padding-top 80
      +mediaSp()
        padding-top 0
  &__text-block
    background #fff
    width 777px
    padding 100px 60px 120px
    margin-left auto
    position relative
    +mediaSp()
      width auto
      padding 50px 30px
      margin-left 8vw
      margin-right -10px
      word-break normal
    p
      regular()
      width 590px
      font-size 1.6rem
      margin-top 5px
      +mediaSp()
        width 100%
        font-size 1.3rem
      + p
        margin-top 1.5em
        line-height 1.75
    .lead
      font-size 2rem
      medium()
      +mediaSp()
        font-size 1.6rem
    a
      font-size 1.6rem
      +mediaSp()
        font-size 1.3rem
    .m__common-heading__title
      text-align left
      line-height 1.1
      margin-bottom 1em
      +mediaSp()
        font-size 1.6rem
    .m__common-btn__arrow
      text-align center
      margin-top 100px
      +mediaSp()
        margin-top 50px
        .m__common__flex-arrow
          width 100%
          font-size 1.5rem
          &::before
            right 50%
            transform translateX(55px)
            transition 0.3s!important
          &::after
            right 50%
            transform translateX(53px)rotate(-45deg)
            transition 0.3s!important
          &:active
            &::before
              width 34px
              transform translateX(65px)
            &::after
              transform translateX(63px)rotate(-45deg)
  &__text-block02
    margin-top 470px
    padding 150px 60px 120px
    +mediaSp()
      margin-top 255px
      padding 50px 30px
      word-break normal
      .m__common-btn__arrow
        .m__common__flex-arrow
          &::before
            transform translateX(70px)
          &::after
            transform translateX(68px)rotate(-45deg)
          &:active
            &::before
              transform translateX(80px)
            &::after
              transform translateX(78px)rotate(-45deg)
    +mediaPc()
      .m__common-btn__arrow
        .m__common__flex-arrow
          padding 16px 70px 16px 40px
  &__contents--img01
    width 100%
    height 50vw
    background url(image_path+'/entertainment/img_entertainment_01.jpg') center top no-repeat
    background-size 100% auto
    margin-bottom -160px
    +mediaSp()
      background-position-y  0%!important
      height 250px
      background-size 100%
      margin-bottom -30px
  &__contents--img02
    width 100%
    height 50vw
    background url(image_path+'/entertainment/img_entertainment_02.jpg') center top no-repeat
    background-size 100% auto
    margin-bottom -160px
    margin-top 100px
    +mediaSp()
      margin-top 50px
      background-position-y  0%!important
      height 250px
      background-size 100%
      margin-bottom -30px
  &__contents--img03
    width 100%
    height 50vw
    background url(image_path+'/entertainment/img_entertainment_03.jpg') center top no-repeat
    background-size 100% auto
    margin-bottom -160px
    margin-top 100px
    +mediaSp()
      margin-top 50px
      background-position-y  0%!important
      height 250px
      background-size 100%
      margin-bottom -30px
  &__contents--img04
    position absolute
    right 0
    z-index 1
    bottom 590px
    +mediaSp()
      bottom 520px
      img
        max-width 100%
  &__contents--img05
    position absolute
    right 0
    z-index 1
    bottom 590px
    +mediaSp()
      bottom 460px
      img
        max-width 100%
