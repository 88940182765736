.m__english_by-entering
  &__content-top
    padding-top 90px
    +mediaSp()
      padding-top 30px
  &__content-bottom
    padding 90px 0 150px
    +mediaSp()
      padding 10px 0 30px
  &__text-block
    padding 30px 20px
    border-bottom 1px solid border-light-gray
    font-size 1.6rem
    word-break break-word
    +mediaSp()
      margin 0 -10px
      padding 20px 10px
      font-size 1.3rem
    &.border0
      border 0
    &.border-top
      border-top 1px solid border-light-gray
    h4
      margin-bottom 5px
      +mediaSp()
        margin-bottom 0
    .m__common__list
      li:before
        color #F0E44D
      &.regular
        regular()
        b
          medium()
        +mediaSp()
          li
            &:not(:last-child)
              margin-bottom 10px
    dl
      dt
        bold()
      dd
        ul.count
          li
            list-style-type lower-alpha
            list-style-position inside
            text-indent -1.2em
            padding-left 1.2em
            &::before
              display none
        a.link
          text-align center
          display block
          +mediaSp()
            margin-bottom 20px

  &__school
    border-bottom 1px solid border-light-gray
    font-size 1.6rem
    padding 30px 0
    +mediaSp()
      font-size 1.3rem
      padding 20px 0
      word-break break-word
    .m__txt--c
      margin-bottom 30px
      +mediaSp()
        text-align left
        margin-bottom 20px
    &.sp-text-center
      +mediaSp()
        *
          text-align center
    dl
      display flex
      justify-content center
      +mediaSp()
        margin-bottom 10px
      dt
        width 200px
        +mediaSp()
          width 170px
    .telnumber
      +mediaPc()
        color font-black
        text-decoration none
        pointer-events none
  &__table
    padding 30px 20px
    font-size 1.6rem
    +mediaSp()
      padding 20px 0 0
      font-size 1.3rem
      margin 0 -10px
      h3,h4,p
        padding 0 10px
      h4
        margin-top 10px
    p
      regular()
      b
        medium()
        margin-left 1em
      +mediaSp()
        margin-top 5px
        b
          margin-left 0.5em
    table
      border 2px solid border-black
      width 750px
      margin 10px auto 40px
      +mediaSp()
        width 100%
        border-left 0
        border-right 0
        margin 5px 0 30px
      tr
        &:not(:first-child)
          border-top 1px solid border-black
        td,th
          height 60px
          vertical-align middle
          text-align center
          medium()
          width 120px
          &:not(:first-child)
            border-left 1px solid border-black
          +mediaSp()
            height 30px
            word-break break-word
          &.w225
            width 225px
            +mediaSp()
              width 33%
          &.w280
            width 280px
            +mediaSp()
              width 42%
        th
          font-size 1.8rem
          &.light-gray
            background bg-light-gray
            border-right 1px solid border-black

          +mediaSp()
            font-size 1.3rem
        td
          font-size 2rem
          +mediaSp()
            font-size 1.3rem
        &.gray
          background bg-gray02
      &.motorcycle
        margin-bottom 90px
        +mediaSp()
          margin-bottom 30px
        td,th
          width 95px
          +mediaSp()
            min-width 11vw
            font-size 1rem
        +mediaPc()
          &:nth-of-type(3)
            margin-bottom 40px

  &__image
    background bg-light-gray
    display flex
    justify-content center
    +mediaSp()
      display block
    .image-block
      width 600px
      +mediaSp()
        width 100%
        img
          max-width 100%
      p
        regular()
        font-size 1.6rem
        padding 20px 75px 40px
        +mediaSp()
          font-size 1.3rem
          padding 10px
  &__print
    text-align center
    margin-top 150px
    +mediaSp()
      margin 0 0 60px
      img
        max-width 100%
    .close
      a
        font-size 2.2rem
        +mediaSp()
          font-size 1.3rem
      &::before
        content "×"
        font-size 3.2rem
        color font-green
        regular()
        position relative
        top 2px
        margin-right 5px
        +mediaSp()
          font-size 1.6rem
          top 0