.m__english__price
  word-break break-word
  padding-top 220px
  margin-bottom 140px
  +mediaSp()
    padding-top 30px
    margin-bottom 30px
  .m__common-heading__title--2lines
    line-height 1
    +mediaSp()
      span
        bold()
  &__price-list
    font-size 1.6rem
    regular()
    &:nth-of-type(n+2)
      padding-top 100px
    +mediaSp()
      margin 0 -10px
      font-size 1.3rem
      &:nth-of-type(n+2)
        padding-top 50px
    .m__common-heading__title
      line-height 1.1
    p.obtain
      text-align center
      font-size 2.7rem
      bold()
      margin 20px 0 10px
      +mediaSp()
        font-size 1.4rem
        margin-bottom 3px
    &.border
      border-top 1px solid border-light-gray
  &__tab-list
    font-size 0
    letter-spacing -.04em
    bold()
    input[type="radio"]
      display none
      &:checked
        + .plan-tab-item
          background main-color
          transition background .5s
          &::after
            content ""
            display block
            width 0
            height 0
            border-style solid
            border-width 10px 7.5px 0 7.5px
            border-color main-color transparent transparent transparent
            position absolute
            bottom -10px
            left 50%
            transform translateX(-50%)
            +mediaSp()
              display none
            &:hover
              background main-color-light
              &::after
                border-color main-color-light transparent transparent transparent
          +mediaSp()
            &:active
              background main-color-light
              &::after
                border-color main-color-light transparent transparent transparent
    .m__common-btn__arrow
      margin 20px 0
      a.m__common__flex-arrow
        font-size 1.6rem
        padding 16px 110px 16px 100px
        +mediaSp()
          padding 16px 70px 16px 40px
          &::before
            transform none
            right 35px
          &::after
            transform rotate(-45deg)
            right 38px
          &:hover
            &::before
              transition 0s !important
          &:active
            &::before
              width 40px
              transform none
              right 19px
              transition width 0.3s, right 0.3s !important
            &::after
              right 22px
              transition right 0.3s !important
        &::before
          width 24px
          bottom 27px
          +mediaPc()
            right 75px
        &::after
          height 8px
          bottom 26px
          +mediaPc()
            right 78px
        +mediaPc()
          &:hover
            &::before
              width 50px
              right 49px
            &::after
              right 52px
    .plan-tab-item
      display inline-block
      width 50%
      border 1px solid main-color
      border-right none
      padding 15px 20px
      text-align center
      position relative
      +mediaSp()
        width 100%
        text-align left
        padding 10px 20px
        border-left none
        border-bottom none
      &:hover
        cursor pointer
        background #eefff9
        transition background 0.3s
      +mediaPc()
        &.last-tab
          border-right 1px solid main-color
      p
        font-size 2rem
        letter-spacing normal
        +mediaSp()
          font-size 1.4rem
          span
            font-size 1.2rem
            vertical-align baseline
            margin-left 2px
    .plan-tab-content
      font-size 1.8rem
      letter-spacing normal
      background bg-light-gray
      padding-bottom 90px
      text-align center
      display none
      &.notab
        display block
        margin-top 20px
        +mediaSp()
          border-top none
          padding-top 0
          margin-top 0
          &::before
            display none
      img
        max-width 100%
      +mediaSp()
        background #fff
        border-top 1px solid main-color
        position relative
        padding 30px 0 0
        &::before
          content ""
          display block
          width 0
          height 0
          border-style solid
          border-width 10px 7.5px 0 7.5px
          border-color main-color transparent transparent transparent
          position absolute
          top 10px
          left 50%
          transform translateX(-50%)
        h3
          background main-color
          text-align center
          font-size 1.4rem
          padding 5px 0
          medium()
          span
            display inline-block
    #tab01:checked ~ #tab01-item,
    #tab02:checked ~ #tab02-item,
    #tab03:checked ~ #tab03-item,
    #tab04:checked ~ #tab04-item,
    #tab05:checked ~ #tab05-item,
    #tab06:checked ~ #tab06-item,
    #tab07:checked ~ #tab07-item,
    #tab08:checked ~ #tab08-item,
    #tab09:checked ~ #tab09-item,
    #tab10:checked ~ #tab10-item
      display block
    &.middle
      input[type="radio"]:checked + .plan-tab-item::after
        display none
      .plan-tab-item
        width 45%
        +mediaSp()
          width 100%
          text-align left
          padding 10px 20px
          border-left none !important
        +mediaPc()
          &:nth-of-type(n+3)
            border-top none
          &:nth-of-type(2n)
            border-right 1px solid main-color
            width 55%
    &.small
      .plan-tab-item
        width 45%
        +mediaSp()
          width 100%
        +mediaPc()
          &:nth-of-type(2n)
            width 55%
  &__notice
    width 900px
    padding 90px 0 100px
    margin 0 auto
    img
      max-width 100%
    +mediaSp()
      width auto
      padding 50px 0 30px
      font-size 1.3rem
    ul
      li
        regular()
        text-indent -1em
        padding-left 1em
        line-height 2
        &::before
          content "・"
    p
      regular()
      text-indent -1em
      padding-left 1em