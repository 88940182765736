.m__kidsroom
  &__introduction
    margin-top 90px
    background bg-header
    color #fff
    padding 40px 0
    +mediaSp()
      margin-top 15px
      padding 10px 0
    p
      font-size 3.2rem
      medium()
      text-align center
      +mediaSp()
        font-size 1.6rem
        span
          display inline-block
  &__detail
    padding 90px 0
    +mediaSp()
      padding 30px 0
    dl
      padding 0 15px
      +mediaSp()
        margin 0 -10px
        padding 0 10px
      dt
        margin-bottom 10px
        +mediaSp()
          margin-bottom 5px
      dd
        regular()
        font-size 1.6rem
        +mediaSp()
          font-size 1.3rem
      + dl
        border-top 1px solid border-light-gray
        padding-top 30px
        margin-top 30px
        +mediaSp()
          padding-top 15px
          margin-top 15px
  &__guide
    padding 90px 0 150px
    +mediaSp()
      padding 20px 0 50px
    .image
      margin 50px auto 0
      text-align center
      width 900px
      +mediaSp()
        width 100%
      + .image
        margin-top 30px
      +mediaSp()
        margin-top 20px
        + .image
          margin-top 15px
        img
          width 100%