.m__friends
  &__card
    margin-top 90px
    padding 60px 0
    display flex
    justify-content center
    align-items center
    font-size 1.6rem
    +mediaSp()
      padding 30px 10px
      margin-top 30px
      font-size 1.3rem
      flex-direction column-reverse
      img
        width 285px
        height auto
    .m__common__list
      li
        margin-top 10px
        &::before
          color #F0E44D
      +mediaSp()
        display none
    .text
      margin-right 40px
      +mediaSp()
        margin 30px 0 0
      p
        margin-top 10px
        +mediaSp()
          margin-top 6px
  &__table
    margin 60px 0
    +mediaSp()
      margin 0
    table
      border 2px solid #000
      width 750px
      margin 0 auto
      +mediaSp()
        width 100%
        border 1px solid #000
        border-left 0
        border-right 0
      tr
        &:not(:first-child)
          border-top 1px solid #000
        th,td
          medium()
          vertical-align middle
          &:nth-child(2)
            border-left 1px solid #000
        td
          padding 17px 10px
          text-align center
          width 50%
          +mediaSp()
            padding 10px 0
          p
            regular()
            font-size 1.6rem
            +mediaSp()
              font-size 1.3rem
            &.red
              color font-red
              font-size 2rem
              medium()
              +mediaSp()
                font-size 1.3rem
              span
                font-size 2.4rem
                vertical-align baseline
                margin-right 5px
                +mediaSp()
                  font-size 1.3rem
        &.special
          th
            background border-light-gray
            font-size 2rem
            padding 18px 10px
            line-height 1.5
            +mediaSp()
              font-size 1.3rem
              padding 8px 0
            span
              font-size 1.6rem
              regular()
              display block
              +mediaSp()
                font-size 1.3rem
        &.type
          th
            background #f0f0f0
            text-align left
            font-size 2rem
            padding 12px 10px
            span
              font-size 1.6rem
              regular()
              vertical-align 1px
            +mediaSp()
              font-size 1.3rem
              letter-spacing -0.05em
              padding 3px 5px
              span
                font-size 1.3rem
                vertical-align baseline
  &__form
    margin 0 auto
    padding-top 40px
    +mediaSp()
      margin 0 -10px
      padding-top 20px