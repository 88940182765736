.futako-top
  .m__school__mv
    background url(image_path + '/futako/top_mv.jpg') center center no-repeat
    background-size cover
    +mediaSp()
      background-image url(image_path + '/futako/top_mv_sp.jpg')
  +mediaSp()
    .m__top__main
      margin-top 89.4vw
      .m__top__title
        top -25vh
.seijo-top
  .m__school__mv
    background url(image_path + '/seijo/top_mv.jpg') center center no-repeat
    background-size cover
    +mediaSp()
      background-image url(image_path + '/seijo/top_mv_sp.jpg')
  +mediaSp()
    .m__top__main
      margin-top 89.4vw
      .m__top__title
        top -25vh
.shakujii-top
  .m__school__mv
    background url(image_path + '/shakujii/top_mv.jpg') center center no-repeat
    background-size cover
    +mediaSp()
      background-image url(image_path + '/shakujii/top_mv_sp.jpg')
  +mediaSp()
    .m__top__main
      margin-top 89.4vw
      .m__top__title
        top -25vh
.akitsu-top
  .m__school__mv
    background url(image_path + '/akitsu/top_mv.jpg') center center no-repeat
    background-size cover
    +mediaSp()
      background-image url(image_path + '/akitsu/top_mv_sp.jpg')
  +mediaSp()
    .m__top__main
      margin-top 89.4vw
      .m__top__title
        top -25vh
.yokohama-top
  .m__school__mv
    background url(image_path + '/yokohama/top_mv.jpg') center center no-repeat
    background-size cover
    +mediaSp()
      background-image url(image_path + '/yokohama/top_mv_sp.jpg')
  +mediaSp()
    .m__top__main
      margin-top 89.4vw
      .m__top__title
        top -20vh
.m__school
  &__mv
    position fixed
    top 0
    left 0
    width 100%
    height 100vh
    z-index 1
    img
      display none
    +mediaSp()
      height 89.3vw
      margin-top 55px

  &__tab-contents
    width 1080px
    margin 150px auto 0
    font-size 0
    letter-spacing -.04em
    +mediaSp()
      width 100%
      margin-top 80px
    &--futako
      .school-tab-item
        &:hover
          background #fbf8d2
    &--shakujii
      .school-tab-item
        &:hover
          background #dbe2f2
    &--seijo
      .school-tab-item
        &:hover
          background #ccf2f2
    &--yokohama
      .school-tab-item
        &:hover
          background #ffddd5
    &--akitsu
      .school-tab-item
        &:hover
          background #fce4cf
    input[type="radio"]
      display none
      &:checked
        + label.school-tab-item
          background main-color
          transition .5s
          &::after
            content ""
            display block
            width 0
            height 0
            border-style solid
            border-width 22px 16px 0 16px
            border-color main-color transparent transparent transparent
            position absolute
            bottom -20px
            left 50%
            margin-left -16px
            +mediaSp()
              border-width 11px 8px 0 8px
              bottom -10px
              margin-left -8px
          &:hover
            cursor default
      &#enrollment-tab:checked ~ #enrollment-content, &#student-tab:checked ~ #student-content
        display block
    label.school-tab-item
      display inline-block
      transition background .5s
      width 50%
      text-align center
      border 2px solid main-color
      background #fff
      position relative
      font-size 2.6rem
      padding 20px 0
      letter-spacing normal
      &:hover
        cursor pointer
      +mediaSp()
        font-size 1.4rem
        padding 16px 0
    .school-tab-content
      display none
      background bg-light-gray02
      padding 60px 30px
      font-size 1.6rem
      +mediaSp()
        padding 20px 0
        font-size 1.4rem
      .school-introduce
        &.pc
          +mediaSp()
            display none
        &.sp
          font-size 1.4rem
          letter-spacing normal
          ul
            display flex
            justify-content space-between
            flex-wrap wrap
            li
              width 49%
              &:nth-child(n+3)
                margin-top 15px
              a
                display block
                text-align center
                line-height 1.2
                span
                  span
                    font-size 1rem
              img
                max-width 100%
                margin-bottom 5px
          +mediaPc()
            display none
          .notice
            font-size 1rem
            color font-red
        .contents
          position relative
        #facility
          .m__common-btn__arrow
            position absolute
            right 0
            top 150px
            &--futako
              top 140px
          .kids-room
            position absolute
            bottom 5px
            left 0
            p
              padding-bottom 15px
              &.m__txtTall
                padding-bottom 0
        #point
          .m__common-btn__arrow
            position absolute
            right 0
            top 260px
          .m__common__link
            margin-top 20px
        #access
          .m__common-btn__arrow
            position absolute
            right 0
            top 98px
          .bus-navi
            position absolute
            left 0
            bottom 0
        #license
          .m__common-btn__arrow
            position absolute
            right 0
            top 280px
        .flex-box
          display flex
          justify-content space-between
          + .flex-box
            margin-top 100px
          .img-box
            display flex
            .separate
              display inline-block
              width 300px
              img
                max-width 100%
          .contents
            width 400px
            font-size 1.6rem
            letter-spacing normal
            +mediaSp()
              width 100%
            .title
              font-size 2.6rem
              font-weight 500
              padding-bottom 10px
              line-height 1.4
              + p
                regular()
          .bus-navi
            p
              font-size 2.6rem
              line-height 1.4
              margin-bottom 10px
            a
              padding-left 55px
              position relative
              &::before
                content ""
                display inline-block
                width 45px
                height 1px
                background bg-green
                position absolute
                left 0
                top 50%
                margin-top -2px
                transition .3s
              &::after
                content ""
                width 15px
                height 1px
                background bg-green
                position absolute
                left 33px
                top 4px
                transform rotate(45deg)
                transition .3s
              svg
                width 205px
                height 25px
                fill bg-green
                transition .3s
              &:hover
                &::before, &::after
                  background bg-light-green
                svg
                  fill bg-light-green
            .notice-box
              font-size 14px
              border 2px solid #f08066
              width 400px
              padding 10px 20px
              display flex
              justify-content center
              align-items center
              color font-red
              margin-bottom 0
              background #fff
              p
               font-size 1.4rem
               margin-bottom 0

      .m__top__school
        margin-top 100px
        transform none
        +mediaSp()
          margin-top 30px
        h3
          font-size 2.6rem
          font-weight 500
          +mediaSp()
            font-size 1.6rem
        .contents
          padding-top 40px
          +mediaSp()
            padding-top 20px
        .link-list
          width 610px
          display flex
          flex-wrap wrap
          justify-content space-between
          margin 30px auto
          +mediaSp()
            width 100%
          li
            width 300px
            +mediaPc()
              &:nth-child(n+3)
                margin-top 10px
            +mediaSp()
              width calc(50% - 0.5px)
              margin-top 1px
            a
              background main-color
              text-align center
              display block
              padding 16px 0
              color font-black
              text-decoration none
              +mediaPc()
                &:hover
                  background main-color-light
          +mediaSp()
            width 100%

      .student-menu
        font-size 1.6rem
        display flex
        flex-wrap wrap
        margin -10px 45px 0
        +mediaSp()
          margin 0 0 -20px
          justify-content space-between
        li
          width 225px
          margin-right 10px
          margin-top 10px
          +mediaPc()
            &:nth-child(4n)
              margin-right 0
          +mediaSp()
            width calc(50% - 0.5px)
            margin 1px 0 0
            &:first-child
              order 11
              width 100%
              a
                justify-content center

          a
            display flex
            height 60px
            align-items center
            width 100%
            background main-color
            color font-black
            text-decoration none
            padding 0 15px
            +mediaSp()
              height 50px
              font-size 1.4rem
            &:hover
              background main-color-light
            span
              display inline-block
              width 18px
              text-align center
              vertical-align middle
              margin-right 10px
              img
                vertical-align middle
      .sp-school-introduce
        +mediaPc()
          display none
        h3
          font-weight 500
          text-align center
          font-size 1.6rem
          letter-spacing -0.05em
        .box
          + .box
            margin-top 30px
          .img-box
            display flex
            .separate
              width 50%
            img
              max-width 100%
          h4.title
            font-weight 500
            font-size 1.5rem
            padding 5px 10px
          .contents
            p
              regular()
              padding 0 10px 10px
            .m__common-btn__arrow
              a.m__common__flex-arrow
                padding 16px 30px 16px 20px
                width 100%
                &::before
                  right auto
                  left 50%
                  margin-left 35px
                &::after
                  right auto
                  left 50%
                  margin-left 55px
                  +mediaSp()
                    transition width 0.3s, right 0.3s, margin-left 0.3s !important
                +mediaSp()
                  &:active
                    &::after
                      margin-left 65px
            .bus-navi
              text-align center
              p
                margin-top 5px
              a
                position relative
                padding-left 35px
                display inline-block
                margin-bottom 10px
                svg
                  fill bg-green
                  width 172px
                  height auto
                &::before
                  content ""
                  display inline-block
                  width 25px
                  height 1px
                  background bg-green
                  position absolute
                  left 0
                  top 50%
                  margin-top -2px
                  transition .3s
                &::after
                  content ""
                  width 8px
                  height 1px
                  background bg-green
                  position absolute
                  left 17px
                  top 9px
                  transform rotate(45deg)
                  transition .3s
              .notice-box
                font-size 14px
                border 2px solid #f08066
                width auto
                padding 10px 20px
                margin 20px 10px
                display flex
                justify-content center
                align-items center
                color font-red
                background #fff
                p
                  font-size 1.4rem
                  margin-bottom 0
                  padding 0
