@import 'modules/_m_common'
@import 'modules/_m_common-heading'
@import 'modules/_m_common-btn'
@import 'modules/_m_common-form'
@import 'modules/_m_entry'
@import 'modules/_m_header'
@import 'modules/_m_footer'
@import 'modules/_m_form'
@import 'modules/_m_top'
@import 'modules/_m_school'
@import 'modules/_m_access'
@import 'modules/_m_access_lower'
@import 'modules/_m_admin'
@import 'modules/_m_news'
@import 'modules/_m_plan'
@import 'modules/_m_plan-stay'
@import 'modules/_m_plan-senior'
@import 'modules/_m_plan-other'
@import 'modules/_m_plan-category'
@import 'modules/_m_plan-category_lower'
@import 'modules/_m_pia'
@import 'modules/_m_faq'
@import 'modules/_m_recruit'
@import 'modules/_m_navi_list'
@import 'modules/_m_foreign-language'
@import 'modules/_m_facility'
@import 'modules/_m_csr'
@import 'modules/_m_training'
@import 'modules/_m_point'
@import 'modules/_m_company'
@import 'modules/_m_timetable'
@import 'modules/_m_campaign'
@import 'modules/_m_stay'
@import 'modules/_m_obtain'
@import 'modules/_m_guide'
@import 'modules/_m_birthday'
@import 'modules/_m_voice'
@import 'modules/_m_press'
@import 'modules/_m_entertainment'
@import 'modules/_m_dlive'
@import 'modules/_m_festival'
@import 'modules/_m_fireworks'
@import 'modules/_m_poster'
@import 'modules/_m_english'
@import 'modules/_m_english_by-entering'
@import 'modules/_m_english_converting'
@import 'modules/_m_english_types'
@import 'modules/_m_english_license-center'
@import 'modules/_m_english_whats'
@import 'modules/_m_english_location-inquiry'
@import 'modules/_m_english_price'
@import 'modules/_m_english_privacy'
@import 'modules/_m_english_company'
@import 'modules/_m_coupon'
@import 'modules/_m_license-plaza'
@import 'modules/_m_kidsroom'
@import 'modules/_m_sitemap'
@import 'modules/_m_60th'
@import 'modules/_m_friends'
@import 'modules/_m_belongings'
@import 'modules/_m_sdgs'
@import 'modules/_m_chinese'

.none
  display none !important

.spNone
  +mediaSp()
    display none !important

.pcNone
  +mediaPc()
    display none !important

.m__bold
  font-weight bold !important

.m__txt--c
  text-align center

.m__text--green
  color font-green02

.m__text--red
  color font-red

.m__text--blue
  color font-blue

.fullW
  width 100%

.halfW
  width 50%

.alphabet
  font-family fontset-alphabet
  font-weight 700

.chinese
  font-family fontset-chinese

// 汎用的に使う、ごく小規模な装飾のみ追記してください（css1行のみで成り立つような記述）
// 基本的にはmodules/以下で新しくファイルを作り、importする形で要素を追記すること
