.m__common
  &__flex-arrow
    display inline-block
    position relative
    padding-right 90px
    font-size 2.4rem
    color font-black
    text-decoration none
    .m__common-heading__alphabet
      font-size 2.4rem
      +mediaSp()
        font-size 1.4rem
    &::before
      content ""
      display block
      width 45px
      height 1px
      background bg-black
      position absolute
      right 20px
      bottom 20px
      transition .3s
    &::after
      content ""
      display block
      height 18px
      width 1px
      background bg-black
      position absolute
      right 25px
      bottom 18px
      transform rotate(-45deg)
      transition .3s
    +mediaPc()
      &:hover
        color font-black
        &::before
          width 65px
          right 0
        &::after
          right 5px
    +mediaSp()
      font-size 1.6rem
      padding-right 30px
      &::before
        width 22px
        bottom 10px
        right 0
      &::after
        height 8px
        bottom 9px
        right 2px
      &:active
        &::before, &::after
          background font-green
        &::before
          width 22px

  &__pagetop
    width 30px
    height 30px
    position fixed
    border-radius 4px
    right 15px
    bottom 60px
    background url(image_path + '/common/icon_pagetop.svg') main-color center center no-repeat
    background-size 15px 10px
    opacity 0
    transition .5s
    z-index 91
    +mediaSp()
      right 15px
      bottom 70px
      width 30px
      height 30px
      background-size 15px 10px
    &.is-fadein
      transition opacity .5s
      opacity 1
      transition .5s
      &:hover
        cursor pointer
        opacity .7

  &__document-request
    position fixed
    right 0
    top 50%
    transform translateY(-50%)
    z-index 90
    opacity 0
    transition .5s
    pointer-events none
    +mediaPc()
      height 240px
      width 60px
    +mediaSp()
      top auto
      bottom 0
      transform translateY(60px)
      width 100%
    &.is-fadein
      opacity 1
      pointer-events auto
      +mediaSp()
        transform translateY(0)
    a
      background bg-green
      display block
      font-size 1.6rem
      letter-spacing 1em
      color #fff
      padding 63px 16px 49px
      transition .5s
      text-decoration none
      +mediaPc()
        -ms-writing-mode tb-rl
        writing-mode vertical-rl
        &:hover
          background bg-light-green
      +mediaSp()
        letter-spacing .5em
        font-size 1.5rem
        text-align center
        padding 17px 0
        &:active
          background bg-light-green

  &__emergency-banner
    position absolute
    right 0
    top 50vh
    transform translateY(-50%)
    z-index 91
    a
      display flex
      align-items center
      justify-content center
      background bg-vivid-red
      width 60px
      padding 40px 0
      color #fff
      font-size 1.6rem
      text-decoration none
      +ie()
        max-height 400px
      &:hover
        background bg-light-red
    +mediaPc()
      a
        -ms-writing-mode tb-rl
        writing-mode vertical-rl
        text-orientation upright
        -webkit-text-orientation upright
    +mediaSp()
      top 55px
      transform none
      width 100%
      a
        font-size 1.4rem
        width 100%
        text-align center
        padding 13px 10px
        &:active
          background bg-light-red
    &--school
      +mediaPc()
        top -30px
        a
          width auto
          -ms-writing-mode lr-tb
          writing-mode horizontal-tb
          padding 16px 40px
          line-height 3rem
  &__link
    padding-left 30px
    position relative
    &::before
      content ""
      width 24px
      height 1px
      background bg-light-green
      display block
      position absolute
      left 0
      top 15px
    &::after
      content ""
      width 1px
      height 9px
      background bg-light-green
      display block
      transform rotate(-45deg)
      position absolute
      left 20px
      top 8px

  &__breadcrumb
    font-size 1.2rem
    position relative
    z-index 2
    +mediaPc()
      margin-top 60px
    &.pc
      +mediaSp()
        display none
    &.sp
      +mediaPc()
        display none
    &.school-top
      +mediaSp()
        display none
    background bg-black
    ul
      margin 0 auto
      display flex
      flex-wrap wrap
      padding 7px 0 7px 75px
      +mediaSp()
        width 100%
        padding 8px 10px
      li
        a, span
          color #fff
        &:not(:last-child)::after
          content ">"
          padding 0 10px
          display inline-block
          color #fff

  &__loading
    position fixed
    background bg-light-gray
    left 0
    top 0
    width 100%
    height 100%
    z-index 10000
    &::before
      content ""
      display block
      width 100%
      height 100%
      position fixed
      left 0
      top 0
      background #fff
      transform translateY(100%)
      //z-index 10001
    &.loading
      &::before
        transform translateY(0px)
        transition transform .5s
      .m__common__loading--number
        opacity 1
        margin-top 20px
        transition 1s .5s
      .m__common__loading--logo
        opacity 1
        margin-top -10px
        transition 1s .5s
      &.is-loaded
        height 0
        transition .5s .5s
        z-index 0
        background transparent
        &::before
          transform translateY(-100%)
          transition transform .5s .5s
        .m__common__loading--number
          opacity 0
          transition .5s .3s
        .m__common__loading--logo
          opacity 0
          transition opacity .5s .3s
    &--logo
      position fixed
      left 50%
      top 50%
      transform translate(-50%, -50%)
      opacity 0
      margin-top 20px
      .logo-dot1, .logo-dot2
        position fixed
        left 50%
        top 50%
        transform translate(-50%, -50%)
      .logo-dot1
        margin-top -7px
        margin-left -2.8px
      .logo-dot2
        margin-top -7px
        margin-left 13.2px
    &--number
      position fixed
      left 50%
      top 50%
      transform translate(-50%, -50%)
      margin-top 50px
      font-size 1.7rem
      opacity 0
      transition 1s
      alphabet()
      bold()

  &__nessesary
    font-size 1.2rem
    color font-red
    display inline-block
    vertical-align baseline

  &__flow--nav
    &.pc
      +mediaSp()
        display none
    &.sp
      +mediaPc()
        display none
    position fixed
    z-index 10
    top 534px
    +mediaSp()
      top auto
      bottom 0
      width 100%
    ul
      font-size 1.6rem
      background bg-green
      width 300px
      padding 0 30px
      +mediaSp()
        width 100%
        display flex
        padding 0
        font-size 1.5rem
      li
        +mediaSp()
          width 100%
          &:first-child
            border-right 1px solid border-light-gray
        a
          color #fff
          text-decoration none
          +mediaSp()
            text-align center
            display block
            padding 16px 0
            &:active
              background bg-green02
        .m__common-btn__arrow
          .m__common__flex-arrow
            padding 20px 10px
            text-align left
            width 100%
            &::before
              right 24px
              bottom 32px
            &::after
              right 27px
              bottom 31px
            &:hover
              &::before
                right 14px
                bottom 32px
              &::after
                right 17px
                bottom 31px
        a
          background bg-green
        &:first-child
          border-bottom 1px solid border-light-gray
          +mediaSp()
            border-bottom 0

  &__list
    li
      text-indent -1em
      padding-left 1em
      +mediaSp()
        font-size 1.3rem
      &::before
        content:'●'
        color main-color
    &--green
      li
        color font-green02
        font-size: 2rem
        +mediaSp()
          font-size 1.3rem
        &::before
          content:'●'
          color font-green02

  &__page__Detail--black
    padding 50px 0
    background #333
    color #fff
    text-align center
    font-size 2.6rem
    +mediaSp()
      padding 15px 10px
      font-size 1.3rem
    &--txtL
      +mediaSp()
        text-align left
    &--padding__none
      +mediaSp()
        padding 15px 0
    p
      span
        font-size 3.2rem
        display block
        +mediaSp()
          font-size 1.6rem
          text-align center
      sup
        vertical-align super
        display inline-block
        font-size 40%
        transform translateY(-10%)
      &.caption
        text-align center
        font-size 1.2rem
  &__info-text
    padding-top 60px
    width 750px
    margin 0 auto
    a
      color font-red
    +mediaSp()
      width 100%
      padding-top 30px
    .text
      text-align center
      border 3px solid border-red
      color font-red
      font-size 2.6rem
      padding 20px 10px
      display block
      &.emp
        color #ff0000
        border 3px solid #ff0000
      +mediaSp()
        font-size 1.3rem
        border 2px solid border-red
        padding 10px 5px
      &--black
        border 3px solid border-black
        color font-black
    &--recruit
      width 800px
      +mediaSp()
        width 100%
      .text
        font-size 2rem
        +mediaSp()
          font-size 1.3rem
+mediaPc()
  _::-webkit-full-page-media, _:future, :root .m__common__emergency-banner a
    padding 40px 9px 40px 0 //safari 文字位置修正
  _::-webkit-full-page-media, _:future, :root .m__common__emergency-banner--school a
    padding 16px 40px
