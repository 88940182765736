.m__point
  &__textbox
    background #fff
    p
      padding-top 7px
      font-size 1.6rem
      regular()
      a
        medium()
      +mediaSp()
        font-size 1.3rem
        span
          font-size 1.2rem
    &--left
      padding 140px 60px 120px
      margin-left -60px
      width 777px
      +mediaSp()
        padding 50px 30px
        margin-left -10px
        width 86vw
    &--right
      padding 100px 60px
      margin-left auto
      margin-right -60px
      width 840px
      +mediaSp()
        padding 50px 30px
        margin-right -10px
        width 86vw
    &--padding
      padding 140px 60px 150px
      +mediaSp()
        padding 50px 30px
    .m__common-heading__mid-title
      +mediaSp()
        font-size 1.3rem

  &__mv1
    width 800px
    height 540px
    margin -60px 0
    position relative
    z-index 0
    overflow hidden
    .img-box
      width 960px
      height 648px
      background url(image_path + '/common/point/img_point_mv1.jpg') center center no-repeat
      background-size auto
      position absolute
      left 50%
      top 50%
      margin-left -480px
      margin-top -324px
      transform translateY(50px)
      +mediaSp()
        background-size cover
    +mediaSp()
      width 82vw
      height 54vw
      margin -20px 0 -20px 30px
      .img-box
        width 82vw
        height 54vw
        margin-left -41vw
        margin-top -27vw

  &__mv2
    margin 150px 0 -100px
    z-index 3
    position relative
    +mediaSp()
      margin 30px auto -20px
      width 82vw
    > img
      max-width 100%

  &__advantage-list
    padding 150px 0 50px
    +mediaSp()
      padding 50px 0 20px
    > ul
      width 1000px
      margin 0 auto
      display flex
      flex-wrap wrap
      justify-content space-between
      text-align center
      +mediaSp()
        width 100%
        display block
      > li
        width 460px
        margin-bottom 100px
        +mediaSp()
          width 100%
          margin-bottom 25px
        .icon
          height 60px
          display flex
          align-items flex-end
          justify-content center
          +mediaSp()
            height 25px
            img
              max-height 25px
        .heading
          padding 13px 0
          font-size 2.6rem
          line-height 1.4
          +mediaSp()
            font-size 1.3rem
            padding 10px 0 0
          span
            margin-right 0.2em
            vertical-align baseline
            font-size 1.6rem
            +mediaSp()
              font-size 1.3rem
        p
          regular()
          font-size 1.6rem
          +mediaSp()
            font-size 1.3rem
          span
            color font-red

  &__contents-list
    padding 100px 0
    +mediaSp()
      padding 40px 0
      .image
        margin 30px 8% 0
        img
          width 100%
    .content-block
      display flex
      justify-content space-between
      padding 50px 0
      +mediaSp()
        display block
        padding 20px 0
      & + .content-block
        border-top 1px solid border-light-gray
    .text
      width 650px
      regular()
      font-size 1.6rem
      a
        medium()
      +mediaSp()
        width 100%
        font-size 1.3rem
      .m__common-heading__mid-title
        letter-spacing -0.04em
        +mediaSp()
          font-size 1.3rem
      p
        padding-top 7px
