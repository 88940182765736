.m__english__whats
  &__content
    font-size 1.6rem
    regular()
    word-break break-word
    +mediaSp()
      font-size 1.3rem
    +mediaPc()
      .l__contents__inner--width
        width 1000px
    .m__common-heading__english
      margin 40px 0 10px
      +mediaSp()
        margin-top 20px
        line-height 1.4
    p + p
      margin-top 30px
      +mediaSp()
        margin-top 15px
  &__lead
    padding-bottom 60px
  &__exam
    padding 90px 0 150px
    background bg-light-gray
    +mediaSp()
      padding 30px 0 60px
    h2
      margin-bottom 80px
      +mediaSp()
        margin-bottom 20px
    dl
      dt
        font-size 1.8rem
        bold()
        +mediaSp()
          font-size 1.5rem
      dd
        margin-bottom 40px
        +mediaSp()
          margin-bottom 20px
    ol
      counter-reset item
      li
        text-indent -1.9em
        padding-left 1.9em
        +mediaSp()
          + li
            margin-top 15px
      > li
        &::before
          counter-increment item 1
          margin-right 0.5em
          content "("counter(item,number)")"
        span
          margin 0 0.5em
    p.notice
      text-indent -1em
      padding-left 1em
      margin-top 0
