.m__company
  &__table
    padding 100px 0
    max-width 750px
    margin 0 auto
    +mediaSp()
      padding 30px 0 40px
      margin 0 -10px
    .telnumber
      +mediaPc()
        color font-black
        text-decoration none
        pointer-events none
      +mediaSp()
        medium()
    table
      border 1px solid border-black
      box-sizing border-box
      +mediaSp()
        border-left 0
        border-right 0
      tr
        &:not(:last-child)
          border-bottom 1px solid border-black
        th,td
          padding 15px
          regular()
          font-size 1.6rem
          vertical-align baseline
          +mediaSp()
            font-size 1.3rem
            padding 10px 7px
        th
          background bg-light-gray
          width 220px
          text-align left
          +mediaSp()
            width 110px
            white-space nowrap
        td
          width 530px
  &__philosophy
    font-size 1.6rem
    margin 0 80px 150px
    regular()
    +mediaSp()
      font-size 1.3rem
      margin 0 0 40px
    a
      medium()
    dt
      margin-top 30px
      +mediaSp()
        medium()
        margin-top 20px
    p
      margin-bottom 30px
      +mediaSp()
        margin-bottom 20px
  &__affiliated
    padding 100px 0 10px
    max-width 750px
    margin 0 auto
    font-size 1.6rem
    +mediaSp()
      padding 30px 0 0
      margin 0 -10px
      font-size 1.3rem
    .telnumber
      +mediaPc()
        color font-black
        text-decoration none
        pointer-events none
      +mediaSp()
        medium()
    .contents-block
      margin-bottom 90px
      +mediaSp()
        margin-bottom 50px
    .link
      position relative
      padding-left 1em
      display block
      +mediaSp()
        font-size 1.4rem
        margin-left 7px
      &::before
        position absolute
        content "▶"
        font-size 1.1rem
        color font-green
        bottom 4px
        left 0
        +mediaSp()
          font-size 0.8rem
    table
      border-top 1px solid border-black
      border-bottom 1px solid border-black
      box-sizing border-box
      margin-top 5px
      tr
        &:first-child
          th,td
            padding-top 15px
            +mediaSp()
              padding-top 10px
        &:last-child
          th,td
            padding-bottom 15px
            +mediaSp()
              padding-bottom 10px
      th,td
        padding 0 15px
        regular()
        vertical-align baseline
        +mediaSp()
          padding 0 7px
      th
        background bg-light-gray
        width 220px
        text-align left
        +mediaSp()
          width 110px
          white-space nowrap
      td
        width 530px
        p
          margin-left 1em
    &.sites
      padding 30px 0 60px
      margin-bottom 150px
      +mediaSp()
        padding 10px 0 30px
        margin-bottom 50px
      .m__common-heading__title
        font-size 2.6rem
        +mediaSp()
          font-size 1.6rem
      .link
        margin-top 30px
        +mediaSp()
          margin-top 10px
  &__alliance
    .splink
      display none
      +mediaSp()
        display flex
        justify-content center
        background bg-light-gray
        padding 15px 0
        font-size 1.3rem
        margin-top 30px
        li
          position relative
          padding-left 1em
          &::before
            position absolute
            content "▼"
            font-size 0.8rem
            top 3px
            left 0
          &:nth-child(2)
            margin-left 35px
            &::before
              color font-green
    .m__common-heading__title
      background bg-header
      color #fff
      font-size 2.6rem
      line-height 60px
      margin-top 100px
      +mediaSp()
        font-size 1.5rem
        line-height 50px
        margin-top 0
    .school-list
      width 1040px
      margin 60px auto
      font-size 1.6rem
      display flex
      flex-direction column
      flex-wrap wrap
      align-items flex-start
      +mediaSp()
        width 100%
        margin 30px 0
        padding 0 10px
        font-size 1.3rem
      &.college
        height 1414px
        @media all and (-ms-high-contrast: none) and (max-width: 767px)
          max-height 100%!important
        +mediaSp()
          height auto
          max-height 1714px
          li
            &:nth-child(16)
              margin-bottom 0
      &.other
        height 605px
        margin-bottom 100px
        +mediaSp()
          height auto
          margin-bottom 30px
          li
            width 100%
      > li
        display flex
        width 345px
        margin-bottom 30px
        +mediaSp()
          width 50%
          margin-bottom 25px
        .initials
          color #999
          width 25px
        .school
          regular()
  &__privacy
    regular()
    font-size 1.6rem
    max-width 910px
    margin 100px auto 150px
    +mediaSp()
      margin 30px 0 30px
      font-size 1.3rem
      padding 0 10px
    .telnumber
      +mediaPc()
        color font-black
        text-decoration none
        pointer-events none
      +mediaSp()
        medium()
        display block
    b
      medium()
    .privacy
      margin-top 30px
      counter-reset item
      +mediaSp()
        margin-top 20px
      > li
        margin-bottom 30px
        text-indent -30px
        padding-left 30px
        +mediaSp()
          margin-bottom 25px
          text-indent -20px
          padding-left 20px
        &::before
          counter-increment item
          content counter(item)'.'
          padding-right 15px
          medium()
          +mediaSp()
            padding-right 7px
        &:nth-child(n+10)
          &::before
            padding-right 5px
            +mediaSp()
              margin-left -5px
      .dot
        li
          text-indent -1em
          padding-left 1em
          &::before
            content "・"
      .number
        li
          counter-increment cnt
          text-indent -2em
          padding-left 2em
          &::before
            content "(" counter(cnt) ") "
      p
        text-indent 0
    .contact
      border-bottom 1px solid border-light-gray
      +mediaSp()
        margin 0 -10px
        border 0
        padding 10px 0
      li
        padding 20px 30px
        border-top 1px solid border-light-gray
        +mediaSp()
          padding 10px 30px
          &:first-child
            border 0