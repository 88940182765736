.m__campaign
  &__img
    padding 100px 0
    +mediaSp()
      padding 0 0 30px
      margin 0 -10px
    img
      margin 0 auto
      display block
      width 100%
      +mediaSp()
        max-width 100%
  &__form
    .m__form
      padding-top 60px
      .m__common-form__radiobutton
        padding-right 30px
      +mediaSp()
        margin 0 -10px
        dl
          dd
            p
              font-size 1.5rem
     .m__form__description
       +mediaSp()
        margin 0 -10px