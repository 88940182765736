.m__recruit
  background bg-light-gray
  margin-top 160px
  position relative
  +mediaSp()
    margin-top 30px
  &__video
    padding-top 400px
    +mediaSp()
      padding-top 0
      margin 0 -10px
    .video
      width 800px
      height 450px
      background bg-gray
      position absolute
      left 50%
      top -100px
      transform translateX(-50%)
      +mediaSp()
        width 100%
        height 56vw
        top 0
        position relative
        background transparent
        transform none
        left 0
      iframe
        width 100%
        height 100%
    dl
      margin 0 40px
      +mediaSp()
        margin 30px 10px 50px
      dt
        font-size 2.6rem
        +mediaSp()
          font-size 1.5rem
      dd
        regular()
        font-size 1.6rem
        +mediaSp()
          font-size 1.4rem
  &__introduce
    margin-top 100px
    padding-bottom 150px
    +mediaSp()
      margin-top 0
      padding-bottom 50px

    &--group-box
      position relative
      padding-top 600px
      &:nth-child(3)
        padding-top 300px
      &:last-child
        padding-top 150px
      +mediaSp()
        padding-top 280px
        &:last-child
          padding-top 50px
      .image
        position absolute
        left 0
        top 40px
        z-index 1
        overflow hidden
        +mediaSp()
          left -10px
        &.width500
          width 500px
          height 750px
          +mediaSp()
            width 250px
            height 375px
            img
              width 100%
          img
            position absolute
            top 0
            left 0
            transform translateY(-250px)
            +mediaSp()
              transform translateY(0)
        &.width600
          width 600px
          height 540px
          img
            position absolute
            top 0
            left 0
            height 100%
            transform translateY(-250px)
          +mediaSp()
            width 300px
            height 270px
            img
              width 100%
              transform translateY(0)

        &.fixed
          position fixed
          top 160px
          left 50%
          transform translateX(-50%)
          margin-left -290px
          &.width600
            margin-left -240px
            +mediaSp()
              margin-left 0
          +mediaSp()
            margin-left 0
            left 0
            transform none
        &.end
          position absolute
          top auto
          bottom 100px
          &.width600
            bottom 120px
          +mediaSp()
            left -10px

      .box
        background #fff
        width 840px
        padding 100px 80px
        position relative
        margin-left auto
        z-index 2
        +mediaSp()
          width 325px
          padding 30px
          height 500px
          overflow hidden
          height 310px
          transition .5s
          .text
            padding-bottom 60px
          &.open
            .js-trigger
              &::before
                display none
              span.close
                display block
              span.open
                display none
        + .box
          margin-top 100px
          +mediaSp()
            margin-top 50px
        h2
          font-size 3.2rem
          margin-bottom 60px
          medium()
          +mediaSp()
            font-size 1.6rem
            margin-bottom 20px
        h3
          color font-green02
          font-size 2.6rem
          line-height 1.4
          medium()
          margin-bottom 10px
          +mediaSp()
            font-size 1.3rem
        p
          regular()
          font-size 1.6rem
          +mediaSp()
            font-size 1.3rem
          b
            medium()
          + p
            margin-top 1.5em
          + h3
            margin-top 40px
            +mediaSp()
              margin-top 20px
        .js-trigger
          padding 10px
          background #fff
          position absolute
          left 0
          bottom 0
          width 100%
          text-align center
          &::before
            content ""
            display block
            width 100%
            height 100px
            position absolute
            bottom 44px
            left 0
            background linear-gradient(rgba(255,255,255,0), #fff)
            z-index 10
          span.close
            display none
          span.open
            color font-light-green
          +mediaPc()
            display none
  &__part-time
    margin-top 100px
    padding-top 100px
    font-size 1.6rem
    +mediaSp()
      font-size 1.3rem
      margin 30px -10px 0
      padding 0
    .box
      background #fff
      width 840px
      padding 100px 80px
      margin-left auto
      +mediaSp()
        width 100%
        padding 0 10px 10px
    dl
      margin-bottom 40px
      dt
        color font-green02
      dd
        font-weight 400
  &__contact
    margin-top 90px
    font-size 1.6rem
    +mediaSp()
      margin 30px -10px 0
      font-size 1.3rem
    .m__form__description
      .title
        border-bottom 1px solid border-light-gray
        p
          regular()
          padding 20px
          +mediaSp()
            padding 10px 0
      .text
        border-bottom 0
  &__career
    margin-top 100px
    padding 100px 0 50px
    font-size 1.6rem
    +mediaSp()
      font-size 1.3rem
      margin 30px -10px 0
      padding 30px 0 20px
    .box
      background #fff
      width 840px
      padding 100px 80px
      margin-left auto
      margin-bottom 50px
      +mediaSp()
        margin-bottom 30px
      h2
        font-size 3.2rem
        medium()
        +mediaSp()
          font-size 1.6rem
      h3
        color font-green02
        font-size 2.6rem
        line-height 1.4
        medium()
        margin-bottom 10px
        +mediaSp()
          font-size 1.3rem
      .img
        margin-top 60px
        +mediaSp()
          margin-top 15px
          img
            width 160px
        .m__common__flex-arrow
          padding-right 80px
          &::before
            right auto
            bottom 30px
          &::after
            right 40px
            bottom 28px
          +mediaPc()
            &:hover
              &::before
                width 65px
          +mediaSp()
            padding-right 30px
            &::before
              right 17px
              bottom 19px
            &::after
              right 19px
              bottom 18px
      +mediaSp()
        width 100%
        padding 40px 10px
        text-align center
