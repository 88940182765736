.m__plan-category
  &__link
    background bg-light-gray
    display flex
    justify-content center
    padding 18px 0
    margin-top 15px
    li
      margin 0 30px
      &::before
        content "▼"
        color font-green
        font-size 1rem
        display inline-block
        margin-right 5px
        &:hover
          color font-light-green
    +mediaSp()
      flex-wrap wrap
      margin 30px -10px 0
      justify-content flex-start
      padding 15px 20px
      li
        margin 0
        &:nth-child(1)
          order 1
          width 45%
        &:nth-child(2)
          order 3
          width 45%
        &:nth-child(3)
          order 5
          width 45%
        &:nth-child(4)
          order 2
          width 55%
        &:nth-child(5)
          order 4
          width 55%
    &.type2
      +mediaSp()
        flex-wrap nowrap
        justify-content space-between
        li
          order unset
          width auto
  &__price-list
    margin-top 150px
    regular()
    +mediaSp()
      margin 30px -10px 0
      &.type2
        font-size 1.3rem
        .m__txt--c
          text-align left
          padding 0 10px
    &--mt90
      margin-top 90px
      +mediaSp()
        background bg-light-gray
        margin-top 0
        padding-top 30px
  &__tab-list
    font-size 0
    letter-spacing -.04em
    margin-top 30px
    medium()
    +mediaSp()
      margin-top 10px
    &--futako
      .plan-tab-item
        &:hover
          background #fbf8d2
    &--shakujii
      .plan-tab-item
        &:hover
          background #dbe2f2
    &--seijo
      .plan-tab-item
        &:hover
          background #ccf2f2
    &--yokohama
      .plan-tab-item
        &:hover
          background #ffddd5
    &--akitsu
      .plan-tab-item
        &:hover
          background #fce4cf
    input[type="radio"]
      display none
      &:checked
        + .plan-tab-item
          background main-color
          transition .5s
          &::after
            content ""
            display block
            width 0
            height 0
            border-style solid
            border-width 10px 7.5px 0 7.5px
            border-color main-color transparent transparent transparent
            position absolute
            bottom -10px
            left 50%
            transform translateX(-50%)
            +mediaSp()
              display none
            &:hover
              background main-color-light
              &::after
                border-color main-color-light transparent transparent transparent
          +mediaSp()
            &:active
              background main-color-light
              &::after
                border-color main-color-light transparent transparent transparent
    .plan-tab-content
      display none
      &.notab
        display block
        +mediaSp()
          border-top none
          padding-top 0
          &::before
            display none
    #first-time-tab:checked ~ #first-time, #motorcycle-license-tab:checked ~ #motorcycle-license, #provisional-license-tab:checked ~ #provisional-license, #limited-release-tab:checked ~ #limited-release
      display block
    .m__common-btn__arrow
      margin 20px 0
      a.m__common__flex-arrow
        font-size 1.6rem
        padding 16px 110px 16px 100px
        +mediaSp()
          padding 16px 70px 16px 40px
          &::before
            transform none
            right 35px
          &::after
            transform rotate(-45deg)
            right 38px
          &:hover
            &::before
              transition 0s !important
          &:active
            &::before
              width 40px
              transform none
              right 19px
              transition width 0.3s, right 0.3s !important
            &::after
              right 22px
              transition right 0.3s !important
        &::before
          width 24px
          bottom 27px
          +mediaPc()
            right 75px
        &::after
          height 8px
          bottom 26px
          +mediaPc()
            right 78px
        +mediaPc()
          &:hover
            &::before
              width 50px
              right 49px
            &::after
              right 52px
    .plan-tab-item
      display inline-block
      width 25%
      border 1px solid main-color
      border-right none
      padding 15px 20px
      text-align center
      position relative
      +mediaSp()
        width 50%
        padding 10px 0
        border-left none
        border-bottom none
        &:nth-child(even)
          border-left 1px solid main-color
      &:hover
        cursor pointer
        transition background 0.3s
      &.last-tab
        border-right 1px solid main-color
        &--sp
          +mediaSp()
            border-right 1px solid main-color
      p
        font-size 1.6rem
        letter-spacing normal
        +mediaSp()
          font-size 1.4rem
    .plan-tab-content
      font-size 1.8rem
      letter-spacing normal
      background bg-light-gray
      padding 30px 0
      text-align center
      img
        max-width 750px
      .coupon
        width 400px
        margin 20px auto 0
        img
          max-width 100%
      +mediaSp()
        background #fff
        border-top 1px solid main-color
        position relative
        &::before
          content ""
          display block
          width 0
          height 0
          border-style solid
          border-width 10px 7.5px 0 7.5px
          border-color main-color transparent transparent transparent
          position absolute
          top 10px
          left 50%
          transform translateX(-50%)
        h3
          background main-color
          text-align center
          font-size 1.4rem
          padding 5px 0
          medium()
          span
            display inline-block
        .coupon
          width 270px
          margin 20px auto
        img
          max-width 100%
    &--third_division
      +mediaSp()
        background bg-light-gray
      .plan-tab-content
        +mediaSp()
          background bg-light-gray
        h3
          +mediaSp()
            background bg-light-gray
      .plan-tab-item
        width calc(100% / 3)
        +mediaSp()
          background #fff
          width 100%
          p
            text-align left
            padding-left 10px
  &__notice
    width 900px
    margin 41px auto 55px
    img
      max-width 100%
    +mediaSp()
      width auto
      margin 0 0 50px
      font-size 1.3rem
    ul
      li
        regular()
        text-indent -1em
        padding-left 1em
        line-height 2
        &::before
          content "・"
        span
          &.notice
            text-indent -15px
            padding-left 14px
            display inline-block
  &__course
    padding 41px 0 55px
    width 900px
    margin 0 auto
    +mediaSp()
      width auto
      margin 0 -10px
    > p
      margin-bottom 50px
      +mediaSp()
        margin-bottom 0
    .another-plan
      margin-top 30px
      +mediaSp()
        font-size 1.3rem
        padding 0 10px
        a
          display inline-block
      dt
        font-size 2.6rem
        +mediaSp()
          font-size 1.5rem
    p.notice
      margin 20px 0
      +mediaSp()
        text-indent -1em
        padding 0 10px 0 1em
        margin-left 10px
        font-size 1.3rem
  &__table
    margin 0 auto
    border 2px solid #000
    width 900px
    +mediaSp()
      border 0
      width 100%
      tbody
        display block
    tr
      th, td
        border 1px solid #000
        vertical-align middle
        +mediaSp()
          border 0
          border-bottom 1px solid #000
      th
        medium()
        text-align left
        padding 20px 15px
        width 255px
        +mediaSp()
          padding 5px 10px
          width 120px
      td
        regular()
        padding 20px
        background #fff
        span
          display inline-block
          margin-right 15px
        +mediaSp()
          padding 5px 10px
          border-left 1px solid #000
          width calc(100vw - 120px)
      &.course
        th
          height 80px
          text-align center
          font-size 3.3rem
          padding 0
          bold()
          +mediaSp()
            font-size 1.65rem
            height 50px
          img
            vertical-align middle
            margin-right 10px
            +mediaSp()
              transform scale(.7)
              margin-right 0
          span.alphabet
            font-size 3.5rem
            vertical-align baseline
            +mediaSp()
              font-size 1.75rem
          &.notice
            padding 6px 0 10px
            p
              color font-red
              font-size 1.4rem
      &.price
        td
          medium()
          font-size 1.8rem
          span.notice
            color font-red
            margin-right 10px
            +mediaSp()
              margin-right 0
          +mediaSp()
            font-size 1.5rem
      &:last-child
        +mediaSp()
          display block
          th, td
            display block
            width 100%
            border-left 0
    + .m__plan-category__table
      margin-top 30px
      +mediaSp()
        margin-top 10px
  &__plan
    width 900px
    margin 120px auto
    +mediaSp()
      width 100%
      margin 50px 0 0
    > p
      margin 0 10px
      regular()
      +mediaSp()
        margin 0
      &.title
        font-size 2.6rem
        medium()
        +mediaSp()
          font-size 1.5rem
    .car-list
      margin 20px 0 60px
      display flex
      justify-content space-between
      +mediaSp()
        margin 5px -10px 20px
        flex-wrap wrap
      li
        text-align center
        margin-bottom 25px
        padding 0 3px
        +mediaSp()
          width 90%
          margin 10px auto 20px
        img
          max-width 100%
        p
          margin-top 10px
          font-size 2.6rem
          bold()
          alphabet()
          line-height 1
          +mediaSp()
            font-size 1.6rem
          span
            font-size 1.6rem
            regular()
            +mediaSp()
              font-size 1.25rem
    .m__common-btn__arrow
      text-align center
      +mediaSp()
        margin 0 -10px
      a.m__common__flex-arrow
        min-width 300px
        +mediaSp()
          min-width 100%
        &::before
          right 70px
          +mediaSp()
            right calc(50% - 60px)
        &::after
          right 72px
          +mediaSp()
            right calc(50% - 58px)
        &:hover
          &::before
            right 60px
            width 34px
            +mediaSp()
              right calc(50% - 70px)
          &::after
            right 62px
            +mediaSp()
              right calc(50% - 68px)
    .plan-type
      display flex
      flex-wrap wrap
      justify-content space-between
      margin-top 80px
      +mediaSp()
        margin-top 40px
      &.block
        display block
        +mediaSp()
          margin-top 10px
        li
          margin-bottom 60px
          +mediaSp()
            margin-bottom 30px
      &.photo
        li
          width 480px
          position relative
          +mediaSp()
            width 100%
          img
            +mediaPc()
              position absolute
              top -50px
              right -470px
      li
        width 430px
        margin-bottom 30px
        font-size 1.5rem
        img
          max-width 100%
        +mediaSp()
          width 100%
          font-size 1.3rem
        a
          display block
          font-size 2.6rem
          position relative
          color font-green
          text-decoration none
          padding 10px 0
          border-bottom 1px solid border-black
          margin-bottom 10px
          +mediaSp()
            font-size 1.5rem
            color font-green
          &::before
            content ""
            display block
            width 44px
            height 1px
            background bg-green02
            position absolute
            right 20px
            bottom 25px
            transition width .3s, right .3s
            +mediaSp()
              width 24px
              background bg-green
          &::after
            content ""
            display block
            position absolute
            background bg-green02
            width 15px
            height 1px
            right 18px
            bottom 30px
            transform rotate(45deg)
            transition width .3s, right .3s
            +mediaSp()
              width 8px
              bottom 27px
              background bg-green02
          &:hover
            &::before
              right 10px
              width 54px
              +mediaSp()
                width 34px
            &::after
              right 8px
        p
          regular()
          b
            medium()
    .limit-plan
      margin 0 -90px 60px
      border-top 1px solid border-light-gray
      border-bottom 1px solid border-light-gray
      padding 60px 90px
      .label
        display inline-block
        padding 2px 10px
        bold()
        background-color main-color
        font-size 1.6rem
      .title
        font-size 2.6rem
        margin 5px 0
        medium()
      .text-box
        background bg-light-gray
        text-align center
        padding 30px
        margin-top 15px
        font-size 1.6rem
        b,.red
          bold()
          font-size 2.4rem
        .red
          color font-red
          line-height 42px
          span
            font-size 1.6rem
            margin-right 0.5em
      +mediaSp()
        margin 0 -10px 30px
        padding 10px 10px 30px
        .label
          font-size 1.5rem
        .title
          font-size 1.5rem
        .text-box
          font-size 1.4rem
          padding 20px 10px
          margin 0 -10px
          b
            font-size 1.3rem
          .red
            font-size 1.8rem
            line-height 1.5
            margin 8px 0
            span
              font-size 1.4rem
              display block
          p
            text-align left
            text-indent -1em
            padding-left 1em
    .m__two-plans
      border-top 1px solid border-light-gray
      border-bottom 1px solid border-light-gray
      padding 60px 0
      margin 60px -90px 0
      +mediaSp()
        margin 40px auto 30px
        padding 30px 0 40px
      .inner
        margin 0 90px
        +mediaSp()
          margin 0 auto
        .title
          font-size 2.6rem
          font-weight 500
          padding-bottom 20px
          +mediaSp()
            font-size 1.5rem
            padding-bottom 10px
        .empArea
          font-weight 600
          font-size 2.4rem
          background bg-light-gray
          text-align center
          padding 30px 0
          +mediaSp()
            font-size 1.4rem
            padding 15px 0
          span
            color font-red
            &.txtS
              font-size 1.8rem
              vertical-align text-bottom
              letter-spacing -0.1rem
              padding-right 3px
              +mediaSp()
                font-size 1.2rem
    &.center
      width 1080px
      +mediaSp()
        width 100%
        padding-bottom 50px
      .plan-type
        border-bottom 1px solid border-light-gray
        justify-content center
        margin-bottom 60px
        margin-top 10px
        +mediaSp()
          margin 10px -10px 30px
          padding 0 10px
        li
          margin-bottom 60px
          width 480px
          +mediaSp()
            margin-bottom 30px
            width 100%

  &__condition
    padding 45px 0 50px
    > dl
      > dt
        margin-top 25px
        medium()
      dd
        regular()
        b,a
          medium()
        .condition-detail
          dl
            display flex
            + dl
              margin-top 1em
            dd
              flex 1
        ul
          li
            list-style decimal
            margin-left 1.2em
            + li
              margin-top 1em
    span.indent
      text-indent -1em
      padding-left 1em

  &__flow
    padding 120px 0 100px
    +mediaSp()
      padding 50px 0
    &--special-case
      padding 0 0 80px
      +mediaSp()
        padding 40px 0 40px
    p.title
      font-size 2.6rem
      +mediaSp()
        font-size 1.5rem
        margin-top 10px
    .flow-list
      margin 30px -60px 0
      display flex
      justify-content space-between
      +mediaSp()
        display block
        margin 10px -10px
      .first-step, .second-step
        width 560px
        +mediaSp()
          width 100%
        p
          text-align center
          font-size 1.8rem
          margin 15px 0
          regular()
          +mediaSp()
            font-size 1.4rem
        .step-num
          background bg-green02
          color #fff
          padding 9px 0
          text-align center
          font-size 2.6rem
          +mediaSp()
            font-size 1.4rem
        ul
          li
            + li
              margin-top 70px
              +mediaSp()
                margin-top 40px
            .block
              color #fff
              background bg-green02
              width 270px
              text-align center
              padding 14.5px 0
              font-size 1.8rem
              margin 0 auto
              position relative
              +mediaSp()
                font-size 1.4rem
                width 180px
              &::before
                content ""
                display block
                width 0
                height 0
                border-style solid
                border-width 15px 11px 0 11px
                border-color bg-green02 transparent transparent transparent
                position absolute
                left 50%
                top -45px
                transform translateX(-50%)
                +mediaSp()
                  top -25px
                  border-width 10px 7.5px 0 7.5px
            &:first-child

              .block
                &::before
                  display none
            .flex
              margin 0 5px
              display flex
              justify-content space-between
            .result
              color font-red
              font-size 2.6rem
              position relative
              margin -20px 0 0
              +mediaSp()
                font-size 1.6rem
                margin 0
                line-height 1.2
                medium()
              &::before
                content ""
                display block
                width 0
                height 0
                border-style solid
                border-width 15px 11px 0 11px
                border-color bg-green02 transparent transparent transparent
                position absolute
                left 50%
                top -25px
                transform translateX(-50%)
                +mediaSp()
                  border-width 10px 7.5px 0 7.5px
                  top -23px
            .notice
              font-size 1.4rem
      .second-step
        +mediaSp()
          margin-top 30px
      &--single-step
        justify-content space-around
        +mediaSp()
          padding-top 30px
          margin 10px 0
        .first-step
          ul
            li
              .block
                width 100%
                font-size 2.6rem
                +mediaSp()
                  font-size 1.6rem
                  padding 3px

  &__introduction
    background bg-header
    text-align center
    padding 50px 0
    margin-top 100px
    color #fff
    +mediaSp()
      margin-top 30px
      padding 13px 5px
    h3
      color #fff
      margin-bottom 5px
      +mediaSp()
        font-size 1.6rem
    p
      font-size 2.6rem
      line-height 1.5
      span
        font-size 1.6rem
        display block
        text-align center
      +mediaSp()
        font-size 1.3rem
        text-align left
        &.txt-c
          text-align center
        span
          font-size 1.3rem
          regular()
          text-align left

  &__detail
    margin-top 60px
    +mediaSp()
      margin-top 30px
    &.mt0
      margin-top 0
      +mediaSp()
        margin-top 0
    .notice
      width 680px
      text-align center
      margin 0 auto 50px
      font-size 2.6rem
      medium()
      color font-red
      border 2px solid border-red
      line-height normal
      padding 15px 0
      +mediaSp()
        margin 0 auto 20px
        max-width 340px
        width 100%
        font-size 1.3rem
        line-height 20px
        padding 10px 0
      .telnumber
        text-decoration none
        color font-red
    h3
      margin-bottom 20px
      +mediaSp()
        margin-bottom 10px
      .alphabet
        font-size 2.6rem
        letter-spacing 5px
        +mediaSp()
          font-size 2rem
    dl
      padding 0 20px 27px
      border-bottom 1px solid border-light-gray
      +mediaSp()
        padding 0 5px 10px
        margin 0 -10px
      &.border
        border-top 1px solid border-light-gray
      dt
        margin 20px 0 10px
        +mediaSp()
          margin 10px 0
        &.m__common-heading__check
          span
            regular()
            font-size 1.6rem
            margin-left 10px
            vertical-align baseline
            +mediaSp()
              font-size 1.3rem
              display block
              margin 10px 0 -5px -20px
      dd
        font-size 1.6rem
        +mediaSp()
          font-size 1.3rem
        .jukyu
          li
            margin-bottom 10px
        p
          margin-top 10px
          regular()
          b
            medium()
        .link
          padding 10px 0 0 0
          +mediaSp()
            padding 0 0 30px 0
          a
            display block
            margin-top 30px
    .m__txt--c
      padding 60px 0
      font-size 1.6rem
      medium()
      +mediaSp()
        padding 30px 0
        font-size 1.3rem
  &__detail2
    margin-top 50px
    padding 100px 0 60px
    +mediaSp()
      margin-top 40px
      padding 0
    .box
      display flex
      margin-bottom 40px
      justify-content space-between
      +mediaSp()
        display block
      .text
        width 640px
        +mediaSp()
          width 100%
        h3
          font-size 2.6rem
          color font-green02
          margin-bottom 10px
          medium()
          +mediaSp()
            font-size 1.3rem
        p
          font-size 1.6rem
          regular()
          +mediaSp()
            font-size 1.3rem
      .img
        img
          width 400px
          +mediaSp()
            width 300px
        +mediaSp()
          margin 30px auto
          display table

  &__feature
    display table
    margin 0 auto
    padding 40px 0
    +mediaSp()
      padding 20px 10px
    li
      medium()
      min-height 60px
      display flex
      align-items center
      +mediaSp()
        min-height 30px
        align-items flex-start
      &:not(:last-child)
        margin-bottom 30px
        +mediaSp()
          margin-bottom 15px
      .icon
        width 60px
        margin-right 20px
        display flex
        align-items center
        justify-content center
        +mediaSp()
          width 30px
          margin-right 10px
          img
            max-width 100%
      .text
        line-height 1.4
      .heading
        font-size 2.6rem
        +mediaSp()
          font-size 1.5rem
      span,p
        font-size 1.6rem
        vertical-align baseline
        +mediaSp()
          font-size 1.3rem
  &__benefits
    border-top 1px solid border-light-gray
    background bg-light-gray
    padding 50px 90px
    regular()
    line-height 2
    +mediaSp()
      padding 20px 10px
      background #fff
      font-size 1.3rem
      line-height 1.75
    .m__common-btn__arrow
      text-align center
      margin 20px 0
      medium()
      a.m__common__flex-arrow
        font-size 1.6rem
        padding 16px 110px 16px 100px
        +mediaSp()
          padding 16px 70px 16px 40px
          width 300px
          &::before
            transform none
            right 50px
          &::after
            transform rotate(-45deg)
            right 53px
          &:hover
            &::before
              transition 0s !important
          &:active
            &::before
              width 40px
              transform none
              right 33px
              transition width 0.3s, right 0.3s !important
            &::after
              right 36px
              transition right 0.3s !important
        &::before
          width 24px
          bottom 29px
          +mediaPc()
            right 75px
        &::after
          height 8px
          bottom 28px
          +mediaPc()
            right 78px
        +mediaPc()
          &:hover
            &::before
              width 50px
              right 49px
            &::after
              right 52px
    .m__common__list
      li::before
        color bg-light-green
    &--borderNone
      border none
      padding 0 160px 80px
      font-weight 500
      font-size 1.6rem
      letter-spacing -0.05rem
      +mediaSp()
        padding 0 10px 40px
        font-size 1.3rem
        letter-spacing 0
        background #f0f0f0
.m__plan-category-2
  &__link
    background bg-light-gray
    display flex
    justify-content center
    padding 18px 0
    margin-top 30px
    li
      margin 0 30px
      color font-green
      &::before
        content "▼"
        color font-green
        font-size 1rem
        display inline-block
        margin-right 5px
      &:nth-child(1),&.sp
        &::before
          display none
      +mediaPc()
        &:nth-child(1)
          margin 0 7px 0 30px
        &:nth-child(2),&:nth-child(3)
          margin 0 7px
        &:nth-child(4)
          margin 0 30px 0 7px
    +mediaSp()
      flex-wrap wrap
      margin 15px -10px 0
      padding 15px 8vw
      justify-content space-between
      li
        margin 0
        &.sp
          width 100%
          margin-top 10px

  &__introduction
    padding 100px 0 150px
    +mediaSp()
      padding 30px 0 50px
    dl
      width 770px
      padding 95px 60px
      background #fff
      +mediaSp()
        width 90%
        padding 50px 30px
      dt
        font-size 3.2rem
        line-height 1.3
        +mediaSp()
          font-size 1.6rem
        > span
          display block
          font-size 2.6rem
          +mediaSp()
            font-size 1.3rem
          &.blue
            display inline
            font-size 3.2rem
            color font-green
            +mediaSp()
              font-size 1.6rem
          > span
            font-size 1.6rem
            vertical-align baseline
            +mediaSp()
              font-size 1.1rem
      dd
        margin-top 20px
        font-size 1.6rem
        +mediaSp()
          margin-top 10px
          font-size 1.3rem
        > p
          font-weight 400
          margin-top 40px
          +mediaSp()
            margin-top 20px
      &:nth-of-type(1)
        padding 150px 60px
        +mediaSp()
          padding 50px 30px 100px
      &:nth-of-type(2)
        padding 100px 80px
        margin -68px 0 100px auto
        +mediaSp()
          padding 50px 30px
          margin -20px 0 50px auto
        dt
          font-size 2.6rem
          color font-green02
          +mediaSp()
            font-size 1.3rem
    > img
      position relative
      z-index 2
      margin -70px 0 0 70px
      +mediaSp()
        width: 78%;
        margin: -70px 0 0 9%;
    .list-line
      width 560px
      padding-top 10px
      +mediaSp()
        width: 100%
      li
        padding 13px 10px 13px 50px
        border-bottom 1px solid border-light-gray
        +mediaSp()
          padding 10px
        &:last-child
          padding-bottom 0
          border-bottom none
  &__media
    padding 130px 0
    +mediaSp()
      padding 50px 0
    h3
      padding-bottom 80px
      text-align center
      font-size 3.2rem
      font-weight 500
      +mediaSp()
        padding-bottom 30px
        font-size 1.6rem
      span
        color font-red
    .flex-box
      display flex
      +mediaSp()
        display block
        padding 0 10px
      & + .flex-box
        padding-top 45px
        margin-top 35px
        border-top 1px solid border-light-gray
        +mediaSp()
          padding-top 30px
          margin-top 40px
      img
        +mediaSp()
          display block
          width 85%
          margin 30px auto 0
    .text
      margin-right 20px
      +mediaSp()
        margin-right 0
      p
        margin-bottom 15px
        +mediaSp()
          margin-bottom 10px
        span
          display inline-block
          padding 0 10px
          background #000
          color #fff
          font-family fontset-alphabet
          font-weight 600
          font-size 1.6rem
      dl
        width 640px
        +mediaSp()
          width 100%
        dt
          font-size 2.6rem
          +mediaSp()
            font-size 1.6rem
          a
            display inline-block
            margin-bottom 15px
            text-decoration underline
            +mediaSp()
              margin-bottom 0
            span
              display block
              font-size 1.6rem
        dd
          padding-top 10px
          font-size 1.6rem
          font-weight 400
          +mediaSp()
            padding-top 5px
            font-size 1.3rem
  &__price-list
    margin-top 150px
    font-size 1.6rem
    regular()
    &:nth-of-type(n+2)
      margin-top 100px
      +mediaSp()
        margin-top 20px
    +mediaSp()
      font-size 1.3rem
      margin 30px -10px 0
    .m__common-heading__title
      line-height 1.1
  &__tab-list
    #motorcycle-MT-license-tab:checked ~ #motorcycle-MT-license, #motorcycle-AT-license-tab:checked ~ #motorcycle-AT-license,
    #basic-license-tab:checked ~ #basic-license, #moped-license-tab:checked ~ #moped-license,
    #basic-license2-tab:checked ~ #basic-license2, #moped-license2-tab:checked ~ #moped-license2
      display block
    .plan-tab-item
      width 50%
      +mediaSp()
        width 100%
        text-align left
        padding 10px 20px
        border-left none !important
      &.last-tab
        border-right 1px solid main-color
        +mediaSp()
          border-right none
.m__plan-category-3
  &__tab-list
    input[type="radio"]:checked + .plan-tab-item::after
      display none
    #no-license-tab:checked ~ #no-license,
    #motorcycle-license-tab:checked ~ #motorcycle-license,
    #MT-license-tab:checked ~ #MT-license,
    #limited-release-tab:checked ~ #limited-release
      display block
    .plan-tab-item
      width 50%
      +mediaSp()
        width 100%
        text-align left
        padding 10px 20px
        border-left none !important
      +mediaPc()
        &:nth-of-type(n+3)
          border-top none
        &:nth-of-type(2n)
          border-right 1px solid main-color
.m__plan-category-4
  &__tab-list
    input[type="radio"]:checked + .plan-tab-item::after
      display none
    #basic-MT-license-tab:checked ~ #basic-MT-license,
    #semi-medium-MT-license-tab:checked ~ #semi-medium-MT-license,
    #semi-medium-license-tab:checked ~ #semi-medium-license,
    #limited-release-AT-tab:checked ~ #limited-release-AT,
    #limited-release-MT-tab:checked ~ #limited-release-MT
      display block
    .plan-tab-item
      width 50%
      +mediaSp()
        width 100%
        text-align left
        padding 10px 20px
        border-left none !important
      +mediaPc()
        &:nth-of-type(-n+3)
          width calc(100% /3 )
        &:nth-of-type(n+4)
          border-top none
        &:nth-of-type(3),&:nth-of-type(5)
          border-right 1px solid main-color
    &.shakujii,&.akitsu,&.yokohama
      .plan-tab-item
        +mediaPc()
          &:nth-of-type(-n+3)
            width 50%
          &:nth-of-type(n+3)
            border-top none
          &:nth-of-type(2n)
            border-right 1px solid main-color
          &:nth-of-type(3)
            border-right none

.m__plan-category-5
  &__tab-list
    #basic-license-tab:checked ~ #basic-license, #other-license-tab:checked ~ #other-license
      display block
    .plan-tab-item
      width 45%
      +mediaSp()
        width 100%
        text-align left
        padding 10px 20px
        border-left none !important
      &.last-tab
        border-right 1px solid main-color
        width 55%
        +mediaSp()
          border-right none
          width 100%
.m__plan-category-8
  &__notice
    margin 100px auto
    font-size 1.6rem
    ul
      li
        line-height 1.8
    +mediaSp()
      margin 0 0 30px
      font-size 1.3rem
  &__condition
    padding 100px 0 150px
    font-size 1.6rem
    +mediaSp()
      padding 30px 0 50px
      font-size 1.3rem
  &__type
    padding 100px 0
    +mediaSp()
      padding 30px 0 10px
    table
      margin 60px auto 0
      border-style solid
      border-color border-black
      border-width 2px
      width 640px
      +mediaSp()
        margin 30px auto 0
        width 100%
        border-width 1px 0
      th,td
        &:not(:first-child)
          border-left 1px solid border-black
        &:nth-child(2n)
          background bg-light-gray
      th
        medium()
        font-size 1.8rem
        vertical-align middle
        +mediaSp()
          font-size 1.3rem
        &.school
            padding 24px 0
            height 140px
            line-height 1
            +mediaSp()
              font-size 1.4rem
              padding 10px 0
              height auto
          &:nth-child(3),&:nth-child(5),&:nth-child(6)
            line-height 2em
          &:nth-child(4)
            line-height 1.35em
        &.type
            border-top 1px solid border-black
            width 165px
            line-height 60px
            padding-left 20px
            text-align left
            +mediaSp()
              width 38%
              line-height 30px
              padding-left 10px
      td
        font-size 2.6rem
        border-top 1px solid border-black
        line-height 60px
        color font-gray
        text-align center
        +mediaSp()
          line-height 30px
          font-size 1.3rem

  &__price
    padding 100px 0
    text-align center
    background bg-light-gray02
    +mediaSp()
      padding 30px 0 15px
      background #fff
    img
      margin-top 20px
      width 750px
      +mediaSp()
        margin-top 10px
        width 100%
.m__plan-category-9
  &__tab-list
    input[type="radio"]:checked + .plan-tab-item::after
      display none
    #basic-license-tab:checked ~ #basic-license,#semi-mid-license-tab:checked ~ #semi-mid-license,#mid-MT-license-tab:checked ~ #mid-MT-license,#mid-license-tab:checked ~ #mid-license
      display block
    .plan-tab-item
      width 50%
      +mediaSp()
        width 100%
        text-align left
        padding 10px 20px
        border-left none !important
      +mediaPc()
        &:nth-of-type(n+3)
          border-top none
        &:nth-of-type(2n)
          border-right 1px solid main-color
.m__plan-category-10
  &__tab-list
    input[type="radio"]:checked + .plan-tab-item::after
      display none
    #s-sm-MT-license-tab:checked ~ #s-sm-MT-license,
    #mid-MT-license-tab:checked ~ #mid-MT-license,
    #mid-license-tab:checked ~ #mid-license,
    #large-license-tab:checked ~ #large-license,#large-class2S-lisence-tab:checked ~ #large-class2S-lisence
      display block
    .plan-tab-item
      width 50%
      +mediaSp()
        width 100%
        text-align left
        padding 10px 20px
        border-left none !important
      +mediaPc()
        &:nth-of-type(n+3)
          width calc(100% /3 )
          border-top none
        &:nth-of-type(2),&:nth-of-type(5)
          border-right 1px solid main-color
.m__plan-category-12
  &__condition
    margin-top 60px
    display flex
    align-items flex-start
    justify-content space-between
    +mediaSp()
      margin 0 -10px
      flex-direction column-reverse
    dl
      width 690px
      padding 0 20px 10px
      border-bottom 1px solid border-light-gray
      +mediaSp()
        padding 15px 10px
        width 100%
      dt
        margin 20px 0 10px
        +mediaSp()
          margin 10px 0
      dd
        font-size 1.6rem
        +mediaSp()
          font-size 1.3rem
        +mediaPc()
          ul
            margin-top 15px
            li
              margin-bottom 10px
    .photo
      p
        font-size 1.6rem
        regular()
      +mediaSp()
        width 100%
        p
          font-size 1.3rem
          padding 10px 10px 0
        img
          width 100%
  &__price
    padding 60px 0 100px
    +mediaSp()
      padding 40px 0 30px
      margin 0 -10px
      h4
        margin 0 10px
    dl
      display flex
      margin-top 5px
      +mediaSp()
        display block
        padding 0 10px
      dt,dd
        font-size 1.6rem
        +mediaSp()
          font-size 1.3rem
      dt
        regular()
        white-space nowrap
        margin-right 5px
      ul
        li
          text-indent -1em
          padding-left 1em
          &::before
            content '・'
    table
      margin 30px auto 60px
      text-align center
      border-style solid
      border-color border-black
      border-width 2px
      width 750px
      +mediaSp()
        margin 15px 0 25px
        width 100%
        border-width 1px 0
      th,td
        width 225px
        &:not(:first-child)
          border-left 1px solid border-black
        &:last-child
          width 300px
        +mediaSp()
          width calc(100% / 3)
      th
        background #cccccc
        medium()
        height 60px
        line-height 60px
        font-size 1.8rem
        +mediaSp()
          font-size 1.3rem
          line-height 30px
          height 30px
      td
        font-size 2rem
        height 90px
        line-height 90px
        border-top 1px solid border-black
        +mediaSp()
          font-size 1.5rem
          line-height 1.4
          height 60px
          vertical-align middle
    .notice
      .telnumber
        +mediaPc()
          pointer-events none
          text-decoration none
          color font-black
        +mediaSp()
          medium()
      p
        font-size 1.6rem
        regular()
        text-indent -1em
        padding-left 1em
        +mediaSp()
          font-size 1.3rem
      +mediaSp()
        padding 0 10px
  &__flow
    padding 60px 0
    +mediaSp()
      padding 30px 0
    h3
      margin-bottom 50px
      +mediaSp()
        margin-bottom 20px
    > dl
      width 750px
      margin 0 auto
      padding 45px 0 25px
      position relative
      +mediaSp()
        width 100%
        padding 30px 0 10px
      &::before
        content ""
        display block
        width 0
        height 0
        border-style solid
        border-width 15px 11px 0 11px
        border-color bg-green02 transparent  transparent
        position absolute
        left 50%
        top 0
        transform translateX(-50%)
        +mediaSp()
          border-width 10px 7.5px 0 7.5px
      &:first-of-type
        padding-top 10px
        &::before
          display none
      > dt
        background bg-green02
        color #fff
        font-size 2.6rem
        line-height 60px
        text-align center
        +mediaSp()
          font-size 1.4rem
          line-height 30px
      > dd
        padding-top 20px
        font-size 1.8rem
        regular()
        +mediaSp()
          padding 5px
          font-size 1.4rem
    .linkPc
      text-align center
      display flex
      justify-content center
      align-items baseline
      margin-top 10px
      medium()
      .tel
        margin-left 50px
        span
          vertical-align baseline
          font-size 2.6rem
          margin-left 0.5em
    .linkSp
      .m__common-btn__arrow
        margin-top 15px
        text-align center
        .m__common__flex-arrow
          width 300px
          medium()
          &.tel
            &::before,&::after
              display none
            span
              font-size 1.3rem
              vertical-align baseline
              margin-right 0.5em
          &.pdf
            padding-right 45px
          &.schedule
            &::before,&::after
              margin-right 30px
    .info
      margin 10px auto
      medium()
      max-width 440px
      +mediaSp()
        padding-left 20px
      dl
        display flex
        align-items  baseline
        dt
          white-space nowrap
        .tel
          font-size 2.6rem
          margin-left 0.5em
          vertical-align baseline
          +mediaSp()
            font-size 1.8rem
            flex-wrap nowrap
        .address
          margin-left 1em
          span
            display inline-block
    .notice
      p
        font-size 1.6rem
        regular()
        text-indent -1em
        padding-left 1em
        +mediaSp()
          font-size 1.3rem
  &__payment
    margin 100px auto
    width 750px
    > p
      font-size 1.6rem
      regular()
      margin 40px 0
    +mediaSp()
      width 100%
      margin 40px 0
      padding 0 10px
      > p
        font-size 1.3rem
        margin 10px 0 15px
    .payee
      border 2px solid border-green
      padding 20px 160px
      font-size 1.8rem
      +mediaSp()
        padding 20px 10px
        font-size 1.5rem
    .notice
      p
        regular()
        font-size 1.6rem
        regular()
        text-indent -1em
        padding-left 1em
        +mediaSp()
          font-size 1.3rem
      +mediaSp()
        margin-top 15px
  &__detail
    padding 60px 0
    font-size 1.6rem
    +mediaSp()
      padding 30px 0
      font-size 1.3rem
    > dl
      margin-bottom 20px
      > dt
        medium()
      > dd
        padding-left 1em
        regular()
        &.detail
          padding-left 0
          +mediaPc()
            dl
              display flex
              dt
                span
                  white-space nowrap
                .letter2
                  letter-spacing 2em
                  margin-right -2em
                .letter3
                  letter-spacing 0.5em
                  margin-right -0.5em
          dd
            li
              padding-left 1em
              text-indent -1em
              &:before
                content "・"
          +mediaSp()
            .spflex
              display flex
            dl
              margin-bottom 20px
  &__contact
    margin 100px 0
    text-align center
    p
      font-size 1.6rem
    .tel
      font-size 1.8rem
      margin-top 10px
      .telnumber
        font-size 2.6rem
        +mediaPc()
          pointer-events none
          color font-black
          text-decoration none
          margin-left 1em
        +mediaSp()
          font-size 1.8rem
      span
        display none
    +mediaSp()
      margin 40px 0
      p
        font-size 1.3rem
        regular()
      .tel
        font-size 1.5rem
        margin-top 0
        span
          margin-right 0.5em
          display inline-block
  &__schedule
    padding 90px 0
    width 900px
    margin 85px auto 0
    p
      font-size 1.6rem
      regular()
      margin-top 30px
    +mediaSp()
      padding 30px 10px 50px
      width 100%
      margin 30px 0 0
      img
        width 100%
      p
        font-size 1.3rem
        margin-top 10px
.m__plan-category-13
  &__flow
    padding 60px 0
    +mediaSp()
      padding 30px 0
    h3
      margin-bottom 50px
      +mediaSp()
        margin-bottom 20px
    dl
      width 750px
      margin 0 auto
      padding 60px 0 50px
      +mediaSp()
        width 100%
        padding 30px 0 10px
      position relative
      &::before
        content ""
        display block
        width 0
        height 0
        border-style solid
        border-width 15px 11px 0 11px
        border-color bg-green02 transparent  transparent
        position absolute
        left 50%
        top 0
        transform translateX(-50%)
        +mediaSp()
          border-width 10px 7.5px 0 7.5px
      &:first-of-type
        padding-top 10px
        &::before
          display none
      &:nth-of-type(3)
        dd
          padding-top 0
      dt
        background bg-green02
        color #fff
        font-size 2.6rem
        line-height 60px
        text-align center
        +mediaSp()
          font-size 1.4rem
          line-height 30px
      dd
        padding-top 20px
        font-size 1.8rem
        regular()
        +mediaSp()
          padding 5px
          font-size 1.4rem
  &__price
    padding 60px 0 120px
    +mediaSp()
      padding 40px 0 30px
      margin 0
    .txt-r
      text-align right
      margin-top 30px
      regular()
      font-size 1.6rem
      +mediaSp()
        font-size 1.2rem
        margin-top 10px
    .price-list
      margin 10px 0 60px
      padding 0 20px
      border-bottom 1px solid border-light-gray
      +mediaSp()
        margin 25px -10px
        padding 0
      .title
        display flex
        align-items flex-end
        +mediaSp()
          padding 0 10px
        a
          font-size 1.6rem
          margin-left 20px
          +mediaSp()
            font-size 1.3rem
            line-height 15px
            margin-left 10px
      .item
        padding-top 20px
        +mediaSp()
          padding-top 5px
        ul
          margin-left 3px
          li
            font-size 1.6rem
            +mediaSp()
              font-size 1.3rem
            span
              regular()
              margin-right 1rem
        table
          margin 30px auto 60px
          text-align center
          border-style solid
          border-color border-black
          border-width 2px
          width 750px
          +mediaSp()
            margin 15px 0 25px
            width 100%
            border-width 1px 0
          th,td
            &:not(:first-child)
              border-left 1px solid border-black
          th
            background #cccccc
            medium()
            height 60px
            width 250px
            line-height 60px
            font-size 1.8rem
            &.red
              background bg-red
            +mediaSp()
              font-size 1.3rem
              width 33%
              line-height 30px
              height 30px
          td
            font-size 2rem
            height 90px
            line-height 90px
            border-top 1px solid border-black
            &.red
              background #fbdfd9
            +mediaSp()
              font-size 1.5rem
              line-height 1.4
              height 60px
              vertical-align middle
    .notice
      padding 0 20px
      font-size 1.6rem
      regular()
      +mediaSp()
        padding 0
        font-size 1.3rem
        margin-left -5px
  &__contact
    text-align center
    padding 60px 0
    +mediaSp()
      margin 0 -10px
      padding 50px 0
    .m__common-btn__arrow
      margin 60px 0 90px
      +mediaSp()
        margin 30px 0 0
      .m__common__flex-arrow
        width 320px
        padding 16px 80px 16px 50px
        &::before
          right 107px
          +mediaSp()
            right auto
            left 50%
            margin-left 22px
        &::after
          right 109px
          +mediaSp()
            right auto
            left 50%
            margin-left 42px
        +mediaPc()
          &:hover
            &::before
              right 97px
            &::after
              right 99px
        +mediaSp()
          width 100%
          &:active
            background bg-green02
            transition .3s
            &::before
              width 24px
            &::after
              margin-left 42px
