.m__plan-category_lower
  &__schoollist
    font-size 1.6rem
    text-align center
    display block
    margin-bottom -28px
    +mediaSp()
      font-size 1.3rem
      margin-bottom 0
  &__special
    text-align center
    margin-top 90px
    padding-bottom 0
    img
      width 100%
    +mediaSp()
      margin 30px -10px 0
      padding-bottom 0
      img
        width 100%
    .present
      width 1200px
      +mediaSp()
        width 100%
    .m__common-btn__arrow
      .m__common__flex-arrow
        width 400px
        +mediaSp()
          width 100%
          margin 0 -20px
          padding 16px 60px 16px 20px
        &::before
          right 48px
          +mediaPc()
            bottom 28px
          +mediaSp()
            right auto
            left 50%
            margin-left 100px
        &::after
          right 51px
          +mediaPc()
            bottom 27px
          +mediaSp()
            right auto
            left 50%
            margin-left 121px
        +mediaPc()
          &:hover
            &::before
              right 38px
            &::after
              right 41px
        +mediaSp()
          &:active
            background bg-green02
            transition .3s
            &::before
              width 24px
            &::after
              margin-left 121px
  &__free-trial
    margin-top 90px
    text-align center
    +mediaSp()
      margin 0 -10px
    h3
      line-height 1.4
    .img
      display flex
      margin-bottom 60px
      justify-content center
      +mediaSp()
        margin-bottom 30px
        img
          max-width 50%
    .m__common-btn__arrow--school
      margin 40px 0 60px
      +mediaSp()
        margin 30px 0
      .m__common__flex-arrow
        font-size 1.6rem
        padding 16px 40px 16px 30px
        min-width 300px
        &::before
          width 24px
          bottom 27px
          +mediaPc()
            right 75px
        &::after
          height 8px
          bottom 26px
          +mediaPc()
            right 78px
        +mediaPc()
          &:hover
            &::before
              width 34px
              right 65px
            &::after
              right 68px
        +mediaSp()
          font-size 1.5rem
          width 100%
          &:active
            &::before
              width 24px
          &::before,&::after
            margin-right 45px

  &__feature
    display table
    margin 0 auto
    padding 90px 0
    +mediaSp()
      padding 50px 0
    .point
      text-align center
      margin-top 20px
      medium()
      +mediaSp()
        margin-top 5px
    ul
      margin-top 60px
      +mediaSp()
        margin 30px -5px 0 -3px
      li
        padding-left 80px
        font-size 2.6rem
        margin-bottom 30px
        medium()
        min-height 60px
        position relative
        display flex
        align-items center
        +mediaSp()
          font-size 1.5rem
          padding-left 40px
          margin-bottom 15px
          min-height 30px
        p
          line-height 1.4
          span
            font-size 1.6rem
            display block
            +mediaSp()
              font-size 1.3rem
        &::before
          content ''
          position absolute
          background url(image_path+'/plan/icon_time.svg') no-repeat center
          background-size 60px 60px
          width 80px
          height 60px
          left -10px
          top 50%
          transform translateY(-50%)
          +mediaSp()
            background-size 30px 30px
            width 40px
            height 30px
            left -5px
        &:nth-child(2)
          &::before
            background url(image_path+'/plan/icon_cancel.svg') no-repeat center
            background-size 48px 54px
            +mediaSp()
              background-size 24px 27px
        &:nth-child(3)
          &::before
            background url(image_path+'/plan/icon_graduate.svg') no-repeat center
            background-size 60px 45px
            +mediaSp()
              background-size 30px 22.5px
        &:nth-child(4)
          &::before
            background url(image_path+'/plan/icon_carender.svg') no-repeat center
            background-size 68px 58px
            +mediaSp()
              background-size 34px 29px
        &:nth-child(5)
          &::before
            background url(image_path+'/plan/icon_manner.svg') no-repeat center
            background-size 60px 46px
            +mediaSp()
              background-size 30px 23px
        &:nth-child(6)
          &::before
            background url(image_path+'/plan/icon_ladies.svg') no-repeat center
            background-size 47px 60px
            +mediaSp()
              background-size 23.5px 30px
        &:nth-child(7)
          &::before
            background url(image_path+'/plan/icon_pia.svg') no-repeat center
            background-size 66px 50px
            +mediaSp()
              background-size 33px 25px
        &:last-child
          margin-bottom 0
      &.society
        margin-top 0
        +mediaSp()
          margin -10px 0
        span
          &.small
            display inline-block
            vertical-align baseline
        li
          +mediaSp()
            margin-bottom 20px
          &.reserve
            &::before
              background url(image_path+'/plan/icon_reserve.svg') no-repeat center
              background-size 73px 47px
              +mediaSp()
                background-size 37px 24px
          &.trial
            &::before
              background url(image_path+'/plan/icon_trial.svg') no-repeat center
              background-size 60px 53px
              +mediaSp()
                background-size 30px 27px
          &.lesson
            &::before
              background url(image_path+'/plan/icon_freelesson.svg') no-repeat center
              background-size 60px 53px
              +mediaSp()
                background-size 30px 27px
          &.ledies
            &::before
              background url(image_path+'/plan/icon_ladies.svg') no-repeat center
              background-size 47px 60px
              +mediaSp()
                background-size 24px 30px
                top 22px
          &.pia
            &::before
              background url(image_path+'/plan/icon_pia.svg') no-repeat center
              background-size 66px 50px
              +mediaSp()
                background-size 33px 25px
          &.manner
            &::before
              background url(image_path+'/plan/icon_manner.svg') no-repeat center
              background-size 60px 47px
              +mediaSp()
                background-size 30px 24px
          &.nirin
            &::before
              background url(image_path+'/plan/icon_rental.svg') no-repeat center
              background-size 55px 51px
              +mediaSp()
                background-size 28px 26px
                top 22px
          &.parking
            &::before
              background url(image_path+'/plan/icon_parking.svg') no-repeat center
              background-size 53px 53px
              +mediaSp()
                background-size 27px 27px
          &.pay
            &::before
              background url(image_path+'/plan/icon_pay.svg') no-repeat center
              background-size 53px 53px
              +mediaSp()
                background-size 27px 27px
  &__faq
    padding 90px 0
    +mediaSp()
      padding 50px 0
    h3
      margin-bottom 30px
      span
        vertical-align -3px
        margin-left 5px
    dl
      padding 20px 0
      border-bottom 1px solid border-light-gray
      +mediaSp()
        padding 10px
        margin 0 -10px
      dt
        font-size 1.8rem
        color font-green
        medium()
        margin-bottom 20px
        +mediaSp()
          padding-left 13.5px
          font-size 1.3rem
          text-indent -13.5px
          margin-bottom 10px
      dd
        padding-left 16px
        text-indent -16px
        font-size 1.6rem
        regular()
        +mediaSp()
          padding-left 13px
          font-size 1.3rem
          text-indent -13px
        span
          color font-red
          medium()
  &__introduction
    background bg-header
    text-align center
    padding 50px 0
    margin-top 90px
    color #fff
    +mediaSp()
      margin-top 30px
      padding 13px 5px
    p
      font-size 3.2rem
      color #fff
      +mediaSp()
        font-size 1.6rem
  &__course-list
    margin 60px 0
    +mediaSp()
      margin 30px 0 40px
    dl
      padding 0 20px 27px
      border-bottom 1px solid border-light-gray
      position relative
      &:nth-of-type(1)
        border-top 1px solid border-light-gray
      +mediaSp()
        padding 10px 5px
        margin 0 -10px
      dt
        padding 20px 0 20px 40px
        +mediaSp()
          margin 10px 0
          padding 0 0 0 20px
      dd
        font-size 1.6rem
        regular()
        +mediaSp()
          font-size 1.3rem
        &.m__common-btn__arrow
          +mediaPc()
              position absolute
              top 50%
              transform translateY(-50%)
              right 150px
          +mediaSp()
            margin 20px -5px
            .m__common__flex-arrow
              width 100%
              &::before
                right auto
                left 50%
                margin-left 30px
              &:after
                right auto
                left 50%
                margin-left 50px
              &:active
                background bg-green02
                transition .3s
                &::before
                  width 24px
                &::after
                  margin-left 50px
