.m__admin
  &__wrapper
    background #fafaff
    padding 40px 0

  &__inner
    background #fff
    padding 50px 40px

  &__login
    text-align center

  &__login-name
    margin-top 20px
    select
      width 200px
      min-height 27px
      display inline-block

  &__login-password
    margin-top 10px
    input
      width 200px
      display inline-block

  &__login-button
    margin-top 20px

  &__navi
    padding-bottom 10px
    border-bottom 1px solid #ccc
    display flex
    justify-content space-between
    li
      display inline-block
      margin-right 30px
      a, span
        display inline-block
        text-align center
        font-size 2rem
        font-weight bold
    .logout
      text-align right
      font-size 2rem
      font-weight bold

  &__search-form
    margin-top 30px
    dt
      display inline-block
      width 15%
      vertical-align top
      margin-top 20px
      font-weight bold
      font-size 1.6rem
    dd
      display inline-block
      width 85%
      vertical-align top
      margin-top 20px
    .checkboxes, .radiobuttons
      label
        display inline-block
        margin-right 20px

  &__search-buttons
    margin 30px 0 50px
    text-align center
    .button
      display inline-block
      a, input[type="button"]
        width 160px
        height 40px
    .button:nth-of-type(n+1)
      margin-left 20px

  &__count
    font-size 2rem
    font-weight bold
    text-align center

  &__notice
    font-weight bold
    color #F00
    text-align center

  &__paginate
    text-align center
    font-size 2rem
    font-weight bold
    padding 5px 0
    .pagination > span
      margin 0 3px

  &__table
    width 100%
    background #fff
    th, td
      border 1px solid #aaa
      padding 2px 5px
    th
      background #fafaff
    tr:nth-of-type(2n+1) > td
      background #fafafa
    .delete
      vertical-align middle
      a
        width 30px
        line-height 24px
        background #f33
        display inline-block
        color #fff
        font-size 1.2rem
        text-decoration none
        font-weight bold
        border-radius 5px
        text-align center
        box-shadow 2px 2px 0px 0px rgba(0, 0, 0, 0.5)
        transition 0.3s
        &:hover
          opacity 0.5

  &__table--entry
    font-size 1.2rem
    td
      height 50px
      vertical-align top
    th:nth-of-type(1), td:nth-of-type(1)
      width 4%
      text-align center
      font-weight bold
    th:nth-of-type(2), td:nth-of-type(2)
      width 9%
    th:nth-of-type(3), td:nth-of-type(3)
      width 6%
    th:nth-of-type(4), td:nth-of-type(4)
      width 6%
    th:nth-of-type(5), td:nth-of-type(5)
      width 13%
    th:nth-of-type(6), td:nth-of-type(6)
      width 4%
    th:nth-of-type(7), td:nth-of-type(7)
      width 18%
    th:nth-of-type(8), td:nth-of-type(8)
      width 18%
    th:nth-of-type(9), td:nth-of-type(9)
      width 9%
    th:nth-of-type(10), td:nth-of-type(10)
      width 14%
  &__table--campaign
    td
      vertical-align top
    th:nth-of-type(1), td:nth-of-type(1)
      width 5%
    th:nth-of-type(2), td:nth-of-type(2)
      width 35%
    th:nth-of-type(3), td:nth-of-type(3)
      width 10%
    th:nth-of-type(4), td:nth-of-type(4)
      width 5%
    th:nth-of-type(5), td:nth-of-type(5)
      width 20%
    th:nth-of-type(6), td:nth-of-type(6)
      width 10%
    th:nth-of-type(7), td:nth-of-type(7)
      width 15%

  &__entry-detail
    margin-top 50px
    dt, dd
      display inline-block
      margin-top 5px
      vertical-align middle
    dt
      font-weight bold
      width 20%
      padding 10px
      background #eee
    dd
      width 80%
      padding 0 20px

  &__entry-delete
    margin-top 40px
    a
      width 200px
      line-height 50px
      background #f33
      display inline-block
      color #fff
      font-size 1.6rem
      text-decoration none
      font-weight bold
      border-radius 5px
      text-align center
      box-shadow 2px 2px 0px 0px rgba(0, 0, 0, 0.5)
      transition 0.3s
      &:hover
        opacity 0.5
