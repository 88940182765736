.m__dlive
  &__introduction
    margin-top 95px
    background bg-header
    color #fff
    padding 40px 0 50px
    +mediaSp()
      padding 15px 10px
      margin-top 30px
    h3
      font-size 3.2rem
      medium()
      text-align center
      +mediaSp()
        font-size 1.6rem
    .emp
      color font-red
      a
        color font-red
    .small
      font-size 2.5rem
      +mediaSp()
        font-size 1.4rem
  &__info-text
    padding-top 60px
    width 750px
    margin 0 auto
    a
      color font-red
    +mediaSp()
      width 100%
      padding-top 30px
    .text
      text-align center
      border 3px solid border-red
      color font-red
      font-size 2.6rem
      padding 20px 10px
      &.emp
        color #ff0000
        border 3px solid #ff0000
      +mediaSp()
        font-size 1.3rem
        border 2px solid border-red
        padding 10px 5px
      &--black
        border 3px solid border-black
        color font-black
  &__image
    padding 60px 0 100px
    text-align center
    &.out
      img
        opacity 0.5
    +mediaSp()
      padding 30px 0
      margin 0 -10px
      img
        width 100%
  &__link
    text-align center
    padding 60px 0
    font-size 1.6rem
    +mediaSp()
      padding 30px 0
      font-size 1.3rem
  &__detail
    padding 60px 0
    +mediaSp()
      padding 30px 0
      margin 0 -10px
    .m__common-heading__title
      font-size 2.6rem
      margin-bottom 20px
      +mediaSp()
        font-size 1.3rem
        margin-bottom 10px
      .alphabet
        font-size 3.2rem
        +mediaSp()
          font-size 2rem
    .text-block
      padding 20px
      border-bottom 1px solid border-light-gray
      +mediaSp()
        padding 10px 10px
        word-break normal
      p
        font-size 1.6rem
        margin-bottom 10px
        +mediaSp()
          font-size 1.3rem
          margin-bottom 15px
          &.ticket
            line-height 2.2
      .release
        font-size 2.6rem
        +mediaSp()
          font-size 1.6rem
      &:last-of-type
        border 0
      a
        &.telnumber
          margin-left 0.5em
          +mediaPc()
            pointer-events none
            text-decoration none
            color font-black
        &.email
          margin-left 0.5em
      .support
        margin-top 20px
        +mediaSp()
          margin-top 10px
        .text
          regular()
          font-size 1.6rem
          margin-left 20px
          padding-top 26px
        +mediaSp()
          a
            width 32%
          .logo
            width 80%
          .text
            font-size 1.3rem
            width 63%
            margin-left -5px
        ul
          display flex
          justify-content left
          +mediaSp()
            flex-wrap wrap
            justify-content center
          li
            width 405px
            display flex
            +mediaSp()
              justify-content center
              padding 10px 0
  &__button-area
    margin 100px 0
    text-align center
    .m__common-btn__arrow
      .m__common__flex-arrow
        width 260px
    +mediaSp()
      margin 50px 0
      .m__common-btn__arrow
        .m__common__flex-arrow
          &::before
            right 45px
          &::after
            right 48px
          &:active
            &::before
              right 35px
              width 34px
            &::after
              right 38px
    .m__common-btn__arrow--back
      .m__common__flex-arrow
        padding 16px 40px 16px 70px
        &::before,&::after
          transition width 0.3s, left 0.3s !important
          right auto
        &::before
          left 35px
        &::after
          left 38px
          transform rotate(45deg)
        +mediaPc()
          &:hover
            &::before
              left 25px
              right auto
            &::after
              left 28px
              right auto
        +mediaSp()
          &::before
            left 45px
          &::after
            left 48px
            transform rotate(45deg)
          &:active
            background bg-green02
            color #fff
            transition 0.3s linear !important
    &--cancelled
      margin 40px 0 100px
    &--bottom
      margin 120px 0
      +mediaSp()
        margin 50px 0
  &__artist
    +mediaPc()
      padding 60px 0
      .accordion-content
        .notice
          text-align center
    +mediaSp()
      margin 0 -10px
    .m__txt--c
      font-size 1.6rem
      medium()
      +mediaSp()
        font-size 1.3rem
  &__artist-list
    display flex
    flex-wrap wrap
    justify-content space-between
    width 890px
    margin 40px auto
    +mediaSp()
      width 100%
      margin 10px 0
    li
      width 200px
      margin-bottom 20px
      +mediaSp()
        width calc(50% - 10px)
        img
          width 100%
      p
        font-size 1.6rem
        margin-top 5px
        +mediaSp()
          font-size 1.3rem
      +mediaPc()
        &:nth-child(6)
          margin-top -8px
        &:nth-child(7)
          margin-top: -23px;
        &:nth-child(8)
          margin-top: -24px;
        &:nth-child(10)
          margin-top -17px
        &:nth-child(12)
          margin-top 4px
        &:nth-child(15)
          margin-top -6px
        &:nth-child(16)
          margin-top -15px
      +mediaSp()
        &:nth-child(6)
          margin-top -5px
        &:nth-child(7)
          margin-top 0
        &:nth-child(8)
          margin-top 0
        &:nth-child(10)
          margin-top -17px
        &:nth-child(12)
          margin-top 3px
        &:nth-child(15)
          margin-top 0
        &:nth-child(16)
          margin-top -10px
  &__message
    +mediaPc()
      padding 100px 0
      width 880px
      margin 0 auto
    +mediaSp()
      margin 0 -10px
    .m__common-heading__title
      font-size 2.6rem
      text-align left
    p
      margin-top 10px
      font-size 1.6rem
      regular()
      +mediaSp()
        font-size 1.3rem
        word-break normal
      &.txt-r
        text-align right
    .boxBlue
      background #B2E5E5
      padding 5px 0
      text-align center
      margin-top 25px
      +mediaSp()
        margin-top 15px
      .text
        font-weight 500
        margin-top 0
  &__cooperation
    padding 40px 0
    border-top 1px solid border-light-gray
    text-align center
    +mediaSp()
      border 0
      margin 0 -10px
      padding 0
    .m__common-heading__title
      font-size 2.6rem
    p
      margin-top 30px
      font-size 1.6rem
      text-align center
      +mediaSp()
        font-size 1.3rem
        word-break normal
        text-align left
        margin-top 15px
  &__sponsorship
    padding 40px 0
    border-top 1px solid border-light-gray
    text-align center
    +mediaSp()
      border 0
      margin 0 -10px
      padding 0
      .m__dlive__accordion
        .accordion-content
          padding-bottom 0
    .m__common-heading__title
      font-size 2.6rem
    ul
      display flex
      justify-content center
      align-items center
      flex-wrap wrap
      margin-top 20px
      +mediaSp()
        justify-content left
        margin-top 0
      li
        margin 0 15px 20px
        +mediaSp()
          margin 0 10px 20px 7px
        img
          display block
        &.blank
          width 100%
          margin 0
          +mediaSp()
            display none

  &__cosponsorship
    padding 40px 0 80px
    border-top 1px solid border-light-gray
    text-align center
    +mediaSp()
      border 0
      margin 0 -10px
      padding 0
      text-align left
      ul
        li
          &:not(:last-child)
            margin-bottom 20px
          svg
            vertical-align -13px
            width 160px
            &:hover
              color font-green
          &::before
            content "・"
          &:hover
            color font-green
    .m__common-heading__title
      font-size 2.6rem
    p
      margin-top 30px
      font-size 1.6rem
      line-height 2.2
      margin-left -95px
      a
        text-decoration none
        color font-black
        transition none
        position relative
        padding-right 5px
        &:hover
          color font-green
        svg
          width 160px
          vertical-align -7px
          &.kawasaki
            vertical-align -13px
          &:hover
            opacity 0.7
        .livezound
          position absolute
          top -54px
        img
          vertical-align middle
  &__voice
    padding 90px 0 130px
    +mediaSp()
      padding 0
      margin 0 -10px
      background #fff
      .m__txt--c
        margin-top 20px
        span
          display inline-block
      .m__dlive__accordion
        .accordion-content
          padding 0
    .m__common-heading__title
      font-size 2.6rem
    ul
      display flex
      flex-wrap wrap
      justify-content space-between
      align-items flex-start
      width 900px
      margin 50px auto 0
      +mediaSp()
        width 100%
        display block
        margin 0
      li
        background #fff
        padding 15px 20px 20px
        width 430px
        margin-bottom 25px
        +mediaSp()
          margin-bottom 0
          padding 15px 10px
          width 100%
          word-break normal
          border-bottom 1px solid border-light-gray
        p
          regular()
          +mediaPc()
            background linear-gradient(180deg, border-light-gray 1px, transparent 1px)
            background-size 100% 2em
            line-height 2em
            padding-bottom 1px
            position relative
            font-size 1.6rem
            &::before
              width 100%
              height 2px
              background #fff
              position absolute
              content ""
              top 0
          +mediaPc()
            &.text-narrow
              letter-spacing -0.5px
        &:nth-child(odd)
          color #666699
        &:nth-child(even)
          color #339966
        +mediaPc()
          &:nth-child(3)
            order 3
            color #339966
            margin-top -63px
          &:nth-child(4)
            order 4
            color #666699
          &:nth-child(5)
            order 5
            margin-top -122px
          &:nth-child(6)
            order 6
          &:nth-child(7)
            order 8
            color #339966
            margin-top -145px
          &:nth-child(8)
            order 7
          &:nth-child(9)
            order 9
          &:nth-child(10)
            order 10
            margin-top -216px
          &:nth-child(11)
            order 12
            margin-top -276px
          &:nth-child(12)
            order 11
          &:nth-child(13)
            order 13
          &:nth-child(14)
            order 14
            margin-top -111px
          &:nth-child(15)
            order 16
            margin-top -110px
          &:nth-child(16)
            order 15
          &:nth-child(17)
            order 17
          &:nth-child(18)
            order 18
            margin-top -141px
          &:nth-child(19)
            order 20
            margin-top -170px
          &:nth-child(20)
            order 19
            letter-spacing -0.08em
          &:nth-child(21)
            order 21
          &:nth-child(22)
            order 22
            margin-top -200px
          &:nth-child(23)
            order 23
            margin-left 470px
            margin-top -230px
          &:nth-child(24)
            order 24
          &:nth-child(25)
            order 25
            margin-top -41px
  &__accordion
    +mediaSp()
      .title
        position relative
        padding 16px 10px
        transition 0.3s linear
        background bg-light-gray
        border-top 2px solid #fff
        .m__common-heading__title
          text-align left
          font-size 1.6rem
        .js-trigger
          position absolute
          right 10px
          top 18px
          transition 0.3s ease-out
        &.open
          border-top 2px solid bg-light-gray
          .js-trigger
            transform rotate(45deg)
      .accordion-content
        display none
        padding 15px 10px 20px
        background #fff
        a
          text-decoration none
          color font-black
          &:active
            color font-green
          svg
            &:active
              fill font-green
  &__prevention
    padding 90px 0 0
    width 900px
    margin 50px auto 0
    +mediaSp()
      width 100%
      padding 0
      margin-top 30px
    .main-title
      font-size 2.6rem
      gothic()
      text-align center
      font-weight 500
      +mediaSp()
        font-size 1.6rem
    .sub-title
      padding 25px 0 40px
      text-align center
      font-size 2rem
      +mediaSp()
        font-size 1.4rem
        padding 15px 0 30px
    .text-block
      padding 45px 0
      border-top 2px solid #ccc
      +mediaSp()
        padding 20px 0
    .text-block-title
      font-weight 500
      gothic()
      text-align center
      font-size 2rem
      padding-bottom 7px
      +mediaSp()
        font-size 1.4rem
    .emp-subtitle
      font-size 2.4rem
      text-align center
      color font-red
      padding-bottom 10px
      line-height 3.6rem
      +mediaSp()
        font-size 1.4rem
        line-height 2rem
    .text
      font-size 1.8rem
      font-weight 300
      padding-left 17px
      text-indent -17px
      +mediaSp()
        font-size 1.4rem
        padding-left 14px
        text-indent -14px
    .text-list
      li
        font-size 1.8rem
        padding-bottom 5px
        padding-left 17px
        text-indent -17px
        font-weight 300
        +mediaSp()
          font-size 1.4rem
          padding-left 14px
          text-indent -14px
