.m__license-plaza
  &__introduction
    margin-top 90px
    background bg-header
    color #fff
    padding 40px 0
    +mediaSp()
      margin-top 30px
      padding 10px 0
    p
      font-size 2.6rem
      medium()
      text-align center
      +mediaSp()
        font-size 1.3rem
        span
          display inline-block
  &__image
    background url(image_path+'/shakujii/img_licenseplaza.jpg') center center no-repeat
    background-size 120% auto
    width 100%
    height 30.3vw
    +mediaSp()
      background-size 110% auto
  &__map
    padding-bottom 60px
    +mediaSp()
      margin-bottom 40px
      padding-bottom 0
    .map
      padding-left 0
      +mediaSp()
        iframe
          height 106.7vw
          margin-bottom 10px
      a
        font-size 1.6rem
        +mediaSp()
          font-size 1.3rem
      .scroll-overview
        left 0
        width 100%
        +mediaSp()
          height 106.7vw
  &__access
    regular()
    font-size 1.6rem
    width 820px
    margin 0 auto
    padding 50px 0
    +mediaSp()
      width 100%
      padding 20px 10px
      font-size 1.3rem
    .notice
      color font-red
      .indent
        text-indent -1em
        padding-left 1em