.m__english__location-inquiry
  word-break break-word
  a.telNumber
    margin-left 0.5em
    +mediaPc()
      pointer-events none
      color font-black
      text-decoration none
  .m__english__title
    margin-top 220px
    margin-bottom 68px
    +mediaSp()
      margin 30px 0
    h2
      font-size 4rem
      text-align center
      +mediaSp()
        font-size 2rem
    ul
      display flex
      flex-wrap wrap
      width 450px
      font-size 2.4rem
      margin 0 auto
      +mediaSp()
        font-size 1.4rem
        width 100%
        max-width 300px
      li
        margin-left 24px
        &::before
          content '▼'
          display inline-block
          font-size 1.2rem
          vertical-align middle
          margin-right 4px
          color font-green
        &:nth-child(1)
          margin-left 0
        &:nth-child(4)
          margin-left 0
  &__map
    padding-bottom 60px
    +mediaSp()
      padding-bottom 0
    &:last-of-type
      .l__from__wrap
        border none
        padding-bottom 80px
        +mediaSp()
          padding-bottom 0
    .m__detail
      .school
        padding 0 20px
        +mediaSp()
          padding 0 10px
      p
        font-size 1.6rem
        +mediaSp()
          font-size 1.3rem
      h3
        color font-green02
        font-size 3.2rem
        +mediaSp()
          font-size 2rem
      .tel
        font-size 2.6rem
        +mediaSp()
          font-size 1.6rem
      .m__text
        margin-bottom 40px
        regular()
        a
          medium()
        +mediaSp()
          margin-bottom 30px
      &:nth-child(n+2)
        h3
          margin-top 60px
    .map
      padding-left 0
      a
        font-size 1.6rem
        +mediaSp()
          font-size 1.3rem
      .scroll-overview
        left 0
        width 100%
    .l__from__wrap
      border-bottom 1px solid #ccc
      padding 0 20px 60px
      +mediaSp()
        margin 0
        padding 0 10px 40px
      .m__from
        margin-top 32px
        &:nth-child(n+2)
          +mediaSp()
            margin-top 20px
        .m__circle
          color main-color
      .m__indent
        text-indent 1em
        regular()
