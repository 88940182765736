.m__pia
  &__login
    .l__contents__inner--width
        padding 0 60px
        +mediaSp()
          padding 0 5px
    &__form
      font-size 1.6rem
      padding 70px 0 50px
      medium()
      +mediaSp()
        font-size 1.3rem
      dl
        display flex
        align-items center
        justify-content center
        margin-bottom 40px
        +mediaSp()
          padding 0 5px
        dt
          margin-right 20px
          +mediaSp()
            margin-right 10px
            white-space nowrap
        dd
          +mediaSp()
            width 100%
          p
            font-size 1.2rem
            color #666
            margin-top -21px
      .m__form__error-text
        text-align center
      .m__common-btn__arrow
        text-align center
        input
          display inline-block
          background bg-black
          color #fff
          font-size 1.6rem
          padding 16px 60px 16px 50px
          min-width 200px
          cursor pointer
          inputReset()
          medium()
          line-height 1.75
          +mediaSp()
            font-size 1.5rem
            width 100%
            &:active
              background bg-green02
              transition .3s
        .m__common__flex-arrow
          padding-right 0
          +mediaSp()
            width 100%
          &::before,&::after
            background #fff
            transition width .3s, right .3s !important
          &::before
            width 24px
            right 35px
            bottom 28px
            +mediaSp()
              right auto
              left 50%
              margin-left 35px
          &::after
            height 9px
            right 38px
            bottom 27px
            +mediaSp()
              right auto
              left 50%
              margin-left 55px
          +mediaPc()
            &:hover
              &::before
                right 25px
                width 34px
              &::after
                right 28px

    &__introduction
      background bg-header
      text-align center
      padding 50px 0
      margin-top 100px
      +mediaSp()
        margin-top 30px
        padding 13px 5px
      p
        color #fff
        font-size 2.6rem
        line-height 1.5
        +mediaSp()
          font-size 1.3rem
          text-align left
        &.title
          font-size 3.2rem
          margin-bottom 5px
          +mediaSp()
            font-size 1.6rem
            text-align center
    &__description
      padding 40px 0
      font-size 1.6rem
      +mediaSp()
        padding 15px 0
      dt
        margin 20px 0
        +mediaSp()
          margin 10px 0
      .howto
        padding-bottom 20px
        font-weight 400
        +mediaSp()
            font-size 1.3rem
      .border
        position relative
        &::after
          content ''
          position absolute
          height 1px
          width 1080px
          background border-light-gray
          left 50%
          transform translateX(-50%)
          bottom 0
          +mediaSp()
            width 100%
      ul
        padding-bottom 20px
        +mediaSp()
          padding-bottom 10px

  //インストラクター紹介
  &__list
    font-size 1.6rem
    margin-bottom 100px
    +mediaSp()
      font-size 1.3rem
      margin-bottom 60px
    .l__contents__inner--width
      +mediaSp()
        padding 0
    section
      padding 0 20px
      border-bottom 1px solid border-light-gray
      +mediaSp()
        padding 0 7px
      &.m__pia__list__contents
        padding 0
      &.m__pia__list__search
        +mediaSp()
          padding 0
      h3
        font-size 2.6rem
        text-align center
        margin-top 60px
        gothic()
        +mediaSp()
          font-size 1.4rem
          margin-top 30px
    .m__btn
      a, input[type="submit"]
        -webkit-appearance none
        appearance none
        border-radius 0
        margin 0
        display inline-block
        vertical-align middle
        line-height 1.4
        font-weight 500
        border none
        font-size 100%
        padding 9px 24px
        color #fff
        text-decoration none
        &:hover
            opacity 0.8
            transition .3s
      &--back
        a, input[type="submit"]
          background #999
      &--regist
        a, input[type="submit"]
          background bg-light-green
    &__name
      margin-top 95px
      +mediaSp()
        margin-top 25px
      .head
        display flex
        justify-content space-between
        flex-direction row-reverse
        +mediaSp()
          display block
          .logout
            text-align right
            margin-bottom 15px
            display block
      .red
        color font-red
        text-indent -0.8rem
        padding-left 1rem
        +mediaSp()
          text-indent -0.5rem
        .emp
          font-weight bold
          text-decoration underline
    &__name-list
      ul
        margin 40px auto 60px
        display flex
        justify-content center
        +mediaSp()
          flex-wrap wrap
          width 170px
          margin 15px auto 25px
        li
          margin-right 30px
          &:last-child
            margin-rignt 0
          +mediaSp()
            margin 0 20px 15px 0
            &:nth-child(5n)
              margin-right 0
            &:last-child
              margin auto
    &__search
      .serch-box
        background bg-light-gray
        width 720px
        padding 32px 7px 50px
        margin 30px auto 60px
        +mediaSp()
          width 100%
          margin 10px auto 30px
          padding 25px 7px 35px
        .notice
          color font-gray
        .input-box
          display flex
          justify-content center
          align-items flex-end
          +mediaSp()
            .input-item
              width 100%
          .search-btn
            input
              background bg-green02
              color #fff
              width 80px
              cursor pointer
              height 40px
              inputReset()
              font-size 1.6rem
              &:hover
                opacity 0.8
                transition .3s
    &__contents
      .pagelink
        margin 20px auto
        display flex
        justify-content space-between
        width 720px
        +mediaSp()
          width 100%
          margin 15px auto
          padding 0 7px
      .list-area
        background bg-light-gray
        padding 30px 60px
        +mediaSp()
          padding 15px 7px
        .results
          font-size 2.6rem
          text-align center
        .results_error
          text-align center
        ul
          display flex
          justify-content space-between
          flex-wrap wrap
          margin 30px 0
          +mediaSp()
            margin 15px 0
          li
            margin-top 30px
            padding 20px
            max-width 465px
            width 100%
            min-height 130px
            background #fff
            display flex
            position relative
            +mediaPc()
              &:nth-child(-n+2)
                margin-top 0
            +mediaSp()
              margin-top 15px
              padding 12px
              height auto
              &:first-child
                margin-top 0
            .photo
              position relative
              z-index 0
              margin-right 10px
              width 80px
              +mediaSp()
                width 64px
              img
                width 100%
              &::after
                position absolute
                content ''
                z-index 1
                width 100%
                height 100%
                top 0
                left 0
            .info
              font-weight 400
              +mediaPc()
                margin-bottom 40px
            .m__btn--regist
              position absolute
              bottom 20px
              right 20px
              +mediaSp()
                bottom 19px
                right 12px

    //確認・完了画面
    &__preview,&__thanks
      background bg-light-gray
      width 720px
      margin 60px auto
      padding 60px 130px
      +mediaSp()
        width 100%
        margin 30px auto
        padding 30px 10px
      .white-box
        background #fff
        width 465px
        padding 20px
        height 273px
        text-align center
        +mediaSp()
          width 100%
          height 218px
          padding 15px 10px 20px
        .photo
          position relative
          z-index 0
          width 80px
          margin 0 auto
          +mediaSp()
            width 64px
            margin 0 auto
          img
            width 100%
          &::after
            position absolute
            content ''
            z-index 1
            width 100%
            height 100%
            top 0
            left 0
        .info
          margin-top 20px
          font-weight 400
          +mediaSp()
            margin-top 10px
        .btn-area
          display flex
          width 260px
          justify-content space-between
          margin 20px auto 10px
          +mediaSp()
            width 240px
    &__thanks
      .white-box
        .text
          margin 60px 0
          font-weight 400
          +mediaSp()
            margin 55px 0
          .red
            color font-red
        .btn-area
          display block
          width 100%
