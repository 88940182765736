.m__header
  background rgba(#fff,0.8)
  position fixed
  top 0
  left 0
  width 100%
  z-index 100
  &__contents
    height 60px
    display flex
    justify-content space-between
    align-items center
    padding 0 0 0 75px
    +mediaSp()
      padding 0 10px
      height 55px
    .logo
      position relative
      z-index 100
      +mediaPc()
        margin-top 10px
        a
          display inline-block
      +mediaSp()
        line-height 1
      span
        vertical-align -3px
        font-size 1.6rem
        line-height 1
        transition .3s
        display inline-block
        color font-black
        +mediaSp()
          padding-left 0
          display block
          margin-top 2px

      img
        width 200px
        height 20px
        display inline-block
        vertical-align bottom
        margin-right 20px
        +mediaSp()
          width 185px
          height 18px
          margin-right 10px
      +mediaSp()
        img
          width 185px
          display block
      a
        display inline
        text-decoration none
        &:hover
          img
            opacity .7
          span
            color font-gray

    .link
      display flex
      align-items center
      > ul
        display flex
        margin-top 15px
        min-width 492px
        +mediaSp()
          display none
        > li
          margin-right 20px
          position relative
          a
            color font-black
            text-decoration none
            &:hover
              color font-green
          &.main-menu
            cursor pointer
            color font-black
            &:hover
              color font-green
              transition 0.5s
          .sub-menu
            position absolute
            top 0
            left 0
            padding-top 55px
            display none
            &.open
              display block
            ul
              background #fff
              padding 20px
              position relative
              display flex
              width 190px
              box-shadow 0 2px 8px rgba(0 0 0 30%)
              flex-wrap wrap
              &.shakujii
                width 220px
              &.column
                align-items flex-end
                flex-wrap wrap
                width 410px
                height 180px
                padding 10px 20px 20px
                li
                  width 175px
                  &:nth-child(5)
                    margin-top 0
                &--seijo
                  width 190px
                  height auto
                  padding 20px
                &--akitsu
                  height 230px
                  padding 5px 20px 20px
              li
                &:not(:first-child)
                  margin-top 13px
                a
                  text-decoration underline
                  color font-green
                  &:hover
                    color #6cd
              &::before
                content ''
                display block
                position absolute
                width 15px
                height 15px
                background #fff
                transform rotate(45deg) skew(15deg,15deg)
                top -6px
                left 30px

      .school-link
        display flex
        height 60px
        +mediaSp()
          height 55px
          margin-right -10px
        .integrated, .school
          display flex
          align-items center
          justify-content center
          width 125px
          position relative
          z-index 100
          transition opacity .3s
          +mediaPc()
            cursor pointer
            &:hover
              opacity .7
          +mediaSp()
            width 60px
            padding 5px 0
          p
            text-align center
            font-size 1.2rem
            line-height 1.2
          .menu
            margin 0 10px 0 20px
            height 22px
            span
              background bg-black
              height 4px
              margin-top -2px
              &:first-child, &:last-child
                margin-top 0
          +mediaSp()
            display block
            .menu
              margin 5px auto 0
              height 10px
              span
                height 1px
                width 10px
                margin-top 0
        .integrated
          background #fff
        .school
          background main-color
      .area
        text-align center
        +mediaSp()
          text-align center
        img
          vertical-align middle
          +mediaSp()
            width 19px
        span
          padding-left 10px
          +mediaSp()
            display block
            padding-left 0
            text-align center
            font-size 1rem
        a
          color font-black
          text-decoration none
          &:hover
            color font-green
            opacity 1
          +mediaSp()
            img
              height 27.5px
      .recruit
        margin-left 25px
        +mediaSp()
          text-align center
          margin-left 15px
        img
          vertical-align middle
          +mediaSp()
            width 30px
        span
          padding-left 10px
          +mediaSp()
            display block
            padding-left 0
            text-align center
            font-size 1rem
        a
          color font-black
          text-decoration none
          &:hover
            color font-green
            opacity 1
          +mediaSp()
            img
              height 27.5px
      .menu
        position relative
        width 25px
        height 20px
        margin-left 40px
        z-index 100
        margin-right 30px
        +mediaSp()
          margin-left 25px
          margin-right 0
        span
          display inline-block
          background bg-green
          width 25px
          height 3px
          position absolute
          left 50%
          top 50%
          transform translateX(-50%)
          margin-top -1px
          transition transform .3s
          +ie()
            margin-top -1.5px
          &:first-child
            top 0
            margin-top 0
          &:last-child
            top auto
            bottom -1px
            margin-top 0
        &:hover
          cursor pointer
          span
            background bg-light-green
        &.open
          span
            &:first-child
              top 50%
              transform translate(-50%, -50%)rotate(45deg)
            &:nth-child(2)
              height 0
            &:last-child
              top 50%
              bottom auto
              transform translate(-50%, -50%)rotate(-45deg)
    &--english
      .link
        ul
          li
            margin-left 30px

            a
              text-decoration underline
              alphabet()
  &__menu
    position fixed
    width 100%
    height 100%
    top 0
    left 0
    z-index 99
    overflow hidden
    transition transform .7s
    &--background
      &::before
        +ie()
          transform translateX(100%)translateX(750px)
          transition transform .7s
      &-inner
        +ie()
          transform translateX(100%)
          transition transform .7s
          background transparent !important
          &::after
            content ""
            display block
            width 750px
            height 100%
            background rgba(bg-black, 0.95)
            position absolute
            left 0
            top 0
    &.open
      .m__header__menu--background
        &::before
          +ie()
            transform translateX(0)
            transition transform .7s
        &-inner
          +ie()
            transform translateX(10px)
            transition transform .7s
          li
            &.submenu
              +ie()
                &::before, &::after
                  transition opacity .3s !important
            > div
              +ie()
                transition 0s !important
            a, p
              span
                +ie()
                  transition opacity .3s !important
    +mediaPc()
      transform translateX(100%)
      &--english
        display none
      &--background
        &::before
          content ""
          display block
          background rgba(#fff,0.8)
          width calc(100vw - 750px)
          height 100%
          position fixed
          left 0
          top 0
          +ie()
            transform translateX(100%)
            transition transform .7s
            width calc(100% - 750px)
        &-inner
          display block
          width 760px
          height 100%
          background rgba(bg-black, 0.95)
          position fixed
          right 0
          top 0
          overflow-y scroll
          min-height 780px
          -ms-overflow-style none
          scrollbar-width none
          &::-webkit-scrollbar
            display none
          .m__header__menu--nav
            > ul
              position relative
              z-index 100
              width 760px
              left 0
              li
                a, p
                  display block
                  color #fff
                  padding 10px 0
                  font-size 1.6rem
                  span
                    opacity 0
                    display inline-block
                    text-align left
                    transform translateX(-20px)
                  &.line-height
                    line-height 1.3
                > ul
                  a, p
                    text-align right
                    span
                      width 675px
              > li
                a, p
                  text-align right
                  span
                    width 685px
                > ul
                  li
                    &.submenu
                      width 100%
                      position relative
                      &.student
                        background main-color
                        a, p
                          color font-black
                        &::before, &::after
                          background font-black
                      &::before
                        content ""
                        width 12px
                        height 2px
                        background #fff
                        position absolute
                        opacity 0
                        right 270px
                        top 50%
                        transform translateY(-50%)
                        transition right .3s, opacity .3s

                      &::after
                        content ""
                        height 12px
                        width 2px
                        background #fff
                        position absolute
                        right 275px
                        opacity 0
                        top 50%
                        transform translateY(-50%)
                        transition right .3s, opacity .3s

                    a, p
                      span
                        padding-left 25px
                    > div
                      position fixed
                      top 0
                      right -240px
                      height 100%
                      overflow hidden
                      background rgba(#fff, 0.2)
                      width 0
                      transition right .3s
                      ul
                        width 100%
                        li
                          a
                            color #fff
                            &:hover
                              color font-green
                            span
                              width 100%
                              opacity 0
                              transform translateX(-20px)

              for i in 0..20
                li
                  &:nth-child({i*1})
                    a, p
                      span
                        transition transform .3s ((.05 * i) + .5)s, opacity .3s ((.05 * i) + .5)s, width .3s
                        +ie()
                          transition opacity .3s !important

      &--nav
        height calc(100vh - 100px)
        overflow-y auto
        margin-top 100px
        -ms-overflow-style none
        scrollbar-width none
        &::-webkit-scrollbar
          display none
      &.open
        transform translateX(0)
        transition transform .7s
        +ie()
          transform translateX(10px)
        .m__header__menu--background
          &-inner
            .m__header__menu--nav
              &.school-menu
                position fixed
                left 750px
                top 0
                width calc(100% - 750px)
                height auto
                ul
                  width 100%
                  li
                    a
                      color font-black
                      span
                        width calc(100% - 70px)

              > ul
                height auto
                > li
                  > a, > p
                    &:hover
                      transition background .2s
                      background rgba(#fff, 0.2)
                  > ul
                    > li
                      transition background .2s
                      &.student
                        &:hover
                          transition background .2s
                          background main-color
                        > div
                          background main-color
                          ul
                            li
                              a
                                &:hover
                                  background main-color-light
                        &.menu-hover
                          width calc(100% - 250px)
                          transition width 0.3s
                          &::before
                            right 20px
                          &::after
                            right 25px
                          span
                            width 435px
                      &:hover
                        background rgba(#fff, 0.2)
                        transition background .2s
                        a, p
                          opacity 1
                          cursor pointer

                  > a, > p
                    > span
                      transform translateX(0)
                      opacity 1
                  > ul
                    > li
                      > a, > p
                        > span
                          transform translateX(0)
                          opacity 1
                      &.submenu
                        width 100%
                        transition width .3s
                        position relative
                        &::before, &::after
                          opacity 1
                        > div
                          ul
                            width 100%
                          ul
                            position absolute
                            left 0
                            li
                              a
                                span
                                  width 100%
                                  opacity 0
                                  transition opacity 0.2s
                                  letter-spacing -1px
                          &#menu-school
                            ul
                              top 198px
                          &#menu-contents
                            ul
                              top 250px
                              li
                                a
                                  padding 7px 10px
                          &#menu-about
                            ul
                              top 353px
                          &#menu-entertainment
                            ul
                              top 400px
                          &#school-menu-about
                            ul
                              top 399px
                          &#menu-language
                            ul
                              top 543px
                          &#school-price
                            ul
                              top 198px
                          &#school-joyful
                            ul
                              top 251px
                          &#school-introduce
                            ul
                              top 304px
                          &#student
                            top 0
                            padding-top 150px
                          &#school-language
                            ul
                              top 640px
                      &.submenu:hover
                        width calc(100% - 250px)
                        transition width .3s
                        &::before
                          transform translateY(-50%)rotate(45deg)
                          right 20px
                          transition right .3s, opacity .3s
                        &::after
                          transform translateY(-50%)rotate(45deg)
                          right 25px
                          transition right .3s, opacity .3s
                        > a, > p
                          > span
                            width 425px
                        > div
                          right 0
                          width 250px
                          height 100%
                          transition right .3s
                          for i in 0..20
                            li
                              a, p
                                span
                                  opacity 1
                                  transform translateX(0)
                              &:nth-child({i*1})
                                a
                                  span
                                    transition transform .3s (.05 * i)s, opacity .3s (.05 * i)s

                for i in 0..20
                  li
                    &:nth-child({i*1})
                      a
                        span, p
                          transition transform .3s ((.05 * i) + .5)s, opacity .3s ((.05 * i) + .5)s, width .3s
                          +ie()
                            transition transform .3s ((.05 * i) + .5)s, opacity .3s ((.05 * i) + .5)s, width 0
                      &.submenu
                        &::before, &::after
                          transition right .3s, transform 0s, opacity .3s ((.05 * i) + .5)s

                  li
                    &:nth-last-child({i*1})
                      z-index i
                    &.lang
                      z-index 21

    +mediaSp()
      top 50px
      height calc(100vh - 50px)
      display none
      overflow-y auto
      -ms-overflow-style none
      scrollbar-width none
      &::-webkit-scrollbar
        display none
      background bg-header
      padding-bottom env(safe-area-inset-bottom);
      &--integrated, &--school
        top 55px
        height calc(100vh - 55px)
      &--english
        .m__header__menu--nav
          > ul
            > li
              background bg-black
              p.line-height
                padding-top 10px
      .m__header__menu--nav
        > ul
          > li
            &:first-child
              background bg-black
            > ul
              background bg-header
          li
            a, p
              display block
              padding 15px 40px 15px 20px
              font-size 1.5rem
              color #fff
              text-decoration none
              &:hover
                background bg-blue
              &.line-height
                line-height 1.3
            &.submenu
              position relative
              &.student
                background main-color
                &::before, &::after
                  background bg-black
                p, a
                  color font-black
                > div
                  background main-color
              > a
                pointer-events none
              &::before
                content ""
                width 1px
                height 11px
                background #fff
                display inline-block
                position absolute
                color #fff
                font-size 1.5rem
                right 25px
                top 23px
                transition .3s
              &::after
                content ""
                width 11px
                height 1px
                background #fff
                display inline-block
                position absolute
                color #fff
                font-size 1.5rem
                right 20px
                top 28px
                transition .3s
              &.open
                &::before
                  transform rotate(45deg)
                  transition .3s
                &::after
                  transform rotate(45deg)
                  transition .3s
              > div
                display none
                background bg-header02
                a
                  padding-left 60px

  &__sns
    margin-left 100px
    margin-top 50px
    padding-bottom 30px
    position absolute
    z-index 100
    width 100%
    ul
      display flex
      width 264px
      justify-content space-between
      align-items center
    +mediaSp()
      margin 0
      padding 0
      background bg-header
      border-top 1px solid #fff
      ul
        justify-content center
        margin 0 auto
        li
          a
            display block
            padding 30px 20px 30px
          img
            width 30px
            height 30px

  &__top-menu
    position fixed
    right 0
    top 50%
    transform translate3d(0, -50%, 0)
    z-index 99
    opacity 0
    pointer-events none
    transition transform .5s, opacity .5s
    &.is-fadein
      opacity 1
      pointer-events auto
    &.stop
      position absolute
      top auto
      transform none
      .m__header__student-submenu
        position absolute
    > ul
      > li
        border-bottom 1px solid #fff
        position relative
        &:last-child
          +mediaPc()
            border-bottom 1px solid transparent
          &.open
            border-color #fff
            a
              background main-color
              +mediaSp()
                .icon
                  img.open
                    display none
                  img.close
                    margin 1px 0
                    display inline-block
        > a
          display flex
          align-items center
          justify-content center
          text-align center
          width 60px
          height 70px
          color font-black
          background main-color
          text-decoration none
          flex-wrap wrap
          line-height 1
          font-size 1.2rem
          padding-top 5px
          +mediaPc()
            &:hover
              background main-color-light
              .icon
                opacity 0.6
              > span
                opacity 0.65
          .icon
            width 100%
            img.close
              display none
            +mediaSp()
              padding 5px
              img
                max-height 22px

          > span
            line-height 1
            display block
            text-align center
            span.sp
              display none
          +mediaSp()
            font-size 1rem
            br
              display none
            > span
              span.sp
                display inline
            span.alphabet
              vertical-align baseline
    +mediaSp()
      bottom 0
      top auto
      transform translate3d(0, 65px, 0)
      margin-top 0
      z-index 99
      width 100%
      transition transform .5s, opacity .5s, padding-bottom .05s
      opacity 0
      pointer-events none
      background #fff
      &.is-fadein
        transform translate3d(0, 0, 0)
        opacity 1
        pointer-events auto
      > ul
        display flex
        position relative
        z-index 98
        justify-content space-around
        >li
          border none
          width 25%
          > a
            background #fff
            width 100%
            display block
            height 65px
            padding 10px 0 5px
            &:hover
              background main-color

  &__student-submenu
    position fixed
    right 75px
    top calc(50% - 142px)
    z-index 99
    border-bottom 1px solid transparent
    overflow hidden
    width 0
    opacity 0
    transition .5s
    &::-webkit-scrollbar
      display none
    &.open
      opacity 1
      width 451px
    ul
      width 451px
      display flex
      flex-wrap wrap
      li
        width 225px
        border-top 1px solid #fff
        &:nth-child(-n+2)
          border-top 0
        &:nth-child(2n)
          border-left 1px solid #fff
        a
          display flex
          height 60px
          align-items center
          width 100%
          background main-color
          color font-black
          text-decoration none
          padding 0 15px
          +mediaPc()
            &:hover
              background main-color-light
          span
            display inline-block
            width 18px
            text-align center
            vertical-align middle
            margin-right 10px
            img
              vertical-align middle
        &.access
          width 100%
          a
            justify-content center
    +mediaSp()
      display block
      position fixed
      right 0
      top auto
      bottom 0
      transform translateY(365px)
      transition transform .7s
      border-bottom 0
      z-index 97
      width auto
      opacity 1
      ul
        width 100%
        li
          width 50%
          a
            font-size 1.4rem
            padding 0 10px
            span
              width 15px
              margin-right 5px
              img
                max-width 15px
                max-height 15px
      &.open
        transform translateY(-65px)
        width 100%
        transition transform .7s
+ie()
  .m__header__menu.open .m__header__menu--background-inner .m__header__menu--nav > ul > li > ul > li.submenu:hover
    > div
      width 240px