.m__plan-senior
  &__menu__Area
    .m__menu__list
      padding-top 20px
      +mediaSp()
        padding-top 10px
      dt
        padding 25px 25px 15px
        border-top 1px solid border-light-gray
        span
          display block
        +mediaSp()
          padding 15px 10px 5px
      dd
        font-size 1.6rem
        padding 0 25px 20px
        .indent
          padding-left 15px
        .txtEmp
          color font-red
        +mediaSp()
          padding 0 10px 15px
          font-size 1.3rem
        .flex-list
          display flex
          align-items center
          flex-wrap wrap
          max-width 430px
          margin 0 auto
          li
            white-space nowrap
            width 200px
            display inline-block
            margin-top 20px
            a
              text-decoration none
              color font-black
              margin-left 1em
              +mediaPc()
                pointer-events none
            +mediaSp()
              margin-top 10px
              width 100%
              text-align center
            &:nth-child(2n)
              margin-left 30px
              +mediaSp()
                margin-left 0
      &--no__borderTop
        padding-top 10px
        margin-bottom 60px
        +mediaSp()
          padding-top 0
          margin-bottom 15px
        dt
          &:first-child
            border-top none
    &--lede
      .text
        font-size 2.3rem
        +mediaSp()
          font-size 1.5rem
      .image
        img
          max-width 100%
          display block
          margin 0 auto
  &__table__Area
    margin 0 auto
    display flex
    align-items flex-end
    justify-content center
    +mediaSp()
      width 100%
      display block
    .flex-text
      margin-left 10px
      +mediaSp()
        margin 10px 10px 0
    .txt__sub__h2
      text-align center
      font-size 1.6rem
      padding-bottom 50px
      +mediaSp()
        padding-bottom 20px
        font-size 1.3rem
    .ttlTable
      font-size 1.8rem
      width 750px
      margin 0 auto
      +mediaSp()
        width 100%
        font-size 1.3rem
      &:before
        content '■'
        color #66BB99
    .m__table
      width 740px
      font-size 1.8rem
      border 2px solid #1a1a1a
      +mediaSp()
        width 100%
        font-size 1.3rem
        border 1px solid #1a1a1a
        border-left none
        border-right none
      th
        background #ccc
        vertical-align middle
        padding 0
        border 1px solid #1a1a1a
        +mediaSp()
          border-left none
          padding 5px 0
          &:last-child
            border-right none
        span
          display inline-block
          text-align left
          &.pl15
            padding-left 15px
            +mediaSp()
              padding-left 0
      td
        border-top 1px solid #333
        vertical-align middle
        text-align center
        &:not(:first-child)
          border-left 1px solid #333
        &.ttlCarType
          background #F0F0F0
          text-align center
          padding 3px 30px
          +mediaSp()
            width 12%
            padding 15px 0
  .text-notice
    text-align right
    margin 10px 14vw 0px 0
    +mediaSp()
      margin-right 0
  &__program__Area
    margin 0 auto
    font-size 1.6rem
    padding-bottom 100px
    +mediaSp()
      padding 30px 0
      font-size 1.3rem
    ul
      li
        padding-left 1em
        text-indent -1em
  &__link__Area
    display block
    .m__common-heading__title
      border-top 1px solid border-light-gray
    .m__navi_list__busNavi__inner
      background transparent
