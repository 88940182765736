.m__facility
  &__mv
    position fixed
    left 0
    top 60px
    width 100%
    height 100vh
    z-index 1
    background-size cover
    background-position center center
    background-repeat no-repeat
    +mediaSp()
      height 60vw
      top 55px
    &--futako
      background-image url(image_path + '/futako/aboutschool/img_facility_mv.jpg')
    &--seijo
      background-image url(image_path + '/seijo/aboutschool/img_facility_mv.jpg')
    &--shakujii
      background-image url(image_path + '/shakujii/aboutschool/img_facility_mv.jpg')
      background-position-y -30px
      +mediaSp()
        background-position-y top
        background-size 103%
    &--akitsu
      background-image url(image_path + '/akitsu/aboutschool/img_facility_mv.jpg')
      +mediaSp()
        background-position-y top
    &--yokohama
      background-image url(image_path + '/yokohama/aboutschool/img_facility_mv.jpg')
      +mediaSp()
        background-position-y top
        background-size 110%

  &__contents
    margin-top calc(100vh - 95px)
    position relative
    z-index 2
    background bg-light-gray
    +mediaSp()
      margin-top calc(60vw - 37px)
    .l__contents__inner--width
      position relative
    #trainingcar
      padding-top 70px
  &__text-block
    background #fff
    width 777px
    padding 100px 60px 120px
    +mediaSp()
      width auto
      padding 30px 10px
      margin 0 -10px
    p
      regular()
      width 595px
      font-size 1.6rem
      margin-top 5px
      +mediaSp()
        width 100%
        font-size 1.3rem
      + p
        margin-top 1.5em
        line-height 1.75
    a
      font-size 1.6rem
      +mediaSp()
        font-size 1.3rem
    .m__common-heading__title
      text-align left
      +mediaSp()
        font-size 1.6rem
        text-align center
  &__scroll-box
    position relative
    +mediaSp()
      margin 0 -10px
    + .m__facility__scroll-box
      margin-top 50px
    &:nth-child(odd)
      +mediaPc()
        .m__facility__slide-image
          margin-left 0
        .m__facility__scroll-text-box
          left auto
          right 0
          text-align right
          .m__facility__contents-title
            text-align left
          p
            display inline-block
            text-align left
  &__slide-image
    margin-left auto
    background-position center center
    background-repeat no-repeat
    position relative
    +mediaPc()
      width 800px
      height 540px
      margin-top 0
      background-size 140% auto
    +mediaSp()
      width 80vw
      height 54vw
      margin-top 50px
      background-size cover
    &--futako1
      background-image url(image_path + '/futako/aboutschool/img_facility_about01.jpg')
      +mediaPc()
        width 600px
        height 870px
        margin-top -150px
      +mediaSp()
        height 120vw
    &--futako2
      +mediaPc()
        margin-top -80px
      background-image url(image_path + '/futako/aboutschool/img_facility_about02.jpg')
    &--futako3
      background-image url(image_path + '/futako/aboutschool/img_facility_about03.jpg')
    &--futako4
      background-image url(image_path + '/futako/aboutschool/img_facility_about04.jpg')
    &--seijo1
      +mediaPc()
        margin-top -150px
      background-image url(image_path + '/seijo/aboutschool/img_facility_about01.jpg')
    &--seijo2
      background-image url(image_path + '/seijo/aboutschool/img_facility_about02.jpg')
    &--seijo3
      background-image url(image_path + '/seijo/aboutschool/img_facility_about03.jpg')
    &--seijo4
      background-image url(image_path + '/seijo/aboutschool/img_facility_about04.jpg')
    &--shakujii1
      +mediaPc()
        margin-top -150px
      background-image url(image_path + '/shakujii/aboutschool/img_facility_about01.jpg')
    &--shakujii2
      background-image url(image_path + '/shakujii/aboutschool/img_facility_about02.jpg')
    &--shakujii3
      background-image url(image_path + '/shakujii/aboutschool/img_facility_about03.jpg')
    &--shakujii4
      background-image url(image_path + '/shakujii/aboutschool/img_facility_about04.jpg')
    &--akitsu1
      +mediaPc()
        margin-top -150px
      background-image url(image_path + '/akitsu/aboutschool/img_facility_about01.jpg')
      background-size 100% auto
    &--akitsu2
      background-image url(image_path + '/akitsu/aboutschool/img_facility_about02.jpg')
      background-size 100% auto
    &--akitsu3
      background-image url(image_path + '/akitsu/aboutschool/img_facility_about03.jpg')
      background-size 100% auto
    &--akitsu4
      background-image url(image_path + '/akitsu/aboutschool/img_facility_about04.jpg')
      background-size 100% auto
    &--yokohama1
      +mediaPc()
        margin-top -150px
      background-image url(image_path + '/yokohama/aboutschool/img_facility_about01.jpg')
    &--yokohama2
      background-image url(image_path + '/yokohama/aboutschool/img_facility_about02.jpg')
      +mediaPc()
        width 600px
        height 870px
      +mediaSp()
        height 120vw
    &--yokohama3
      background-image url(image_path + '/yokohama/aboutschool/img_facility_about03.jpg')
      +mediaPc()
        width 600px
        height 870px
        margin-top -80px
      +mediaSp()
        height 120vw
    &--yokohama4
      margin-top -80px
      background-image url(image_path + '/yokohama/aboutschool/img_facility_about04.jpg')
      +mediaSp()
        margin-top 0
  &__contents-title
    alphabet()
    opacity .8
    color #fff
    font-size 6rem
    line-height 1
    margin-bottom 30px
    +mediaSp()
      font-size 3rem
      margin-bottom 10px
      span
        display none
  &__scroll-text-box
    position absolute
    bottom -100px
    left 0
    +mediaSp()
      position static
      padding 0 10px
      margin-top -20px
    p
      font-size 1.6rem
      +mediaSp()
        font-size 1.3rem
      +mediaPc()
        min-height 84px

  &__point--img
    width 100%
    height 75vw
    background url(image_path + '/futako/aboutschool/img_facility_about05.jpg') center top no-repeat
    background-size 120% auto
    margin-bottom -150px
    +mediaSp()
      margin-bottom -70px
      margin-top 50px
  &__car-list
    margin-top -80px
    position relative
    padding-bottom 180px
    z-index 2
    +mediaSp()
      margin-top 0
      padding-bottom 50px
    > li
      display flex
      justify-content space-between
      align-items flex-start
      +mediaSp()
        flex-wrap wrap
      + li
        margin-top 100px
        +mediaSp()
          margin-top 0
      .thumb
        width 600px
        position relative
        +mediaSp()
          width 80vw
          height auto
          order 1
          margin-top 50px
          margin-right -10px
          margin-left auto
          img
            max-width 100%
        span
          alphabet()
          font-size 6rem
          color #fff
          opacity .8
          position absolute
          right 10px
          bottom -25px
          line-height 1
          +mediaSp()
            font-size 3rem
            bottom -13px
          &.small
            font-size 4.6rem
            bottom -20px
            right 20px
            +mediaSp()
              font-size 2.3rem
              bottom -10px
          &.two-lines
            bottom -58px
            +mediaSp()
              bottom -30px

      .features
        font-size 1.6rem
        margin-top 150px
        width 400px
        +mediaSp()
          width 100%
          order 2
          margin-top 30px
          font-size 1.3rem
        .title
          font-size 3.2rem
          line-height 1.2
          alphabet()
          margin-bottom 20px
          +mediaSp()
            font-size 1.6rem
            + p
              margin-top -15px
          span
            font-weight 500
            &.type
              gothic()
              font-size 1.6rem
              display block
              +mediaSp()
                font-size 1.3rem
        dl
          dt
            margin-top 35px
            +mediaSp()
              margin-top 20px
            &:first-child
              margin-top 0
          dd
            regular()
            &.mt30
              +mediaSp()
                margin-top 0
