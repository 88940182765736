.m__belongings
  &__textArea
    padding 80px 0 60px
    font-size 1.6rem
    +mediaSp()
      padding 50px 0 25px
    &--short
      padding 50px 0 20px
      +mediaSp()
        padding 30px 0 0
    ul
      padding-left 20px
      +mediaSp()
        padding-left 0
        font-size 1.4rem
      li
        text-indent -1em
        padding-left 1em
        padding-bottom 30px
        font-weight 300