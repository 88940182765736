.m__sdgs
  &__introduction
    margin-top 95px
    background bg-header
    color #fff
    padding 40px 0 50px
    +mediaSp()
      padding 15px 10px
      margin-top 30px
    h3
      font-size 2.6rem
      medium()
      width 1033px
      margin 0 auto
      text-align left
      +mediaSp()
        font-size 1.6rem
        width 100%
  &__goal__list
    padding 100px 0
    +mediaSp()
      padding 50px 0
      margin 0 -10px
    .goal-list
      border 2px solid #000
      width 750px
      margin 0 auto
      +mediaSp()
        width 100%
      tr
        td
          background #fff
          border 1px solid
          vertical-align middle
          a
            color font-black
          &.title
            background bg-light-gray
            text-align center
            font-size 2.4rem
            padding 5px 0
            +mediaSp()
              font-size 1.5rem
          &.text
            width 95%
            font-size 1.8rem
            padding 20px 10px 20px 20px
            +mediaSp()
              font-size 1.3rem
              padding 10px 20px 10px 15px
            p
              &:before
                content "●"
                color #66CCCC
                padding-right 3px
          &.img
            width 5%
            padding 20px
            +mediaSp()
              padding 10px
            img
              +mediaSp()
                width 60px
                height 60px
    .table-inner-list
      > li
        text-indent -20px
        padding-left 20px
        +mediaSp()
          text-indent -16px
          padding-left 16px
        &:before
          content "●"
          color #66CCCC
          padding-right 3px
      .table-inner-list-lower
        padding-left 20px
  &__goal__detail
    padding 100px 0 30px
    width 990px
    margin 0 auto
    +mediaSp()
      width 100%
      padding 50px 0
    .image-list
      display flex
      flex-wrap wrap
      justify-content space-between
      +mediaSp()
        display block
      li
        width 480px
        +mediaSp()
          width 100%
        .image
          padding-bottom 15px
          +mediaSp()
            width 100%
        .text-area
          display flex
          align-items center
          .ico
            width 15%
            margin-right 15px
            +mediaSp()
              margin-right 10px
            img
              width 70px
              +mediaSp()
                width 100%
          .text
            font-size 1.6rem
            width: 85%;
            +mediaSp()
              font-size 1.3rem
            &.textNarrow
              letter-spacing -1px
              +mediaSp()
                letter-spacing normal
        &:nth-child(-n+11)
          margin-bottom 70px
          +mediaSp()
            margin-bottom 30px
