.m__english__types
  &__content
    padding 50px 0 140px
    font-size 1.6rem
    regular()
    word-break break-word
    +mediaSp()
      font-size 1.3rem
      padding 10px 0 60px
    .m__common-heading__english
      margin 40px 0 10px
      +mediaSp()
        margin-top 20px
        line-height 1.4
    p + p
      margin-top 15px
  &__table
    margin 30px 0 60px
    +mediaSp()
      margin 15px -10px 40px
    table
      border 2px solid border-black
      width 750px
      margin 0 auto
      +mediaSp()
        width 100%
        border-left 0
        border-right 0
        margin 5px 0 30px
      tr
        &:not(:first-child)
          border-top 1px solid border-black
        &:first-child
          th
            height 90px
            text-align center
            background bg-gray02
            +mediaSp()
              height 50px
              padding 5px
        th,td
          medium()
          font-size 1.8rem
          padding 20px
          text-align left
          vertical-align middle
          +mediaSp()
            font-size 1.3rem
            padding 10px
        th
          background bg-light-gray
          width 280px
          +mediaSp()
            width 45%
          &:first-child
            border-right 1px solid border-black
        td
          width 470px
          +mediaSp()
            width 55%
  &__image
    background bg-light-gray
    display flex
    justify-content center
    margin-top 150px
    +mediaSp()
      display block
      margin 0 0 60px
    .image-block
      width 400px
      +mediaSp()
        width 100%
        img
          width 100%
      p
        regular()
        font-size 1.6rem
        padding 15px 0
        text-align center
        +mediaSp()
          font-size 1.3rem
          padding 10px