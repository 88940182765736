.m__timetable
  &__notice
    padding 55px 0
    +mediaSp()
      padding 25px 0
    li
      font-size 1.6rem
      letter-spacing -0.01em
      text-indent -1.5rem
      padding-left 1rem
      +mediaSp()
        font-size 1.3rem
        letter-spacing normal
        text-indent -1em
        padding-left 1em
      &::before
        content "●"
        color main-color
  &__warning
    text-align center
    padding 60px 0 80px
    +mediaSp()
      padding 30px 0
    p
      display inline-block
      padding 10px 40px
      color font-red
      border 2px solid border-red
      font-size 1.8rem
      line-height 1.4
      +mediaSp()
        font-size 1.3rem
        padding 10px 0
        width 100%
  &__table-contents
    +mediaSp()
      margin 0 -5px
    .other-time-link
      font-size 1.6rem
      &--l
        text-align left
      &--r
        text-align right
    .group-block
      display flex
      margin-top 20px
      +mediaSp()
        flex-wrap wrap
        margin 30px -5px 0
      li
        width 216px
        padding 22px
        alphabet()
        bold()
        font-size 2.4rem
        text-align center
        line-height 1.2
        +mediaSp()
          width calc(100% / 3)
          font-size 1.6rem
          padding 6px 15px
        &:hover
          cursor pointer
        &::before
          content "▼"
          font-size 1.4rem
          vertical-align 2px
          padding-right 3px
          +mediaSp()
            font-size 1.1rem
        &#btn-groupA
          background bg-futako
          +mediaPc()
            &:hover
              background bg-light-futako
        &#btn-groupB
          background bg-seijo
          +mediaPc()
            &:hover
              background bg-light-seijo
        &#btn-groupC
          background bg-shakujii
          +mediaPc()
            &:hover
              background bg-light-shakujii
        &#btn-groupD
          background bg-akitsu
          +mediaPc()
            &:hover
              background bg-light-akitsu
        &#btn-groupE
          background bg-yokohama
          +mediaPc()
            &:hover
              background bg-light-yokohama
    .timetable-navigation
      margin 40px 0 10px
      +mediaSp()
        margin 30px 0 15px
      ul
        display flex
        justify-content flex-end
        li
          margin 0 10px
          &:first-child
            margin-right 20px
          a
            alphabet()
            font-size 2.4rem
            font-weight bold
            transition color 0s
            +mediaSp()
              font-size 1.4rem
            &.prev::before
              content "＜"
            &.next::after
              content "＞"
            &.disabled
              color rgba(font-gray, 0.67)
              pointer-events none
    .timetable-block
      position relative
    .timetable-left
      position absolute
      left 0
      top 62px
      z-index 2
      +mediaSp()
        top 44px
      table
        width 281px
        +mediaSp()
          width 152px
        tr
          th, td
            &:first-child
              width 20px
            +mediaSp()
              &:nth-child(2)
                width 80px
              &:last-child
                width 48px
    .timetable-right
      overflow-x scroll
      margin-left 279px
      position relative
      -ms-overflow-style none
      scrollbar-width none
      &::-webkit-scrollbar
        display none
      +mediaSp()
        margin-left 150px
      table
        width 2000px
        +mediaSp()
          width 1050px
      .block-group
        display flex
        width 2000px
        +mediaSp()
          width 1190px
        li
          width 400px
          padding 10px
          alphabet()
          bold()
          font-size 2.4rem
          +mediaSp()
            width 238px
            font-size 1.6rem
            padding 8px 15px
          &#groupA
            background bg-futako
          &#groupB
            background bg-seijo
          &#groupC
            background bg-shakujii
          &#groupD
            background bg-akitsu
          &#groupE
            background bg-yokohama
    table
      border 2px solid border-black
      table-layout fixed
      tr
        > th, > td
          border 1px solid border-black
          text-align center
          font-size 1.8rem
          font-weight normal
          vertical-align middle
          width 56px
          height 60px
          white-space nowrap
          &:nth-child(7n+1)
            border-left 2px solid border-black
          &:first-child
            border-left-width 1px
          +mediaSp()
            font-size 1.1rem
            width 34px
            height 30px
        th
          background bg-light-gray
          &.sunday
            color font-red
          &.satuday
            color font-green
