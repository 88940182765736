// ==========================================================================
// var
// ==========================================================================

fontset-gothic = 'M PLUS 1p', "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック", "メイリオ", sans-serif
fontset-alphabet = "Roboto Condensed", 'M PLUS 1p', "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック", Meiryo, "メイリオ", sans-serif
fontset-chinese = 'Noto Sans SC', "Roboto Condensed", 'M PLUS 1p', "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック", Meiryo, "メイリオ", sans-serif

breakPoint = 767px

font-black = #1A1A1A
font-green = #00AACC
font-light-green = #66ccdd
font-red = #F08066
font-blue = #0099FF
font-vivid-red = #FF0000
font-gray = #666666
font-green02 = #3B8E6B
bg-black = #1A1A1A
bg-green = #3B8E6B
bg-green02 = #00AACC
bg-light-green = #66BB99
bg-gray = #908F97
bg-gray02 = #cccccc
bg-light-gray = #F0F0F0
bg-light-gray02 = #efefef
bg-header = #333
bg-header02 = #505050
bg-footer = #363636
bg-footer02 = #202020
bg-red = #F08066
bg-vivid-red = #ff0000
bg-light-red = #ff6666

bg-futako = #F0E44D
bg-light-futako = #F4EC82
bg-seijo = #66CCCC
bg-light-seijo = #94DBDB
bg-shakujii = #99B0E3
bg-light-shakujii = #B8C8EB
bg-akitsu = #F09950
bg-light-akitsu = #F5B885
bg-yokohama = #F08066
bg-light-yokohama = #F4A694

border-black = #1A1A1A
border-red = #F08066
border-vivid-red = #FF0000
border-gray = #666666
border-light-gray = #cccccc
border-green = #00AACC

main-color = #66BB99
main-color-light = #FBF8D2

image_path = '../images'
css_path = '../css'
js_path = '../js'

// ==========================================================================
// mixin
// ==========================================================================


//カーニング自動調整
kerning()
  -webkit-font-feature-settings "pkna"
  font-feature-settings "pkna"
  letter-spacing 0.03em

// 矢印装飾
arrow()
  &::before
    content ""
    display inline-block
    width 45px
    height 1px
    background bg-green02
    position absolute
    right 20px
    top 50%
    transition .3s
    +mediaSp()
      width 22px
      right 10px
  &::after
    content ""
    width 15px
    height 1px
    background bg-green02
    position absolute
    right 17px
    top 50%
    transform translateY(-5px)rotate(45deg)
    transition .3s
    +mediaSp()
      width 8px
      right 9px
      margin-top 2px
  &:hover
    &::before
      width 55px
      right 20px
      margin-top 2px
    &::after
      right 17px



//メディアクエリ
mediaSp()
  @media only screen and (max-width breakPoint)
    {block}
mediaPc()
  @media print, screen and (min-width breakPoint + 1)
    {block}
media(responsive)
  @media only screen and (max-width responsive)
    {block}
retina()
  @media only screen and (-webkit-min-device-pixel-ratio: 2)
    {block}
ie()
  @media all and (-ms-high-contrast: none) and (min-width breakPoint + 1)
   {block}
//フォントずれIEハック
ieHeight(ieHeight)
  @media all and (-ms-high-contrast: none)
    line-height ieHeight
    *::-ms-backdrop
      line-height ieHeight
  &:not(:target)
    line-height ieHeight + \9

// clearfix
clearfix()
  &:after
    content "."
    display block
    clear both
    height 0
    visibility hidden

//gradient
gradient(gradient1, gradientStart, gradient2, gradientEnd)
  background linear-gradient(gradient1 gradientStart, gradient2 gradientEnd)

//フォントファミリー
gothic()
  font-family fontset-gothic
  font-weight 500
alphabet()
  font-family fontset-alphabet
  font-weight 800
chinese()
  font-family fontset-chinese
  font-weight 500
regular()
  font-weight 400
medium()
  font-weight 500
bold()
  font-weight 800
//ボタンのデザインリセット
inputReset()
  border-radius: 0
  -webkit-box-sizing: content-box
  -webkit-appearance: none
  appearance: none
  border: none
  box-sizing: border-box
  &::-webkit-search-decoration
    display: none
  &::focus
    outline-offset: -2px

// レスポンシブの縦余白指定
mt-h(mth)
  margin-top mth
  +mediaSp()
    margin-top mth * 0.5
pt-h(pth)
  padding-top pth
  +mediaSp()
    padding-top pth * 0.5
mb-h(mbh)
  margin-bottom mbh
  +mediaSp()
    margin-bottom mbh * 0.5
pb-h(pbh)
  padding-bottom pbh
  +mediaSp()
    padding-bottom pbh * 0.5

mt(mtpc, mtsp)
  margin-top mtpc
  +mediaSp()
    margin-top mtsp
mb(mbpc, mbsp)
  margin-bottom mbpc
  +mediaSp()
    margin-bottom mbsp
pt(ptpc, ptsp)
  padding-top ptpc
  +mediaSp()
    padding-top ptsp
pb(pbpc, pbsp)
  padding-bottom pbpc
  +mediaSp()
    padding-bottom pbsp
