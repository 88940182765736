.m__entry
  &__entry__txt__Area
    padding-bottom 100px
    +mediaSp()
      padding-bottom 0
    .m__entry__flow
      padding 30px 0
      font-size 1.6rem
      border-top 1px solid border-light-gray
      border-bottom 1px solid border-light-gray
      ul
        +mediaSp()
          padding 0 10px
        li
          text-indent -1.6em
          padding-left 1.6em
      +mediaSp()
        font-size 1.3rem
        padding 15px 0
      .ttl__txt
        font-size 2.6rem
        +mediaSp()
          font-size 1.3rem
          padding-left 10px
    .m__entry__notice
      padding 30px 0
      font-size 1.6rem
      border-bottom 1px solid border-light-gray
      +mediaSp()
        font-size 1.3rem
        border-bottom none
        padding 15px 0
      ul
        +mediaSp()
          padding 0 10px
        li
          text-indent -1em
          padding-left 1em
          &.txtRed
            color font-red
          &.txtEmp
            font-weight 600
          &:before
            content '●'
            color #F0DD4D
  &__btn__Area
    width 950px
    padding 0 40px
    margin 0 auto
    padding-top 50px
    display flex
    flex-wrap wrap
    justify-content space-between
    padding-bottom 150px
    +mediaSp()
      padding 30px 0 50px
      display block
      width 100%
    .m__common-btn__arrow
      a
        width 400px
        height 90px
        display flex
        flex-direction column
        justify-content center
        text-align left
        +mediaSp()
          width 100%
          height 60px
        &.m__common__flex-arrow
          &::before
            width 48px
            right 55px
            bottom 40px
            +mediaSp()
              width 24px
              bottom 26px
          &::after
            height 15px
            right 59px
            bottom 38px
            +mediaSp()
              bottom 26px
              height 9px
          +mediaPc()
            &:hover
              &::before
                right 45px
                width 58px
              &::after
                right 48px
      &.m__common-btn__arrow--blue
        padding-bottom 20px
        +mediaSp()
          padding-bottom 1px
  &__form
    margin 60px auto 0
    padding-top 80px
    +mediaSp()
      margin 30px -10px 0
      padding-top 30px
    .m__form
      &--preview,&--thanks
        padding 0 20px 140px
        .m__common-btn__arrow,.m__common-btn__arrow--back
          margin 0 auto
        +mediaSp()
          padding 0 10px 50px
          .m__common-btn__arrow
            margin 0
          .m__common-btn__arrow--back
            margin 0 0 15px 0
    .m__common-btn__arrow
      margin 0 0 0 550px
      +mediaSp()
        margin 0 -10px
      input
        display inline-block
        background bg-black
        color #fff
        font-size 1.6rem
        padding 16px 60px 16px 50px
        min-width 200px
        cursor pointer
        inputReset()
        medium()
        line-height 1.75
        +mediaPc()
          &:hover
            background bg-green02
            transition .3s linear
        +mediaSp()
          font-size 1.5rem
          width 100%
          &:active
            background bg-green02
            transition .3s linear
      .m__common__flex-arrow
        padding-right 0
        +mediaSp()
          width 100%
          &:active
            color #fff
          display block
        &::before,&::after
          background #fff
        &::before
          width 24px
          right 35px
          bottom 28px
          +mediaSp()
            right auto
            left 50%
            margin-left 35px
        &::after
          height 9px
          right 38px
          bottom 27px
          +mediaSp()
            right auto
            left 50%
            margin-left 55px
      &--back
        input
          padding 16px 40px 16px 60px
        .m__common__flex-arrow
          &::before
            right auto
            left 35px
            +mediaSp()
              left auto
              right 50%
              margin-right 35px
          &::after
            right auto
            left 38px
            transform rotate(45deg)
            +mediaSp()
              left auto
              right 50%
              margin-right 55px
  &__course
    padding 100px 20px
    margin 100px -20px 40px
    border-top 1px solid border-black
    border-bottom 1px solid border-black
    +mediaSp()
      margin 45px -10px 0
      padding 45px 10px 0
      border-bottom 0
    .title
      medium()
      font-size 2rem
      margin-bottom 30px
      +mediaSp()
        font-size 1.6rem
        margin-bottom 10px
    .text
      p
        font-size 1.6rem
        regular()
        margin-bottom 20px
        +mediaSp()
          font-size 1.3rem
        b
          medium()
        &.price
          color font-red
          medium()
          span
            font-size 2rem
            vertical-align baseline
            +mediaSp()
              font-size 1.6rem
        .red
          color font-red
          medium()
      &.camp
        p
          margin-bottom 0
        +mediaSp()
          margin-bottom 30px
    &.camp,&.other
      border-bottom 0
      margin-bottom 10px
      padding-bottom 0
      +mediaPc()
        .m__entry__course-list
          li
            position relative
            .text
              margin-bottom 90px
              &.camp
                margin-bottom 100px
            .title
              width 100%
            .m__common-btn__arrow
              position absolute
              bottom 0
              left 50%
              transform translateX(-50%)
  &__course-list
    margin 100px -20px 0
    display flex
    +mediaSp()
      display block
      margin 20px -10px 0
    li
      padding 0 20px
      border-right 1px solid border-black
      flex 1
      +mediaSp()
        border-right 0
        border-bottom 1px solid border-black
        padding 30px 10px
      &:last-child
        border 0
        padding-bottom 0
      .m__common-btn__arrow
        margin 0
        text-align center
        +mediaSp()
          margin 0 auto
          input
            width 150px
            min-width auto
            padding 16px 60px 16px 30px
          .m__common__flex-arrow
            &::before
              margin-left 22px
            &::after
              margin-left 42px
      .title
        text-align center
