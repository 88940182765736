.m__english__privacy
  font-size 1.6rem
  regular()
  word-break break-word
  padding 90px 0 150px
  +mediaSp()
    font-size 1.3rem
    padding 30px 0 60px
  +mediaPc()
    .l__contents__inner--width
      width 980px
  ol
    counter-reset item
    margin 20px 0 60px
    padding-left 1em
    +mediaSp()
      padding-left 0
      margin-bottom 40px
    li
      text-indent -1.3em
      padding-left 1.3em
      margin-bottom 30px
      +mediaSp()
        margin-bottom 20px
      &::before
        counter-increment item 1
        margin-right 0.3em
        content counter(item)"."
        medium()
      ol
        counter-reset item
        margin 0
        padding 0
        li
          text-indent -1.9em
          padding-left 1.9em
          margin-bottom 0
          &::before
            content "("counter(item)")"
            margin-right 0.5em
            regular()
  ul
    &.no-number
      li
        &::before
          content none
  .info
    padding-left 2.3em
    medium()
    span
      margin-right 0.5em
    +mediaSp()
      padding-left 1.3em
  .telnumber
    margin-left 0.5em
    +mediaPc()
      pointer-events none
      color font-black
      text-decoration none
  .contact
    border-bottom 1px solid border-light-gray
    +mediaSp()
      margin 0 -10px
      border 0
      padding 10px 0
    li
      padding 20px
      border-top 1px solid border-light-gray
      margin-bottom 0px
      text-indent 0
      &::before
        content none
      +mediaSp()
        padding 10px 30px
        &:first-child
          border 0
