.m__navi_list
  .nav_list_ttl
    svg
      width 342px
      height 42px
      fill #3b8e6b
      -webkit-transition 0.3s
      transition 0.3s
      display block
      margin 0 auto
      +mediaSp()
        width 170px
        height 21px
  &__busNavi__inner
    margin 0 auto 0
    background #f0f0f0
    .descArea
      padding 50px 0
      background #333
      color #fff
      text-align center
      font-size 32px
      +mediaSp()
        font-size 16px
        padding 15px 0
      span
        display block
        font-size 26px
        +mediaSp()
          font-size 13px
    img
      display block
      margin 0 auto
      padding-top 100px
      width 400px
      +mediaSp()
        padding-top 33px
        width 80%
    &__school__link__Area
      width 860px
      display flex
      flex-wrap wrap
      justify-content space-between
      margin 0 auto
      &--pt110--pb65
        padding-top 110px
        padding-bottom 130px
        +mediaSp()
          padding-top 30px
          padding-bottom 100px
      +mediaSp()
        width 100%
      .m__common-btn__arrow
        width 400px
        margin-bottom 20px
        +mediaSp()
          width 100%
          margin-bottom 0
        &.futako
          a
            width 100%
            text-align left
            padding-left 11%
            +mediaSp()
              padding-left 15%
        &.akitsu
          a
            width 100%
            text-align left
            padding-left 11%
            +mediaSp()
              padding-left 15%
        &.shakujii
          a
            width 100%
            text-align left
            padding-left 11%
            +mediaSp()
              padding-left 15%
        &.seijo
          a
            width 100%
            text-align left
            padding-left 11%
            +mediaSp()
              padding-left 15%
        &.yokohama
          a
            width 100%
            text-align left
            padding-left 11%
            +mediaSp()
              padding-left 15%
      &--center
        justify-content center
