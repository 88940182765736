.m__common-btn
  &__arrow
    a
      display inline-block
      background bg-black
      color #fff
      font-size 1.6rem
      padding 10px
      min-width 200px
      text-align center
      +mediaSp()
        font-size 1.4rem
      &.m__common__flex-arrow
        padding 16px 60px 16px 50px
        &::before, &::after
          background #fff
          transition width .3s, right .3s !important
        &::before
          width 24px
          right 35px
          bottom 26px
        &::after
          height 9px
          right 38px
          bottom 25px
        +mediaPc()
          &:hover
            color #fff
            &::before
              right 25px
              width 34px
            &::after
              right 28px
    &--blue
      a
        background bg-green02
        +mediaPc()
          &:focus
            color font-light-green
    &--red
      a
        background bg-vivid-red
        +mediaPc()
          &:focus
            color font-light-green
    &--school
      a.m__common__flex-arrow
        color font-black
        padding 16px 100px 16px 30px
        font-size 2.4rem
        +mediaSp()
          font-size 1.6rem
          padding-right 30px
          &:focus
            color font-gray
        &::before, &::after
          background bg-black
        &::before
          width 43px
          bottom 34px
          +mediaSp()
            width 22px
            bottom 26px
            right 50%
            transform translateX(120px)
        &::after
          height 12px
          bottom 33px
          +mediaSp()
            height 8px
            bottom 25px
            right 50%
            transform translateX(118px)rotate(-45deg)
        &:hover
          color font-gray
          &::before, &::after
            background font-gray
          &::before
            width 53px
            +mediaSp()
              width 22px
    &.futako
      a
        background bg-futako
        &:hover
          background bg-light-futako
        +mediaSp()
          -webkit-tap-highlight-color:rgba(0,0,0,0);
          cursor:pointer;
          &:focus
            background bg-light-futako
    &.seijo
      a
        background bg-seijo
        &:hover
          background bg-light-seijo
        +mediaSp()
          -webkit-tap-highlight-color:rgba(0,0,0,0);
          cursor:pointer;
          &:focus
            background bg-light-seijo
    &.shakujii
      a
        background bg-shakujii
        &:hover
          background bg-light-shakujii
        +mediaSp()
          -webkit-tap-highlight-color:rgba(0,0,0,0);
          cursor:pointer;
          &:focus
            background bg-light-shakujii
    &.akitsu
      a
        background bg-akitsu
        &:hover
          background bg-light-akitsu
        +mediaSp()
          -webkit-tap-highlight-color:rgba(0,0,0,0);
          cursor:pointer;
          &:focus
            background bg-light-akitsu
    &.yokohama
      a
        background bg-yokohama
        &:hover
          background bg-light-yokohama
        +mediaSp()
          -webkit-tap-highlight-color:rgba(0,0,0,0);
          cursor:pointer;
          &:focus
            background bg-light-yokohama
    &--senior
      a
        background #3b8e6b
        width 400px
        &:hover
          background #66bb99
        +mediaSp()
          width 100%
          -webkit-tap-highlight-color:rgba(0,0,0,0);
          cursor:pointer;
          &:focus
            background 66bb99
        &.m__common__flex-arrow
          font-size 2.4rem
          text-align left
          +mediaSp()
            font-size 1.6rem
            padding-right 30px
            margin-bottom 2px
          &::before
            width 43px
            bottom 34px
            +mediaSp()
              width 22px
              bottom 26px
              right 50%
              transform translateX(120px)
          &::after
            height 12px
            bottom 33px
            +mediaSp()
              height 8px
              bottom 25px
              right 50%
              transform translateX(118px)rotate(-45deg)
          &:hover
            &::before
              width 53px
              +mediaSp()
                width 22px
