.m__form
  font-size 1.6rem
  padding 90px 20px 140px
  .m__common-form__selectbox.field_wrap
    display inline-block
  .field_wrap
    display block
    align-items baseline
  +mediaSp()
    font-size 1.5rem
    padding 50px 10px
    .m__common-form__selectbox--large.field_wrap
      display block!important
  .telnumber
    +mediaPc()
      pointer-events none
      color font-black
      text-decoration none
    +mediaSp()
      medium()
  dl
    display flex
    align-items baseline
    margin-bottom 50px
    +mediaSp()
      display block
      margin-bottom 30px
    +mediaPc()
      &.item-long
        flex-wrap wrap
        dt
          margin-bottom 20px
        dd
          margin-left 270px
    &.headhing
      flex-wrap wrap
      .ttl
        width 100%
        color font-green02
        margin-bottom 10px
        +mediaSp()
          margin-bottom 5px
    &.age
      dd
        input
          margin-right 10px
    &.birthday
      dd
        span
          margin 0 15px 0 10px
          &.date
            margin-right 0
        +mediaSp()
          display flex
          align-items center
          justify-content space-between
          flex-wrap wrap
          span
            margin 0 5px
          .m__common-form__selectbox
            flex 1
            select
              width 100%
    &.postal-code
      .m__common-form__input--medium
        input
          width 150px
          margin-right 20px
          +mediaSp()
            width 200px
            margin-right 5px
    &.phone
      dd
        display flex
        align-items center
        flex-wrap wrap
        span
          margin 0 15px
        +mediaSp()
          width 100%
          justify-content space-between
          span
            margin 0 3px
            display inline
          .m__common-form__input--mini
            white-space nowrap
            input
              max-width 97px
              width 100%
    &.text
      align-items flex-start
    &.occasion
      dt
        margin-bottom 0
    &.personal-information
      dd
        .link
          margin-bottom 15px
        +mediaSp()
          .link
            font-size 1.3rem
            margin-left 5px
    &.possession_license
      dd
        +mediaPc()
          .not_have
            padding-right 20px
          .learning
            display block
            padding-top 10px
        +mediaSp()
          .same-checkbox-pad
            padding 10px 20px 10px
            display block
    dt
      min-width 270px
      +mediaPc()
        line-height 1
      +mediaSp()
        width 100%
        margin-bottom 5px
        span
          font-size 1.3rem
      span
        font-size 1.2rem
        color font-gray
        vertical-align baseline
        margin-left 3px

    dd
      width 770px
      +mediaSp()
        width 100%
        .m__common-form__checkbox, .m__common-form__radiobutton
          min-width 150px
          padding 10px 20px 10px
      p
        margin-bottom 5px
        +mediaSp()
          font-size 1.4rem
      .text
        padding-left 20px
      .notice
        font-size 1.4rem
        font-weight normal
      &.flex
        +mediaPc()
          display flex
          align-items center
          flex-wrap wrap
      ul
        +mediaPc()
          margin-top 10px
        .w100
          width 100%
          margin-bottom 20px
          +mediaSp()
            display none

  &__btn
    margin 0 0 0 550px
    +mediaSp()
      margin 0 -10px
      display block
    .m__common__flex-arrow
      padding-right 0
      +mediaSp()
        width 100%
        display block
      &::before,&::after
        display none
      input
        display inline-block
        color #fff
        font-size 1.6rem
        padding 16px 60px 16px 50px
        min-width 200px
        cursor pointer
        inputReset()
        medium()
        line-height 1.75
        background  bg-black url(image_path+'/common/icon_btn_arrow_right.svg') no-repeat bottom 27px right 35px / 26px 9px
        margin 0 auto
        +mediaPc()
          &:hover
            background  bg-green02 url(image_path+'/common/icon_btn_arrow_right.svg') no-repeat bottom 27px right 35px / 26px 9px
            transition .3s linear
        +mediaSp()
          font-size 1.5rem
          width 100%
          padding 16px 40px
          background  bg-black url(image_path+'/common/icon_btn_arrow_right.svg') no-repeat bottom 27px right 33% / 26px 9px
          &:active
            background  bg-green02 url(image_path+'/common/icon_btn_arrow_right.svg') no-repeat bottom 27px right 33% / 26px 9px
            transition .3s linear
      input[name="submitBack"]
        padding 16px 40px 16px 60px
        margin 0 auto
        background  bg-black url(image_path+'/common/icon_btn_arrow_left.svg') no-repeat bottom 27px left 35px / 26px 9px
        +mediaPc()
          &:hover
            background  bg-green02 url(image_path+'/common/icon_btn_arrow_left.svg') no-repeat bottom 27px left 35px / 26px 9px
        +mediaSp()
          margin 0 0 15px 0
          padding 16px 40px
          background  bg-black url(image_path+'/common/icon_btn_arrow_left.svg') no-repeat bottom 27px left 33% / 26px 9px
          &:active
            background  bg-green02 url(image_path+'/common/icon_btn_arrow_left.svg') no-repeat bottom 27px left 33% / 26px 9px

  &--preview
    font-size 1.6rem
    padding 100px 0 140px
    +mediaSp()
      padding 25px 10px 50px
      font-size 1.5rem
    dl.possession_license
      dd
        .has,.learning
          display block
        +mediaSp()
          .same-checkbox-pad
            padding 0
            display inline-block
    dd
      font-weight 400
      padding-left 20px
    .m__form__btn
      margin 0
      +mediaSp()
        margin 0 -10px
      +mediaPc()
        .m__common__flex-arrow
          display flex
          justify-content space-between
          max-width 540px
          margin 0 auto
      &--entry
        +mediaSp()
          margin 0 -10px
        +mediaPc()
          display flex
          justify-content space-between
          max-width 540px
          margin 0 auto
  &--thanks
    font-size 1.6rem
    padding 100px 0 375px
    +mediaSp()
      padding 25px 10px 175px
      font-size 1.5rem
    p
      margin-bottom 30px
      regular()
      b
        medium()
      +mediaSp()
        font-size 1.3rem

  &__error-text
    color font-vivid-red
    width 100%
    display block

  &__entry
    .m__common-form__input
      span
        min-width 40px
        display inline-block
        vertical-align middle
    .p-country-name
      display none
    .name,.name-kana
      dd
        .m__common-form__input
          +mediaPc()
            &:nth-of-type(1)
              margin-right 25px
          +mediaSp()
            display flex
            flex-wrap wrap
            align-items center
            &:nth-of-type(1)
              margin-bottom 30px
            .field_with_errors,input
              flex 1
        &.flex
          align-items baseline
        .field_with_errors
          display inline-block
        .field_wrap
          display inline-block
    .address
      dd
        +mediaPc()
          .m__common-form__selectbox
            margin-right 15px
        .m__common-form__input--full
          flex 1
        +mediaSp()
          .m__common-form__selectbox
            &--medium
              margin-bottom 30px
              width 120px
    .address02
      .bold-red
        color font-red
        medium()
        +mediaPc()
          margin-top -28px
    .menu
      +mediaPc()
        .m__common-form__radiobutton
          min-width 130px

  &__description
    regular()
    +mediaSp()
      font-size 1.3rem
    .telnumber
      +mediaPc()
        pointer-events none
        color font-black
        text-decoration none
      +mediaSp()
        medium()
    .text
      padding 20px
      border-bottom 1px solid border-black
      +mediaSp()
        padding 10px
      + .text
        border 0
    .notice
      medium()
      &.indent
        p
          regular()
          padding-left 1em
          text-indent -1em
      &.emp
        font-weight bold
        color font-red
    .red
      color font-red
    .nesessary
      padding 20px
      +mediaSp()
        padding 15px 10px 0
