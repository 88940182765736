.m__poster
  &__introduction
    background bg-header
    text-align center
    padding 40px 0 40px
    margin-top 100px
    color #fff
    +mediaSp()
      margin-top 30px
      padding 13px 5px
    h3
      font-size 3.2rem
      medium()
      +mediaSp()
        font-size 1.6rem
    p
      font-size 2.6rem
      +mediaSp()
        font-size 1.3rem
      span
        color font-green
        font-size 2.9rem
        display block
        +mediaSp()
          font-size 1.6rem

  &__button-area
    padding 120px 0 150px
    text-align center
    .m__common-btn__arrow
      .m__common__flex-arrow
        width 260px
    +mediaSp()
      padding 50px 0
    .m__common-btn__arrow--back
      .m__common__flex-arrow
        padding 16px 40px 16px 70px
        &::before,&::after
          transition width 0.3s, left 0.3s !important
          right auto
        &::before
          left 35px
        &::after
          left 38px
          transform rotate(45deg)
        +mediaPc()
          &:hover
            &::before
              left 25px
              right auto
            &::after
              left 28px
              right auto
        +mediaSp()
          &::before
            left 45px
          &::after
            left 48px
            transform rotate(45deg)
          &:active
            background bg-green02
            color #fff
            transition 0.3s linear !important
  &__menu
    &.pc
      +mediaSp()
        display none
      padding-top 40px
      display flex
      li
        font-size 2.4rem
        color font-green
        margin-right 40px
        alphabet()
    &.sp
      +mediaPc()
        display none
      padding-top 40px
      box-sizing border-box
      margin-bottom 30px
      dt
        alphabet()
        font-size 1.3rem
      dd
        margin-bottom 10px
        img
          width 100%
  &__notice
    text-align right
    &.sp
      font-size 1.2rem
      +mediaPc()
        display none
    &.pc
      +mediaSp()
        display none

  &__content
    padding-bottom 100px
    +mediaSp()
      margin 0 -10px
      padding-bottom 0
    td
      +mediaPc()
        &:not(:first-child)
          padding-left 27px
        padding-top 30px
      p
        color font-green
        font-size 1.2rem
        text-align right
        position relative
        padding 5px 0
        +mediaSp()
          padding 0
        span
          position absolute
          left 0
          top 0
          font-size 1.6rem
          alphabet()
          color font-black
          +mediaSp()
            font-size 1.3rem
      +mediaSp()
        display inline-block
        width 50%
        padding 10px
        box-sizing border-box
        position:relative
        vertical-align bottom
        img
          width 100%
          height auto