.m__stay
  &__description
    margin-top 95px
    background bg-header
    font-size 2.6rem
    color #fff
    padding 40px 0 50px
    +mediaSp()
      padding 15px 0 20px
      margin-top 30px
    h3
      font-size 3.2rem
      medium()
      text-align center
      margin-bottom 5px
      +mediaSp()
        font-size 1.6rem
    p
      +mediaPc()
        width 1080px
      margin 0 auto
      line-height 1.4
      text-align center
      +mediaSp()
        font-size 1.3rem
  &__mv
    width 100%
    height 60vw
    background url(image_path+'/form/stay_mv.jpg') center top no-repeat
    background-size 110%
    +mediaSp()
      background-size cover
  &__sub-mv
    width 100%
    height 33.3vw
    background url(image_path+'/form/stay_mv2.jpg') center top no-repeat
    background-size 110%
    +mediaSp()
      background-size cover
  &__overview
    background bg-light-gray
    padding 65px 0
    font-size 1.6rem
    +mediaSp()
      padding 20px 0
      font-size 1.3rem
    .l__contents__inner
      padding 0 25px
      +mediaSp()
        padding 0 10px
  &__point
    width 1080px
    margin 0 auto
    border-bottom 1px solid border-light-gray
    padding 50px 0 40px
    +mediaSp()
      width 100%
      padding 20px 10px
    ul
      max-width 800px
      margin 0 auto
      li
        font-size 2.6rem
        max-width 800px
        medium()
        position relative
        margin-bottom 10px
        +mediaSp()
          font-size 1.5rem
          margin-bottom 0
          text-indent -18px
          padding-left 18px
        span
          regular()
          font-size 1.6rem
          display block
          padding-left 40px
          +mediaSp()
            font-size 1.3rem
            display inline-block
            padding-left 20px
            vertical-align baseline
        &::before
          content ""
          display inline-block
          width 30px
          height 30px
          background-color bg-light-green
          border-radius 50%
          vertical-align -5px
          margin-right 10px
          +mediaSp()
            width 15px
            height 15px
            vertical-align -3px
        &::after
          content ""
          display inline-block
          width 12px
          height 6px
          border-left 3px solid #fff
          border-bottom 3px solid #fff
          position absolute
          left 8px
          top 16px
          transform rotate(-45deg)
          +mediaSp()
            height 3px
            width 6px
            border-width 2px
            left 3.5px
            top 10px

  &__table
    padding-top 50px
    max-width 750px
    margin 0 auto
    +mediaSp()
      padding-top 20px
    h4
      font-size 1.8rem
      medium()
      &::before
        content '■'
        color bg-light-green
      +mediaSp()
        font-size 1.3rem
    table
      border 2px solid border-black
      margin-bottom 50px
      +mediaSp()
        width 100%
        table-layout fixed
        border 1px solid border-black
        border-left 0
        border-right 0
        margin-bottom 30px
      tr
        th,td
          height 60px
          text-align center
          font-size 1.8rem
          vertical-align middle
          medium()
          width 180px
          &:not(:first-child)
            border-left 1px solid border-black
          +mediaSp()
            font-size 1.3rem
            height 30px
            width 22%
            &:last-child
              width 30%
        &:not(:last-child)
          border-bottom 1px solid border-black
        th
          background bg-gray02
        .gray
          background bg-light-gray
        p
          text-align left
          display inline-block
          +mediaSp()
            padding 9px 0
  &__notice
    width 1080px
    border-bottom 1px solid border-light-gray
    margin 0 auto
    padding-bottom 90px
    +mediaSp()
      width 100%
      padding 0 10px 30px
    ul
      max-width 750px
      margin 20px auto 0
      li
        regular()
        text-indent -1em
        padding-left 1em
        &::before
          content "・"
  &__hotel
    width 1080px
    margin 0 auto
    padding 60px 0 90px
    +mediaSp()
      width 100%
      padding 30px 0
    .info
      display flex
      justify-content center
      font-size 1.6rem
      regular()
      padding-top 50px
      +mediaSp()
        display block
        font-size 1.3rem
        padding 30px 10px 10px
      .text
        b
          medium()
        +mediaPc()
          margin-right 80px
      .img
        img
          max-width 100%
          +mediaSp()
            padding 15px 0
      a.telnumber
        +mediaPc()
          pointer-events none
          color font-black
          text-decoration none
        +mediaSp()
          medium()
  &__contact
    padding-top 50px
    +mediaSp()
      padding-top 30px
      font-size 1.3rem
    .m__form__description
      .tel-number
        medium()
        padding 20px
        border-bottom 1px solid border-light-gray
        +mediaSp()
          padding 10px
        a
          font-size 2.6rem
          vertical-align baseline
          padding-left 10px
          +mediaPc()
            color font-black
            pointer-events none
            text-decoration none
          +mediaSp()
            font-size 1.5rem
      .text
        border-bottom 0
        padding 20px 20px 0
        +mediaSp()
          padding 10px 0 0
    .m__form
      +mediaSp()
        margin 0
