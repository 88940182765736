.m__footer
  background bg-footer
  position relative
  z-index 1
  +mediaPc()
    .l__contents__inner
      transform translateY(-40px)
      transition transform .5s ease-out
      width 1060px
      padding-left 30px
      padding-right 30px
    .m__footer__menu--pc
      opacity 0
      transition opacity .5s ease-out

    &.fade-in
      .l__contents__inner
        transform translateY(0px)
        transition transform .8s ease-out
      .m__footer__menu--pc
        opacity 1
        transition opacity .5s ease-in
  +mediaPc()
    padding-top 5%
  .l__contents__inner
    +mediaSp()
      padding 0
  &--english
    background none
    padding 0
    alphabet()
    regular()
  &__menu
    &--pc
      display flex
      justify-content space-between
      padding-bottom 10px
      position relative
      +mediaSp()
        display none
      > ul
        width 23%
        &.first
          width 15%
        &.last
          width 10%
        > li
          padding-top 44px
          &.about
            padding-top 20px
          a, p
            color #fff
          a
            text-decoration underline
            &:hover
              color font-green

          ul
            li
              margin-top 10px
            &.left
              li
                padding-left 20px
                regular()
      &.seijo
        padding-bottom 120px
      &.yokohama
        padding-bottom 70px
      .link-list
        position absolute
        right 0
        bottom 0
        display flex
        justify-content space-between
        width 500px
        flex-wrap wrap
        &.akitsu
          bottom 20px
        li
          padding 0
          &:nth-child(n+3)
            margin-top 20px
          a
            display block
            width 220px
            padding 12px 0
            border 1px solid #fff
            text-align center
            text-decoration none
            +mediaPc()
              &:hover
                background font-green
                color #fff
    &--sp
      +mediaPc()
        display none
      > ul
        > li
          &:first-child
            background bg-black
          > ul
            background bg-header
        li
          a, p
            display block
            padding 15px 40px 15px 20px
            font-size 1.5rem
            color #fff
            text-decoration none
            &.line-height
              line-height 1.3
            &:active
              background bg-blue
          &.submenu
            position relative
            &.student
              background main-color
              &::before, &::after
                background bg-black
              a, p
                color font-black
              div
                background main-color
            > a
              pointer-events none
            &::before
              content ""
              width 1px
              height 11px
              background #fff
              display inline-block
              position absolute
              color #fff
              font-size 1.5rem
              right 25px
              top 22px
              transition .3s
            &::after
              content ""
              width 11px
              height 1px
              background #fff
              display inline-block
              position absolute
              color #fff
              font-size 1.5rem
              right 20px
              top 27px
              transition .3s
            &.open
              &::before
                transform rotate(45deg)
                transition .3s
              &::after
                transform rotate(45deg)
                transition .3s
            > div
              display none
              background bg-header02
              a
                padding-left 60px


  &__icon-link
    border-top 1px solid #fff
    padding 35px 0
    ul
      display flex
      justify-content space-between
      align-items center
      width 500px
      margin 0 auto
      +mediaSp()
        width 295px
        li
          img
            width 30px
          &:last-child
            img
              width 45px

  &__copyright
    background bg-footer02
    +mediaSp()
      .career &
        padding-bottom 58px
      .recruit &
        padding-bottom 58px
    p
      text-align center
      color #fff
      padding 18px 0
      +mediaSp()
        font-size 1.2rem
        padding 20px 0

  &__description
    color #fff
    padding 30px 0
    text-align center
    font-size 1.4rem
    border-top 1px solid #fff
    +mediaSp()
      text-align left
      padding 20px
      font-size 1.3rem
    p
      span
        +mediaPc()
          padding-left 30px
        +mediaSp()
          display inline-block
    .telnumber
      +mediaPc()
        color #fff
        text-decoration none
        pointer-events none