.m__faq
  &__title
    +mediaSp()
      .alphabet
        font-size 2rem
  &__nav
    margin 90px 0 80px
    font-size 1.6rem
    background bg-light-gray
    padding 40px 0
    display flex
    justify-content center
    +mediaSp()
      flex-wrap wrap
      margin 20px -10px 50px
      padding 25px 0
    li
      margin-right 105px
      a
        margin-left 3px
      &.sp
        display none
        +mediaSp()
          display block
          width 100%
          &::before
            display none
      &::before
        content '▼'
        color font-green
        font-size 1.1rem
        +mediaSp()
          font-size 0.8rem
      &:last-child
        margin-right 0
      +mediaSp()
        margin-right 0
        margin-left 20px
        width 130px
        font-size 1.4rem
        &:nth-child(-n+2)
          margin-bottom 20px
        &:nth-child(1),&:nth-child(4)
          margin-left 0

  &__contents
    h2
      text-align left
    a.telnumber
      +mediaPc()
        pointer-events none
        color font-black
        text-decoration none
    +mediaSp()
      margin 0 -5px
    &#students
      margin-top -60px
      padding-top 60px;
  &__accordion
    margin 30px 0 80px
    +mediaSp()
      margin 10px 0 50px
    > li
      position relative
      cursor pointer
      margin-bottom 30px
      &.anchor
        padding-top 55px
        margin-top -56px
      +mediaSp()
        margin-bottom 15px
      .title
        font-size 1.6rem
        padding 34px 60px 34px 20px
        color font-green
        border 2px solid bg-green02
        position relative
        display block
        transition .3s
        span
          margin-right 3px
        +mediaSp()
          padding 17px 35px 17px 23px
          font-size 1.3rem
          text-indent -1.3em
          position relative
          z-index 1
        &:hover
          color #fff
          .js-trigger
            color #fff
        &.open
          color #fff
          background bg-green02
          .js-trigger
            transform rotate(45deg)
            color #fff
            +mediaSp()
              top 29px
              right 9px
        &::before
          position absolute
          top 0
          right: 0
          bottom 0
          left 0
          z-index -1
          content ''
          background bg-green02
          transform-origin left top
          transform scale(1, 0)
          transition transform .3s
        &:hover::before
          transform-origin left bottom
          transform scale(1, 1)

        .js-trigger
          position absolute
          right 35px
          top 35px
          font-size 1.8rem
          transition .3s
          color font-green
          +mediaSp()
            right 5px
            font-size 1.4rem
            top 16px

      .accordion-content
        padding 30px 60px 0
        margin 0 0 40px
        display none
        text-indent -20px
        font-size 1.6rem
        +mediaSp()
          padding 20px 10px 0 25px
          font-size 1.3rem
          text-indent -1.3em
        span
          color font-red
          margin-right 3px
          &.red
            margin-right 0
        .flex
          display flex
        ul
          li
            padding-left 1em
            text-indent -1em
            margin-bottom 10px
        p
          padding-left 1em
          text-indent -1em

  &__contact
    text-align center
    padding 60px 0
    +mediaSp()
      margin 0 -10px
      padding 50px 0
    .m__common-btn__arrow
      margin 60px 0 90px
      +mediaSp()
        margin 30px 0 0
      .m__common__flex-arrow
        width 320px
        padding 16px 80px 16px 50px
        &::before
          right 90px
          +mediaSp()
            right auto
            left 50%
            margin-left 35px
        &::after
          right 92px
          +mediaSp()
            right auto
            left 50%
            margin-left 55px
        +mediaPc()
          &:hover
            &::before
              right 80px
            &::after
              right 82px
        +mediaSp()
          width 100%
          &:active
            background bg-green02
            transition .3s
            &::before
              width 24px
            &::after
              margin-left 55px
