.m__common-heading
  &__alphabet
    bold()
    font-size 4rem
    font-family fontset-alphabet
    opacity 0
    transform translateX(-30px)
    transition transform 1s, opacity 1s
    &.show
      transform translateX(0)
      span
        opacity 1
    span
      display inline-block
      opacity 0
      for i in 0..100
        &:nth-child({i*1})
          transition transform 0.5s ease (0.1 * i)s, opacity 0.5s ease (0.1 * i)s
    +mediaSp()
      font-size 2rem

  &__title
    text-align center
    font-size 3.2rem
    gothic()
    .alphabet
      alphabet()
      font-size 4rem
    .chinese
      chinese()
      bold()
    &.red
      color font-red
    span
      font-size 1.6rem
      vertical-align baseline
      +mediaSp()
        font-size 1.3rem
      &.middle
        font-size 2.6rem
        +mediaSp()
          font-size 1.3rem
      &.middle2
        font-size 2.4rem
        +mediaSp()
          font-size 1.2rem
      &.regular
        regular()
    +mediaSp()
      font-size 1.8rem
      .alphabet
        font-size 2rem
    &--left
      text-align left
      &.elder
        +mediaSp()
          padding-left 10px
          font-size 2.5rem
    &--two-lines
      line-height 1.2
    &--two-lines-indent
      padding-left 4rem
      text-indent -4.1rem
      line-height 1.2
      +mediaSp()
        padding-left 3rem
        text-indent -2rem
        font-size 2.5rem
  &__title02
    text-align center
    font-size 3.2rem
    line-height 1.3
    gothic()
    .alphabet
      alphabet()
      font-size 2.4rem
      display block
      font-weight 400
    +mediaSp()
      font-size 1.8rem
      .alphabet
        font-size 1.2rem
  &__title03
    text-align center
    font-size 3.2rem
    line-height 1.4
    gothic()
    span
      font-size 1.6rem
      display block
      &.middle
        font-size 2rem
        +mediaSp()
          font-size 1.3rem
    .alphabet
      font-size 2.6rem
      letter-spacing 0.2em
      +mediaSp()
        font-size 1.3rem
    +mediaSp()
      font-size 1.8rem
      span
        font-size 1.3rem

  &__check
    padding-left 40px
    font-size 2.6rem
    position relative
    medium()
    line-height 30px
    +mediaSp()
      font-size 1.5rem
      padding-left 20px
      line-height 15px
    &::before,&::after
      content ''
      display block
      position absolute
    &::before
      width 30px
      height 30px
      border-radius 50%
      background #66bb99
      left 0
      top 0
      +mediaSp()
        width 15px
        height 15px
    &::after
      border-right 3px solid #fff
      border-bottom 3px solid #fff
      width 8px
      height 14px
      transform rotate(45deg)
      left 9px
      top 4px
      +mediaSp()
        width 3px
        height 7px
        left 5px
        top 2px
        border-right 2px solid #fff
        border-bottom 2px solid #fff
    &--plan__society
      &::before
        top 20px
        +mediaSp()
          top 0
      &::after
        top 24px
        +mediaSp()
          top 2px
  &__mid-title
    medium()
    font-size 2.6rem
    +mediaSp()
      font-size 1.6rem
  &__english
    text-align left
    font-size 2.6rem
    bold()
    +mediaSp()
      font-size 1.6rem
    &--green
      color font-green02
  &__underline
    text-align left
    font-size 2.6rem
    font-weight 500
    border-bottom 1px solid #ccc
    margin-bottom 30px
    span
      padding 0 0 10px 20px
    +mediaSp()
      font-size 1.5rem
      margin-bottom 10px
      span
        padding-left 0
  &__info-text
    padding-top 60px
    width 800px
    margin 0 auto
    +mediaSp()
      padding-top 30px
      width 100%
    .text
      text-align center
      border 3px solid #f08066
      color #f08066
      font-size 2.6rem
      padding 20px 10px
      +mediaSp()
        font-size: 1.3rem;
        border 2px solid #f08066
        padding 10px 5px
