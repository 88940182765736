/* ==========================================================================*/
/* layout */
/* ==========================================================================*/

.l__contents
  &__wrapper
    position relative
    &--gray
      background bg-light-gray
    &--lower
      margin-top 140px
      +mediaSp()
        margin-top 30px
    &--english
      margin-top 220px
      +mediaSp()
        margin-top 30px
    &--brdr-btm
      border-bottom 1px solid #ccc
  &__inner
    width 950px
    padding 0 40px
    margin 0 auto
    +mediaSp()
      width 100%
      padding 0 10px
    &--pd__spNone
      +mediaSp()
        padding 0
    &--width
      width 1160px
      padding 0 40px
      margin 0 auto
      +mediaSp()
        width 100%
        padding 0 10px
      &--gray__pcNone
        +mediaSp()
          background bg-light-gray
      &--pd__spNone
        +mediaSp()
          padding 0
  &__fade-in
    opacity 0
    transition 1.2s
    transform translate3d(0, 60px, 0)
    .m__common__flex-arrow
      &::before, &::after
        opacity 0
        transition width .3s, right .3s, opacity .8s 1.5s
    +mediaSp()
      transform translate3d(0, 30px, 0)
    &.topdown
      opacity 0
      transition transform 1.2s, opacity 1.2s
      transform translate3d(0, -60px, 0)
      +mediaSp()
        transform translate3d(0, -30px, 0)
    &.fade-in
      opacity 1
      transition transform 1.2s, opacity 1.2s
      transform translate3d(0, 0, 0)
      .m__common__flex-arrow
        &::before, &::after
          opacity 1
          transition width .3s, right .3s, opacity .8s 1.5s
  &__fade-in--scroll
    opacity 0
    transform translate3d(0, 60px, 0)
    transition 1.2s
    +mediaSp()
      transform translate3d(0, 30px, 0)
    &.fade-in
      opacity 1
      transform translate3d(0, 0, 0)
      transition opacity 1.2s
/* ==========================================================================*/
/* margin padding system */
/* ==========================================================================*/

for i in 0..10
  .mt{i*5}
    margin-top 5px * i !important
  .mb{i*5}
    margin-bottom 5px * i !important
  .pt{i*5}
    padding-top 5px * i !important
  .pb{i*5}
    padding-bottom 5px*i !important

.mt30-15
  mt-h(30px)
.pt30-15
  pt-h(30px)
.mb30-15
  mb-h(30px)
.pb30-15
  pb-h(30px)
.pb30-05
  pb(30px,5px)

.mt40-20
  mt-h(40px)
.pt40-20
  pt-h(40px)
.mb40-20
  mb-h(40px)
.pb40-20
  pb-h(40px)
.pt40-0
  pt(40px,0)

.mt40-30
  mt(40px,30px)
.pt40-30
  pt(40px,30px)
.mb40-30
  mb(40px,30px)
.pb40-30
  pb(40px,30px)

.mt50-30
  mt(50px,30px)
.pt50-30
  pt(50px,30px)
.mb50-30
  mb(50px,30px)
.pb50-30
  pb(50px,30px)
.mb50-20
  mb(50px,20px)

.mt60-30
  mt-h(60px)
.pt60-30
  pt-h(60px)
.mb60-30
  mb-h(60px)
.pb60-30
  pb-h(60px)

.mt80-40
  mt-h(80px)
.pt80-40
  pt-h(80px)
.mb80-40
  mb-h(80px)
.pb80-40
  pb-h(80px)

.mt100-30
  mt(100px,30px)
.pt100-30
  pt(100px,30px)
.mb100-30
  mb(100px,30px)
.pb100-30
  pb(100px,30px)
