.m__english__company
  padding 100px 0 150px
  +mediaSp()
    padding 30px 0 50px
  table
    width 750px
    margin 0 auto
    border 1px solid border-black
    +mediaSp()
      width 100%
      border-left 0
      border-right 0
    tr
      &:not(:first-child)
        border-top 1px solid border-black
    th,td
      font-size 1.6rem
      padding 15px
      vertical-align baseline
      word-break break-word
      +mediaSp()
        font-size 1.3rem
        padding 15px 10px
    th
      text-align left
      medium()
      background bg-light-gray
      +mediaSp()
        width 30%
    td
      regular()
  .telnumber
    margin-left 0.5em
    +mediaPc()
      pointer-events none
      color font-black
      text-decoration none
