.m__access__lower
  &__map
    position relative
    padding-bottom 100px
    +mediaSp()
      margin 0 -0 50px
      padding-bottom 0
    .map
      text-align center
      position relative
      +mediaSp()
        padding-left 0
      iframe
        width 100%
        height 800px
        margin-bottom 20px
        +mediaPc()
          pointer-events none
        +mediaSp()
          height 400px
      .scroll-overview
        display none
        position absolute
        left 60px
        top 0
        width calc(100% - 60px)
        height 55vw
        background rgba(bg-black, 0.5)
        p
          position absolute
          left 50%
          top 50%
          transform translate(-50%, -50%)
          font-size 2.6rem
          color #fff
  &__notice
    font-size 1.6rem
    width 750px
    margin 0 auto
    +mediaSp()
      font-size 1.3rem
      width 100%
    ul
      li
        padding-left 20px
        text-indent -20px
        &:before
          content '・'
          padding-right 3px

  &__schoolInfo
    background bg-light-gray
    text-align center
    padding 60px 0
    +mediaSp()
      padding 30px 10px 48px
    dl
      font-size 1.6rem
      display flex
      flex-wrap wrap
      justify-content space-between
      width 820px
      margin 0 auto
      +mediaSp()
        width 100%
      dt
        font-size 2.6rem
        width 100%
        padding-bottom 10px
        +mediaSp()
          font-size 1.6rem
      dd
        font-size 1.6rem
        text-align left
        text-indent -20px
        padding-left 20px
        +mediaSp()
          font-size 1.3rem
        &.listWide
          &:before
            content '●'
            color main-color
            padding-right: 3px;
        ul
          li
            &:before
              content '●'
              color main-color
              padding-right: 3px;
          span
            padding-left 15px

