.m__training
  &__description
    margin-top 95px
    background bg-black
    font-size 2.6rem
    color #fff
    padding 40px 0 50px
    +mediaSp()
      padding 15px 0 20px
      margin-top 30px
    h3
      font-size 3.2rem
      medium()
      text-align center
      margin-bottom 5px
      +mediaSp()
        font-size 1.6rem
    p
      +mediaPc()
        width 1080px
      margin 0 auto
      line-height 1.4
      +mediaSp()
        font-size 1.3rem
        text-align center

  &__mv
    width 100%
    height 60vw
    background url(image_path + '/form/training_mv.jpg') center top no-repeat
    background-size 110%
    +mediaSp()
      background-size cover
  &__sub-mv
    width 100%
    height 33.3vw
    background url(image_path + '/form/training_mv2.jpg') center top no-repeat
    background-size 110%
    +mediaSp()
      background-size cover
  &__overview
    background bg-light-gray
    padding 65px 0
    +mediaSp()
      padding 20px 0
    p
      padding 0 30px
      +mediaSp()
        padding 0
      &.icon-document
        +mediaPc()
          padding-left 60px
          background url(image_path + '/icon/icon_document.svg') left center no-repeat
          background-size 48px 55px
          font-size 1.6rem
        +mediaSp()
          text-align center
          font-size 1.3rem
        span
          font-size 2.6rem
          line-height 1.4
          text-align left
          +mediaSp()
            font-size 1.5rem
            display inline-block
            padding-left 30px
            background url(image_path + '/icon/icon_document.svg') left center no-repeat
            background-size 24px 27.5px
            margin-bottom 5px

  &__introduce
    padding-top 50px
    +mediaSp()
      padding-top 20px
    h3
      font-size 2.6rem
      medium()
      border-bottom 1px solid border-light-gray
      text-align center
      padding 0 0 20px
      +mediaSp()
        font-size 1.5rem
        margin 0 -10px
    .course-block
      padding 30px 20px 50px
      +mediaSp()
        padding 20px 10px
        margin 0 -10px
      + .course-block
        border-top 1px solid border-light-gray
      h4
        font-size 2.6rem
        medium()
        position relative
        +mediaSp()
          font-size 1.5rem
        span
          font-size 1.6rem
          vertical-align baseline
          +mediaSp()
            font-size 1.3rem
            display block
          a
            display inline-block
            margin-left 5px
            +mediaSp()
              margin-left 2px
        &::before
          content ""
          display inline-block
          width 30px
          height 30px
          background-color bg-light-green
          border-radius 50%
          vertical-align -5px
          margin-right 10px
          +mediaSp()
            width 15px
            height 15px
            vertical-align -3px
        &::after
          content ""
          display inline-block
          width 12px
          height 6px
          border-left 3px solid #fff
          border-bottom 3px solid #fff
          position absolute
          left 8px
          top 16px
          transform rotate(-45deg)
          +mediaSp()
            height 3px
            width 6px
            border-width 2px
            left 3.5px
            top 10px
      > p
        regular()
        margin 10px 0 20px
        +mediaSp()
          font-size 1.3rem
          margin 5px 0 20px
      .course-list
        display flex
        +mediaSp()
          display block
        .list-block
          +mediaPc()
            width 50%
            padding 15px 0 5px
            + .list-block
              border-left 1px solid border-light-gray
              padding-left 20px
          +mediaSp()
            + .list-block
              margin-top 20px
          .category
            regular()
          ul
            &.green
              li
                &::before
                  content "●"
                  color bg-seijo
            &.yellow
              li
                &::before
                  content "●"
                  color bg-futako
  &__address
    padding 35px 0 60px
    text-align center
    +mediaSp()
      padding 25px 0 30px
    h3
      medium()
      font-size 2.6rem
      +mediaSp()
        font-size 1.5rem
    ul
      +mediaPc()
        width 610px
      margin 15px auto 50px
      text-align left
      +mediaSp()
        margin 10px 0 30px
      li
        font-size 1.6rem
        regular()
        +mediaSp()
          font-size 1.3rem
          text-indent -7px
          padding-left 15px
        + li
          margin-top 10px
        span
          display inline-block
          width 320px
          medium()
          &::before
            content "●"
            color bg-light-green
          +mediaSp()
            width auto
    .m__common-btn__arrow
      +mediaSp()
        margin 0 -10px
        .m__common__flex-arrow
          width 100%
          &::before
            right 50%
            transform translateX(120px)
          &::after
            right 50%
            transform translateX(118px)rotate(-45deg)
  &__company
    padding 35px 0 60px
    border-top 1px solid border-light-gray
    margin 0 -10px
    +mediaSp()
      padding 25px 0 30px
    h3
      text-align center
      font-size 2.6rem
      vertical-align baseline
      medium()
      +mediaSp()
        font-size 1.5rem
      span
        font-size 1.6rem
        regular()
        vertical-align baseline
        +mediaSp()
          font-size 1.3rem
    p
      +mediaPc()
        width 900px
      margin 20px auto 0
      font-size 1.6rem
      regular()
      +mediaSp()
        font-size 1.3rem
        padding 0 10px

  &__contact
    padding-top 40px
    +mediaSp()
      padding-top 30px
      font-size 1.3rem
    .m__form__description
      .tel-number
        medium()
        padding 20px
        border-bottom 1px solid border-light-gray
        +mediaSp()
          margin 0 -10px
          padding 10px
        a
          font-size 2.6rem
          vertical-align baseline
          padding-left 10px
          +mediaPc()
            color font-black
            pointer-events none
          +mediaSp()
            font-size 1.5rem
      .text
        border-bottom 0
        padding 20px 20px 0
        +mediaSp()
          padding 10px 0 0
    .m__form
      margin 0 -10px
