.m__obtain
  &__introduction
    background bg-header
    text-align center
    padding 50px 0
    margin-top 100px
    color #fff
    +mediaSp()
      margin-top 30px
      padding 13px 15px
    h3
      color #fff
      margin-bottom 5px
      +mediaSp()
        font-size 1.6rem
    p
      font-size 2.6rem
      line-height 1.5
      +mediaSp()
        font-size 1.3rem
  &__detail
    margin 100px 0
    +mediaSp()
      margin 30px -10px
    p
      font-size 1.6rem
      border-bottom 1px solid border-light-gray
      padding 5px 20px
      regular()
    > dl
      border-bottom 1px solid border-light-gray
      padding 15px 20px
      +mediaPc()
        display flex
        align-items center
        &.condition
          align-items baseline
          &--h240
            position relative
            height 240px
      +mediaSp()
        &:first-of-type
          border-top 1px solid border-light-gray
        text-align center
      > dt
        font-size 1.8rem
        margin-right 20px
        width 340px
        +mediaSp()
          font-size 1.5rem
          width 100%
      > dd
        regular()
        font-size 1.6rem
        +mediaSp()
          font-size 1.4rem
          position relative
          padding-top 20px
          &::before
            position absolute
            content "▼"
            top 0
            left 50%
            transform translateX(-50%)
            font-size 1.4rem
            color font-red
        span
          vertical-align baseline
        .red
          font-size 2.4rem
          text-align left
          bold()
          color font-red
          margin-right 20px
          &::before
            content "▶"
            font-size 1.6rem
            vertical-align 3px
          +mediaSp()
            font-size 1.8rem
            text-align center
            margin-right 0
            &::before
              display none
        .notice
          +mediaSp()
            text-align left
        .notice-right
          position absolute
          width 420px
          right -45px
          border-bottom none
          text-indent -1em
          padding-left 1em
          +mediaSp()
            position relative
            width auto
            right 0
            font-size 14px
            text-indent 0
            padding 0
            border-bottom none
            text-align right
        dl
          display flex
          align-items center
          dt
            text-align right
            width 130px
            margin-right 5px
          &.left
            dt
              text-align left
            +mediaSp()
              dt
                text-align center
              dd
                min-width 0
          +mediaSp()
            align-items baseline
            justify-content center
            dd
             text-align left
             min-width 135px
            dt
              min-width 110px


  &__type
    padding 60px 0 120px
    +mediaSp()
      padding 30px 0 40px
      margin 0 -10px
    table
      border 2px solid border-black
      margin 60px auto 0
      +mediaSp()
        border-left none
        border-right none
        width 100%
        margin-top 30px
      tr
        &.headTtl
          th
            height 135px
            line-height 1
            +mediaSp()
              height auto
              font-size 1.4rem
            &.letter3
              line-height 2em
            &.letter4
              line-height 1.35em
        &:not(:first-child)
          border-top 1px solid border-black
      th,td
        background #fff
        font-size 1.8rem
        medium()
        vertical-align middle
        text-align center
        &:nth-child(even)
          background bg-light-gray
        &:not(:first-child)
          border-left 1px solid border-black
        +mediaSp()
          font-size 1.3rem
      th
        &.carType
          width 205px
          text-align left
          padding-left 10px
          +mediaSp()
            width 40%
      td
        width 87px
        height 60px
        color font-gray
        font-size 2.6rem
        +mediaSp()
          font-size 1.3rem
          height 30px
          width auto
