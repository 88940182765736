.m__common-form
  &__input
    display inline
    input
      inputReset()
      padding 8px 10px
      background #fff
      font-size 1.6rem
      height 40px
    &--mini
      input
        display inline-block
        width 100px
    &--medium
      input
        display inline-block
        width 300px
        +mediaSp()
          width 100%
    &--full
      width 100%
      input
        display block
        width 100%
    .field_with_errors
      vertical-align middle
      input
        border 1px solid border-vivid-red
  &__textarea
    textarea
      inputReset()
      padding 8px 10px
      width 100%
      height 200px
      resize vertical
      font-size 1.6rem
    .field_with_errors
      display inline-block
      textarea
        border 1px solid border-vivid-red
    &--full
      width 100%
      textarea
        display block
        width 100%
      .field_with_errors
        display block
  &__checkbox
    display inline-block
    padding-right 20px
    label
      display inline-block
      cursor pointer
      position relative
      padding-left 30px
      input
        display none
      &.is-checked .icon
        inputReset()
        background #fff
        &::before
          content ""
          display block
          width 9px
          height 15px
          border-bottom 3px solid bg-green
          border-right 3px solid bg-green
          position absolute
          left 5px
          bottom 4px
          transform rotate(45deg)
          box-sizing border-box
    .icon
      position absolute
      left 0
      top 3px
      width 20px
      height 20px
      border 0
      background-color #fff
      display inline-block
      +mediaSp()
        top 3px
        margin-right 5px
    span
      vertical-align baseline
  &__radiobutton
    display inline-block
    padding-right 20px
    label
      display inline-block
      cursor pointer
      position relative
      padding-left 30px
      input
        display none
      &.is-checked .icon
        inputReset()
        background #fff
        border-radius 50%
        &::before
          content ""
          display block
          width 10px
          height 10px
          background bg-green
          border-radius 50%
          position absolute
          left 5px
          bottom 5px
    .icon
      position absolute
      left 0
      top 3px
      width 20px
      height 20px
      border-radius 50%
      border 0
      background-color #fff
      display inline-block
      +mediaSp()
        top 3px
        margin-right 5px
    span
      vertical-align baseline
  &__selectbox
    position relative
    display inline-block
    select
      inputReset()
      padding 8px 10px
      background #fff
      font-size 1.6rem
      height 40px
      appearance none
    .field_with_errors
      select
        border 1px solid border-vivid-red
    &::after
      content ""
      position absolute
      pointer-events none
      width 0
      height 0
      border-style solid
      border-width 8px 8px 0 8px
      border-color bg-green transparent transparent transparent
      top 16px
      right 10px
      +mediaSp()
        right 15px
    &--mini
      select
        width 100px
      +mediaSp()
        display block
        select
          width 100%
    &--medium
      select
        width 150px
      +mediaSp()
        display block
        select
          width 100%
    &--large
      select
        width 230px
      +mediaSp()
        display block
        select
          width 100%
