.m__top
  &__mv
    position fixed
    top 0
    left 0
    width 100%
    height 100vh
    z-index 1
    video
      position absolute
      left 50%
      top 50%
      transform translate(-50%, -50%)
      min-height 100vh
      min-width 100%
      width auto
      height auto
    .mv-slider-sp
      +mediaSp()
        position relative
        overflow hidden
        height 100vh
        width 100%
        li
          width 100%
          height 100vh
          opacity 0
          width 100%
          height 680px
          background-position center center
          background-repeat no-repeat
          background-size cover
          position absolute
          left 0
          top 0
          -webkit-animation anime 30s 0s infinite
          animation anime 30s 0s infinite
          &.carousel1
            background-image url(image_path + '/school/koyama.jpg')
          &.carousel2
            background-image url(image_path + '/school/audi_y_f.jpg')
            -webkit-animation-delay 6s
            animation-delay 6s
          &.carousel3
            background-image url(image_path + '/school/f_lobby_01.jpg')
            -webkit-animation-delay 12s
            animation-delay 12s
          &.carousel4
            background-image url(image_path + '/school/y_bus.jpg')
            -webkit-animation-delay 18s
            animation-delay 18s
          &.carousel5
            background-image url(image_path + '/school/course_a.jpg')
            -webkit-animation-delay 24s
            animation-delay 24s
        @keyframes anime
          0%
            opacity 0
          8%
            opacity 1
          17%
            opacity 1
          25%
            opacity 0
            transform scale(1.2)
            z-index 9
          100%
            opacity 0
  &__title
    position absolute
    top 50vh
    left 75px
    z-index 2
    transform translateY(-50%)
    +mediaSp()
      left 50%
      transform translate(-50%, -50%)
    img
      width 515px
      opacity 0
      transform translateX(-50px)
      transition 1.5s
      +mediaSp()
        width 300px
        transform translateX(-30px)
    &.show
      img
        opacity 1
        transform translateX(0)

  &__main
    position relative
    z-index 3
    background #fff
    margin-top 100vh
    +mediaSp()
      margin-top calc(100vh - 55px)
    .m__top__title
      top -50vh
    .m__common__breadcrumb.pc
      margin-top 0
  &__information
    padding-top 140px
    padding-bottom 90px
    text-align center
    +mediaSp()
      padding-top 45px
      padding-bottom 10px
      &.bottom
        padding-top 65px
  &__banner-carousel
    overflow hidden
    padding-bottom 20px
    ul
      width 1015px
      margin 0 auto
      li
        img
          max-width 100%
      +mediaSp()
        width 100%
    .slick-list
      overflow visible
      .slick-slide
        margin 0 5px
    .slick-prev
      font-size 0
      width 12px
      height 18px
      border none
      background url(image_path + '/common/icon_arrow_left.svg') center center no-repeat
      background-size 12px 18px
      position absolute
      left 150px
      bottom 0
      padding 0
      &:hover
        cursor pointer
      +mediaSp()
        width 6px
        height 9px
        background-size 6px 9px
        left 20px
    .slick-next
      font-size 0
      width 12px
      height 18px
      border none
      background url(image_path + '/common/icon_arrow_right.svg') center center no-repeat
      background-size 12px 18px
      position absolute
      right 150px
      bottom 0
      padding 0
      &:hover
        cursor pointer
      +mediaSp()
        width 6px
        height 9px
        background-size 6px 9px
        right 20px
    .slick-dots
      display flex
      justify-content center
      align-items center
      width 600px
      margin 20px auto 0
      +mediaSp()
        width 100%
        margin-top 10px
      li
        margin 0 7px
        button
          font-size 0
          padding 0
          background bg-gray
          width 10px
          height 10px
          border none
          border-radius 50%
          &:hover
            cursor pointer
        &.slick-active
          button
            background bg-light-green
  &__news
    position relative
    h2
      text-align center
    > .m__common__flex-arrow
      position absolute
      right 0
      top 20px
      +mediaSp()
        font-size 1.4rem
        top 6px
    ul
      border-top 1px solid border-black
      +mediaSp()
        margin 0 -10px
      li
        a
          padding 20px
          border-bottom 1px solid border-black
          display flex
          font-size 1.6rem
          text-decoration none
          align-items baseline
          +mediaSp()
            display block
            padding 10px
          .date
            color font-black
            +mediaPc()
              width 120px
            +mediaSp()
              font-size 1.2rem
          .title
            flex 1
            text-decoration underline
            +mediaSp()
              font-size 1.3rem
              display inline
          .label
            display flex
            align-items center
            +mediaSp()
              display inline-block
              vertical-align 0
              line-height 1
            span
              color #fff
              font-size 1.2rem
              padding 0 6px
              margin-right 10px
              background-color bg-shakujii
              +mediaPc()
                line-height 1.4
              +mediaSp()
                margin-right 5px
                font-size 0.9rem
              &.new
                background-color #66cccc
              &.attention
                background-color #f09950
                letter-spacing 2px
              &.important
                background-color #f08066
                letter-spacing 2px
              &.etc
                background-color #99b0e3
              &:last-of-type
                margin-right 15px
                +mediaSp()
                  margin-right 7px
  &__school
    background bg-light-gray
    position relative
    margin-top 150px
    opacity 0
    transition 1.2s
    transform translateY(-60px)
    &.not-fadein
      opacity 1
    +mediaSp()
      transform translateY(-30px)
      margin-top 100px
    &.fade-in
      opacity 1
      transition 1.2s
      transform translateY(0)
    .image
      height calc((100vw * 0.6135) - 15vw)
      position relative
      +mediaSp()
        height calc(100vw * 0.6135)
    .l__contents__fade-in
      .m__common__flex-arrow
        &::before, &::after
          opacity 0
          transition width .3s, right .3s, opacity .8s 1.5s
      .license-list, .subject
        li
          opacity 0
          transform translateY(-20px)
      &.fade-in
        .m__common__flex-arrow
          &::before, &::after
            opacity 1
            transition width .3s, right .3s, opacity .8s 1.5s
        .license-list, .subject
          li
            white-space nowrap
            opacity 1
            transform translateY(0)
            for i in 0..10
              &:nth-child({i*1})
                transition opacity .3s (0.15*i)s, transform .3s (0.15*i)s

    .school-name
      width 100%
      padding 20px 0
      +mediaSp()
        position absolute
        top 0
        left 0
      .l__contents__fade-in
        transform translateY(0)
      .name
        width 1015px
        margin 0 auto
        display flex
        justify-content space-between
        align-items center
        .m__common__flex-arrow
          .m__common-heading__alphabet
            bold()
            font-size 3.2rem
            display inline-block
            vertical-align baseline
          .alphabet
            font-size 2.4rem
            vertical-align baseline
            padding-left 20px
      +mediaSp()
        bottom auto
        top -60px
        padding 0
        .name
          width 100%
          .m__common__flex-arrow
            width 100%
            padding 20px
            &:hover
              &::before, &::after
                background font-black
            &::before
              right auto
              left 290px
              bottom 30px
            &::after
              right auto
              left 310px
              bottom 29px
            .m__common-heading__alphabet
              font-size 1.8rem
            .alphabet
              font-size 1.6rem
          .access
            display none
    .contents
      background bg-light-gray
      padding 65px 0 45px
      +mediaSp()
        padding 20px 0 10px
    .license-list
      display flex
      justify-content center
      width 100%
      li
        width 100px
        height 100px
        display flex
        align-items center
        justify-content center
        border-radius 10px
        margin 0 5px
        position relative
        overflow hidden
        &::before
          content ""
          display block
          width 100%
          height 0
          position absolute
          left 0
          bottom 0
          z-index 1
          opacity 1
          transition height .2s
          +mediaSp()
            transition 0s
        &:hover
          +mediaPc()
            &::before
              height 100%
              transition height .2s
        &:hover
          +mediaSp()
            &::before
              height 100%
              transition 0s
        .inner-box
          display flex
          justify-content center
          align-items center
          flex-wrap wrap
          position relative
          z-index 2
          color font-black
          text-decoration none
          &:hover
            opacity 1
          .img
            display flex
            margin 0 auto
            width 100px
            height 50px
            align-items flex-end
            justify-content center
            +mediaSp()
              width 90%
              height calc(((100vw - 30px) / 4) / 2)
              max-height 50px
              img
                max-width 100%
                max-height 90%
          p
            width 100%
            line-height 1.2
            text-align center
            display flex
            justify-content center
            align-items center
            height 32px
            margin-top 5px
            font-size 1.4rem
            bold()
            +mediaSp()
              font-size 1.2rem
              height 28px

      +mediaSp()
        flex-wrap wrap
        justify-content flex-start
        li
          width calc((100vw - 30px) / 4)
          height calc((100vw - 30px) / 4)
          border-radius 5px
          &:first-child, &:nth-child(5n)
            margin-left 0
          &:nth-child(4n)
            margin-right 0
          &:nth-child(n+5)
            margin-top 10px

    .subject
      display flex
      align-items center
      justify-content center
      margin-top 30px
      &--two-row
        width 940px
        flex-wrap wrap
        margin 30px auto 0
        +mediaSp()
          width auto
          flex-wrap no-wrap
      li
        margin-right 20px
        font-size 1.6rem
        &.link
          &::before
            content "▶"
            font-size 1rem
            vertical-align middle
            padding-right 5px
            color font-green
        +mediaSp()
          margin-right 17px
        &:last-child
          margin-right 0
        a
          &:hover
            color font-green
        span
          &::before
            content "・"
      +mediaSp()
        flex-wrap wrap
        justify-content flex-start
        padding 0 10px
        margin-top 10px
        li
          font-size 1.3rem
      & + .subject
        margin-top: 15px
        +mediaSp()
          margin-top: 2px
    .sp-access
      +mediaPc()
        display none
      display flex
      li
        text-align center
        width 50%
        a
          color font-black
          font-size 1.6rem
          padding 16px 0
          text-decoration none
          display block
        + li
          border-left 1px solid #fff

    .flex-box
      display flex
      justify-content center
      align-items center
      +mediaSp()
        display block
        margin-bottom 0
      .license-list
        width auto
      .subject
        width 415px
        flex-wrap wrap
        margin-top 0
        margin-left 30px
        justify-content flex-start
        li
          margin 5px 20px 5px 0
        +mediaSp()
          width 100%
          margin-left 0
          margin-top 10px
          li
            margin 0 20px 0 0
    &--futako
      .image
        background url(image_path + '/school/futako.jpg') center 0 no-repeat
        background-size 100% auto
      .school-name
        background bg-futako
        +mediaSp()
          background transparent
      .m__common__flex-arrow
        +mediaSp()
          background bg-futako
          &:hover
            background bg-light-futako
          &:hover
            color font-black
      .license-list
        li
          background bg-futako
          &::before
            background bg-light-futako
      .sp-access
        li
          background bg-futako
    &--seijo
      .image
        background url(image_path + '/school/seijo.jpg') center 0 no-repeat
        background-size 100% auto
        +mediaSp()
          background-size 120% auto
      .school-name
        background bg-seijo
        +mediaSp()
          background transparent
      .m__common__flex-arrow
        +mediaSp()
          background bg-seijo
          &:hover
            background bg-light-seijo
          &:hover
            color font-black
      .license-list
        li
          background bg-seijo
          &::before
            background bg-light-seijo
      .sp-access
        li
          background bg-seijo
    &--shakujii
      .image
        background url(image_path + '/school/shakujii.jpg') center 0 no-repeat
        background-size 100% auto
      .school-name
        background bg-shakujii
        +mediaSp()
          background transparent
      .m__common__flex-arrow
        +mediaSp()
          background bg-shakujii
          &:hover
            background bg-light-shakujii
          &:hover
            color font-black
      .license-list
        li
          background bg-shakujii
          &::before
            background bg-light-shakujii
      .sp-access
        li
          background bg-shakujii
    &--akitsu
      .image
        background url(image_path + '/school/akitsu.jpg') center 0 no-repeat
        background-size 100% auto
      .school-name
        background bg-akitsu
        +mediaSp()
          background transparent
      .m__common__flex-arrow
        +mediaSp()
          background bg-akitsu
          &:hover
            background bg-light-akitsu
          &:hover
            color font-black
      .license-list
        li
          background bg-akitsu
          &::before
            background bg-light-akitsu
      .sp-access
        li
          background bg-akitsu
    &--yokohama
      .image
        background url(image_path + '/school/yokohama.jpg') center 0 no-repeat
        background-size 100% auto
      .school-name
        background bg-yokohama
        +mediaSp()
          background transparent
      .m__common__flex-arrow
        +mediaSp()
          background bg-yokohama
          &:hover
            background bg-light-yokohama
          &:hover
            color font-black
      .license-list
        li
          background bg-yokohama
          &::before
            background bg-light-yokohama
      .sp-access
        li
          background bg-yokohama

  &__scroll
    position absolute
    left 30px
    top -140px
    z-index 10
    &.top
      top calc(100vh - 200px)
      +mediaSp()
        left 10px
        top calc(60vh)
        a
          font-size 1.6rem
          &::before
            left 25px
            animation arrow-animation1 3s ease 0s infinite normal
            bottom -65px
          &::after
            left 29px
            animation arrow-animation2 3s ease 0s infinite normal
            bottom -65px
    +mediaSp()
      left 0px
      top calc(78vw)
    a
      color #fff
      text-decoration none
      position relative
      font-size 1.6rem
      text-shadow 0 0 10px #000
      display inline-block
      +mediaSp()
        font-size 1.3rem
      span
        transform rotate(90deg)
        display inline-block
      &::before, &::after
        content ""
        background #fff
        position absolute
        box-shadow 0 0 10px #000
      &::before
        width 1px
        height 40px
        left 22px
        bottom -65px
        animation arrow-animation1 3s ease 0s infinite normal
        +mediaSp()
          left 18px
          height 20px
          animation arrow-animation3 3s ease 0s infinite normal
          bottom -45px
      &::after
        width 1px
        height 10px
        bottom -65px
        left 26px
        transform rotate(45deg)
        animation arrow-animation2 3s ease 0s infinite normal
        +mediaSp()
          left 22px
          bottom -45px
          animation arrow-animation4 3s ease 0s infinite normal

@keyframes arrow-animation1
  0%
    height 40px
    bottom -65px
  10%
    height 40px
    bottom -65px
  35%
    height 60px
    bottom -85px
  65%
    height 60px
    bottom -85px
  90%
    height 40px
    bottom -65px
  100%
    height 40px
    bottom -65px

@keyframes arrow-animation2
  0%
    bottom -65px
  10%
    bottom -65px
  35%
    bottom -85px
  65%
    bottom -85px
  90%
    bottom -65px
  100%
    bottom -65px

@keyframes arrow-animation3
  0%
    height 20px
    bottom -45px
  10%
    height 20px
    bottom -45px
  35%
    height 40px
    bottom -65px
  65%
    height 40px
    bottom -65px
  90%
    height 20px
    bottom -45px
  100%
    height 20px
    bottom -45px

@keyframes arrow-animation4
  0%
    bottom -45px
  10%
    bottom -45px
  35%
    bottom -65px
  65%
    bottom -65px
  90%
    bottom -45px
  100%
    bottom -45px
