.m__plan-stay
  &__txtinfoArea
    padding 50px 0
    background #333
    color #fff
    text-align center
    font-size 2.6rem
    +mediaSp()
      margin 30px 0 0
      padding 15px 10px
      font-size 1.3rem
      text-align left
    span
      font-size 3.2rem
      display block
      +mediaSp()
        font-size 1.6rem
        text-align center
  &__table__Area
    width 750px
    margin 0 auto
    +mediaSp()
      width 100%
    .txt__sub__h2
      text-align center
      font-size 1.6rem
      padding-bottom 50px
      +mediaSp()
        padding-bottom 20px
        font-size 1.3rem
    .ttlTable
      font-size 1.8rem
      width 750px
      margin 0 auto
      +mediaSp()
        width 100%
        font-size 1.3rem
      &:before
        content '■'
        color #66BB99
    .m__table
      width 750px
      margin 0 auto
      font-size 1.8rem
      border 2px solid #1a1a1a
      +mediaSp()
        width 100%
        font-size 1.3rem
        border 1px solid #1a1a1a
        border-left none
        border-right none
      th
        background #ccc
        vertical-align middle
        padding 20px 0
        border 1px solid #1a1a1a
        +mediaSp()
          border-left none
          padding 5px 0
          &:last-child
            border-right none
        span
          display inline-block
          text-align left
          &.pl15
            padding-left 15px
            +mediaSp()
              padding-left 0
      td
        border-top 1px solid #333
        vertical-align middle
        text-align left
        padding-left 30px
        &:not(:first-child)
          border-left 1px solid #333
        &.ttlCarType
          background #F0F0F0
          text-align center
          padding 15px 30px
          +mediaSp()
            width 12%
            padding 15px 0
      &--stay
        margin-top 60px
        +mediaSp()
          margin-top 30px
        th
          background #F0F0F0
    .txt__sub__table
      font-size 1.6rem
      width 750px
      margin 0 auto
      li
        text-indent -1em
        padding-left 1em
      +mediaSp()
        width 100%
        font-size 1.3rem
        padding 0 10px
        text-indent
      &--pt30
        padding-top 30px
      &--txtR
        text-align right
    &--gray
      background #F0F0F0
      width 100%
      padding-bottom 50px
      +mediaSp()
        padding 30px 0
      .m__table
        td
          background #fff
          text-align center
          padding-left 0
  &__notice__Area
    margin 0 auto
    font-size 1.6rem
    padding-bottom 100px
    +mediaSp()
      padding 30px 0
      font-size 1.3rem
    ul
      li
        padding-left 1em
        text-indent -1em
  &__hotel__Area
    padding-bottom 100px
    +mediaSp()
      padding-bottom 30px
    dl
      display flex
      justify-content space-between
      font-size 1.6rem
      border-bottom 1px solid #ccc
      padding 30px 0
      +mediaSp()
        display block
        padding 0
        font-size 1.3rem
        margin-bottom 15px
      dt
        span
          font-size 2.6rem
          color #3B8E6B
          vertical-align baseline
          +mediaSp()
            font-size 1.5rem
      dd
        img
          +mediaSp()
            width 100%
            padding 15px 0
    .txt__sub__list
      font-size 2.6rem
      color #F08066
      padding-top 30px
      +mediaSp()
        font-size 1.5rem
        padding-top 0
  &__voice__Area
    padding-bottom 100px
    +mediaSp()
      padding-bottom 30px
    ul
      display flex
      +mediaSp()
        display block
      li
        padding-left 60px
        +mediaSp()
          padding-left 0
        p
          font-size 1.6rem
          +mediaSp()
            font-size 1.3rem
          span
            font-size 2.6rem
            color #3B8E6B
            display block
            +mediaSp()
              font-size 1.5rem
        &:first-child
          border-right 1px solid #ccc
          padding-right 60px
          padding-left 0
          +mediaSp()
            padding 0 0 15px 0
            border-right none
            border-bottom  1px solid #ccc
            margin-bottom 15px
  &__plan__apply__Area
    padding 50px 0 130px
    +mediaSp()
      padding 50px 0
    .m__common-btn__arrow
      margin-top 50px
      +mediaSp()
        margin-top 30px
    a.m__common__flex-arrow
      width 320px
      padding 16px 80px 16px 50px
      +mediaSp()
        width 100%
      &::before
        right 107px
        +mediaSp()
          right auto
          left 50%
          margin-left 22px
      &::after
        right 109px
        +mediaSp()
          right auto
          left 50%
          margin-left 42px
      +mediaPc()
        &:hover
          &::before
            right 97px
          &::after
            right 99px
      +mediaSp()
        &:active
          background bg-green02
          transition .3s
          &::before
            width 24px
          &::after
            margin-left 42px