.m__plan
  &__tableListArea
    font-size 1.8rem
    width 700px
    margin 100px auto 150px
    +mediaSp()
      width 100%
      font-size 1.3rem
      margin 30px auto 60px
    table
      border 2px solid #1a1a1a
      margin 0 auto
      +mediaSp()
        border-left none
        border-right none
        width 100%
      tr
        width 90px
        &.headTtl
          th
            height 135px
            line-height 1
            +mediaSp()
              height auto
            &:nth-child(even)
              background #efffff
            &:nth-child(1)
              background #fff
            &:not(:first-child)
              border-left 1px solid #1a1a1a
            &.letter3
              line-height 2em
            &.letter4
              line-height 1.35em
      &.bg--white
        background #fff
      th
        vertical-align middle
        padding 10px
        font-weight 500
      td
        width 90px
        height 60px
        font-size 2.6rem
        vertical-align middle
        text-align center
        border-top 1px solid #333
        +mediaSp()
          font-size 1.3rem
          height auto
          width auto
        &:not(:first-child)
          border-left 1px solid #333
        &.carType
          width 210px
          text-align left
          vertical-align middle
          padding: 10px
          padding-left 20px
          font-weight: 500
          font-size 1.8rem
          +mediaSp()
            font-size 1.3rem
            width 40%
            padding 10px
        span
          a
            text-decoration none
            +mediaSp()
              text-decoration underline
        &:nth-child(even)
          background #efffff